import React,{Component} from 'react';
import { Tab, Nav } from 'react-bootstrap';
import dateFormat from 'dateformat';
import { getUserLogs } from '../../config/services/usersService'
import TabTitle from './TabTitle'
class Logs extends Component{
    constructor(props) {
        super(props);

        this.state = {
            userLogs:[]
        }   
    }

    getUserLogs = () => {
        const { userId }  = this.state;
        let paramsObj = {
            params: { notesOfUserId: userId }
        }
        getUserLogs(paramsObj)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.setState({ 
                        userLogs: response.responseData.result, 
                        loader: false 
                    })
                } else if (response.statusCode == 0) {
                    // cogoToast.error(response.error.errorMessage);
                }
            })
    }

    componentDidMount() {
        const { id  } = this.props;
        this.setState({ userId: id }, ()=>{
            this.getUserLogs();
        } )     
    }
    render(){
        const { userLogs } = this.state
        return (
            <div className="body-container cm_profile_wrapper cm_profile_wrapper_bg">
             <TabTitle title="Logs" />
            <div className="cm_left_tabs">
                <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                        <Nav.Link eventKey="first"><i className="fa fa-cutlery mr-1 side_icons" /> Diet logs</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="second"> <i className="fa fa-heartbeat mr-1 side_icons" />Fitness logs</Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
            <div className="cm_right_tabs">
                <Tab.Content>
                    <Tab.Pane eventKey="first">
                        <ul className="cm_questions p-0">
                            {userLogs.filter(notes => notes.noteType === 1).map((notes, i) => (
                                <div className="alert alert-dark log_box" key={i}>
                                    <h4 className="alert-heading">{notes.note}</h4>
                                    <div className="row mt-3">
                                        <div className="col-md-6">
                                            <div className="usr_log">Recorded by: {notes.recordedBy.email}</div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-0 usr_log text-right">Recorded on: {dateFormat(notes.created, "d mmmm yyyy")}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                        <ul className="cm_questions">
                            {userLogs.filter(notes => notes.noteType === 2).map((notes, i) => (
                                <div className="alert alert-dark log_box" key={i}>
                                    <h4 className="alert-heading">{notes.note}</h4>
                                    <p className="usr_log">Recorded by: {notes.recordedBy.email}</p>
                                    <p className="mb-0 usr_log">Recorded on: {dateFormat(notes.created, "d mmmm yyyy")}</p>
                                </div>))}
                        </ul>
                    </Tab.Pane>
                   
                </Tab.Content>
            </div>
            </div>                                
    );
    }
}

export default Logs;
import axios from 'axios';
import API from '../Api';

const accessToken = localStorage.getItem('accessToken');

// export function getUserList(params) {
//     return axios({
//         method: 'get',
//         url: API.GET_APP_USERS + params,
//         headers: {
//             accessToken: accessToken,
//             Authorization: API.AUTH,
//         }
//     })
// }


export function getUserList(params) {
    return axios.get(API.GET_APP_USERS, { params })
}

// export function getUser(params) {
//     return axios({
//         method: 'get',
//         url: API.GET_USER + '/' + params,
//         headers: {
//             accessToken: accessToken,
//             Authorization: API.AUTH,
//         }
//     })
// }

export function getUser(params) {
    return axios({
        method: 'get',
        url: API.GET_USER + '/' + params,
        headers: {
            accessToken: accessToken,
            Authorization: API.AUTH,
        }
    })
    //return axios.get(API.GET_USER, params)
}

export function userChangeStatus(params) {
    return axios.post(API.USER_CHANGE_STATUS, params)
}


export function getTotalUsersCount() {
    return axios({
        method: 'get',
        url: API.GET_TOTAL_USERS,
        headers: {
            accessToken: accessToken,
            Authorization: API.AUTH,

        }
    })
}


export function getAllDataCount(params) {
    return axios.get(API.GET_ALL_DATA_COUNT, params)
}


export function checkSession(params) {
    return axios.post(API.CHECK_SESSION, params)
}


export function userStats(params) {
    return axios({
        method: 'post',
        url: API.USER_STATS,
        data: params,
    })
}

export function getUserTarget(params) {
    return axios({
        method: 'post',
        url: API.GET_USER_TARGET,
        data: params,
    })
}

export function getSelectedTimeSlots(params) {
    return axios.get(API.GET_SELECTED_TIME_SLOTS, params)
}

export function assignPolicies(params) {
    return axios.post(API.ASSIGN_POLICIES, params)
}


export function waterIntakeGraph(params) {
    return axios.post(API.WATER_INTAKE_GRAPH, params)
}

export function stepsGraph(params) {
    return axios.post(API.STEPS_GRAPH, params)
}

export function heartRateGraph(params) {
    return axios.post(API.HEART_RATE_GRAPH, params)
}

export function caloriesGraph(params) {
    return axios.post(API.CALORIES_GRAPH, params)
}

export function sleepGraph(params) {
    return axios.post(API.SLEEP_GRAPH, params)
}

export function weightGraph(params) {
    return axios.post(API.WEIGHT_GRAPH, params)
}

export function updateKyc(params) {
    return axios.post(API.UPDATE_KYC, params)
}

export function assignDieticianToMultipleUsers(params) {
    return axios.post(API.ASSIGN_DIETICIAN_TO_MULTIPLE_USERS, params)
}

export function assignCouponToMultipleUsers(params) {
    return axios.post(API.ASSIGN_COUPON_TO_MULTIPLE_USERS, params)
}

export function getUserLogs(params) {
    return axios.get(API.GET_USER_LOGS, params)
}

export function getUserWithTrainerNotes(params) {
    return axios.get(API.GET_USER_WITH_TRAINER_LOGS, params)
}

export function getUsersMessages(params) {
    return axios.get(API.GET_USER_MESSAGES_ADMIN,params)
}

export function sendOtp(params) {
    return axios.post(API.SEND_OTP,params)
}

export function exportUserRequest(params) {
    return axios({
        method: 'post',
        url: API.GET_EXPORT_USERS,
        responseType: 'blob',
        data: params
    })
}

export function getConfigs(params) {
    return axios.get(API.GET_CONFIGS, params);
}

export function getLeaderboardConfig(params) {
    return axios.post(API.GET_LEADERBOARD_CONFIG, params);
}

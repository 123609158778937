import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { editDietPlan } from '../../config/services/dietPlanService';
import { addDays } from 'date-fns';
import { perDayCaloriesCount } from '../constants/perDayCalories';
import { calorieBreakDown } from './Calculations';
import { toast } from 'react-toastify';

class EditDietPlan extends Component {

    constructor(props) {
        super(props);

        this.state = {
            morningSnacksDiet: [],
            breakfastDiet: [],
            dietPlanDetails: {},
            lunchDiet: [],
            dinnerDiet: [],
            morningSnacksDietCalorie: 0,
            updateDisabled: true,

            validFrom: new Date(new Date().setHours(0, 0, 0, 0)),
            validTo: addDays(new Date(new Date().setHours(23, 59, 59, 0)), 1),
            morningSnacksListing: [],
            breakfastListing: [],
            lunchListing: [],
            dinnerListing: [],
            midMorningDietListing: [],
            midEveningDietListing: [],
            bedTimeDietListing: [],

            dietPlanDetails: [],

            breakfastDietCalorie: 0,
            lunchDietCalorie: 0,
            dinnerDietCalorie: 0,
            midMorningDietCalorie: 0,
            midEveningDietCalorie: 0,
            bedTimeDietCalorie: 0,

            morningSnacksDietTime: "00:00",
            breakfastDietTime: "00:00",
            midMorningDietTime: "00:00",
            lunchDietTime: "00:00",
            midEveningDietTime: "00:00",
            dinnerDietTime: "00:00",
            bedTimeDietTime: "00:00",

            recommendedMorningSnacksCalories: 0,
            recommendedBreakfastCalories: 0,
            recommendedMidMorningCalories: 0,
            recommendedLunchCalories: 0,
            recommendedMidEveningCalories: 0,
            recommendedDinnerCalories: 0,
            recommendedBedTimeCalories: 0,
        }
    }


    validFromChange = date => this.setState({ updateDisabled: false, validFrom: new Date(new Date(date).setHours(0, 0, 0, 0)) });

    validToChange = date => this.setState({ updateDisabled: false, validTo: new Date(new Date(date).setHours(23, 59, 59, 0)) });



    removeOption = (fieldName, index) => {
        let { morningSnacksListing, breakfastListing, midMorningDietListing, lunchListing, midEveningDietListing, dinnerListing, bedTimeDietListing } = this.state
        this.setState({ updateDisabled: false })

        switch (fieldName) {
            case 'morningSnack':
                if (morningSnacksListing.length == 1) {
                    toast.error("Morning snacks should contain atleast one option and one food item")
                } else {
                    morningSnacksListing.splice(index, 1);
                    this.setState({ morningSnacksListing })
                }
                break;
            case 'breakfast':
                if (breakfastListing.length == 1) {
                    toast.error("Brekfast should contain atleast one option and one food item")
                } else {
                    breakfastListing.splice(index, 1);
                    this.setState({ breakfastListing })
                }
                break;
            case 'midMorning':
                midMorningDietListing.splice(index, 1);
                this.setState({ midMorningDietListing })
                break;
            case 'lunch':
                if (lunchListing.length == 1) {
                    toast.error("Lunch should contain atleast one option and one food item")
                } else {
                    lunchListing.splice(index, 1);
                    this.setState({ lunchListing })
                }
                break;
            case 'midEvening':
                midEveningDietListing.splice(index, 1);
                this.setState({ midEveningDietListing })
                break;
            case 'dinner':
                if (dinnerListing.length == 1) {
                    toast.error("Dinner should contain atleast one option and one food item")
                } else {
                    dinnerListing.splice(index, 1);
                    this.setState({ dinnerListing })
                }
                break;
            case 'bedTime':
                bedTimeDietListing.splice(index, 1);
                this.setState({ bedTimeDietListing })
                break;

        }
    }

    onFoodChange = (event, data, index, optionsIndex) => {
        this.setState({ updateDisabled: false })

        switch (event) {
            case "morningSnack":
                const morningSnacksListing = Array.from(this.state.morningSnacksListing);
                morningSnacksListing[optionsIndex].morningSnacksDiet[index] = {
                    foodId: data.value,
                    label: data.label,
                    calorieContent: data.calorie,
                    unit: data.foodUnit,
                    protein: data.protein,
                    fat: data.fat,
                    carb: data.carb,
                    fibre: data.fibre,
                    foodUnit: data.foodUnit,
                    quantity: 1
                };

                this.setState({ morningSnacksListing }, () => this.calculateCaloriesAssigned('morningSnack', optionsIndex))
                break;

            case "breakfast":
                const breakfastListing = Array.from(this.state.breakfastListing);

                breakfastListing[optionsIndex].breakfastDiet[index] = {
                    foodId: data.value, label: data.label, calorieContent: data.calorie, unit: data.foodUnit,
                    protein: data.protein,
                    fat: data.fat,
                    carb: data.carb,
                    fibre: data.fibre,
                    foodUnit: data.foodUnit,
                    quantity: 1
                };

                this.setState({ breakfastListing }, () => this.calculateCaloriesAssigned('breakfast', optionsIndex))
                break;

            case "midMorning":
                const midMorningDietListing = Array.from(this.state.midMorningDietListing);

                midMorningDietListing[optionsIndex].midMorningDiet[index] = {
                    foodId: data.value, label: data.label, calorieContent: data.calorie, unit: data.foodUnit,
                    protein: data.protein,
                    fat: data.fat,
                    carb: data.carb,
                    fibre: data.fibre,
                    foodUnit: data.foodUnit,
                    quantity: 1
                };

                this.setState({ midMorningDietListing }, () => this.calculateCaloriesAssigned('midMorning', optionsIndex));
                break;

            case "lunch":
                const lunchListing = Array.from(this.state.lunchListing);
                lunchListing[optionsIndex].lunchDiet[index] = {
                    ...this.state.lunchListing[index], foodId: data.value, label: data.label, calorieContent: data.calorie, unit: data.foodUnit,
                    protein: data.protein,
                    fat: data.fat,
                    carb: data.carb,
                    fibre: data.fibre,
                    foodUnit: data.foodUnit,
                    quantity: 1
                };
                this.setState({ lunchListing }, () => this.calculateCaloriesAssigned('lunch', optionsIndex));
                break;


            case "midEvening":
                const midEveningDietListing = Array.from(this.state.midEveningDietListing);
                midEveningDietListing[optionsIndex].midEveningDiet[index] = {
                    foodId: data.value,
                    label: data.label,
                    calorieContent: data.calorie,
                    unit: data.foodUnit,
                    protein: data.protein,
                    fat: data.fat,
                    carb: data.carb,
                    fibre: data.fibre,
                    foodUnit: data.foodUnit,
                    quantity: 1
                };
                this.setState({ midEveningDietListing }, () => this.calculateCaloriesAssigned('midEvening', optionsIndex))
                break;

            case "dinner":
                const dinnerListing = Array.from(this.state.dinnerListing);
                dinnerListing[optionsIndex].dinnerDiet[index] = {
                    foodId: data.value, label: data.label, calorieContent: data.calorie, unit: data.foodUnit,
                    protein: data.protein,
                    fat: data.fat,
                    carb: data.carb,
                    fibre: data.fibre,
                    foodUnit: data.foodUnit,
                    quantity: 1
                };

                this.setState({ dinnerListing }, () => this.calculateCaloriesAssigned('dinner', optionsIndex));
                break;


            case "bedTime":
                const bedTimeDietListing = Array.from(this.state.bedTimeDietListing);
                bedTimeDietListing[optionsIndex].bedTimeDiet[index] = {
                    foodId: data.value, label: data.label, calorieContent: data.calorie, unit: data.foodUnit,
                    protein: data.protein,
                    fat: data.fat,
                    carb: data.carb,
                    fibre: data.fibre,
                    foodUnit: data.foodUnit,
                    quantity: 1
                };
                this.setState({ bedTimeDietListing }, () => this.calculateCaloriesAssigned('bedTime', optionsIndex));
                break;
        }
    }

    onUnitChange = (event, data, index) => {
        this.setState({ updateDisabled: false })

        switch (event) {
            case "morningSnack":
                const morningSnacksListing = Array.from(this.state.morningSnacksListing);
                morningSnacksListing[index] = { ...this.state.morningSnacksListing[index], unit: data.target.value };
                this.setState({ morningSnacksListing })

                break;

            case "breakfast":
                const breakfastListing = Array.from(this.state.breakfastListing);
                breakfastListing[index] = { ...this.state.breakfastListing[index], unit: data.target.value };
                this.setState({ breakfastListing });
                break;

            case "midMorning":
                const midMorningDietListing = Array.from(this.state.midMorningDietListing);
                midMorningDietListing[index] = { ...this.state.midMorningDietListing[index], unit: data.target.value };
                this.setState({ midMorningDietListing });
                break;

            case "lunch":
                const lunchListing = Array.from(this.state.lunchListing);
                lunchListing[index] = { ...this.state.lunchListing[index], unit: data.target.value };
                this.setState({ lunchListing });
                break;

            case "midEvening":
                const midEveningDietListing = Array.from(this.state.midEveningDietListing);
                midEveningDietListing[index] = { ...this.state.midEveningDietListing[index], unit: data.target.value };
                this.setState({ midEveningDietListing });
                break;

            case "dinner":
                const dinnerListing = Array.from(this.state.dinnerListing);
                dinnerListing[index] = { ...this.state.dinnerListing[index], unit: data.target.value };
                this.setState({ dinnerListing });
                break;

            case "bedTime":
                const bedTimeDietListing = Array.from(this.state.bedTimeDietListing);
                bedTimeDietListing[index] = { ...this.state.bedTimeDietListing[index], unit: data.target.value };
                this.setState({ bedTimeDietListing });
                break;
        }
    }

    onQtyChange = (event, data, index, optionsIndex) => {
        this.setState({ updateDisabled: false })

        switch (event) {
            case "morningSnack":
                const morningSnacksListing = Array.from(this.state.morningSnacksListing);
                morningSnacksListing[optionsIndex].morningSnacksDiet[index] = { ...this.state.morningSnacksListing[optionsIndex].morningSnacksDiet[index], quantity: data.target.value };

                this.setState({ morningSnacksListing }, () => this.calculateCaloriesAssigned('morningSnack', optionsIndex))
                break;

            case "breakfast":
                const breakfastListing = Array.from(this.state.breakfastListing);

                breakfastListing[optionsIndex].breakfastDiet[index] = { ...this.state.breakfastListing[optionsIndex].breakfastDiet[index], quantity: data.target.value };

                this.setState({ breakfastListing }, () => this.calculateCaloriesAssigned('breakfast', optionsIndex));
                break;

            case "midMorning":
                const midMorningDietListing = Array.from(this.state.midMorningDietListing);

                midMorningDietListing[optionsIndex].midMorningDiet[index] = { ...this.state.midMorningDietListing[optionsIndex].midMorningDiet[index], quantity: data.target.value };

                this.setState({ midMorningDietListing }, () => this.calculateCaloriesAssigned('midMorning', optionsIndex));
                break;

            case "lunch":
                const lunchListing = Array.from(this.state.lunchListing);

                lunchListing[optionsIndex].lunchDiet[index] = { ...this.state.lunchListing[optionsIndex].lunchDiet[index], quantity: data.target.value };

                this.setState({ lunchListing }, () => this.calculateCaloriesAssigned('lunch', optionsIndex));
                break;

            case "midEvening":
                const midEveningDietListing = Array.from(this.state.midEveningDietListing);

                midEveningDietListing[optionsIndex].midEveningDiet[index] = { ...this.state.midEveningDietListing[optionsIndex].midEveningDiet[index], quantity: data.target.value };

                this.setState({ midEveningDietListing }, () => this.calculateCaloriesAssigned('midEvening', optionsIndex));
                break;

            case "dinner":
                const dinnerListing = Array.from(this.state.dinnerListing);

                dinnerListing[optionsIndex].dinnerDiet[index] = { ...this.state.dinnerListing[optionsIndex].dinnerDiet[index], quantity: data.target.value };

                this.setState({ dinnerListing }, () => this.calculateCaloriesAssigned('dinner', optionsIndex));
                break;


            case "bedTime":
                const bedTimeDietListing = Array.from(this.state.bedTimeDietListing);

                bedTimeDietListing[optionsIndex].bedTimeDiet[index] = { ...this.state.bedTimeDietListing[optionsIndex].bedTimeDiet[index], quantity: data.target.value };

                this.setState({ bedTimeDietListing }, () => this.calculateCaloriesAssigned('bedTime', optionsIndex));
                break;
        }
    }

    handleOnChange = e => this.setState({ [e.target.name]: e.target.value, updateDisabled: false });

    addMoreFood = (fieldName, e, optionsIndex) => {
        switch (fieldName) {

            case "morningSnack":
                let morningSnacksListing = Array.from(this.state.morningSnacksListing)
                morningSnacksListing[optionsIndex].morningSnacksDiet ? morningSnacksListing[optionsIndex].morningSnacksDiet.push({}) :
                    morningSnacksListing[optionsIndex] = { morningSnacksDiet: [{}] }

                this.setState({ morningSnacksListing })
                break;

            case "breakfast":

                let breakfastListing = Array.from(this.state.breakfastListing)
                breakfastListing[optionsIndex].breakfastDiet ? breakfastListing[optionsIndex].breakfastDiet.push({}) :
                    breakfastListing[optionsIndex] = { breakfastDiet: [{}] }

                this.setState({ breakfastListing })
                break;

            case "midMorning":

                let midMorningDietListing = Array.from(this.state.midMorningDietListing)
                midMorningDietListing[optionsIndex].midMorningDiet ? midMorningDietListing[optionsIndex].midMorningDiet.push({}) :
                    midMorningDietListing[optionsIndex] = { midMorningDiet: [{}] }
                this.setState({ midMorningDietListing })
                break;

            case "lunch":
                let lunchListing = Array.from(this.state.lunchListing)
                lunchListing[optionsIndex].lunchDiet ? lunchListing[optionsIndex].lunchDiet.push({}) :
                    lunchListing[optionsIndex] = { lunchDiet: [{}] }

                this.setState({ lunchListing })
                break;

            case "midEvening":
                let midEveningDietListing = Array.from(this.state.midEveningDietListing)
                midEveningDietListing[optionsIndex].midEveningDiet ? midEveningDietListing[optionsIndex].midEveningDiet.push({}) :
                    midEveningDietListing[optionsIndex] = { midEveningDiet: [{}] }
                this.setState({ midEveningDietListing })
                break;

            case "dinner":
                let dinnerListing = Array.from(this.state.dinnerListing)
                dinnerListing[optionsIndex].dinnerDiet ? dinnerListing[optionsIndex].dinnerDiet.push({}) :
                    dinnerListing[optionsIndex] = { dinnerDiet: [{}] }
                this.setState({ dinnerListing })
                break;

            case "bedTime":

                let bedTimeDietListing = Array.from(this.state.bedTimeDietListing)
                bedTimeDietListing[optionsIndex].bedTimeDiet ? bedTimeDietListing[optionsIndex].bedTimeDiet.push({}) :
                    bedTimeDietListing[optionsIndex] = { bedTimeDiet: [{}] }
                this.setState({ bedTimeDietListing })
                break;

            default: break;

        }
    }

    calculateCaloriesAssigned = (fieldName, optionsIndex) => {
        const { morningSnacksListing, breakfastListing, midMorningDietListing, lunchListing, midEveningDietListing, dinnerListing, bedTimeDietListing } = this.state

        let newCalorie;
        let newProtein;
        let newCarbs;
        let newFat;
        let newFibre;


        switch (fieldName) {
            case "morningSnack":
                newCalorie = 0;
                newProtein = 0;
                newCarbs = 0;
                newFat = 0;
                newFibre = 0;

                morningSnacksListing[optionsIndex].morningSnacksDiet.map(food => {
                    if (food) {
                        let { calorieContent, protein, fibre, fat, carb, quantity } = food;
                        newCalorie = newCalorie + ((calorieContent ? calorieContent : 0) * quantity);
                        newProtein = newProtein + ((protein ? protein : 0) * quantity);
                        newCarbs = newCarbs + ((carb ? carb : 0) * quantity);
                        newFat = newFat + ((fat ? fat : 0) * quantity);
                        newFibre = newFibre + ((fibre ? fibre : 0) * quantity);
                    }
                })

                morningSnacksListing[optionsIndex].morningSnacksDietCalorie = newCalorie.toFixed(1);
                morningSnacksListing[optionsIndex].morningSnacksDietProtein = newProtein.toFixed(1);
                morningSnacksListing[optionsIndex].morningSnacksDietCarbs = newCarbs.toFixed(1);
                morningSnacksListing[optionsIndex].morningSnacksDietFat = newFat.toFixed(1);
                morningSnacksListing[optionsIndex].morningSnacksDietFibre = newFibre.toFixed(1);

                this.setState({ morningSnacksListing })
                break;

            case "breakfast":
                newCalorie = 0;
                newProtein = 0;
                newCarbs = 0;
                newFat = 0;
                newFibre = 0;

                breakfastListing[optionsIndex].breakfastDiet.map(food => {

                    if (food) {
                        let { calorieContent, protein, fibre, fat, carb, quantity } = food;
                        newCalorie = newCalorie + ((calorieContent ? calorieContent : 0) * quantity);
                        newProtein = newProtein + ((protein ? protein : 0) * quantity);
                        newCarbs = newCarbs + ((carb ? carb : 0) * quantity);
                        newFat = newFat + ((fat ? fat : 0) * quantity);
                        newFibre = newFibre + ((fibre ? fibre : 0) * quantity);
                    }
                })

                breakfastListing[optionsIndex].breakfastDietCalorie = newCalorie.toFixed(1);
                breakfastListing[optionsIndex].breakfastDietProtein = newProtein.toFixed(1);
                breakfastListing[optionsIndex].breakfastDietCarbs = newCarbs.toFixed(1);
                breakfastListing[optionsIndex].breakfastDietFat = newFat.toFixed(1);
                breakfastListing[optionsIndex].breakfastDietFibre = newFibre.toFixed(1);

                this.setState({ breakfastListing })
                break;

            case "midMorning":
                newCalorie = 0;
                newProtein = 0;
                newCarbs = 0;
                newFat = 0;
                newFibre = 0;

                midMorningDietListing[optionsIndex].midMorningDiet.map(food => {
                    if (food) {
                        let { calorieContent, protein, fibre, fat, carb, quantity } = food;
                        newCalorie = newCalorie + ((calorieContent ? calorieContent : 0) * quantity);
                        newProtein = newProtein + ((protein ? protein : 0) * quantity);
                        newCarbs = newCarbs + ((carb ? carb : 0) * quantity);
                        newFat = newFat + ((fat ? fat : 0) * quantity);
                        newFibre = newFibre + ((fibre ? fibre : 0) * quantity);
                    }
                })

                midMorningDietListing[optionsIndex].midMorningDietCalorie = newCalorie.toFixed(1);
                midMorningDietListing[optionsIndex].midMorningDietProtein = newProtein.toFixed(1);
                midMorningDietListing[optionsIndex].midMorningDietCarbs = newCarbs.toFixed(1);
                midMorningDietListing[optionsIndex].midMorningDietFat = newFat.toFixed(1);
                midMorningDietListing[optionsIndex].midMorningDietFibre = newFibre.toFixed(1);
                this.setState({ midMorningDietListing })
                break;

            case "lunch":
                newCalorie = 0;
                newProtein = 0;
                newCarbs = 0;
                newFat = 0;
                newFibre = 0;

                lunchListing[optionsIndex].lunchDiet.map(food => {
                    if (food) {
                        let { calorieContent, protein, fibre, fat, carb, quantity } = food;
                        newCalorie = newCalorie + ((calorieContent ? calorieContent : 0) * quantity);
                        newProtein = newProtein + ((protein ? protein : 0) * quantity);
                        newCarbs = newCarbs + ((carb ? carb : 0) * quantity);
                        newFat = newFat + ((fat ? fat : 0) * quantity);
                        newFibre = newFibre + ((fibre ? fibre : 0) * quantity);
                    }
                })

                lunchListing[optionsIndex].lunchDietCalorie = newCalorie.toFixed(1);
                lunchListing[optionsIndex].lunchDietProtein = newProtein.toFixed(1);
                lunchListing[optionsIndex].lunchDietCarbs = newCarbs.toFixed(1);
                lunchListing[optionsIndex].lunchDietFat = newFat.toFixed(1);
                lunchListing[optionsIndex].lunchDietFibre = newFibre.toFixed(1);

                this.setState({ lunchListing })
                break;


            case "midEvening":

                newCalorie = 0;
                newProtein = 0;
                newCarbs = 0;
                newFat = 0;
                newFibre = 0;

                midEveningDietListing[optionsIndex].midEveningDiet.map(food => {
                    if (food) {
                        let { calorieContent, protein, fibre, fat, carb, quantity } = food;
                        newCalorie = newCalorie + ((calorieContent ? calorieContent : 0) * quantity);
                        newProtein = newProtein + ((protein ? protein : 0) * quantity);
                        newCarbs = newCarbs + ((carb ? carb : 0) * quantity);
                        newFat = newFat + ((fat ? fat : 0) * quantity);
                        newFibre = newFibre + ((fibre ? fibre : 0) * quantity);
                    }
                })

                midEveningDietListing[optionsIndex].midEveningDietCalorie = newCalorie.toFixed(1);
                midEveningDietListing[optionsIndex].midEveningDietProtein = newProtein.toFixed(1);
                midEveningDietListing[optionsIndex].midEveningDietCarbs = newCarbs.toFixed(1);
                midEveningDietListing[optionsIndex].midEveningDietFat = newFat.toFixed(1);
                midEveningDietListing[optionsIndex].midEveningDietFibre = newFibre.toFixed(1);

                this.setState({ midEveningDietListing })
                break;

            case "dinner":
                newCalorie = 0;
                newProtein = 0;
                newCarbs = 0;
                newFat = 0;
                newFibre = 0;

                dinnerListing[optionsIndex].dinnerDiet.map(food => {
                    if (food) {
                        let { calorieContent, protein, fibre, fat, carb, quantity } = food;
                        newCalorie = newCalorie + ((calorieContent ? calorieContent : 0) * quantity);
                        newProtein = newProtein + ((protein ? protein : 0) * quantity);
                        newCarbs = newCarbs + ((carb ? carb : 0) * quantity);
                        newFat = newFat + ((fat ? fat : 0) * quantity);
                        newFibre = newFibre + ((fibre ? fibre : 0) * quantity);
                    }
                })

                dinnerListing[optionsIndex].dinnerDietCalorie = newCalorie.toFixed(1);
                dinnerListing[optionsIndex].dinnerDietProtein = newProtein.toFixed(1);
                dinnerListing[optionsIndex].dinnerDietCarbs = newCarbs.toFixed(1);
                dinnerListing[optionsIndex].dinnerDietFat = newFat.toFixed(1);
                dinnerListing[optionsIndex].dinnerDietFibre = newFibre.toFixed(1);

                this.setState({ dinnerListing })
                break;

            case "bedTime":
                newCalorie = 0;
                newProtein = 0;
                newCarbs = 0;
                newFat = 0;
                newFibre = 0;

                bedTimeDietListing[optionsIndex].bedTimeDiet.map(food => {
                    if (food) {
                        let { calorieContent, protein, fibre, fat, carb, quantity } = food;
                        newCalorie = newCalorie + ((calorieContent ? calorieContent : 0) * quantity);
                        newProtein = newProtein + ((protein ? protein : 0) * quantity);
                        newCarbs = newCarbs + ((carb ? carb : 0) * quantity);
                        newFat = newFat + ((fat ? fat : 0) * quantity);
                        newFibre = newFibre + ((fibre ? fibre : 0) * quantity);
                    }
                })

                bedTimeDietListing[optionsIndex].bedTimeDietCalorie = newCalorie.toFixed(1);
                bedTimeDietListing[optionsIndex].bedTimeDietProtein = newProtein.toFixed(1);
                bedTimeDietListing[optionsIndex].bedTimeDietCarbs = newCarbs.toFixed(1);
                bedTimeDietListing[optionsIndex].bedTimeDietFat = newFat.toFixed(1);
                bedTimeDietListing[optionsIndex].bedTimeDietFibre = newFibre.toFixed(1);

                this.setState({ bedTimeDietListing })
                break;

            default: break;
        }
    }

    submitEditDietPlan = (e) => {

        e.preventDefault();
        const { morningSnacksListing, breakfastListing, midMorningDietListing, lunchListing, midEveningDietListing, dinnerListing, bedTimeDietListing, recommendedMorningSnacksCalories,
            recommendedBreakfastCalories,
            recommendedMidMorningCalories,
            recommendedLunchCalories,
            recommendedMidEveningCalories,
            recommendedDinnerCalories,
            recommendedBedTimeCalories,
            morningSnacksDietTime,
            breakfastDietTime,
            midMorningDietTime,
            lunchDietTime,
            midEveningDietTime,
            dinnerDietTime,
            bedTimeDietTime, dietPlanDetails, perDayCalories } = this.state

        let paramsObj = {
            dietPlanId: dietPlanDetails.dietPlanId,
            morningSnacksOptions: morningSnacksListing,
            breakfastOptions: breakfastListing,
            midMorningOptions: midMorningDietListing,
            lunchOptions: lunchListing,
            midEveningOptions: midEveningDietListing,
            dinnerOptions: dinnerListing,
            bedTimeOptions: bedTimeDietListing,

            perDayCalories,
            recommendedMorningSnacksCalories,
            recommendedBreakfastCalories,
            recommendedMidMorningCalories,
            recommendedLunchCalories,
            recommendedMidEveningCalories,
            recommendedDinnerCalories,
            recommendedBedTimeCalories,
            morningSnacksDietTime,
            breakfastDietTime,
            midMorningDietTime,
            lunchDietTime,
            midEveningDietTime,
            dinnerDietTime,
            bedTimeDietTime,
        };

        this.editDietPlanApi(paramsObj);


        // if (!morningSnacksListing.length && !breakfastListing.length && !midMorningDietListing.length && !lunchListing.length && !midEveningDietListing.length && !dinnerListing.length && !bedTimeDietListing.length) {
        //     this.setState({ validationError: "Atleast one course meal is mandatory!" })
        // }
        // else if (this.checkValidation()) {
        //     this.setState({ validationError: "Fill all the details in a course." })
        // }
        // else if (!validFrom || !validTo) {
        //     this.setState({ validationError: "Valid from and Valid To cannot be empty" })
        // } else {
        //     this.setState({ validationError: "" })
        //     this.editDietPlanApi(paramsObj);
        // }
    }

    checkValidation = () => {
        const { morningSnacksListing, breakfastListing, midMorningDietListing, lunchListing, midEveningDietListing, dinnerListing, bedTimeDietListing,
            morningSnacksDietCalorie, breakfastDietCalorie, midMorningDietCalorie, lunchDietCalorie, midEveningDietCalorie,
            dinnerDietCalorie, bedTimeDietCalorie } = this.state

        let notValid = false;

        if (morningSnacksListing.length) {
            morningSnacksListing.map(mealDetails => {
                if (!mealDetails.calorieContent || !mealDetails.quantity || !mealDetails.unit || !morningSnacksDietCalorie)
                    notValid = true;
            })
        }
        if (breakfastListing.length) {
            breakfastListing.map(mealDetails => {
                if (!mealDetails.calorieContent || !mealDetails.quantity || !mealDetails.unit || !breakfastDietCalorie)
                    notValid = true;
            })
        }
        if (midMorningDietListing.length) {
            midMorningDietListing.map(mealDetails => {
                if (!mealDetails.calorieContent || !mealDetails.quantity || !mealDetails.unit || !midMorningDietCalorie)
                    notValid = true;
            })
        }
        if (lunchListing.length) {
            lunchListing.map(mealDetails => {
                if (!mealDetails.calorieContent || !mealDetails.quantity || !mealDetails.unit || !lunchDietCalorie)
                    notValid = true;
            })
        }
        if (midEveningDietListing.length) {
            midEveningDietListing.map(mealDetails => {
                if (!mealDetails.calorieContent || !mealDetails.quantity || !mealDetails.unit || !midEveningDietCalorie)
                    notValid = true;
            })
        }
        if (dinnerListing.length) {
            dinnerListing.map(mealDetails => {
                if (!mealDetails.calorieContent || !mealDetails.quantity || !mealDetails.unit || !dinnerDietCalorie)
                    notValid = true;
            })
        }
        if (bedTimeDietListing.length) {
            bedTimeDietListing.map(mealDetails => {
                if (!mealDetails.calorieContent || !mealDetails.quantity || !mealDetails.unit || !bedTimeDietCalorie)
                    notValid = true;
            })
        }
        return notValid
    }

    editDietPlanApi = (params) => {
        let self = this;
        editDietPlan(params)
            .then(function (res) {
                let response = res.data
                if (response.statusCode == 1) {
                    toast.success(response.responseData.message)
                    self.props.handleClose();
                    self.props.getUserDietPlan();
                } else if (response.statusCode == 0) {
                    self.setState({ validationError: response.error.errorMessage })
                }
            })
    }


    deleteFoodItem = (fieldName, index, e, optionsIndex) => {
        let { morningSnacksListing, breakfastListing, midMorningDietListing, lunchListing, midEveningDietListing, dinnerListing, bedTimeDietListing,
            morningSnacksDietCalorie, breakfastDietCalorie, midMorningDietCalorie, lunchDietCalorie, midEveningDietCalorie, dinnerDietCalorie, bedTimeDietCalorie } = this.state

        let calorieDeducted;
        let proteinDeducted;
        let carbsDeducted;
        let fatDeducted;
        let fibreDeducted;


        this.setState({ updateDisabled: false }) //to enable update button

        switch (fieldName) {
            case "morningSnack":
                //to Delete the calories count of deleted food from list of selected food items for diet plan..

                if (morningSnacksListing[optionsIndex].morningSnacksDiet[index]) {

                    let { calorieContent, quantity, protein, carb,
                        fat, fibre } = morningSnacksListing[optionsIndex].morningSnacksDiet[index];

                    let { morningSnacksDietCalorie, morningSnacksDietProtein, morningSnacksDietCarbs,
                        morningSnacksDietFat, morningSnacksDietFibre } = morningSnacksListing[optionsIndex]

                    calorieDeducted = (calorieContent && quantity ? calorieContent * quantity : 0);
                    proteinDeducted = (protein && quantity ? protein * quantity : 0);
                    carbsDeducted = (carb && quantity ? carb * quantity : 0);
                    fatDeducted = (fat && quantity ? fat * quantity : 0);
                    fibreDeducted = (fibre && quantity ? fibre * quantity : 0);
                    //Total calories to be deducted according to quantity

                    morningSnacksDietCalorie = morningSnacksDietCalorie - calorieDeducted;
                    morningSnacksDietProtein = morningSnacksDietProtein - proteinDeducted;
                    morningSnacksDietCarbs = morningSnacksDietCarbs - carbsDeducted;
                    morningSnacksDietFat = morningSnacksDietFat - fatDeducted;
                    morningSnacksDietFibre = morningSnacksDietFibre - fibreDeducted;
                    // deduction from total calories for Morning Snacks


                    morningSnacksListing[optionsIndex].morningSnacksDietCalorie = morningSnacksDietCalorie.toFixed(1);
                    morningSnacksListing[optionsIndex].morningSnacksDietProtein = morningSnacksDietProtein.toFixed(1);
                    morningSnacksListing[optionsIndex].morningSnacksDietCarbs = morningSnacksDietCarbs.toFixed(1);
                    morningSnacksListing[optionsIndex].morningSnacksDietFat = morningSnacksDietFat.toFixed(1);
                    morningSnacksListing[optionsIndex].morningSnacksDietFibre = morningSnacksDietFibre.toFixed(1);
                }

                morningSnacksListing[optionsIndex].morningSnacksDiet.splice(index, 1);
                this.setState({ morningSnacksListing })
                break;

            case "breakfast":
                if (breakfastListing[optionsIndex].breakfastDiet[index]) {

                    let { calorieContent, quantity, protein, carb,
                        fat, fibre } = breakfastListing[optionsIndex].breakfastDiet[index];

                    let { breakfastDietCalorie, breakfastDietProtein, breakfastDietCarbs,
                        breakfastDietFat, breakfastDietFibre } = breakfastListing[optionsIndex]

                    calorieDeducted = (calorieContent * quantity);
                    proteinDeducted = (protein * quantity);
                    carbsDeducted = (carb * quantity);
                    fatDeducted = (fat * quantity);
                    fibreDeducted = (fibre * quantity);

                    breakfastDietCalorie = breakfastDietCalorie - calorieDeducted;
                    breakfastDietProtein = breakfastDietProtein - proteinDeducted;
                    breakfastDietCarbs = breakfastDietCarbs - carbsDeducted;
                    breakfastDietFat = breakfastDietFat - fatDeducted;
                    breakfastDietFibre = breakfastDietFibre - fibreDeducted;

                    breakfastListing[optionsIndex].breakfastDietCalorie = breakfastDietCalorie.toFixed(1);
                    breakfastListing[optionsIndex].breakfastDietProtein = breakfastDietProtein.toFixed(1);
                    breakfastListing[optionsIndex].breakfastDietCarbs = breakfastDietCarbs.toFixed(1);
                    breakfastListing[optionsIndex].breakfastDietFat = breakfastDietFat.toFixed(1);
                    breakfastListing[optionsIndex].breakfastDietFibre = breakfastDietFibre.toFixed(1);
                }
                breakfastListing[optionsIndex].breakfastDiet.splice(index, 1);
                this.setState({ breakfastListing, breakfastDietCalorie })
                break;

            case "midMorning":
                if (midMorningDietListing[optionsIndex].midMorningDiet[index]) {

                    let { calorieContent, quantity, protein, carb,
                        fat, fibre } = midMorningDietListing[optionsIndex].midMorningDiet[index];

                    let { midMorningDietCalorie, midMorningDietProtein, midMorningDietCarbs,
                        midMorningDietFat, midMorningDietFibre } = midMorningDietListing[optionsIndex]

                    calorieDeducted = (calorieContent * quantity);
                    proteinDeducted = (protein * quantity);
                    carbsDeducted = (carb * quantity);
                    fatDeducted = (fat * quantity);
                    fibreDeducted = (fibre * quantity);
                    //Total calories according to quantity

                    midMorningDietCalorie = midMorningDietCalorie - calorieDeducted;
                    midMorningDietProtein = midMorningDietProtein - proteinDeducted;
                    midMorningDietCarbs = midMorningDietCarbs - carbsDeducted;
                    midMorningDietFat = midMorningDietFat - fatDeducted;
                    midMorningDietFibre = midMorningDietFibre - fibreDeducted;

                    midMorningDietListing[optionsIndex].midMorningDietCalorie = midMorningDietCalorie.toFixed(1);
                    midMorningDietListing[optionsIndex].midMorningDietProtein = midMorningDietProtein.toFixed(1);
                    midMorningDietListing[optionsIndex].midMorningDietCarbs = midMorningDietCarbs.toFixed(1);
                    midMorningDietListing[optionsIndex].midMorningDietFat = midMorningDietFat.toFixed(1);
                    midMorningDietListing[optionsIndex].midMorningDietFibre = midMorningDietFibre.toFixed(1);
                }

                midMorningDietListing[optionsIndex].midMorningDiet.splice(index, 1);
                this.setState({ midMorningDietListing })
                break;

            case "lunch":
                if (lunchListing[optionsIndex].lunchDiet[index]) {

                    let { calorieContent, quantity, protein, carb,
                        fat, fibre } = lunchListing[optionsIndex].lunchDiet[index];

                    let { lunchDietCalorie, lunchDietProtein, lunchDietCarbs,
                        lunchDietFat, lunchDietFibre } = lunchListing[optionsIndex]

                    calorieDeducted = (calorieContent * quantity);
                    proteinDeducted = (protein * quantity);
                    carbsDeducted = (carb * quantity);
                    fatDeducted = (fat * quantity);
                    fibreDeducted = (fibre * quantity);
                    //Total calories according to quantity

                    lunchDietCalorie = lunchDietCalorie - calorieDeducted;
                    lunchDietProtein = lunchDietProtein - proteinDeducted;
                    lunchDietCarbs = lunchDietCarbs - carbsDeducted;
                    lunchDietFat = lunchDietFat - fatDeducted;
                    lunchDietFibre = lunchDietFibre - fibreDeducted;

                    lunchListing[optionsIndex].lunchDietCalorie = lunchDietCalorie.toFixed(1);
                    lunchListing[optionsIndex].lunchDietProtein = lunchDietProtein.toFixed(1);
                    lunchListing[optionsIndex].lunchDietCarbs = lunchDietCarbs.toFixed(1);
                    lunchListing[optionsIndex].lunchDietFat = lunchDietFat.toFixed(1);
                    lunchListing[optionsIndex].lunchDietFibre = lunchDietFibre.toFixed(1);

                }
                lunchListing[optionsIndex].lunchDiet.splice(index, 1);
                this.setState({ lunchListing })
                break;

            case "midEvening":
                if (midEveningDietListing[optionsIndex].midEveningDiet[index]) {
                    let { calorieContent, quantity, protein, carb,
                        fat, fibre } = midEveningDietListing[optionsIndex].midEveningDiet[index];

                    let { midEveningDietCalorie, midEveningDietProtein, midEveningDietCarbs,
                        midEveningDietFat, midEveningDietFibre } = midEveningDietListing[optionsIndex]

                    calorieDeducted = (calorieContent * quantity);
                    proteinDeducted = (protein * quantity);
                    carbsDeducted = (carb * quantity);
                    fatDeducted = (fat * quantity);
                    fibreDeducted = (fibre * quantity);
                    //Total calories according to quantity

                    midEveningDietCalorie = midEveningDietCalorie - calorieDeducted;
                    midEveningDietProtein = midEveningDietProtein - proteinDeducted;
                    midEveningDietCarbs = midEveningDietCarbs - carbsDeducted;
                    midEveningDietFat = midEveningDietFat - fatDeducted;
                    midEveningDietFibre = midEveningDietFibre - fibreDeducted;

                    midEveningDietListing[optionsIndex].midEveningDietCalorie = midEveningDietCalorie.toFixed(1);
                    midEveningDietListing[optionsIndex].midEveningDietProtein = midEveningDietProtein.toFixed(1);
                    midEveningDietListing[optionsIndex].midEveningDietCarbs = midEveningDietCarbs.toFixed(1);
                    midEveningDietListing[optionsIndex].midEveningDietFat = midEveningDietFat.toFixed(1);
                    midEveningDietListing[optionsIndex].midEveningDietFibre = midEveningDietFibre.toFixed(1);
                }

                midEveningDietListing[optionsIndex].midEveningDiet.splice(index, 1);
                this.setState({ midEveningDietListing })
                break;


            case "dinner":
                if (dinnerListing[optionsIndex].dinnerDiet[index]) {
                    let { calorieContent, quantity, protein, carb,
                        fat, fibre } = dinnerListing[optionsIndex].dinnerDiet[index];

                    let { dinnerDietCalorie, dinnerDietProtein, dinnerDietCarbs,
                        dinnerDietFat, dinnerDietFibre } = dinnerListing[optionsIndex]

                    calorieDeducted = (calorieContent * quantity);
                    proteinDeducted = (protein * quantity);
                    carbsDeducted = (carb * quantity);
                    fatDeducted = (fat * quantity);
                    fibreDeducted = (fibre * quantity);
                    //Total calories according to quantity

                    dinnerDietCalorie = dinnerDietCalorie - calorieDeducted;
                    dinnerDietProtein = dinnerDietProtein - proteinDeducted;
                    dinnerDietCarbs = dinnerDietCarbs - carbsDeducted;
                    dinnerDietFat = dinnerDietFat - fatDeducted;
                    dinnerDietFibre = dinnerDietFibre - fibreDeducted;

                    dinnerListing[optionsIndex].dinnerDietCalorie = dinnerDietCalorie.toFixed(1);
                    dinnerListing[optionsIndex].dinnerDietProtein = dinnerDietProtein.toFixed(1);
                    dinnerListing[optionsIndex].dinnerDietCarbs = dinnerDietCarbs.toFixed(1);
                    dinnerListing[optionsIndex].dinnerDietFat = dinnerDietFat.toFixed(1);
                    dinnerListing[optionsIndex].dinnerDietFibre = dinnerDietFibre.toFixed(1);
                }

                dinnerListing[optionsIndex].dinnerDiet.splice(index, 1);
                this.setState({ dinnerListing })
                break;


            case "bedTime":
                if (bedTimeDietListing[optionsIndex].bedTimeDiet[index]) {
                    let { calorieContent, quantity, protein, carb,
                        fat, fibre } = bedTimeDietListing[optionsIndex].bedTimeDiet[index];

                    let { bedTimeDietCalorie, bedTimeDietProtein, bedTimeDietCarbs,
                        bedTimeDietFat, bedTimeDietFibre } = bedTimeDietListing[optionsIndex]


                    calorieDeducted = (calorieContent * quantity);
                    proteinDeducted = (protein * quantity);
                    carbsDeducted = (carb * quantity);
                    fatDeducted = (fat * quantity);
                    fibreDeducted = (fibre * quantity);
                    //Total calories according to quantity

                    bedTimeDietCalorie = bedTimeDietCalorie - calorieDeducted;
                    bedTimeDietProtein = bedTimeDietProtein - proteinDeducted;
                    bedTimeDietCarbs = bedTimeDietCarbs - carbsDeducted;
                    bedTimeDietFat = bedTimeDietFat - fatDeducted;
                    bedTimeDietFibre = bedTimeDietFibre - fibreDeducted;

                    bedTimeDietListing[optionsIndex].bedTimeDietCalorie = bedTimeDietCalorie.toFixed(1);
                    bedTimeDietListing[optionsIndex].bedTimeDietProtein = bedTimeDietProtein.toFixed(1);
                    bedTimeDietListing[optionsIndex].bedTimeDietCarbs = bedTimeDietCarbs.toFixed(1);
                    bedTimeDietListing[optionsIndex].bedTimeDietFat = bedTimeDietFat.toFixed(1);
                    bedTimeDietListing[optionsIndex].bedTimeDietFibre = bedTimeDietFibre.toFixed(1);
                }

                bedTimeDietListing[optionsIndex].bedTimeDiet.splice(index, 1);
                this.setState({ bedTimeDietListing })

                break;

            default: break;
        }
    }


    componentWillReceiveProps() {

        let { morningSnacksList, breakfastList, midMorningDietList, lunchList, midEveningDietList, dinnerList, bedTimeDietList, dietPlanDetails } = this.props;

        var morningSnacksListing = [], breakfastListing = [], midMorningDietListing = [],
            lunchListing = [], midEveningDietListing = [], dinnerListing = [], bedTimeDietListing = [];

        let calorie;
        let protein;
        let carbs;
        let fat;
        let fibre;

        if (morningSnacksList.length) {
            morningSnacksList.map((option, optionsIndex) => {
                calorie = 0;
                protein = 0;
                carbs = 0;
                fat = 0;
                fibre = 0;

                morningSnacksListing[optionsIndex] = {}
                morningSnacksListing[optionsIndex].morningSnacksDiet = []

                option && option.morningSnacksDiet.map((food, index) => {

                    let { calorieContent, proteinContent, fibreContent, fatContent, carbContent, quantity } = food;

                    calorie = calorie + ((calorieContent ? calorieContent : 0) * quantity);
                    protein = protein + ((proteinContent ? proteinContent : 0) * quantity);
                    carbs = carbs + ((carbContent ? carbContent : 0) * quantity);
                    fat = fat + ((fatContent ? fatContent : 0) * quantity);
                    fibre = fibre + ((fibreContent ? fibreContent : 0) * quantity);

                    morningSnacksListing[optionsIndex].morningSnacksDiet.push({
                        label: food.foodName,

                        value: food._id, quantity: food.quantity, unit: food.unit, foodId: food._id, calorieContent: food.calorieContent,
                        protein: food.proteinContent,
                        fat: food.fatContent,
                        carb: food.carbContent,
                        fibre: food.fibreContent,
                    })
                })
                morningSnacksListing[optionsIndex].morningSnacksDietCalorie = calorie
                morningSnacksListing[optionsIndex].morningSnacksDietProtein = protein
                morningSnacksListing[optionsIndex].morningSnacksDietCarbs = carbs
                morningSnacksListing[optionsIndex].morningSnacksDietFat = fat
                morningSnacksListing[optionsIndex].morningSnacksDietFibre = fibre
            })
        }

        if (breakfastList.length) {
            breakfastList.map((option, optionsIndex) => {

                calorie = 0;
                protein = 0;
                carbs = 0;
                fat = 0;
                fibre = 0;

                breakfastListing[optionsIndex] = {}
                breakfastListing[optionsIndex].breakfastDiet = []

                option && option.breakfastDiet.map((food, index) => {
                    let { calorieContent, proteinContent, fibreContent, fatContent, carbContent, quantity } = food;

                    calorie = calorie + ((calorieContent ? calorieContent : 0) * quantity);
                    protein = protein + ((proteinContent ? proteinContent : 0) * quantity);
                    carbs = carbs + ((carbContent ? carbContent : 0) * quantity);
                    fat = fat + ((fatContent ? fatContent : 0) * quantity);
                    fibre = fibre + ((fibreContent ? fibreContent : 0) * quantity);

                    breakfastListing[optionsIndex].breakfastDiet.push({
                        label: food.foodName, value: food._id, quantity: food.quantity, unit: food.unit, foodId: food._id, calorieContent: food.calorieContent, protein: food.proteinContent,
                        fat: food.fatContent,
                        carb: food.carbContent,
                        fibre: food.fibreContent,
                    })
                })

                breakfastListing[optionsIndex].breakfastDietCalorie = calorie
                breakfastListing[optionsIndex].breakfastDietProtein = protein
                breakfastListing[optionsIndex].breakfastDietCarbs = carbs
                breakfastListing[optionsIndex].breakfastDietFat = fat
                breakfastListing[optionsIndex].breakfastDietFibre = fibre
            })
        }

        if (midMorningDietList.length) {
            midMorningDietList.map((option, optionsIndex) => {

                calorie = 0;
                protein = 0;
                carbs = 0;
                fat = 0;
                fibre = 0;

                midMorningDietListing[optionsIndex] = {}
                midMorningDietListing[optionsIndex].midMorningDiet = []

                option && option.midMorningDiet.map((food, index) => {
                    let { calorieContent, proteinContent, fibreContent, fatContent, carbContent, quantity } = food;
                    calorie = calorie + ((calorieContent ? calorieContent : 0) * quantity);
                    protein = protein + ((proteinContent ? proteinContent : 0) * quantity);
                    carbs = carbs + ((carbContent ? carbContent : 0) * quantity);
                    fat = fat + ((fatContent ? fatContent : 0) * quantity);
                    fibre = fibre + ((fibreContent ? fibreContent : 0) * quantity);


                    midMorningDietListing[optionsIndex].midMorningDiet.push({
                        label: food.foodName, value: food._id, quantity: food.quantity, unit: food.unit, foodId: food._id, calorieContent: food.calorieContent, protein: food.proteinContent,
                        fat: food.fatContent,
                        carb: food.carbContent,
                        fibre: food.fibreContent,
                    })
                })

                midMorningDietListing[optionsIndex].midMorningDietCalorie = calorie
                midMorningDietListing[optionsIndex].midMorningDietProtein = protein
                midMorningDietListing[optionsIndex].midMorningDietCarbs = carbs
                midMorningDietListing[optionsIndex].midMorningDietFat = fat
                midMorningDietListing[optionsIndex].midMorningDietFibre = fibre
            })
        }


        if (lunchList.length) {
            lunchList.map((option, optionsIndex) => {
                calorie = 0;
                protein = 0;
                carbs = 0;
                fat = 0;
                fibre = 0;

                lunchListing[optionsIndex] = {}
                lunchListing[optionsIndex].lunchDiet = []

                option && option.lunchDiet.map((food, index) => {
                    let { calorieContent, proteinContent, fibreContent, fatContent, carbContent, quantity } = food;
                    calorie = calorie + ((calorieContent ? calorieContent : 0) * quantity);
                    protein = protein + ((proteinContent ? proteinContent : 0) * quantity);
                    carbs = carbs + ((carbContent ? carbContent : 0) * quantity);
                    fat = fat + ((fatContent ? fatContent : 0) * quantity);
                    fibre = fibre + ((fibreContent ? fibreContent : 0) * quantity);

                    lunchListing[optionsIndex].lunchDiet.push({
                        label: food.foodName, value: food._id, quantity: food.quantity, unit: food.unit, foodId: food._id, calorieContent: food.calorieContent, protein: food.proteinContent,
                        fat: food.fatContent,
                        carb: food.carbContent,
                        fibre: food.fibreContent
                    })
                })

                lunchListing[optionsIndex].lunchDietCalorie = calorie
                lunchListing[optionsIndex].lunchDietProtein = protein
                lunchListing[optionsIndex].lunchDietCarbs = carbs
                lunchListing[optionsIndex].lunchDietFat = fat
                lunchListing[optionsIndex].lunchDietFibre = fibre
            })
        }

        if (midEveningDietList.length) {
            midEveningDietList.map((option, optionsIndex) => {

                calorie = 0;
                protein = 0;
                carbs = 0;
                fat = 0;
                fibre = 0;

                midEveningDietListing[optionsIndex] = {}
                midEveningDietListing[optionsIndex].midEveningDiet = []

                option && option.midEveningDiet.map((food, index) => {

                    let { calorieContent, proteinContent, fibreContent, fatContent, carbContent, quantity } = food;
                    calorie = calorie + ((calorieContent ? calorieContent : 0) * quantity);
                    protein = protein + ((proteinContent ? proteinContent : 0) * quantity);
                    carbs = carbs + ((carbContent ? carbContent : 0) * quantity);
                    fat = fat + ((fatContent ? fatContent : 0) * quantity);
                    fibre = fibre + ((fibreContent ? fibreContent : 0) * quantity);

                    midEveningDietListing[optionsIndex].midEveningDiet.push({
                        label: food.foodName, value: food._id, quantity: food.quantity, unit: food.unit, foodId: food._id, calorieContent: food.calorieContent, protein: food.proteinContent,
                        fat: food.fatContent,
                        carb: food.carbContent,
                        fibre: food.fibreContent
                    })
                })

                midEveningDietListing[optionsIndex].midEveningDietCalorie = calorie
                midEveningDietListing[optionsIndex].midEveningDietProtein = protein
                midEveningDietListing[optionsIndex].midEveningDietCarbs = carbs
                midEveningDietListing[optionsIndex].midEveningDietFat = fat
                midEveningDietListing[optionsIndex].midEveningDietFibre = fibre
            })
        }

        if (dinnerList.length) {
            dinnerList.map((option, optionsIndex) => {

                calorie = 0;
                protein = 0;
                carbs = 0;
                fat = 0;
                fibre = 0;

                dinnerListing[optionsIndex] = {}
                dinnerListing[optionsIndex].dinnerDiet = []

                option && option.dinnerDiet.map((food, index) => {

                    let { calorieContent, proteinContent, fibreContent, fatContent, carbContent, quantity } = food;
                    calorie = calorie + ((calorieContent ? calorieContent : 0) * quantity);
                    protein = protein + ((proteinContent ? proteinContent : 0) * quantity);
                    carbs = carbs + ((carbContent ? carbContent : 0) * quantity);
                    fat = fat + ((fatContent ? fatContent : 0) * quantity);
                    fibre = fibre + ((fibreContent ? fibreContent : 0) * quantity);

                    dinnerListing[optionsIndex].dinnerDiet.push({
                        label: food.foodName, value: food._id, quantity: food.quantity, unit: food.unit, foodId: food._id, calorieContent: food.calorieContent, protein: food.proteinContent,
                        fat: food.fatContent,
                        carb: food.carbContent,
                        fibre: food.fibreContent
                    })
                })

                dinnerListing[optionsIndex].dinnerDietCalorie = calorie
                dinnerListing[optionsIndex].dinnerDietProtein = protein
                dinnerListing[optionsIndex].dinnerDietCarbs = carbs
                dinnerListing[optionsIndex].dinnerDietFat = fat
                dinnerListing[optionsIndex].dinnerDietFibre = fibre
            })
        }

        if (bedTimeDietList.length) {
            bedTimeDietList.map((option, optionsIndex) => {

                calorie = 0;
                protein = 0;
                carbs = 0;
                fat = 0;
                fibre = 0;

                bedTimeDietListing[optionsIndex] = {}
                bedTimeDietListing[optionsIndex].bedTimeDiet = []

                option && option.bedTimeDiet.map((food, index) => {

                    let { calorieContent, proteinContent, fibreContent, fatContent, carbContent, quantity } = food;
                    calorie = calorie + ((calorieContent ? calorieContent : 0) * quantity);
                    protein = protein + ((proteinContent ? proteinContent : 0) * quantity);
                    carbs = carbs + ((carbContent ? carbContent : 0) * quantity);
                    fat = fat + ((fatContent ? fatContent : 0) * quantity);
                    fibre = fibre + ((fibreContent ? fibreContent : 0) * quantity);


                    bedTimeDietListing[optionsIndex].bedTimeDiet.push({
                        label: food.foodName, value: food._id, quantity: food.quantity, unit: food.unit, foodId: food._id, calorieContent: food.calorieContent, protein: food.proteinContent,
                        fat: food.fatContent,
                        carb: food.carbContent,
                        fibre: food.fibreContent
                    })
                })

                bedTimeDietListing[optionsIndex].bedTimeDietCalorie = calorie
                bedTimeDietListing[optionsIndex].bedTimeDietProtein = protein
                bedTimeDietListing[optionsIndex].bedTimeDietCarbs = carbs
                bedTimeDietListing[optionsIndex].bedTimeDietFat = fat
                bedTimeDietListing[optionsIndex].bedTimeDietFibre = fibre
            })
        }

        if (dietPlanDetails) {
            this.setState({
                recommendedMorningSnacksCalories: dietPlanDetails.recommendedMorningSnacksCalories,
                recommendedBreakfastCalories: dietPlanDetails.recommendedBreakfastCalories,
                recommendedMidMorningCalories: dietPlanDetails.recommendedMidMorningCalories,
                recommendedLunchCalories: dietPlanDetails.recommendedLunchCalories,
                recommendedMidEveningCalories: dietPlanDetails.recommendedMidEveningCalories,
                recommendedDinnerCalories: dietPlanDetails.recommendedDinnerCalories,
                recommendedBedTimeCalories: dietPlanDetails.recommendedBedTimeCalories,
                morningSnacksDietTime: dietPlanDetails.morningSnacksDietTime,
                breakfastDietTime: dietPlanDetails.breakfastDietTime,
                midMorningDietTime: dietPlanDetails.midMorningDietTime,
                lunchDietTime: dietPlanDetails.lunchDietTime,
                midEveningDietTime: dietPlanDetails.midEveningDietTime,
                dinnerDietTime: dietPlanDetails.dinnerDietTime,
                bedTimeDietTime: dietPlanDetails.bedTimeDietTime,
                perDayCalories: dietPlanDetails.perDayCalories,
            })
        }
        this.setState({
            morningSnacksListing, breakfastListing, midMorningDietListing, lunchListing, midEveningDietListing, dinnerListing, bedTimeDietListing, dietPlanDetails
        })
    }


    addMoreOption = (fieldName, index) => {
        const { morningSnacksListing, breakfastListing, midMorningDietListing, lunchListing, midEveningDietListing, dinnerListing, bedTimeDietListing } = this.state

        switch (fieldName) {
            case "morningSnack":

                morningSnacksListing.push({ morningSnacksDiet: [] });
                this.setState({ morningSnacksListing })
                break;

            case "breakfast":
                breakfastListing.push({ breakfastDiet: [] });
                this.setState({ breakfastListing })
                break;

            case "midMorning":
                midMorningDietListing.push({ midMorningDiet: [] });
                this.setState({ midMorningDietListing })
                break;

            case "lunch":
                lunchListing.push({ lunchDiet: [] });
                this.setState({ lunchListing })
                break;

            case "midEvening":
                midEveningDietListing.push({ midEveningDiet: [] });
                this.setState({ midEveningDietListing })
                break;

            case "dinner":
                dinnerListing.push({ dinnerDiet: [] });
                this.setState({ dinnerListing })
                break;

            case "bedTime":
                bedTimeDietListing.push({ bedTimeDiet: [] });
                this.setState({ bedTimeDietListing })
                break;

            default: break;
        }
    }


    handleClose = () => {
        this.props.handleClose();
        this.setState({ updateDisabled: true, validationError: '' })
    }

    selectCalorieBreakdown = (selectedOption) => {
        this.setState({ updateDisabled: false })

        new Promise(function (res, rej) {
            res(calorieBreakDown(selectedOption));
        })
            .then((result) => {
                let { recommendedMorningSnacksCalories,
                    recommendedBreakfastCalories,
                    recommendedMidMorningCalories,
                    recommendedLunchCalories,
                    recommendedMidEveningCalories,
                    recommendedDinnerCalories,
                    recommendedBedTimeCalories,
                    perDayCalories } = result;
                this.setState({
                    recommendedMorningSnacksCalories,
                    recommendedBreakfastCalories,
                    recommendedMidMorningCalories,
                    recommendedLunchCalories,
                    recommendedMidEveningCalories,
                    recommendedDinnerCalories,
                    recommendedBedTimeCalories,
                    perDayCalories
                })
            })
    }

    render() {
        let { openEditPopUp, foodItems } = this.props;

        let { validationError, morningSnacksListing, breakfastListing, midMorningDietListing, lunchListing, midEveningDietListing, dinnerListing, bedTimeDietListing, updateDisabled, morningSnacksDietTime,
            breakfastDietTime,
            midMorningDietTime,
            lunchDietTime,
            midEveningDietTime,
            dinnerDietTime,
            bedTimeDietTime,
            perDayCalories,
            recommendedMorningSnacksCalories,
            recommendedBreakfastCalories,
            recommendedMidMorningCalories,
            recommendedLunchCalories,
            recommendedMidEveningCalories,
            recommendedDinnerCalories,
            recommendedBedTimeCalories, morningSnacksError } = this.state;


        return (
            <Modal
                show={openEditPopUp}
                onHide={this.handleClose}
                size="lg"
                dialogClassName="cm_big_modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Diet Plan</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Select
                        isSearchable
                        options={perDayCaloriesCount}
                        placeholder="Select a calorie break down"
                        value={{ label: perDayCalories + " Calories per day" }}
                        onChange={(val) => this.selectCalorieBreakdown(val)}
                    />

                    <form onSubmit={this.submitEditDietPlan}>
                        <div className="form_group_wrapper">
                            {morningSnacksError ? <div className="alert alert-danger" role="alert">
                                {morningSnacksError}:
                        </div> : null}

                            <h4 className="cm_pos_rel cm_orange mt-0">Pre-Breakfast*
                                <span className="btn btn-info" onClick={(val) => this.addMoreOption('morningSnack', val)}><i className="fa fa-plus-square"></i> Add Option</span>
                            </h4>

                            <div className="row">
                                <div className="form-group col-sm-6">
                                    <label>Calories</label>
                                    <input className="form-control" type="number" placeholder="Calories" value={recommendedMorningSnacksCalories} onChange={this.handleOnChange} name="recommendedMorningSnacksCalories" />
                                </div>

                                <div className="form-group col-sm-6">
                                    <label>Timing:</label>
                                    <input className="form-control" type="time" name="morningSnacksDietTime" value={morningSnacksDietTime} onChange={this.handleOnChange} />
                                </div>
                            </div>





                            {morningSnacksListing.map((option, i) => (
                                <React.Fragment key={i}>
                                    <h4 className="cm_pos_rel">Pre-Breakfast Option {i + 1}
                                        <span className="btn btn-danger" onClick={() => this.removeOption('morningSnack', i)}><i className="fa fa-trash" /></span>
                                    </h4>

                                    {option.morningSnacksDiet.map((data, index) => (
                                        <div className="row" key={index}>
                                            <div className="form-group col-6">
                                                <label>food {index + 1}</label>
                                                <Select
                                                    isSearchable
                                                    value={data}
                                                    options={foodItems}
                                                    onChange={(val) => this.onFoodChange('morningSnack', val, index, i)}
                                                />
                                            </div>


                                            <div className="form-group col-3">
                                                <label>Quantity</label>
                                                <input type="number" value={data.quantity} className="form-control" min={1} max={20} onChange={(val) => this.onQtyChange('morningSnack', val, index, i)} />
                                            </div>

                                            {/* 1=pc, 2=glass,3=cup, 4=katori, 5=bowl, 6=mug */}

                                            <div className="form-group col-3">
                                                <i className="fa fa-minus-square cm_abs" onClick={(val) => this.deleteFoodItem('morningSnack', index, val, i)} />
                                                <label>Unit</label>
                                                <select className="form-control" value={data.unit} onChange={(val) => this.onUnitChange('morningSnack', val, index)} disabled >
                                                    <option selected value="" disabled>-</option>
                                                    <option value="pc">pc</option>
                                                    <option value="tablespoon">tablespoon</option>
                                                    <option value="teaspoon">teaspoon</option>
                                                    <option value="glass">glass (250 ml)</option>
                                                    <option value="cup">cup (240 ml)</option>
                                                    <option value="katori">katori (150 gm)</option>
                                                    <option value="bowl">bowl (325 gm)</option>
                                                    <option value="mug">mug (350 ml)</option>
                                                </select>
                                            </div>

                                            <div className="form-group col">
                                                <label>Calories</label>
                                                <input type="number" className="form-control"
                                                    value={data.calorieContent} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Protein</label>
                                                <input type="number" className="form-control"
                                                    value={data.protein} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Fat</label>
                                                <input type="number" className="form-control"
                                                    value={data.fat} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Carbs</label>
                                                <input type="number" className="form-control"
                                                    value={data.carb} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Fibre</label>
                                                <input type="number" className="form-control"
                                                    value={data.fibre} disabled step=".01"
                                                />
                                            </div>


                                        </div>))}

                                    <div className="mb-5">
                                        <span className="btn btn-info" onClick={(val) => this.addMoreFood('morningSnack', val, i)}>
                                            <i className="fa fa-plus-square"></i> Add more food item
                                        </span>
                                    </div>

                                    <div className="form-group row">
                                        <div className="form-group col">
                                            <label>Diet Calories</label>
                                            <input type="text" className="form-control" name="morningSnacksDietCalorie"
                                                value={option.morningSnacksDietCalorie} />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Protein</label>
                                            <input type="text" className="form-control" name="morningSnacksDietProtein"
                                                value={option.morningSnacksDietProtein}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fat</label>
                                            <input type="text" className="form-control" name="morningSnacksDietFat"
                                                value={option.morningSnacksDietFat}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Carbs</label>
                                            <input type="text" className="form-control" name="morningSnacksDietCarbs"
                                                value={option.morningSnacksDietCarbs}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fibre</label>
                                            <input type="text" className="form-control" name="morningSnacksDietFibre"
                                                value={option.morningSnacksDietFibre}
                                            />
                                        </div>
                                    </div>

                                </React.Fragment>
                            ))}
                        </div>



                        <div className="form_group_wrapper">

                            <h4 className="cm_pos_rel cm_orange">Break Fast*
                                <span className="btn btn-info" onClick={(val) => this.addMoreOption('breakfast', val)}><i className="fa fa-plus-square"></i> Add Option</span>
                            </h4>

                            <div className="row">
                                <div className="form-group col-sm-6">
                                    <label>Calories</label>
                                    <input className="form-control" type="number" placeholder="Calories" value={recommendedBreakfastCalories} onChange={this.handleOnChange} name="recommendedBreakfastCalories" />
                                </div>

                                <div className="form-group col-sm-6">
                                    <label>Timing:</label>
                                    <input className="form-control" type="time" name="breakfastDietTime" value={breakfastDietTime} onChange={this.handleOnChange} />
                                </div>
                            </div>



                            {breakfastListing.map((option, i) => (
                                <React.Fragment key={i}>
                                    <h4 className="cm_pos_rel">Break Fast Option {i + 1}
                                        <span className="btn btn-danger" onClick={() => this.removeOption('breakfast', i)}><i className="fa fa-trash"></i></span>
                                    </h4>

                                    {option.breakfastDiet.map((data, index) => (
                                        <div className="row" key={index}>
                                            <div className="form-group col-6">
                                                <label>food {index + 1} </label>
                                                <Select
                                                    isSearchable
                                                    value={data}
                                                    options={foodItems}
                                                    onChange={(val) => this.onFoodChange('breakfast', val, index, i)}
                                                />
                                            </div>


                                            <div className="form-group col-3">
                                                <label>Quantity</label>
                                                <input type="number" className="form-control" value={data.quantity} min={1} max={20} onChange={(val) => this.onQtyChange('breakfast', val, index, i)} />
                                            </div>


                                            <div className="form-group col-3">
                                                <i className="fa fa-minus-square cm_abs" onClick={(val) => this.deleteFoodItem('breakfast', index, val, i)} />
                                                <label>Unit</label>
                                                <select className="form-control" value={data.unit} onChange={(val) => this.onUnitChange('breakfast', val, index)} disabled>
                                                    <option selected value="" disabled>-</option>
                                                    <option value="pc">pc</option>
                                                    <option value="tablespoon">tablespoon</option>
                                                    <option value="teaspoon">teaspoon</option>
                                                    <option value="glass">glass (250 ml)</option>
                                                    <option value="cup">cup (240 ml)</option>
                                                    <option value="katori">katori (150 gm)</option>
                                                    <option value="bowl">bowl (325 gm)</option>
                                                    <option value="mug">mug (350 ml)</option>
                                                </select>
                                            </div>

                                            <div className="form-group col">
                                                <label>Calories</label>
                                                <input type="number" className="form-control"
                                                    value={data.calorieContent} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Protein</label>
                                                <input type="number" className="form-control"
                                                    value={data.protein} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Fat</label>
                                                <input type="number" className="form-control"
                                                    value={data.fat} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Carbs</label>
                                                <input type="number" className="form-control"
                                                    value={data.carb} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Fibre</label>
                                                <input type="number" className="form-control"
                                                    value={data.fibre} disabled step=".01"
                                                />
                                            </div>

                                        </div>))}



                                    <div className="mb-5">
                                        <span className="btn btn-info" onClick={(val) => this.addMoreFood('breakfast', val, i)}>
                                            <i className="fa fa-plus-square" /> Add more food item
                                        </span>
                                    </div>

                                    <div className="form-group row">
                                        <div className="form-group col">
                                            <label>Diet Calories</label>
                                            <input type="text" className="form-control" name="breakfastDietCalorie"
                                                value={option.breakfastDietCalorie} />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Protein</label>
                                            <input type="text" className="form-control" name="breakfastDietProtein"
                                                value={option.breakfastDietProtein}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fat</label>
                                            <input type="text" className="form-control" name="breakfastDietFat"
                                                value={option.breakfastDietFat}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Carbs</label>
                                            <input type="text" className="form-control" name="breakfastDietCarbs"
                                                value={option.breakfastDietCarbs}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fibre</label>
                                            <input type="text" className="form-control" name="breakfastDietFibre"
                                                value={option.breakfastDietFibre}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>


                        <div className="form_group_wrapper">

                            <h4 className="cm_pos_rel cm_orange mt-0">Mid Morning
                                <span className="btn btn-info" onClick={(val) => this.addMoreOption('midMorning', val)}><i className="fa fa-plus-square"></i> Add Option</span>
                            </h4>

                            <div className="row">
                                <div className="form-group col-sm-6">
                                    <label>Calories</label>
                                    <input className="form-control" type="number" placeholder="Calories" value={recommendedMidMorningCalories} onChange={this.handleOnChange} name="recommendedMidMorningCalories" />
                                </div>

                                <div className="form-group col-sm-6">
                                    <label>Timing:</label>
                                    <input className="form-control" type="time" name="midMorningDietTime" value={midMorningDietTime} onChange={this.handleOnChange} />
                                </div>
                            </div>




                            {midMorningDietListing.map((option, i) => (
                                <React.Fragment key={i}>
                                    <h4 className="cm_pos_rel">Mid Morning Option {i + 1}
                                        <span className="btn btn-danger" onClick={() => this.removeOption('midMorning', i)}><i className="fa fa-trash"></i></span>
                                    </h4>


                                    {option.midMorningDiet.map((data, index) => (
                                        <div className="row" key={index}>
                                            <div className="form-group col-6">
                                                <label>food {index + 1} </label>
                                                <Select
                                                    isSearchable
                                                    value={data}
                                                    options={foodItems}
                                                    onChange={(val) => this.onFoodChange('midMorning', val, index, i)}
                                                />
                                            </div>


                                            <div className="form-group col-3">
                                                <label>Quantity</label>
                                                <input type="number" className="form-control" value={data.quantity} min={1} max={20} onChange={(val) => this.onQtyChange('midMorning', val, index, i)} />
                                            </div>


                                            <div className="form-group col-3">
                                                <i className="fa fa-minus-square cm_abs" onClick={(val) => this.deleteFoodItem('midMorning', index, val, i)} />
                                                <label>Unit</label>
                                                <select className="form-control" value={data.unit} onChange={(val) => this.onUnitChange('midMorning', val, index)} disabled>
                                                    <option selected value="" disabled>-</option>
                                                    <option value="pc">pc</option>
                                                    <option value="tablespoon">tablespoon</option>
                                                    <option value="teaspoon">teaspoon</option>
                                                    <option value="glass">glass (250 ml)</option>
                                                    <option value="cup">cup (240 ml)</option>
                                                    <option value="katori">katori (150 gm)</option>
                                                    <option value="bowl">bowl (325 gm)</option>
                                                    <option value="mug">mug (350 ml)</option>
                                                </select>
                                            </div>

                                            <div className="form-group col">
                                                <label>Calories</label>
                                                <input type="number" className="form-control"
                                                    value={data.calorieContent} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Protein</label>
                                                <input type="number" className="form-control"
                                                    value={data.protein} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Fat</label>
                                                <input type="number" className="form-control"
                                                    value={data.fat} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Carbs</label>
                                                <input type="number" className="form-control"
                                                    value={data.carb} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Fibre</label>
                                                <input type="number" className="form-control"
                                                    value={data.fibre} disabled step=".01"
                                                />
                                            </div>

                                        </div>))}



                                    <div className="mb-5">
                                        <span className="btn btn-info" onClick={(val) => this.addMoreFood('midMorning', val, i)}>
                                            <i className="fa fa-plus-square"></i> Add more food item
                                        </span>
                                    </div>

                                    <div className="form-group row">
                                        <div className="form-group col">
                                            <label>Diet Calories</label>
                                            <input type="text" className="form-control" name="midMorningDietCalorie"
                                                value={option.midMorningDietCalorie} />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Protein</label>
                                            <input type="text" className="form-control" name="midMorningDietProtein"
                                                value={option.midMorningDietProtein}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fat</label>
                                            <input type="text" className="form-control" name="midMorningDietFat"
                                                value={option.midMorningDietFat}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Carbs</label>
                                            <input type="text" className="form-control" name="midMorningDietCarbs"
                                                value={option.midMorningDietCarbs}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fibre</label>
                                            <input type="text" className="form-control" name="midMorningDietFibre"
                                                value={option.midMorningDietFibre}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}

                        </div>

                        <div className="form_group_wrapper">

                            <h4 className="cm_pos_rel cm_orange">Lunch*
                                <span className="btn btn-info" onClick={(val) => this.addMoreOption('lunch', val)}><i className="fa fa-plus-square"></i> Add Option</span>
                            </h4>

                            <div className="row">
                                <div className="form-group col-sm-6">
                                    <label>Calories</label>
                                    <input className="form-control" type="number" placeholder="Calories" value={recommendedLunchCalories} onChange={this.handleOnChange} name="recommendedLunchCalories" />
                                </div>

                                <div className="form-group col-sm-6">
                                    <label>Timing:</label>
                                    <input className="form-control" type="time" name="lunchDietTime" value={lunchDietTime} onChange={this.handleOnChange} />
                                </div>
                            </div>




                            {lunchListing.map((option, i) => (
                                <React.Fragment key={i}>
                                    <h4 className="cm_pos_rel">Lunch Option {i + 1}
                                        <span className="btn btn-danger" onClick={() => this.removeOption('lunch', i)}><i className="fa fa-trash"></i></span>
                                    </h4>

                                    {option.lunchDiet.map((data, index) => (
                                        <div className="row" key={index}>
                                            <div className="form-group col-6">
                                                <label>food {index + 1}</label>
                                                <Select
                                                    isSearchable
                                                    value={data}
                                                    options={foodItems}
                                                    onChange={(val) => this.onFoodChange('lunch', val, index, i)}
                                                />
                                            </div>


                                            <div className="form-group col-3">
                                                <label>Quantity</label>
                                                <input type="number" value={data.quantity} className="form-control" min={1} max={20} onChange={(val) => this.onQtyChange('lunch', val, index, i)} />
                                            </div>


                                            <div className="form-group col-3">
                                                <i className="fa fa-minus-square cm_abs" onClick={(val) => this.deleteFoodItem('lunch', index, val, i)} />
                                                <label>Unit</label>
                                                <select className="form-control" value={data.unit} onChange={(val) => this.onUnitChange('lunch', val, index)} disabled>
                                                    <option selected value="" disabled>-</option>
                                                    <option value="pc">pc</option>
                                                    <option value="tablespoon">tablespoon</option>
                                                    <option value="teaspoon">teaspoon</option>
                                                    <option value="glass">glass (250 ml)</option>
                                                    <option value="cup">cup (240 ml)</option>
                                                    <option value="katori">katori (150 gm)</option>
                                                    <option value="bowl">bowl (325 gm)</option>
                                                    <option value="mug">mug (350 ml)</option>
                                                </select>
                                            </div>

                                            <div className="form-group col">
                                                <label>Calories</label>
                                                <input type="number" className="form-control"
                                                    value={data.calorieContent} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Protein</label>
                                                <input type="number" className="form-control"
                                                    value={data.protein} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Fat</label>
                                                <input type="number" className="form-control"
                                                    value={data.fat} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Carbs</label>
                                                <input type="number" className="form-control"
                                                    value={data.carb} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Fibre</label>
                                                <input type="number" className="form-control"
                                                    value={data.fibre} disabled step=".01"
                                                />
                                            </div>
                                        </div>))}



                                    <div className="mb-5">
                                        <span className="btn btn-info" onClick={(val) => this.addMoreFood('lunch', val, i)}>
                                            <i className="fa fa-plus-square"></i> Add more food item
                                        </span>
                                    </div>

                                    <div className="form-group row">
                                        <div className="form-group col">
                                            <label>Diet Calories</label>
                                            <input type="text" className="form-control" name="lunchDietCalorie"
                                                value={option.lunchDietCalorie} />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Protein</label>
                                            <input type="text" className="form-control" name="lunchDietProtein"
                                                value={option.lunchDietProtein}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fat</label>
                                            <input type="text" className="form-control" name="lunchDietFat"
                                                value={option.lunchDietFat}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Carbs</label>
                                            <input type="text" className="form-control" name="lunchDietCarbs"
                                                value={option.lunchDietCarbs}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fibre</label>
                                            <input type="text" className="form-control" name="lunchDietFibre"
                                                value={option.lunchDietFibre}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>

                        <div className="form_group_wrapper">

                            <h4 className="cm_pos_rel cm_orange">Mid Evening
                                <span className="btn btn-info" onClick={(val) => this.addMoreOption('midEvening', val)}><i className="fa fa-plus-square"></i> Add Option</span>
                            </h4>

                            <div className="row">
                                <div className="form-group col-sm-6">
                                    <label>Calories</label>
                                    <input className="form-control" type="number" placeholder="Calories" value={recommendedMidEveningCalories} onChange={this.handleOnChange} name="recommendedMidEveningCalories" />
                                </div>

                                <div className="form-group col-sm-6">
                                    <label>Timing:</label>
                                    <input className="form-control" type="time" name="midEveningDietTime" value={midEveningDietTime} onChange={this.handleOnChange} />
                                </div>
                            </div>


                            {midEveningDietListing.map((option, i) => (
                                <React.Fragment key={i}>

                                    <h4 className="cm_pos_rel">Mid Evening Option {i + 1}
                                        <span className="btn btn-danger" onClick={() => this.removeOption('midEvening', i)}><i className="fa fa-trash"></i></span>
                                    </h4>

                                    {option.midEveningDiet.map((data, index) => (
                                        <div className="row" key={index}>
                                            <div className="form-group col-6">
                                                <label>food {index + 1} </label>
                                                <Select
                                                    isSearchable
                                                    value={data}
                                                    options={foodItems}
                                                    onChange={(val) => this.onFoodChange('midEvening', val, index, i)}
                                                />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Quantity</label>
                                                <input type="number" className="form-control" value={data.quantity} min={1} max={20} onChange={(val) => this.onQtyChange('midEvening', val, index, i)} />
                                            </div>


                                            <div className="form-group col-3">
                                                <i className="fa fa-minus-square cm_abs" onClick={(val) => this.deleteFoodItem('midEvening', index, val, i)} />
                                                <label>Unit</label>
                                                <select className="form-control" value={data.unit} onChange={(val) => this.onUnitChange('midEvening', val, index)} disabled>
                                                    <option selected value="" disabled>-</option>
                                                    <option value="pc">pc</option>
                                                    <option value="tablespoon">tablespoon</option>
                                                    <option value="teaspoon">teaspoon</option>
                                                    <option value="glass">glass (250 ml)</option>
                                                    <option value="cup">cup (240 ml)</option>
                                                    <option value="katori">katori (150 gm)</option>
                                                    <option value="bowl">bowl (325 gm)</option>
                                                    <option value="mug">mug (350 ml)</option>
                                                </select>
                                            </div>

                                            <div className="form-group col">
                                                <label>Calories</label>
                                                <input type="number" className="form-control"
                                                    value={data.calorieContent} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Protein</label>
                                                <input type="number" className="form-control"
                                                    value={data.protein} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Fat</label>
                                                <input type="number" className="form-control"
                                                    value={data.fat} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Carbs</label>
                                                <input type="number" className="form-control"
                                                    value={data.carb} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Fibre</label>
                                                <input type="number" className="form-control"
                                                    value={data.fibre} disabled step=".01"
                                                />
                                            </div>

                                        </div>))}



                                    <div className="mb-5">
                                        <span className="btn btn-info" onClick={(val) => this.addMoreFood('midEvening', val, i)}>
                                            <i className="fa fa-plus-square"></i> Add more food item
                                        </span>
                                    </div>

                                    <div className="form-group row">
                                        <div className="form-group col">
                                            <label>Diet Calories</label>
                                            <input type="text" className="form-control" name="midEveningDietCalorie"
                                                value={option.midEveningDietCalorie} />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Protein</label>
                                            <input type="text" className="form-control" name="midEveningDietProtein"
                                                value={option.midEveningDietProtein}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fat</label>
                                            <input type="text" className="form-control" name="midEveningDietFat"
                                                value={option.midEveningDietFat}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Carbs</label>
                                            <input type="text" className="form-control" name="midEveningDietCarbs"
                                                value={option.midEveningDietCarbs}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fibre</label>
                                            <input type="text" className="form-control" name="midEveningDietFibre"
                                                value={option.midEveningDietFibre}
                                            />
                                        </div>
                                    </div>

                                </React.Fragment>))}
                        </div>


                        <div className="form_group_wrapper">

                            <h4 className="cm_pos_rel cm_orange">Dinner*
                                <span className="btn btn-info" onClick={(val) => this.addMoreOption('dinner', val)}><i className="fa fa-plus-square"></i> Add Option</span>
                            </h4>

                            <div className="row">
                                <div className="form-group col-sm-6">
                                    <label>Calories</label>
                                    <input className="form-control" type="number" placeholder="Calories" value={recommendedDinnerCalories} onChange={this.handleOnChange} name="recommendedDinnerCalories" />
                                </div>

                                <div className="form-group col-sm-6">
                                    <label>Timing:</label>
                                    <input className="form-control" type="time" name="dinnerDietTime" value={dinnerDietTime} onChange={this.handleOnChange} />
                                </div>
                            </div>




                            {dinnerListing.map((option, i) => (
                                <React.Fragment key={i}>
                                    <h4 className="cm_pos_rel">Dinner Option {i + 1}
                                        <span className="btn btn-danger" onClick={() => this.removeOption('dinner', i)}><i className="fa fa-trash"></i></span>
                                    </h4>

                                    {option.dinnerDiet.map((data, index) => (
                                        <div className="row" key={index}>
                                            <div className="form-group col-6">
                                                <label>food {index + 1}</label>

                                                <Select
                                                    isSearchable
                                                    value={data}
                                                    options={foodItems}
                                                    onChange={(val) => this.onFoodChange('dinner', val, index, i)}
                                                />
                                            </div>


                                            <div className="form-group col-3">
                                                <label>Quantity</label>
                                                <input type="number" className="form-control" value={data.quantity} min={1} max={20} onChange={(val) => this.onQtyChange('dinner', val, index, i)} />
                                            </div>


                                            <div className="form-group col-3">
                                                <i className="fa fa-minus-square cm_abs" onClick={(val) => this.deleteFoodItem('dinner', index, val, i)} />
                                                <label>Unit</label>
                                                <select className="form-control" value={data.unit} onChange={(val) => this.onUnitChange('dinner', val, index)} disabled>
                                                    <option selected value="" disabled>-</option>

                                                    <option value="pc">pc</option>
                                                    <option value="tablespoon">tablespoon</option>
                                                    <option value="teaspoon">teaspoon</option>
                                                    <option value="glass">glass (250 ml)</option>
                                                    <option value="cup">cup (240 ml)</option>
                                                    <option value="katori">katori (150 gm)</option>
                                                    <option value="bowl">bowl (325 gm)</option>
                                                    <option value="mug">mug (350 ml)</option>
                                                </select>
                                            </div>

                                            <div className="form-group col">
                                                <label>Calories</label>
                                                <input type="number" className="form-control"
                                                    value={data.calorieContent} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Protein</label>
                                                <input type="number" className="form-control"
                                                    value={data.protein} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Fat</label>
                                                <input type="number" className="form-control"
                                                    value={data.fat} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Carbs</label>
                                                <input type="number" className="form-control"
                                                    value={data.carb} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Fibre</label>
                                                <input type="number" className="form-control"
                                                    value={data.fibre} disabled step=".01"
                                                />
                                            </div>

                                        </div>))}

                                    <div className="mb-5">
                                        <span className="btn btn-info" onClick={(val) => this.addMoreFood('dinner', val, i)}>
                                            <i className="fa fa-plus-square"></i> Add more food item
                                        </span>
                                    </div>

                                    <div className="form-group row">
                                        <div className="form-group col">
                                            <label>Diet Calories</label>
                                            <input type="text" className="form-control" name="dinnerDietCalorie"
                                                value={option.dinnerDietCalorie} />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Protein</label>
                                            <input type="text" className="form-control" name="dinnerDietProtein"
                                                value={option.dinnerDietProtein}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fat</label>
                                            <input type="text" className="form-control" name="dinnerDietFat"
                                                value={option.dinnerDietFat}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Carbs</label>
                                            <input type="text" className="form-control" name="dinnerDietCarbs"
                                                value={option.dinnerDietCarbs}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fibre</label>
                                            <input type="text" className="form-control" name="dinnerDietFibre"
                                                value={option.dinnerDietFibre}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>))}
                        </div>


                        <div className="form_group_wrapper">

                            <h4 className="cm_pos_rel cm_orange">Bed Time
                                <span className="btn btn-info" onClick={(val) => this.addMoreOption('bedTime', val)}><i className="fa fa-plus-square"></i> Add Option</span>
                            </h4>

                            <div className="row">
                                <div className="form-group col-sm-6">
                                    <label>Calories</label>
                                    <input className="form-control" type="number" placeholder="Calories" value={recommendedBedTimeCalories} onChange={this.handleOnChange} name="recommendedBedTimeCalories" />
                                </div>

                                <div className="form-group col-sm-6">
                                    <label>Timing:</label>
                                    <input className="form-control" type="time" name="bedTimeDietTime" value={bedTimeDietTime} onChange={this.handleOnChange} />
                                </div>
                            </div>




                            {bedTimeDietListing.map((option, i) => (
                                <React.Fragment key={i}>
                                    <h4 className="cm_pos_rel">Bed Time Option {i + 1}
                                        <span className="btn btn-danger" onClick={() => this.removeOption('bedTime', i)}><i className="fa fa-trash"></i></span>
                                    </h4>


                                    {option.bedTimeDiet.map((data, index) => (
                                        <div className="row" key={index}>
                                            <div className="form-group col-6">
                                                <label>food {index + 1}</label>
                                                <Select
                                                    isSearchable
                                                    value={data}
                                                    options={foodItems}
                                                    onChange={(val) => this.onFoodChange('bedTime', val, index, i)}
                                                />
                                            </div>

                                            <div className="form-group col-3">
                                                <label>Quantity</label>
                                                <input type="number" className="form-control" value={data.quantity} min={1} max={20} onChange={(val) => this.onQtyChange('bedTime', val, index, i)} />
                                            </div>

                                            <div className="form-group col-3">
                                                <i className="fa fa-minus-square cm_abs" onClick={(val) => this.deleteFoodItem('bedTime', index, val, i)} />
                                                <label>Unit</label>
                                                <select className="form-control" value={data.unit} onChange={(val) => this.onUnitChange('bedTime', val, index)} disabled>
                                                    <option selected value="" disabled>-</option>
                                                    <option value="pc">pc</option>
                                                    <option value="tablespoon">tablespoon</option>
                                                    <option value="teaspoon">teaspoon</option>
                                                    <option value="glass">glass (250 ml)</option>
                                                    <option value="cup">cup (240 ml)</option>
                                                    <option value="katori">katori (150 gm)</option>
                                                    <option value="bowl">bowl (325 gm)</option>
                                                    <option value="mug">mug (350 ml)</option>
                                                </select>
                                            </div>

                                            <div className="form-group col">
                                                <label>Calories</label>
                                                <input type="number" className="form-control"
                                                    value={data.calorieContent} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Protein</label>
                                                <input type="number" className="form-control"
                                                    value={data.protein} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Fat</label>
                                                <input type="number" className="form-control"
                                                    value={data.fat} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Carbs</label>
                                                <input type="number" className="form-control"
                                                    value={data.carb} disabled step=".01"
                                                />
                                            </div>

                                            <div className="form-group col">
                                                <label>Fibre</label>
                                                <input type="number" className="form-control"
                                                    value={data.fibre} disabled step=".01"
                                                />
                                            </div>
                                        </div>))}


                                    <div className="mb-5">
                                        <span className="btn btn-info" onClick={(val) => this.addMoreFood('bedTime', val, i)}>
                                            <i className="fa fa-plus-square"></i> Add more food item
                                        </span>
                                    </div>

                                    <div className="form-group row">
                                        <div className="form-group col">
                                            <label>Diet Calories</label>
                                            <input type="text" className="form-control" name="bedTimeDietCalorie"
                                                value={option.bedTimeDietCalorie} />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Protein</label>
                                            <input type="text" className="form-control" name="bedTimeDietProtein"
                                                value={option.bedTimeDietProtein}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fat</label>
                                            <input type="text" className="form-control" name="bedTimeDietFat"
                                                value={option.bedTimeDietFat}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Carbs</label>
                                            <input type="text" className="form-control" name="bedTimeDietCarbs"
                                                value={option.bedTimeDietCarbs}
                                            />
                                        </div>
                                        <div className="form-group col">
                                            <label>Diet Fibre</label>
                                            <input type="text" className="form-control" name="bedTimeDietFibre"
                                                value={option.bedTimeDietFibre}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>))}
                        </div>



                        <div className="form_group_wrapper">
                            <h4 className="text-center text-danger">{validationError}</h4>
                            <button className="btn btn-success" type="submit" disabled={updateDisabled}>Update.</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

export default EditDietPlan;
import React, { Component } from "react";
import dateFormat from "dateformat";
import Pagination from "react-js-pagination";
import HOC from "../../HOC";
import { LoaderSvg } from "../Reusable";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import {
  addCareer,
  updateCareer,
  getCareerListing,
  getCareerConfig,
  changeCareerStatus,
} from "../../config/services/careerService";

import { toast } from "react-toastify";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import Select from "react-select";

class Trainers extends Component {
  constructor(props) {
    super(props);

    this.searchTimeOut = null;
    this.state = {
      openAddCareerPopup: false,
      openViewTrainer: false,
      viewTrainerDetails: {},
      careersList: [],
      searchTimeOut: 0,
      loader: false,
      validationError: "",
      sortType: 1,
      sortField: "",
      pageNo: 1,
      countsPerPage: 10,
      editCareerPopup: false,
      submitLoader: false,
      department: "",
      location: "",
      jobType: "",
      category: "",
      name: "",
      description: "",
      requirements: "",
      tempCareer: "",
      categoryOrder: "",
      jobOrder: "",
      metaTitle:"",
      metaDescription:"",
      _status: [
        {
          id: 1,
          name: "Active",
        },
        {
          id: 0,
          name: "InActive",
        },
      ],
      status: {
        id: 1,
        name: "Active",
      },
    };
  }

  handleAddTrainerPopup = () => {
    this.setState({
      openAddCareerPopup: !this.state.openAddCareerPopup,
      department: "",
      location: "",
      jobType: "",
      category: "",
      name: "",
      description: "",
      requirements: "",
      categoryOrder: "",
      jobOrder: "",
      metaTitle:"",
      metaDescription:"",
      status: {
        id: 1,
        name: "Active",
      },
    });
  };

  closeModal = () =>
    this.setState({
      openAddCareerPopup: false,
      openViewTrainer: false,
      viewTrainerDetails: {},
      department: "",
      location: "",
      jobType: "",
      category: "",
      name: "",
      description: "",
      requirements: "",
      editCareerPopup: false,
      submitLoader: false,
      status: {
        id: 1,
        name: "Active",
      },
      categoryOrder: "",
      jobOrder: "",
    });

  componentDidMount() {
    try {

      this._getCareerConfig();
      let params = {
        params: {
          count: this.state.countsPerPage,
          sortField: "created",
          sortType: -1,
          pageNo: this.state.pageNo - 1,
        },
      };
  
      this.getCareerListing(params);
      
    } catch (error) {
        console.log(error);
      
    }

  }

  handlePagination = (pageNumber) =>
    this.setState({ pageNo: pageNumber }, () => {
      let params = {
        params: {
          pageNo: this.state.pageNo - 1,
          count: this.state.countsPerPage,
        },
      };

      this.getCareerListing(params);
    });

  getCareerListing(params) {
    try {
      let self = this;
      this.setState({ loader: true });

      if (!params) {
        params = {
          params: {
            pageNo: this.state.pageNo - 1,
            count: this.state.countsPerPage,
            sortField: "created",
            sortType: -1,
          },
        };
      }

      getCareerListing(params).then(function (res) {
        const response = res.data;
        if (response.statusCode == 1) {
          self.setState({
            careersList: response.responseData.result[0],
            totalTrainers: response.responseData.result[1],
            loader: false,
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  _getCareerConfig = async () => {
    try {
      let response = await getCareerConfig();
      this.setState({ config: response.data.responseData.result });
    } catch (error) {
      console.log(error);
    }
  };

  handleSelectType = (type, name) => {
    this.setState({ [name]: type, validationError: "" });
  };

  submitAddCareer = async (e) => {
    e.preventDefault();
    const {
      department,
      location,
      jobType,
      category,
      name,
      description,
      requirements,
      categoryOrder,
      jobOrder,
      status,
      metaTitle,
      metaDescription
    } = this.state;

    let params = {
      department,
      location,
      jobType,
      category,
      name,
      description,
      requirements,
      categoryOrder,
      jobOrder,
      status: status && status.id ? status.id : 0,
      metaTitle,
      metaDescription
    };

    if (!name || !category || !categoryOrder || !jobOrder ||!metaTitle || !metaDescription) {
      this.setState({
        validationError: "Fields marked (*) are mandatory.",
      });
      return;
    } else {
      this.setState({ submitLoader: true });

      this.setState({ validationError: "" });
      this.submitAddCareerApi(params);
    }
  };

  submitAddCareerApi = (params) => {
    addCareer(params).then((res) => {
      this.setState({ submitLoader: false });
      const response = res.data;
      if (response.statusCode == 1) {
        toast.success(response.responseData.message);
        this.closeModal();
        let params = {
          params: {
            count: this.state.countsPerPage,
            sortField: "created",
            sortType: -1,
          },
        };
        this.getCareerListing(params);
      } else if (response.statusCode == 0) {
        this.setState({
          validationError: response.error.errorMessage,
          submitLoader: false,
        });
      }
    });
  };

  handleTrainerSearch = (e) => {
    let params = {
      params: {
        count: this.state.countsPerPage,
        search: e.target.value,
      },
    };

    if (this.searchTimeOut) clearTimeout(this.searchTimeOut);

    this.searchTimeOut = setTimeout(
      function () {
        this.getCareerListing(params);
      }.bind(this),
      400
    );
  };

  handleEditTrainer = (trainer) => {
    let { config } = this.state;
    let {
      department,
      location,
      jobType,
      category,
      name,
      description,
      requirements,
      _id,
      jobOrder,
      categoryOrder,
      metaTitle,
      metaDescription
    } = trainer;

    let __status = this.state._status[1];

    if (trainer && trainer.status) {
      __status = this.state._status[0];
    }

    this.setState({
      editCareerPopup: !this.state.editCareerPopup,
      department,
      location,
      jobType,
      category,
      name,
      description,
      requirements,
      tempCareerId: _id,
      jobOrder,
      categoryOrder,
      status: __status,
      metaTitle,
      metaDescription
    });
  };

  submitEditTrainer = async (e) => {
    const {
      department,
      location,
      jobType,
      category,
      name,
      description,
      requirements,
      tempCareerId,
      status,
      jobOrder,
      categoryOrder,
      metaTitle,
      metaDescription
    } = this.state;

    let params = {
      department,
      location,
      jobType,
      category,
      name,
      description,
      requirements,
      careerId: tempCareerId,
      status: status && status.id ? status.id : 0,
      jobOrder,
      categoryOrder,
      metaTitle,
      metaDescription
    };

    if (!name || !category || !categoryOrder || !jobOrder || !metaTitle || !metaDescription) {
      this.setState({
        validationError: "Fields marked (*) are mandatory.",
      });
      return;
    } else {
      this.setState({ submitLoader: true });
      this.setState({ validationError: "" });
    }

    updateCareer(params).then((res) => {
      this.setState({ submitLoader: false });
      const response = res.data;
      if (response.statusCode == 1) {
        toast.success(response.responseData.message);
        this.closeModal();
        this.getCareerListing();
      } else if (response.statusCode == 0) {
        this.setState({ validationError: response.error.errorMessage });
      }
    });
  };

  handleChangeStatus(id, status) {
    this.setState({ [id]: true, progress: 0.5 });

    let self = this;
    let obj = {
      careerId: id,
      careerStatus: status === 1 ? 0 : 1,
    };

    changeCareerStatus(obj).then((res) => {
      if (res.data.statusCode === 1) {
        self.getCareerListing();
        self.setState({ [id]: false });
      }
    });
  }

  // AllTypes
  render() {
    const {
      careersList,
      loader,
      validationError,
      pageNo,
      countsPerPage,
      totalTrainers,
      editCareerPopup,
      submitLoader,
      config,
      department,
      location,
      jobType,
      category,
      openAddCareerPopup,
      name,
      description,
      requirements,
      categoryOrder,
      jobOrder,
      _status,
      status,
      metaTitle,
      metaDescription
    } = this.state;

    return (
      <HOC>
        <div className="body-container-wrapper career-body">
          <div className="body-container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">Careers</li>
            </ol>
            {loader ? (
              <div className="loader_wrapper">
                <LoaderSvg />
              </div>
            ) : null}

            <div className="users_header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-4">
                    <h4 className="cm_page_heading">Careers</h4>
                  </div>

                  <div className="col-md-3 text-right cm_search">
                    <input
                      type="text"
                      className="form-control m-b-md-15"
                      placeholder="Search by Name"
                      name="search"
                      onChange={this.handleTrainerSearch}
                    />
                    <i className="fa fa-search" />
                  </div>

                  <div className="col-md-3  m-b-md-15">
                    <button
                      onClick={() => this.handleAddTrainerPopup()}
                      className="btn btn-primary btn-block"
                    >
                      Add Career
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered table-striped text-center">
                <thead>
                  <tr>
                    <th scope="col">Sr. No</th>
                    <th scope="col" className="filter_text">
                      Name
                    </th>

                    <th scope="col" className="filter_text">
                      Description
                    </th>

                    <th>Location</th>
                    <th scope="col">Category</th>
                    <th scope="col">Job Type</th>
                    <th scope="col">Department</th>
                    <th scope="col" className="filter_text">
                      Created Date
                    </th>
                    <th scope="col" className="action-btn">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {careersList.map((trainer, index) => (
                    <tr key={index}>
                      <td>{index + 1 + (pageNo - 1) * countsPerPage}</td>
                      {/* cm_pos_realtive more_padding */}
                      <td className="">
                        {trainer.name}
                      </td>
                      <td>{trainer.description}</td>

                      <td>{trainer.location}</td>

                      <td>{trainer.category}</td>

                      <td>{trainer.jobType}</td>

                      <td>{trainer.department}</td>

                      <td>{dateFormat(trainer.created, "d mmmm yyyy")}</td>
                      <td>
                        <button
                          className="btn btn-primary btn-xs"
                          onClick={() => this.handleEditTrainer(trainer)}
                        >
                          <i className="fa fa-pencil-square-o" />
                        </button>

                        <span
                          className={`cm_ckeckbox_wrapper ${
                            trainer.status === 1 ? "cm_active" : "cm_inactive"
                          }`}
                          onClick={() =>
                            this.handleChangeStatus(trainer._id, trainer.status)
                          }
                        >
                          <span className="cm_ckeckbox_btn"></span>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {!careersList.length ? (
                <h3 className="empty_error">
                  Sorry, We couldn't find any content!{" "}
                  <span>{this.state.deviceSearch}</span>
                </h3>
              ) : null}
            </div>

            <Pagination
              activePage={pageNo}
              itemsCountPerPage={countsPerPage}
              totalItemsCount={totalTrainers}
              pageRangeDisplayed={4}
              onChange={this.handlePagination}
            />
          </div>
        </div>

        {openAddCareerPopup ? (
          <Modal
            show={openAddCareerPopup}
            onHide={this.closeModal}
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Career</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form className="mb-5">
                <div className="row">

                <div className="form-group col-6">
                    <label>Meta Title*</label>
                    <input
                      type="text"
                      className="form-control"
                      maxLength={100}
                      onChange={(e) =>
                        this.handleSelectType(e.target.value, "metaTitle")
                      }
                      name="metaTitle"
                      value={metaTitle}
                    />
                  </div>

                  <div className="form-group col-6">
                    <label>Meta Description*</label>
                    <input
                      type="text"
                      className="form-control"
                      maxLength={100}
                      onChange={(e) =>
                        this.handleSelectType(e.target.value, "metaDescription")
                      }
                      name="metaDescription"
                      value={metaDescription}
                    />
                  </div>


                  <div className="form-group col-6">
                    <label>Department</label>
                    <input
                      type="text"
                      className="form-control"
                      maxLength={100}
                      onChange={(e) =>
                        this.handleSelectType(e.target.value, "department")
                      }
                      name="department"
                      value={department}
                    />
                  </div>

                  <div className="form-group col-6">
                    <label>Job Type</label>
                    <input
                      type="text"
                      className="form-control"
                      maxLength={100}
                      onChange={(e) =>
                        this.handleSelectType(e.target.value, "jobType")
                      }
                      name="jobType"
                      value={jobType}
                    />
                  </div>

                  <div className="form-group col-6">
                    <label>Category*</label>
                    <input
                      type="text"
                      className="form-control"
                      maxLength={100}
                      onChange={(e) =>
                        this.handleSelectType(e.target.value, "category")
                      }
                      name="category"
                      value={category}
                    />
                  </div>

                  <div className="form-group col-6">
                    <label>Location</label>
                    <input
                      type="text"
                      className="form-control"
                      maxLength={100}
                      onChange={(e) =>
                        this.handleSelectType(e.target.value, "location")
                      }
                      name="location"
                      value={location}
                    />
                  </div>
                  <div className="form-group col-6">
                    <label>Category Order*</label>
                    <input
                      type="number"
                      autoComplete="off"
                      className="form-control"
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      onChange={(e) =>{
                        if(categoryOrder.length > 2 && e.target.value.length > 2){
                          return
                        }
                        this.handleSelectType(e.target.value, "categoryOrder")
                      }}
                      name="categoryOrder"
                      value={categoryOrder}
                    />
                  </div>

                  <div className="form-group col-6">
                    <label>Job Order*</label>
                    <input
                      type="number"
                      className="form-control"
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      onChange={(e) =>{
                        if(jobOrder.length > 2 && e.target.value.length > 2){
                          return
                        }
                        this.handleSelectType(e.target.value, "jobOrder")
                      }}
                      name="jobOrder"
                      value={jobOrder}
                    />
                  </div>
                  <div className="form-group col-6">
                    <label>Status </label>
                    <Select
                      require={true}
                      name="departmentId"
                      placeholder="Select Type"
                      options={_status}
                      value={status}
                      onChange={(val) => this.handleSelectType(val, "status")}
                      getOptionLabel={(opt) => opt.name}
                      getOptionValue={(opt) => opt.id}
                    />
                  </div>

                  {/* <div className="form-group col-6">
                    <label>jobType </label>
                    <Select
                      require={true}
                      name="jobType"
                      placeholder="Select Type"
                      options={config.jobType}
                      value={jobType}
                      onChange={(val) => this.handleSelectType(val, "jobType")}
                      getOptionLabel={(opt) => opt.name}
                      getOptionValue={(opt) => opt.id}
                    />
                  </div>

                  <div className="form-group col-6">
                    <label>Categories </label>
                    <Select
                      require={true}
                      name="categoryId"
                      placeholder="Select Type"
                      options={config.categories}
                      value={categoryId}
                      onChange={(val) =>
                        this.handleSelectType(val, "categoryId")
                      }
                      getOptionLabel={(opt) => opt.name}
                      getOptionValue={(opt) => opt.id}
                    />
                  </div>

                  <div className="form-group col-6">
                    <label>Locations </label>
                    <Select
                      require={true}
                      name="locationId"
                      placeholder="Select Type"
                      options={config.locations}
                      value={locationId}
                      onChange={(val) =>
                        this.handleSelectType(val, "locationId")
                      }
                      getOptionLabel={(opt) => opt.completeAddress}
                      getOptionValue={(opt) => opt.id}
                    />
                  </div> */}
                </div>

                <div className="row">
                  <div className="form-group col">
                    <label>Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      maxLength={150}
                      onChange={(e) =>
                        this.handleSelectType(e.target.value, "name")
                      }
                      name="name"
                      value={name}
                    />
                  </div>

                  <div className="form-group col">
                    <label>Description</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) =>
                        this.handleSelectType(e.target.value, "description")
                      }
                      name="description"
                      value={description}
                    />
                  </div>
                  <div className="form-group col-12">
                    <label>Requirements</label>
                    <ReactQuill
                      theme="snow"
                      value={requirements}
                      onChange={(data) => {
                        this.handleSelectType(data, "requirements");
                      }}
                      height={250}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          ['link', 'image', 'video'],
                          ["code-block"],
                          ["bold", "italic", "underline", "strike"], // toggled buttons
                          ["blockquote", "code-block"],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [{ script: "sub" }, { script: "super" }], // superscript/subscript
                          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                          [{ direction: "rtl" }], // text direction
                          [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                          [{ font: [] }],
                          [{ align: [] }],
                          ["clean"],
                        ],
                      }}
                    />
                  </div>
                </div>
              </form>
            </Modal.Body>

            {validationError && (
              <h6 className="text-danger text-center">{validationError}</h6>
            )}
            {submitLoader && <ProgressBar animated now={100} />}
            <Modal.Footer>
              <Button
                variant="info"
                type="submit"
                onClick={this.submitAddCareer}
                disabled={submitLoader}
              >
                {" "}
                {submitLoader ? "Submitting Data...." : "Submit"}
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}

        {editCareerPopup ? (
          <Modal
            show={editCareerPopup}
            onHide={this.closeModal}
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Career</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form className="mb-5">
                <div className="row">
                <div className="form-group col-6">
                    <label>Meta Title*</label>
                    <input
                      type="text"
                      className="form-control"
                      maxLength={100}
                      onChange={(e) =>
                        this.handleSelectType(e.target.value, "metaTitle")
                      }
                      name="metaTitle"
                      value={metaTitle}
                    />
                  </div>

                  <div className="form-group col-6">
                    <label>Meta Description*</label>
                    <input
                      type="text"
                      className="form-control"
                      maxLength={100}
                      onChange={(e) =>
                        this.handleSelectType(e.target.value, "metaDescription")
                      }
                      name="metaDescription"
                      value={metaDescription}
                    />
                  </div>
                  <div className="form-group col-6">
                    <label>Department</label>
                    <input
                      type="text"
                      maxLength={100}
                      className="form-control"
                      onChange={(e) =>
                        this.handleSelectType(e.target.value, "department")
                      }
                      name="department"
                      value={department}
                    />
                  </div>

                  <div className="form-group col-6">
                    <label>Job Type</label>
                    <input
                      type="text"
                      maxLength={100}
                      className="form-control"
                      onChange={(e) =>
                        this.handleSelectType(e.target.value, "jobType")
                      }
                      name="jobType"
                      value={jobType}
                    />
                  </div>

                  <div className="form-group col-6">
                    <label>Category*</label>
                    <input
                      type="text"
                      maxLength={100}
                      className="form-control"
                      onChange={(e) =>
                        this.handleSelectType(e.target.value, "category")
                      }
                      name="category"
                      value={category}
                    />
                  </div>

                  <div className="form-group col-6">
                    <label>Location</label>
                    <input
                      type="text"
                      maxLength={100}
                      className="form-control"
                      onChange={(e) =>
                        this.handleSelectType(e.target.value, "location")
                      }
                      name="location"
                      value={location}
                    />
                  </div>

                  <div className="form-group col-6">
                    <label>Category Order*</label>
                    <input
                      type="number"
                      className="form-control"
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      onChange={(e) =>{
                        if(categoryOrder.length > 2 && e.target.value.length > 2){
                          return
                        }
                        this.handleSelectType(e.target.value, "categoryOrder")
                      }}
                      name="categoryOrder"
                      value={categoryOrder}
                    />
                  </div>

                  <div className="form-group col-6">
                    <label>Job Order*</label>
                    <input
                      type="number"
                      className="form-control"
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      onChange={(e) =>{
                        if(jobOrder.length > 2 && e.target.value.length > 2){
                          return
                        }
                        this.handleSelectType(e.target.value, "jobOrder")
                      }}
                      name="jobOrder"
                      value={jobOrder}
                    />
                  </div>
                  <div className="form-group col-6">
                    <label>Status </label>
                    <Select
                      require={true}
                      name="departmentId"
                      placeholder="Select Type"
                      options={_status}
                      value={status}
                      onChange={(val) => this.handleSelectType(val, "status")}
                      getOptionLabel={(opt) => opt.name}
                      getOptionValue={(opt) => opt.id}
                    />
                  </div>
                  
                  {/* <div className="form-group col-6">
                    <label>Department </label>
                    <Select
                      require={true}
                      name="departmentId"
                      placeholder="Select Type"
                      options={config.departments}
                      value={departmentId}
                      onChange={(val) =>
                        this.handleSelectType(val, "departmentId")
                      }
                      getOptionLabel={(opt) => opt.name}
                      getOptionValue={(opt) => opt.id}
                    />
                  </div>

                  <div className="form-group col-6">
                    <label>jobType </label>
                    <Select
                      require={true}
                      name="jobType"
                      placeholder="Select Type"
                      options={config.jobType}
                      value={jobType}
                      onChange={(val) => this.handleSelectType(val, "jobType")}
                      getOptionLabel={(opt) => opt.name}
                      getOptionValue={(opt) => opt.id}
                    />
                  </div>

                  <div className="form-group col-6">
                    <label>Categories </label>
                    <Select
                      require={true}
                      name="categoryId"
                      placeholder="Select Type"
                      options={config.categories}
                      value={categoryId}
                      onChange={(val) =>
                        this.handleSelectType(val, "categoryId")
                      }
                      getOptionLabel={(opt) => opt.name}
                      getOptionValue={(opt) => opt.id}
                    />
                  </div>

                  <div className="form-group col-6">
                    <label>Locations </label>
                    <Select
                      require={true}
                      name="locationId"
                      placeholder="Select Type"
                      options={config.locations}
                      value={locationId}
                      onChange={(val) =>
                        this.handleSelectType(val, "locationId")
                      }
                      getOptionLabel={(opt) => opt.completeAddress}
                      getOptionValue={(opt) => opt.id}
                    />
                  </div>
                </div> */}
                </div>

                <div className="row">
                  <div className="form-group col">
                    <label>Name*</label>
                    <input
                      type="text"
                      maxLength={150}
                      className="form-control"
                      onChange={(e) =>
                        this.handleSelectType(e.target.value, "name")
                      }
                      name="name"
                      value={name}
                    />
                  </div>

                  <div className="form-group col">
                    <label>Description</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) =>
                        this.handleSelectType(e.target.value, "description")
                      }
                      name="description"
                      value={description}
                    />
                  </div>
                  <div className="form-group col-12">
                    <label>Requirements</label>
                    <ReactQuill
                      theme="snow"
                      value={requirements}
                      onChange={(data) => {
                        this.handleSelectType(data, "requirements");
                      }}
                      height={250}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          ['link', 'image', 'video'],
                          ["code-block"],
                          ["bold", "italic", "underline", "strike"], // toggled buttons
                          ["blockquote", "code-block"],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [{ script: "sub" }, { script: "super" }], // superscript/subscript
                          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                          [{ direction: "rtl" }], // text direction
                          [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                          [{ font: [] }],
                          [{ align: [] }],
                          ["clean"],
                        ],
                      }}
                    />
                  </div>
                </div>
              </form>
            </Modal.Body>

            {validationError && (
              <h6 className="text-danger text-center">{validationError}</h6>
            )}
            {submitLoader && <ProgressBar animated now={100} />}
            <Modal.Footer>
              <Button
                variant="info"
                type="submit"
                onClick={() => this.submitEditTrainer()}
                disabled={submitLoader}
              >
                {" "}
                {submitLoader ? "Submitting Data...." : "Submit"}
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}
      </HOC>
    );
  }
}

export default Trainers;

import axios from 'axios';
import API from '../Api';



export function addDietician(params) {
    return axios.post(API.ADD_DIETICIAN,params)
}

export function getAllDieticians(params) {
    return axios.get(API.GET_ALL_DIETICIANS,params)
}


export function assignDietician(params) {
    return axios.post(API.ASSIGN_DIETICIAN,params)
}

export function changeDieticianStatus(params) {
    return axios.post(API.CHANGE_DIETICIAN_STATUS,params)
}

export function getDieticianRatings(params) {
    return axios.get(API.GET_DIETICIAN_RATINGS,params)
}


export const DieticianService = {
    addDietician,
    getAllDieticians,
    assignDietician,
    changeDieticianStatus,
    getDieticianRatings
};
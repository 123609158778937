import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import HOC from '../../HOC';
import { Link } from 'react-router-dom';
import { getUser } from '../../config/services/usersService';
import { getUserAnswers } from '../../config/services/QuestionaireService'
import { LoaderSvg } from '../Reusable';
import PersonalDetails from './PersonalDetails'
import Logs from './Logs'
import Questions from './Questions'
import DietPlan from './DietPlan'
import ExercisePlan from './ExercisePlan'
import Messages from './Messages';
import DietPlanApprovePending from './DietPlanApprovePending';
import DietPlanHistory from './DietPlanHistory';
import ProductService from './PrdoductService';
import UserJobDetails from './UserJobDetails';

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            profile: {
                weight: {},
                height: {},
                preferences: {},
                beforePic: [],
                afterPic: [],
                dieticianDetails: {},
            },
            questionAnswer: [],
            dieticianDetails: {},
            loader: true,
        }
    }

    getUserProfile = () => {
        let id = `?id=${this.props.match.params.id}`;
        this.setState({ Loader: true })
        getUser(id)
            .then(res => {
                if (res.data.statusCode === 1) {
                    this.setState({
                        profile: res.data.responseData,
                        Loader: false
                    })
                }
            })
    }

    componentDidMount() {
        this.getUserProfile()
        this.getUserQuestionAnswer()
    }

    getUserQuestionAnswer = () => {
        const { params: { id } } = this.props.match
        let param = {
            params: { reqUserId: id }
        }
        this.setState({ Loader: true })
        getUserAnswers(param)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.setState({
                        questionAnswer: response.responseData.result ? response.responseData.result.questionAnswer : [],
                        Loader: false
                    })
                }
            })
    }

    onTabSelected(e) {
        this.setState({ selectedTab: e });
    }

    render() {
        const { questionAnswer, profile } = this.state
        let requestId = this.props.match.params.requestId;
        let selectedTab = this.props.match.params.selectedTab


        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container cm_profile_wrapper cm_profile_wrapper_bg">
                        <div className="d-sm-flex justify-content-between border-bottom custom-list mb-3">
                            <ol className="breadcrumb breadcrumb-custom">
                                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item"><Link to="/users">Users</Link></li>
                                <li className="breadcrumb-item">Profile</li>
                            </ol>
                        </div>

                        {this.state.Loader
                            ? <div className="loader_wrapper"><LoaderSvg /></div>
                            : null}

                        <Tabs defaultActiveKey={selectedTab ? selectedTab : "profile"} id="uncontrolled-tab-example" >
                            <Tab eventKey="profile" title="Profile" activeKey={selectedTab}>
                                <PersonalDetails userId={this.props.match.params.id} profile={profile} questionAnswer={questionAnswer} getUserProfile={this.getUserProfile} />
                            </Tab>
                            <Tab eventKey="logs" title="Logs">
                                <Tab.Container defaultActiveKey="first">
                                    <Logs id={this.props.match.params.id} />
                                </Tab.Container>
                            </Tab>

                            <Tab eventKey="questions" title="Questions">
                                <Tab.Container defaultActiveKey="first">
                                    <Questions questionAnswer={questionAnswer} />
                                </Tab.Container>
                            </Tab>
                            <Tab eventKey="dietPlan" title="Diet Plan">
                                <DietPlan id={this.props.match.params.id} getUserDietPlanFunc={this.getUserDietPlanFunc}/>
                            </Tab>
                            {/* <Tab eventKey="DietPlanApprovePending" title="Diet Plan Approval Pending">
                                <DietPlanApprovePending userId={this.props.match.params.id} refreshDietPlan={this.refreshDietPlan}  getUserDietPlanFunc={this.getUserDietPlanFunc}/>
                            </Tab> */}
                            <Tab eventKey="dietPlanHistory" title="Diet Plan History">
                                <DietPlanHistory id={this.props.match.params.id} />
                            </Tab>
                            <Tab eventKey="exercisePlan" title="Exercise Plan">
                                <ExercisePlan id={this.props.match.params.id} />
                            </Tab>
                            <Tab eventKey="messages" title="Messages">
                                {profile._id && <Messages id={this.props.match.params.id} userDetails={profile} getUserProfile={this.getUserProfile}/>}
                            </Tab>
                            <Tab eventKey="productService" title="Product Service">
                                <ProductService userId={this.props.match.params.id} profile={profile} />
                            </Tab>
                            {/* <Tab eventKey="jobDetail" title="Job Detail">
                                <UserJobDetails requestId={requestId} />
                            </Tab> */}
                        </Tabs>

                    </div>
                </div>
            </HOC>
        );
    }
}

export default Profile;
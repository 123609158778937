import React, {useState,useEffect} from 'react';
import {Modal, Button} from 'antd';
import axios from "axios";
import {AddEmoji} from '../../config/services/emojiService';
import {  notification, Space } from 'antd';

const AddEditEmoji = ({
    title,
    isModalVisible,
    handleOk,
    handleCancel,
    upload,
    defaultValues,
    editData
}) => {

    
    const [state, setState] = useState(defaultValues);
    const [alertMessage, setMessage] = useState("");
    const [saveAlert, setSaveAlert] = useState(false);
    //console.log(defaultValues);


    const handleInputChange = (event) => {
       setState({
            ...state,
            [event.target.name]: event.target.value
        })
    }

    let handleFileChange = (e) => {
        setState({
            ...state,
            emojiImageName:e.target.files[0].name,
            emojiImage: e.target.files[0],
            emojiImageUrl:URL.createObjectURL(e.target.files[0])
        })
    }


    let submit = () => {
       
      
      if(state.emojiName.length>0 && state.emojiImageName!=="Upload Image" && state.displayOrder >0 )
      {
        
        upload(state).then(data => {
            setState({
                ... defaultValues
            });
            setMessage("Emoji added successfully !")
            openAddEmojiNotification('success',"Emoji added successfully !");
        })
      }
      else{
        setMessage("All fields Are required !")
        openAddEmojiNotification('error',"All fields Are required !");
      }
   

    }
    let editEmojiData=()=>{
        
        
        if(state.emojiName.length>0 && state.emojiImageName!=="Upload Image" && state.displayOrder >0 )
        {

        

        state.emojiId=defaultValues._id;
        editData(state).then(data => {
            setState({
                ... defaultValues
            });
            openEditEmoji('info',"Updated SuccessFully");
        })
    }

    else{
        setMessage("All fields Are required !")
        openEditEmoji('error',"All fields Are required !");
      }
}

    useEffect(() => {

        setState(defaultValues);
    }, [isModalVisible])

    const openAddEmojiNotification = (type,message) => {
        notification[type]({
          message: 'Emoji',
          description:message,
        });
      };

      const openEditEmoji = (type,message) => {
        notification[type]({
          message: 'Emoji',
          description:message,
        });
      };
      

    return (
        <>
            <Modal  title={title}
                visible={isModalVisible}
                onOk={state.edit?editEmojiData:submit}
                onCancel={handleCancel}>
                <div className="form-group">
                    <label>Emoji Name</label>
                    <input type="text" placeholder="Emoji Name" name="emojiName"
                        value={
                            state.emojiName
                        }
                        className="form-control"
                        onChange={handleInputChange} />
                </div>
                <div className="form-group">
                    <label> Display Order</label>
                    <input type="number" placeholder="Display Order" name="displayOrder"
                        value={
                            state.displayOrder
                        }
                        className="form-control"
                        onChange={handleInputChange} />
                </div>
                <div className="form-group">
                    <label>Emoji Image</label>
                    <label className="form-control position-relative text-truncate" htmlFor="uploadImageEmoji">
                        {state.emojiImageName || state.emojiImageUrl.split("/").pop() }
                        <span className="emoji-action-icon">
                            <i className={`fa fa-${state.emojiImageUrl ? "edit" : "plus"}`}></i>
                        </span>
                    </label>
                  {state.emojiImageUrl?<img src={state.emojiImageUrl} width="50px" height="50px"></img>:""}
                    <input type="file" name="emojiImageUrl"
                        onChange={handleFileChange}
                        id="uploadImageEmoji"
                        className="form-control d-none"/>
                </div>
                <div className="form-group">
                    <label>Emoji Platform</label>
                    <select className="form-control" name="emojiPlatform"
                        onChange={handleInputChange}
                        value={
                            state.emojiPlatform
                    }>
                        <option value="1">OneFitPlus App</option>
                        <option value="2">OneFitPlus Web</option>
                        <option value="3">Fitwarz</option>
                    </select>
                </div>
               
            </Modal>
        </>
    )
}

export default AddEditEmoji;

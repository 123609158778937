import React, { Component } from 'react';
import { FoodService, getFoodCategories, uploadFoodFromExcel } from '../../config/services/FoodService';
import { toast } from 'react-toastify';
import Popup from "reactjs-popup"
import { LoaderSvg, VegIcon } from '../Reusable'
import noImage from '../../assets/images/no-image.jpg'
import Dropzone from 'react-dropzone'
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';


class Foods extends Component {
    constructor(props) {
        super(props);
        this.state = {
            foodList: [],
            foodCategories: [],
            foodImagePrev: "",
            foodImage: "",
            openAddFoodPopup: false,
            foodCategoryPreview: '',
            foodName: "",
            foodCategory: '',
            calorieContent: "",
            fatContent: "",
            proteinContent: "",
            carbContent: "",
            energyContent: "",
            ironContent: "",
            sodiumContent: "",
            potassiumContent: "",
            calciumContent: "",
            vitaminContent: "",
            fibreContent: "",
            foodUnit: "",
            foodType: 1,
            foodSaveLoader: false,
            Loader: false,
            tempFoodId: "",
            deleteFoodPopup: false,
            openEditFoodPopup: false,
            foodEditLoader: false,
            multipleFoodPopup: false,
            foodExcelSheet: '',
            uploading: false,
            count: 16,
            search: '',
            filter: '',
            totalFoodCount: 200,
            pageNo: 1,
        }
    }

    componentDidMount() {
        this.getAllCategories();
        this.getFoodItems();
    }

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => this.getFoodItems());

    getAllCategories = (params) => {
        this.setState({ loader: true })
        getFoodCategories(params)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.setState({ foodCategories: response.responseData.result, totalCategories: response.responseData.totalCount, loader: false })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    onSearch = (e) => {
        const { value } = e.target;
        this.setState({ search: value }, () => {
            this.getFoodItems()
        })
    }

    getFoodItems = (params) => {
        let self = this;
        this.setState({ Loader: true })
        const { count, search, filter, pageNo } = this.state;

        params = {
            params: {
                search,
                count,
                filter,
                pageNo: pageNo - 1,
            }
        }

        FoodService.getAllFoods(params)
            .then(function (res) {
                let response = res.data;
                if (response.statusCode == 1) {
                    self.setState({
                        foodList: response.responseData.result,
                        totalFoodCount: response.responseData.totalCount,
                        Loader: false
                    })

                } else if (response.statusCode == 0) {
                    self.setState({ Loader: false })
                    toast.error(response.error.errorMessage)
                }

            })
    }

    handleChange = (e) => {
        this.setState({ error: '' })
        if (e.target.id == 'foodImage') {
            let file = e.target.files[0];
            let reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                    foodImagePrev: reader.result
                });
            }
            reader.readAsDataURL(file)
            this.setState({
                foodImagePrev: file
            })

            this.setState({
                foodImage: e.target.files[0]
            });
        } else {
            this.setState({
                [e.target.id]: e.target.value,
                errors: false
            })
        }
    }

    handleAddFood = () => {
        this.setState({ openAddFoodPopup: true })
    }

    closeModal = () => {
        this.setState({
            openAddFoodPopup: false, error: "", deleteFoodPopup: false, tempFoodId: "", openEditFoodPopup: false,
            foodName: "", calorieContent: "", fatContent: "", proteinContent: "", carbContent: "", energyContent: "", ironContent: "", sodiumContent: "", potassiumContent: "", calciumContent: "", vitaminContent: "", foodType: 1, fibreContent: "", foodImage: "", foodImagePrev: "", foodCategory: '',
            foodCategoryPreview: '', foodUnit: '', multipleFoodPopup: false, foodExcelSheet: '', uploading: false,
        })
    }

    submitAddFood = (e) => {
        e.preventDefault();
        const { foodName, calorieContent, fatContent, proteinContent, carbContent, energyContent, ironContent, sodiumContent, potassiumContent, calciumContent, vitaminContent, foodType, fibreContent, foodImage, foodCategory, foodUnit } = this.state;

        let paramsObj = new FormData();
        paramsObj.append('foodName', foodName)
        paramsObj.append('calorieContent', calorieContent)
        paramsObj.append('fatContent', fatContent)
        paramsObj.append('proteinContent', proteinContent)
        paramsObj.append('carbContent', carbContent)
        paramsObj.append('energyContent', energyContent)
        paramsObj.append('ironContent', ironContent)
        paramsObj.append('sodiumContent', sodiumContent)
        paramsObj.append('potassiumContent', potassiumContent)
        paramsObj.append('calciumContent', calciumContent)
        paramsObj.append('vitaminContent', vitaminContent)
        paramsObj.append('fibreContent', fibreContent)
        paramsObj.append('foodType', foodType)
        paramsObj.append('foodImage', foodImage)
        paramsObj.append('foodCategory', foodCategory)
        paramsObj.append('foodUnit', foodUnit)


        if (foodName === "") {
            this.setState({ error: "Food name can't be empty" })
        } else if (!foodUnit) {
            this.setState({ error: "Food unit can't be empty!" })
        } else if (!calorieContent) {
            this.setState({ error: "Calorie content can't be empty" })
        }
        else if (!foodCategory) {
            this.setState({ error: "Food category can't be empty" })
        } else if (calorieContent == "" && fatContent == "" && proteinContent == "" && carbContent == "" && energyContent == "" && ironContent == "" && sodiumContent == "" && potassiumContent == "" && calciumContent == "" && vitaminContent == "" && foodType == "" && fibreContent == "") {
            this.setState({ error: "At least one field is required" })
        } else {
            this.submitAddFoodApiCall(paramsObj);
        }
    }

    submitAddFoodApiCall = (params) => {
        let self = this;
        this.setState({ foodSaveLoader: true })
        FoodService.addFood(params)
            .then(function (res) {
                let response = res.data;
                if (response.statusCode == 1) {
                    self.closeModal();
                    self.getFoodItems();
                    toast.success(response.responseData.message)
                    self.setState({ foodSaveLoader: false })

                } else if (response.statusCode == 0) {
                    self.setState({ error: response.error.errorMessage, foodSaveLoader: false })
                }
            })
    }

    handleDeleteFood = (data, event) => {
        let tempFoodId = data._id;
        this.setState({ tempFoodId, deleteFoodPopup: true })
    }

    deleteFood = () => {
        const { tempFoodId } = this.state
        let self = this;
        this.setState({ Loader: true })

        let paramsObj = {
            foodId: tempFoodId
        }
        FoodService.deleteFood(paramsObj)
            .then(function (res) {
                let response = res.data;
                if (response.statusCode == 1) {
                    self.closeModal();
                    self.getFoodItems();
                    self.setState({ Loader: false })
                    toast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    self.setState({ error: response.error.errorMessage, Loader: false })
                }
            })
    }


    handleEditFood = (data, event) => {
        this.setState({
            openEditFoodPopup: true,
            tempFoodId: data._id,
            foodName: data.foodName ? data.foodName : '',
            calorieContent: data.calorieContent ? data.calorieContent : '',
            fatContent: data.fatContent ? data.fatContent : '',
            proteinContent: data.proteinContent ? data.proteinContent : '',
            carbContent: data.carbContent ? data.carbContent : '',
            energyContent: data.energyContent ? data.energyContent : '',
            ironContent: data.ironContent ? data.ironContent : '',
            sodiumContent: data.sodiumContent ? data.sodiumContent : '',
            potassiumContent: data.potassiumContent ? data.potassiumContent : '',
            calciumContent: data.calciumContent ? data.calciumContent : '',
            vitaminContent: data.vitaminContent ? data.vitaminContent : '',
            foodType: data.foodType != null ? data.foodType : 1,
            foodUnit: data.foodUnit ? data.foodUnit : '',
            fibreContent: data.fibreContent ? data.fibreContent : '',
            foodImage: data.foodImage,
            foodCategory: data.foodCategory ? data.foodCategory._id : '',
            foodCategoryPreview: data.foodCategory ? data.foodCategory.categoryTitle : '',
        })
    }


    editFoodSubmit = (e) => {
        e.preventDefault();

        const { foodName, calorieContent, fatContent, proteinContent, carbContent, energyContent, ironContent, sodiumContent, potassiumContent, calciumContent, vitaminContent, foodType, fibreContent, foodImage, tempFoodId, foodCategory, foodUnit } = this.state;

        let paramsObj = new FormData();
        paramsObj.append('foodId', tempFoodId)
        paramsObj.append('foodName', foodName)
        paramsObj.append('calorieContent', calorieContent)
        paramsObj.append('fatContent', fatContent)
        paramsObj.append('proteinContent', proteinContent)
        paramsObj.append('carbContent', carbContent)
        paramsObj.append('energyContent', energyContent)
        paramsObj.append('ironContent', ironContent)
        paramsObj.append('sodiumContent', sodiumContent)
        paramsObj.append('potassiumContent', potassiumContent)
        paramsObj.append('calciumContent', calciumContent)
        paramsObj.append('vitaminContent', vitaminContent)
        paramsObj.append('fibreContent', fibreContent)
        paramsObj.append('foodType', foodType)
        paramsObj.append('foodImage', foodImage)
        paramsObj.append('foodCategory', foodCategory)
        paramsObj.append('foodUnit', foodUnit)


        if (foodName == "") {
            this.setState({ error: "Food name can't be empty!" })
        } else if (!foodUnit) {
            this.setState({ error: "Food unit can't be empty!" })
        } else if (!foodCategory) {
            this.setState({ error: "Food category can't be empty!" })
        } else if (!calorieContent) {
            this.setState({ error: "Calorie content can't be empty!" })
        } else if (calorieContent == "" && fatContent == "" && proteinContent == "" && carbContent == "" && energyContent == "" && ironContent == "" && sodiumContent == "" && potassiumContent == "" && calciumContent == "" && vitaminContent == "" && foodType == "" && fibreContent == "" && foodImage == "") {
            this.setState({ error: "At least one field is required!" })
        } else {
            this.setState({ error: "", foodEditLoader: true })
            this.editFoodApiCall(paramsObj)
        }
    }

    editFoodApiCall = (params) => {
        let self = this;
        FoodService.editFood(params)
            .then(function (res) {
                let response = res.data;
                if (response.statusCode == 1) {
                    self.closeModal();
                    self.getFoodItems();
                    self.setState({ foodEditLoader: false })
                    toast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    self.setState({ error: response.error.errorMessage, foodEditLoader: false })
                }
            })
    }


    filterByCategory = (e) => {
        const { id, value } = e.target

        this.setState({ filter: value }, () => {
            this.getFoodItems()
        })
    }

    multipleFoodUpload = () => { this.setState({ multipleFoodPopup: true }) }

    handleFileDrop = (file) => {
        if (file.length && file[0].type === 'application/vnd.ms-excel' || file[0].type === 'text/csv' || file[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            this.setState({ foodExcelSheet: file[0], error: '' })
        } else {
            this.setState({ error: "This file format is not supported!", foodExcelSheet: '' })
        }
    }

    submitMultipleUpload = (e) => {
        e.preventDefault();
        const { foodExcelSheet } = this.state;

        let params = new FormData()
        params.append('foodExcelSheet', foodExcelSheet)

        if (!foodExcelSheet) {
            this.setState({ error: 'Choose a valid file!' })
        } else {
            this.setState({ error: '', uploading: true })
            uploadFoodFromExcel(params)
                .then(res => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        this.getFoodItems();
                        this.closeModal();
                        this.setState({ uploading: false })
                        toast.success(response.responseData.message)
                    } else if (response.statusCode == 0) {
                        this.setState({ uploading: false })
                        this.setState({ error: response.error.errorMessage })
                    }
                })
        }
    }

    render() {
        const { foodName, calorieContent, fatContent, proteinContent, carbContent, energyContent, ironContent, sodiumContent, potassiumContent, calciumContent, vitaminContent, foodType, fibreContent, foodImage, foodImagePrev, foodList, openAddFoodPopup, error, foodSaveLoader, Loader, deleteFoodPopup, openEditFoodPopup, foodEditLoader, foodCategories, foodCategory, foodCategoryPreview, foodUnit, multipleFoodPopup, uploading, pageNo, count, totalCategories, totalFoodCount } = this.state;
        return (
            <div className="body-container-wrapper">
                <div className="body-container">

                    {
                        Loader
                            ? <div className="loader_wrapper"><LoaderSvg /></div>
                            : null
                    }

                    <div className="users_header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">

                                    <div className="col-md-3">
                                        <h4 className="cm_page_heading">Food Items</h4>
                                    </div>

                                    <div className="col-md-3 ">
                                        <Link to="/foods/foodrequests">Food Requests</Link>
                                    </div>
                                </div>
                                <div className="form-group col">
                                    <select className="form-control" onChange={this.filterByCategory} id="foodCategory">
                                        <option selected disabled> Sort by category.....</option>
                                        <option value="" > All</option>
                                        {foodCategories.map((category, index) => (
                                            <option value={category._id} > {category.categoryTitle} </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-3 text-right cm_search">
                                    <input
                                        type="text"
                                        className="form-control m-b-md-15"
                                        placeholder="Search by food name"
                                        name="search"
                                        onChange={this.onSearch}
                                    />
                                    <i className="fa fa-search"></i>
                                </div>


                            </div>

                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-3  m-b-md-15">
                                    <button onClick={this.handleAddFood} className="btn btn-primary btn-block">Add Food Item</button>
                                </div>
                                <div className="col-md-3  m-b-md-15">
                                    <button onClick={this.multipleFoodUpload} className="btn btn-primary btn-block">Add Multiple Food Items</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        {foodList.map((foodItem, index) => (
                            <div className="col-xl-3 col-lg-4 col-md-6 mb-3">
                                <div className="card card_style">
                                    <img className="card-img-top" src={foodItem.foodImage ? foodItem.foodImage : noImage} alt="Food image" />
                                    {foodItem.foodCategory ? <span class="card_style_cat">{foodItem.foodCategory.categoryTitle}</span> : ''}


                                    <VegIcon color={foodItem.foodType == 1 ? 'green' : 'red'} />

                                    <div className="p-3 text-center">
                                        <h4 className="card-title three_dots" title={foodItem.foodName}>{foodItem.foodName}</h4>
                                        <p className="card-text">{foodItem.calorieContent} cal</p>
                                        <button className="btn btn-primary btn-xs" onClick={this.handleEditFood.bind(this, foodItem)}><i className="fa fa-pencil-square-o"></i></button>
                                        <button className="btn btn-danger btn-xs ml-2" onClick={this.handleDeleteFood.bind(this, foodItem)}><i className="fa fa-trash"></i></button>
                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>

                    {!foodList.length ? <h3 className="empty_error">Sorry, we couldn't find any content!</h3> : null}

                    <Pagination
                        activePage={pageNo}
                        itemsCountPerPage={count}
                        totalItemsCount={totalFoodCount}
                        pageRangeDisplayed={3}
                        onChange={this.handlePageChange}
                    />

                </div>


                <Popup
                    open={openAddFoodPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal big_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>
                            &times;
            </span>
                        <h3 className="text-center mb-2 ">Add New Food</h3>

                        {
                            error ? <h4 className="text-center mb-2  error_message">{error}</h4> : ''
                        }


                        <form onSubmit={this.submitAddFood} className="mb-5">

                            <div className="form-group">
                                <label>Food's Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    name="foodName"
                                    id="foodName"
                                />
                            </div>

                            <div className="row">

                                <div className="form-group col">
                                    <label>Calorie Content (kcal)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="calorieContent"
                                        id="calorieContent"
                                        step=".01"
                                    />
                                </div>



                                <div className="form-group col">
                                    <label>kcal/Unit</label>
                                    <select className="form-control" name="foodUnit" id="foodUnit" onChange={this.handleChange} >
                                        <option selected disabled>Select....</option>
                                        <option value="pc">pc</option>
                                        <option value="tablespoon">tablespoon</option>
                                        <option value="teaspoon">teaspoon</option>
                                        <option value="glass">glass (250 ml)</option>
                                        <option value="cup">cup (240 ml)</option>
                                        <option value="katori">katori (150 gm)</option>
                                        <option value="bowl">bowl (325 gm)</option>
                                        <option value="mug">Tea cup (150 ml)</option>
                                        <option value="mug">Serving</option>
                                        <option value="mug">Small</option>
                                        <option value="mug">Medium</option>
                                        <option value="mug">Large</option>
                                        <option value="mug">Slice</option>
                                        <option value="mug">Pack</option>
                                    </select>
                                </div>

                            </div>

                            <div className="row">
                                <div className="form-group col">
                                    <label>Fat Content (gm)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="fatContent"
                                        id="fatContent"
                                        step=".01"

                                    />
                                </div>

                                <div className="form-group col">
                                    <label>Protein Content (gm)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="proteinContent"
                                        id="proteinContent"
                                        step=".01"

                                    />
                                </div>

                                <div className="form-group col">
                                    <label>Carb Content (gm)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="carbContent"
                                        id="carbContent"
                                        step=".01"

                                    />
                                </div>
                            </div>


                            <div className="row">

                                <div className="form-group col">
                                    <label>Iron Content (gm)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="ironContent"
                                        id="ironContent"
                                        step=".01"

                                    />
                                </div>

                                <div className="form-group col">
                                    <label>Sodium Content (gm)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="sodiumContent"
                                        id="sodiumContent"
                                        step=".01"

                                    />
                                </div>

                                <div className="form-group col">
                                    <label>Potassium Content (gm)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="potassiumContent"
                                        id="potassiumContent"
                                        step=".01"

                                    />
                                </div>
                            </div>


                            <div className="row">
                                <div className="form-group col">
                                    <label>Calcium Content (gm)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="calciumContent"
                                        id="calciumContent"
                                        step=".01"
                                    />
                                </div>


                                <div className="form-group col">
                                    <label>Fibre Content (gm)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="fibreContent"
                                        id="fibreContent"
                                        step=".01"

                                    />
                                </div>

                                <div className="form-group col">
                                    <label>Energy Content</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="energyContent"
                                        id="energyContent"
                                        step=".01"

                                    />
                                </div>


                            </div>

                            <div className="row">
                                <div className="form-group col">

                                    <select className="form-control" onChange={this.handleChange} id="foodCategory">
                                        <option selected disabled> Select food category... </option>
                                        {foodCategories.map((category, index) => (
                                            <option value={category._id} > {category.categoryTitle} </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="form-group ">
                                <label className="d-block">Food Type</label>
                                <div className="radio_wrapper">
                                    <label>
                                        Veg
                                            <input type="radio" defaultChecked={true} onChange={this.handleChange} value={1} id="foodType" name="foodType" />
                                        <span></span>
                                    </label>
                                    <label>
                                        Non-Veg
                                            <input type="radio" value={0} onChange={this.handleChange} id="foodType" name="foodType" />
                                        <span></span>
                                    </label>

                                </div>
                            </div>

                            <div className="form-group">
                                <label className="d-block">Upload Food Image</label>
                                <input type="file" onChange={this.handleChange} id="foodImage" className="form-control" name="foodImage" />
                                {this.state.foodImagePrev ? <img width="400px" src={this.state.foodImagePrev} className="mt-2" /> : null}

                            </div>


                            <button className="btn btn-primary btn-block mt-4" disabled={foodSaveLoader}>
                                {!foodSaveLoader ? <span>Save Food</span> :
                                    <div>
                                        <i className="fa fa-spinner fa-pulse fa-2x fa-fw margin-bottom"></i>
                                        <span>Saving....</span>
                                    </div>
                                }
                            </button>

                        </form>

                    </div>
                </Popup>



                <Popup
                    open={openEditFoodPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal big_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>
                            &times;
                        </span>
                        <h3 className="text-center mb-2">Edit Food Item</h3>

                        <form onSubmit={this.editFoodSubmit} className="mb-5">

                            <div className="form-group">
                                <label>Food's Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    name="foodName"
                                    id="foodName"
                                    step=".01"
                                    value={foodName}
                                />
                            </div>

                            <div className="row">

                                <div className="form-group col">
                                    <label>Calorie Content (kcal)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="calorieContent"
                                        id="calorieContent"
                                        step=".01"
                                        value={calorieContent}
                                    />
                                </div>

                                <div className="form-group col">
                                    <label>Cal/Unit</label>
                                    <select className="form-control" name="foodUnit" id="foodUnit" value={foodUnit} onChange={this.handleChange} >
                                        <option selected value='' disabled>Select....</option>
                                        <option value="pc">pc</option>
                                        <option value="tablespoon">tablespoon</option>
                                        <option value="teaspoon">teaspoon</option>
                                        <option value="glass">glass (250 ml)</option>
                                        <option value="cup">cup (240 ml)</option>
                                        <option value="katori">katori (150 gm)</option>
                                        <option value="bowl">bowl (325 gm)</option>
                                        <option value="mug">Tea cup (150 ml)</option>
                                        <option value="mug">Serving</option>
                                        <option value="mug">Small</option>
                                        <option value="mug">Medium</option>
                                        <option value="mug">Large</option>
                                        <option value="mug">Slice</option>
                                        <option value="mug">Pack</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col">
                                    <label>Fat Content (gm)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="fatContent"
                                        id="fatContent"
                                        step=".01"
                                        value={fatContent}
                                    />
                                </div>

                                <div className="form-group col">
                                    <label>Protein Content (gm)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="proteinContent"
                                        id="proteinContent"
                                        step=".01"
                                        value={proteinContent}
                                    />
                                </div>

                                <div className="form-group col">
                                    <label>Carb Content (gm)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="carbContent"
                                        id="carbContent"
                                        step=".01"
                                        value={carbContent}
                                    />
                                </div>

                            </div>


                            <div className="row">

                                <div className="form-group col">
                                    <label>Iron Content (gm)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="ironContent"
                                        id="ironContent"
                                        step=".01"
                                        value={ironContent}
                                    />
                                </div>

                                <div className="form-group col">
                                    <label>Sodium Content (gm)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="sodiumContent"
                                        id="sodiumContent"
                                        step=".01"
                                        value={sodiumContent}
                                    />
                                </div>

                                <div className="form-group col">
                                    <label>Potassium Content (gm)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="potassiumContent"
                                        id="potassiumContent"
                                        step=".01"
                                        value={potassiumContent}
                                    />
                                </div>
                            </div>


                            <div className="row">
                                <div className="form-group col">
                                    <label>Calcium Content (gm)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="calciumContent"
                                        id="calciumContent"
                                        step=".01"
                                        value={calciumContent}
                                    />
                                </div>
                                <div className="form-group col">
                                    <label>Fibre Content (gm)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="fibreContent"
                                        id="fibreContent"
                                        step=".01"
                                        value={fibreContent}
                                    />
                                </div>

                                <div className="form-group col">
                                    <label>Energy Content (gm)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="energyContent"
                                        id="energyContent"
                                        step=".01"
                                        value={energyContent}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col">
                                    <select className="form-control" onChange={this.handleChange} id="foodCategory">
                                        <option selected disabled> {foodCategoryPreview ? foodCategoryPreview : "Select....."}</option>
                                        {foodCategories.map((category, index) => (
                                            <option value={category._id} > {category.categoryTitle} </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="form-group ">
                                <label className="d-block">Food Type</label>
                                <div className="radio_wrapper">
                                    <label>
                                        Veg
                                            <input type="radio" checked={foodType == 1 ? true : false} onChange={this.handleChange} value={1} id="foodType" name="foodType" />
                                        <span></span>
                                    </label>
                                    <label>
                                        Non-Veg
                                            <input type="radio" checked={foodType == 0 ? true : false} value={2} onChange={this.handleChange} id="foodType" name="foodType" />
                                        <span></span>
                                    </label>

                                </div>

                            </div>

                            <div className="form-group">
                                <label className="d-block">Upload Food Image</label>
                                <input type="file" onChange={this.handleChange} id="foodImage" className="form-control" name="foodImage" />
                                <img width="400px" className="mt-2" src={foodImagePrev ? foodImagePrev : foodImage} />
                            </div>



                            {error ?
                                <h4 className="text-center mb-4 mt-5 error_message">{error}</h4> : ''}

                            < button type="submit" className="btn btn-primary btn-block mt-4" disabled={foodEditLoader}>
                                {!foodEditLoader ? <span>Update</span> :
                                    <div>
                                        <i className="fa fa-spinner fa-pulse fa-2x fa-fw margin-bottom"></i>
                                        <span>Updating....</span>
                                    </div>
                                }
                            </button>
                        </form>
                    </div>
                </Popup>

                <Popup
                    open={deleteFoodPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>
                            &times;
            </span>

                        {error ?
                            <h4 className="text-center mb-4 mt-5 error_message">{error}</h4> : ''}

                        <h3 className="text-center mb-4">Are you sure, you want to delete this food?</h3>
                        <div className="btn_group">
                            <div className="row">

                                <div className="col"><button className="btn btn-danger btn-sm btn-block" onClick={this.deleteFood}>Delete</button></div>
                                <div className="col"><button className="btn btn-warning btn-sm btn-block" onClick={this.closeModal}>Cancel</button></div>

                            </div>
                        </div>
                    </div>
                </Popup>


                <Popup
                    open={multipleFoodPopup}
                    closeOnDocumentClick={false}
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>
                            &times;
            </span>

                        {error ?
                            <h4 className="text-center mb-4 mt-5 error_message">{error}</h4> : ''}

                        <h3 className="text-center mb-4">Upload an excel file.</h3>
                        <div className="btn_group">
                            <div className="row">

                                <div className="col">

                                    <Dropzone
                                        multiple={false}
                                        onDrop={acceptedFiles => this.handleFileDrop(acceptedFiles)}
                                    >
                                        {({ getRootProps, getInputProps, isDragActive, acceptedFiles }) => (
                                            <section>
                                                <div {...getRootProps()} className="dropzone text-center">
                                                    <input {...getInputProps()} />

                                                    <i class="fa fa-cloud-upload" />

                                                    {isDragActive ? <p>Drop it like it's hot!</p> : acceptedFiles.length ? <p>{acceptedFiles[0].name}</p> : <p>Drag file or click to upload</p>}
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>

                                    {/* <input id="foodExcelSheet" name="foodExcelSheet" type="file" accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={this.handleChange} /> */}

                                    <button className="btn btn-warning btn-sm btn-block" disabled={uploading} onClick={this.submitMultipleUpload}>
                                        {uploading ? 'Uploading.......' : 'Upload'}</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </Popup>
            </div >
        )
    }
}


export default Foods;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup"
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import noImage from '../../assets/images/no-image.jpg'
import Pagination from "react-js-pagination";
import { outdoorTrackTags } from "../constants/outdoorTrackTags";
import { getTracks, addTrack, updateTrack, deleteTrack, uploadTracksViaExcel } from '../../config/services/outdoorTrackServices'
import { toast } from 'react-toastify';
import { Button, Modal, Badge, Form, FormGroup, FormLabel } from 'react-bootstrap';
import { getAllStates, getAllCitys } from '../../config/services/servicePincodes'
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import Select from 'react-select';
import MapComponent from './TrackMap';



class RewardProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            tracks: [],
            search: '',
            count: 10,
            totalTracks: 15,
            pageNo: 1,
            sortType: -1,
            sortField: 'created',
            statePageNo: 0,
            stateCount: 1000,
            cityPageNo: 0,
            cityCount: 1000,
            addTrackPopup: false,
            validationError: '',
            imagePreview: [],
            trackName: '',
            trackDistance: '',
            coordinatesFile: '',
            trackImages: [],
            gpxFile: '',
            deleteTrackPopup: false,
            tempTrackId: '',
            editTrackPopup: false,
            viewTrackPopup: false,
            bulkUploadPopup: false,
            excelSheet: '',
            buttonLoader: false,
            tags: [],
            difficulty: '',
            elevation: '',
            description: '',
            address: '',
            routeType: '',
            lat: '',
            long: '',
            allstates: [],
            citys: [],
        }
    }

    componentDidMount() {
        this.getTracksList();
        this.getAllStates();
    }

    getAllStates = () => {
        const { statePageNo, stateCount } = this.state;
        let params = { search: '', sortField: "_id", sortType: 1, pageNo: statePageNo, count: stateCount }
        getAllStates({ params })
        .then(res => {
            let response = res.data
            var states = [];
            if (response.statusCode == 1) {                
                let data = res.data.responseData
                let totalCount = data.totalCount;
                let pgno = statePageNo + 1;
                
                if (data.result && data.result.length > 0) {
                    data.result.forEach(key => {
                        states.push({
                            label: key._id,
                            value: key._id,
                        })
                    })
                }

                this.setState({ searchField: 0, statePageNo: pgno, allstates: states, citys: [], tackCity: '' }, function () {
                    if(totalCount > (pgno * stateCount)) {
                        this.getAllStates();
                    }
                });  
            } else if (response.statusCode == 0) {
                toast.error(response.error.errorMessage)
            }
        })
    }

    handleSteteChange = (value, fieldName, callListApi) => {
        this.setState({ [fieldName]: value, cityFilter: "", cityFilterData: [], apiCalled: true, validationError: '' },function() {            
            this.getAllCitys('statechagne',value, null);  
        });
    }   

    getAllCitys = (action,val, defaultSelect) => {
        const { tackState, stateFilter, citySearch, cityPageNo, cityCount } = this.state;
        let params = { sortField: "_id", sortType: 1, pageNo: cityPageNo, count: cityCount }        
        if(action == 'statechagne') {
            params.states = val;
            params.citySearch = '';
        }
        if(action == 'citychagne') {
            params.states = stateFilter;
            params.citySearch = val;
        }
        
        return getAllCitys({ params })
        .then(res => {
            if (res && res.data.statusCode === 1) {                
                let data = res.data.responseData
                let citys = []
                let option = []
                if (data.result && data.result.length > 0) {
                    data.result.forEach(state => {
                        citys.push({
                            label: state._id,
                            value: state._id
                           })
                    })
                }
                
                this.setState({ "citys": citys, "cityData": citys, apiCalled: false})
                if(defaultSelect) {
                    this.setState({ tackCity: defaultSelect})
                } else {
                    this.setState({ tackCity: ''})
                }
                return citys;       
            } else {
                console.log(res.data.error.errorMessage)
            }
        })
    }

    handleCityChange = (value, fieldName) => {
        this.setState({ [fieldName]: value, apiCalled: true, validationError: '' });
    }

    
    getTracksList = () => {
        let { search, count, pageNo, sortType, sortField } = this.state;
        let paramsObj = {
            params: {
                search, count, pageNo: pageNo - 1, sortType, sortField
            }
        }
        getTracks(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({
                        tracks: res.data.responseData.result,
                        totalTracks: res.data.responseData.totalCount,
                        loader: false
                    })
                } else {
                    this.setState({ loader: false })
                }
            })
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value, validationError: '' })
    }
    handleSearch = e => this.setState({ search: e.target.value, pageNo: 1 }, () => this.getTracksList());

    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? -1 : 1 }, () => this.getTracksList());
    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getTracksList() });

    

    openAddTrackPopup = () => {
        this.setState({ addTrackPopup: !this.state.addTrackPopup })
    }

    handleImgPreivew = (e) => {
        let { imagePreview } = this.state
        let files = Array.from(e.target.files)
        if (files) {
            files.map(image => {
                var imgPreview = {};
                imgPreview.thumbnail= URL.createObjectURL(image);
                imagePreview.push(imgPreview)
            })
            this.setState({ trackImages: files, imagePreview })
        }
    }

    handleGpxUpload= (e) => {
        let { imagePreview } = this.state
        let files = Array.from(e.target.files)
        if (files) {
            this.setState({ gpxFile: files })
        }
    }

    closeModal = () => this.setState({
        addTrackPopup: false, trackName: '', trackDistance: '', trackImages: [], imagePreview: [], deleteTrackPopup: false, tempTrackId: '', editTrackPopup: false, viewTrackPopup: false, bulkUploadPopup: false, validationError: '', excelSheet: '', buttonLoader: false, tags: [], difficulty: '', elevation: '', description: '', address: '', routeType: '', citys: [], 
        tackState: '', tackCity: '',  lat: '', long: '', coordinatesFile: ''
    });

    handleAddTrack = (e) => {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ buttonLoader: true, validationError: "" })

            let { trackName, trackDistance, trackImages, gpxFile, difficulty, tags, description, elevation, address, routeType, tackCity, tackState, lat, long } = this.state

            let formData = new FormData();
            formData.append('trackName', trackName)
            formData.append('trackDistance', trackDistance)
            formData.append('difficulty', difficulty)
            formData.append('tags', JSON.stringify(tags))
            formData.append('description', description)
            formData.append('elevation', elevation)
            formData.append('address', address)
            formData.append('routeType', routeType)
            formData.append('state', tackState)
            formData.append('city', tackCity)
            formData.append('lat', lat)
            formData.append('long', long)

            trackImages && trackImages.length > 0 && trackImages.map(image => {
                formData.append('trackImages', image)
            })
            gpxFile && gpxFile.length > 0 && gpxFile.map(json => {
                formData.append('gpxFile', json)
            })

            addTrack(formData)
                .then(res => {
                    if (res.data.statusCode == 1) {
                        this.setState({ buttonLoader: false }, () => this.getTracksList())
                        this.closeModal();
                        toast.success(res.data.responseData.message)
                    } else
                        if (res.data.statusCode == 0) {
                            var error = res.data.error;
                            if(error && error.errors && error.errors[0] && error.errors[0].message && error.errors[0].message.errorMessage) {
                                this.setState({ validationError: error.errors[0].message.errorMessage, buttonLoader: false })
                            } else {
                                this.setState({ validationError: error.errorMessage, buttonLoader: false })
                            }
                        }
                })
        }
    }

    handleValidation = () => {
        let { trackName, trackDistance, difficulty, tags, description, elevation, address, routeType, tackCity, tackState, lat, long } = this.state
        let valid = true

        if (!trackName) {
            valid = false
            this.setState({ validationError: "Please enter track name" })
        }
        else if (!trackDistance) {
            valid = false
            this.setState({ validationError: "Please enter track distance" })
        }
        else if (!difficulty) {
            valid = false
            this.setState({ validationError: "Please enter track difficulty" })
        }
        else if (!elevation) {
            valid = false
            this.setState({ validationError: "Please enter track's elevation" })
        }
        else if (!tags.length) {
            valid = false
            this.setState({ validationError: "Please select atleast one tag" })
        }
        else if (!routeType) {
            valid = false
            this.setState({ validationError: "Please enter track's routeType" })
        }
        else if (!tackState) {
            valid = false
            this.setState({ validationError: "Please select track's state" })
        }
        else if (!tackCity) {
            valid = false
            this.setState({ validationError: "Please select track's city" })
        }        
        else if (!lat) {
            valid = false
            this.setState({ validationError: "Please enter track lat" })
        }   
        else if (!long) {
            valid = false
            this.setState({ validationError: "Please enter track long" })
        }      
        else if (!description) {
            valid = false
            this.setState({ validationError: "Please enter track's description" })
        }       
        else if (!address) {
            valid = false
            this.setState({ validationError: "Please enter track's address" })
        }      
        else {
            valid = true
            this.setState({ validationError: "" })
        }

        return valid
    }

    openDeleteTrackPopup = (track) => this.setState({ deleteTrackPopup: !this.state.deleteTrackPopup, tempTrackId: track._id });

    deleteTrack = (e) => {
        e.preventDefault();
        const { tempTrackId } = this.state
        let paramsObj = {
            trackId: tempTrackId
        }
        this.setState({ loader: true })
        deleteTrack(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    this.closeModal();
                    this.getTracksList();
                    toast.success(res.data.responseData.message)
                } else
                    if (res.data.statusCode == 0) {
                        this.setState({ loader: false, validationError: res.data.error.errorMessage })
                    }
            })
    }

    openEditTrackPopup = (track) => {
        let { trackName, trackDistance, coordinatesFile, trackImages, _id, description, elevation, tags, difficulty, address, routeType, city, state, geoLocation } = track;
        if(geoLocation && geoLocation.length == 2) {
            this.setState({ lat: geoLocation[1], long: geoLocation[0] })
        }
        
        this.setState({ editTrackPopup: !this.state.editTrackPopup, trackName, trackDistance, coordinatesFile: coordinatesFile, imagePreview: trackImages, tempTrackId: _id, description, elevation, tags, difficulty, address, routeType, tackCity: city, tackState: state })

        this.getAllCitys('statechagne',state, city)
    }

    handleUpdateTrack = (e) => {
        e.preventDefault()
        if (this.handleValidation()) {
            this.setState({ buttonLoader: true })

            let { trackName, trackDistance, trackImages, gpxFile, tempTrackId, difficulty, tags, description, elevation, address, routeType, tackState, tackCity, lat, long } = this.state
            let formData = new FormData();
            formData.append('trackName', trackName)
            formData.append('trackDistance', trackDistance)
            formData.append('trackId', tempTrackId)
            formData.append('difficulty', difficulty)
            formData.append('tags', JSON.stringify(tags))
            formData.append('description', description)
            formData.append('elevation', elevation)
            formData.append('address', address)
            formData.append('routeType', routeType)
            formData.append('state', tackState)
            formData.append('city', tackCity)
            formData.append('lat', lat)
            formData.append('long', long)

            trackImages && trackImages.length > 0 && trackImages.map(image => {
                formData.append('trackImages', image)
            })
            gpxFile && gpxFile.length > 0 && gpxFile.map(json => {
                formData.append('gpxFile', json)
            })

            updateTrack(formData)
                .then(res => {
                    if (res.data.statusCode == 1) {
                        this.setState({ buttonLoader: false }, () => this.getTracksList())
                        this.closeModal();
                        toast.success(res.data.responseData.message)
                    } else
                        if (res.data.statusCode == 0) {
                            this.setState({ validationError: res.data.error.errorMessage, buttonLoader: false })
                        }
                })
        }
    }


    bulkTracksUpload = () => {
        this.setState({ bulkUploadPopup: !this.state.bulkUploadPopup })
    }


    handleFileDrop = (file) => {
        if (file.length && file[0].type === 'application/vnd.ms-excel' || file[0].type === 'text/csv' || file[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            this.setState({ excelSheet: file[0], validationError: '' })
        } else {
            this.setState({ validationError: "This file format is not supported!", excelSheet: '' })
        }
    }

    submitBulkUpload = () => {
        let { excelSheet } = this.state
        if (!excelSheet) {
            this.setState({ validationError: "Please select a file first!" })
        } else {
            let params = new FormData();
            params.append('excelSheet', excelSheet)
            this.setState({ buttonLoader: true })
            uploadTracksViaExcel(params)
                .then(res => {
                    if (res.data.statusCode == 1) {
                        this.setState({ buttonLoader: false }, () => this.getTracksList())
                        this.closeModal();
                        toast.success(res.data.responseData.message)
                    } else
                        if (res.data.statusCode == 0) {
                            this.setState({ validationError: res.data.error.errorMessage, buttonLoader: false })
                        }
                })
        }

    }

    parseDifficulty = (diff) => {
        switch (diff) {
            case 1:
                return "Easy"
            case 2:
                return "Moderate"
            case 3:
                return "Hard"
        }
    }

    parseRouteType = (routeType) => {
        switch (routeType) {
            case 1:
                return "Loop"
            case 2:
                return "Out and Back"
            case 3:
                return "Point to Point"
        }
    }

    selectTrackTags = (tags) => {
        this.setState({ tags, validationError: '' })
    }

    onHandleChangeDecimal = (e) => {
        let value = e.target.value;
        value = value.replace(" ", "");
        if (!Number(value) && value != "" && value != "0" && value != "0.") {
          return;
        }
        this.setState({ [e.target.name]: value });
      };

    render() {
        const {
            loader, search, pageNo, count, totalTracks, tracks, addTrackPopup, validationError, imagePreview, deleteTrackPopup, editTrackPopup, trackName, trackDistance, viewTrackPopup, bulkUploadPopup, buttonLoader, description, tags, elevation, difficulty, address, routeType, allstates, citys, lat, long
        } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Outdoor tracks</li>
                        </ol>
                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-3">
                                        <h4 className="cm_page_heading">Outdoor Tracks</h4>
                                    </div>
                                    <div className="col-md-3 text-right cm_search">
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Track Name....."
                                            onChange={this.handleSearch}
                                        />
                                        <i className="fa fa-search" />
                                    </div>
                                    <div className="col-md-3 text-right">
                                        <button className="btn btn-primary btn-block" onClick={this.openAddTrackPopup}>Add New</button>
                                    </div>
                                    <div className="col-md-3  m-b-md-15">
                                        <button onClick={this.bulkTracksUpload} className="btn btn-primary btn-block">Bulk Upload</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('trackName')}>Track Name &nbsp;
                                <i className={`fa fa-sort-alpha-asc`} />
                                        </th>
                                        <th scope="col">Track Image</th>
                                        <th scope="col">Elevation</th>
                                        <th scope="col">Difficulty</th>
                                        <th scope="col">Tags</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Location Coordinates</th>
                                        <th scope="col">Route Type</th>
                                        <th scope="col">State</th>
                                        <th scope="col">City</th>
                                        <th scope="col">Address</th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('created')}>Created &nbsp;
                                            <i className={`fa fa-sort-numeric-asc`} /> </th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        tracks.map((track, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>

                                                    <td><span className="cm_table_three_dots">{track.trackName}</span></td>

                                                    <td><img src={track.trackImages && track.trackImages[0] ? track.trackImages[0].thumbnail : noImage} className="sm_image" /></td>

                                                    <td className="cm_no_wrap">{track.elevation}</td>
                                                    <td className="cm_no_wrap">{this.parseDifficulty(track.difficulty)}</td>
                                                    <td className="cm_no_wrap">
                                                        {track.tags.map(tag => (
                                                            <Badge variant="dark" className='ml-1'>{tag.label}</Badge>
                                                        ))}
                                                    </td>

                                                    <td className="cm_no_wrap">{track.description}</td>

                                                    <td className="cm_no_wrap">
                                                        {track.geoLocation && track.geoLocation.length == 2 ?
                                                        <>
                                                        {track.geoLocation[1]} , {track.geoLocation[0]}
                                                        </>
                                                        :''}
                                                    </td>
                                                    <td className="cm_no_wrap">{this.parseRouteType(track.routeType)}</td>
                                                    <td className="cm_no_wrap">{track.state}</td>
                                                    <td className="cm_no_wrap">{track.city}</td>

                                                    <td className="cm_no_wrap">{track.address}</td>
                                                    <td className="cm_no_wrap">{dateFormat(track.created, "d mmmm yyyy")}</td>
                                                    <td className="cm_no_wrap">
                                                        <button className="btn btn-dark btn-sm" onClick={() => this.openEditTrackPopup(track)} >Edit</button>
                                                        &nbsp;&nbsp;

                                                        <button className="btn btn-danger btn-sm" onClick={() => this.openDeleteTrackPopup(track)}>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })

                                    }
                                </tbody>
                            </table>


                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalTracks}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!tracks.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}

                        </div>

                    </div>
                </div>


                <Popup
                    open={addTrackPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                    className="ourdoormodal"
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <form onSubmit={this.handleAddTrack}>
                            <div className="form-group">
                                <label>Track Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="trackName"
                                    onChange={this.handleChange}
                                />
                            </div>


                            <div className="row">
                                <div className="form-group col">
                                    <label>Track Distance (in mtrs)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="trackDistance"
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group col">
                                    <label>Track Difficulty</label>
                                    <Form.Control as="select" custom onChange={this.handleChange} name='difficulty'>
                                        <option disabled selected >Select difficulty</option>
                                        <option value={1}>Easy</option>
                                        <option value={2}>Moderate</option>
                                        <option value={3}>Hard</option>
                                    </Form.Control>
                                </div>
                                <div className="form-group col">
                                    <label>Track Elevation (in ft)</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="elevation"
                                        value={elevation}
                                        onChange={this.onHandleChangeDecimal}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col">
                                    <label>Track Tags</label>
                                    <Select
                                        isMulti
                                        isSearchable
                                        options={outdoorTrackTags}
                                        placeholder="Select track tags"
                                        onChange={(val) => this.selectTrackTags(val)}
                                    />
                                </div>
                                <div className="form-group col">
                                    <label>Route Type</label>
                                    <Form.Control as="select" custom onChange={this.handleChange} name='routeType'>
                                        <option disabled selected >Select route type</option>
                                        <option value={1}>Loop</option>
                                        <option value={2}>Out and Back</option>
                                        <option value={3}>Point to Point</option>
                                    </Form.Control>
                                </div>
                            </div>
                            <div className="row">
                                <FormGroup className='col-6'>
                                    <FormLabel>State</FormLabel>  
                                    <Select
                                        className='cm_min_width'
                                        onChange={(e) => this.handleSteteChange(e.value, "tackState", 0)}
                                        placeholder="Select State"
                                        options={allstates}
                                        value={this.state.tackState?allstates.find(element => element.value === this.state.tackState):null}
                                    />  
                                </FormGroup>
                                <FormGroup className='col-6'>
                                    <FormLabel>City</FormLabel>  
                                    <Select
                                        className='cm_min_width'
                                        onChange={(e) => this.handleCityChange(e.value, "tackCity")}
                                        placeholder="Select City"
                                        options={citys}
                                        value={this.state.tackCity?citys.find(element => element.value === this.state.tackCity):null}
                                    />
                                </FormGroup>
                            </div>
                            
                            <div className="row">
                                <div className="form-group col">
                                    <label>Lat</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="lat"
                                        value={lat}
                                        onChange={this.onHandleChangeDecimal}
                                    />
                                </div>
                                <div className="form-group col">
                                    <label>Long</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="long"
                                        value={long}
                                        onChange={this.onHandleChangeDecimal}
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Track Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="description"
                                    onChange={this.handleChange}
                                />
                            </div>



                            <div className="form-group">
                                <label>Track's Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="address"
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>Track Coordinates (upload only gpx file) </label>
                                <input
                                    type="file"
                                    className="form-control"
                                    onChange={this.handleGpxUpload}
                                />
                            </div>

                            <div className="form-group">
                                <label>Track Images</label>
                                <input
                                    type="file"
                                    multiple
                                    className="form-control"
                                    onChange={this.handleImgPreivew}
                                />
                            </div>

                            {
                                imagePreview.map(imageURI =>
                                    (<img className="tracks_img" src={imageURI.thumbnail} alt="Photo uploaded" />))
                            }

                            <div className="text-center">
                                {validationError && <h6 className="text-center mb-2 mt-2 error_message">{validationError}</h6>}
                                <button className="btn btn-primary" type="submit"
                                    disabled={validationError || buttonLoader ? false : false}
                                >{buttonLoader ? 'Adding track...' : 'Add Track'}</button>
                            </div>
                        </form>
                    </div>
                </Popup>

                <Popup
                    open={deleteTrackPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h6 className="text-center mb-2 mt-2">Are you sure, you want to delete this Track?</h6>
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-danger btn-sm btn-block" onClick={this.deleteTrack}>Delete</button>
                            </div>
                            <div className="col">
                                <button className="btn btn-warning btn-sm btn-block" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Popup>

                <Popup
                    open={editTrackPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                    className="ourdoormodal"
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <form onSubmit={this.handleUpdateTrack}>
                            <div className="form-group">
                                <label>Track Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="trackName"
                                    value={trackName}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="row">
                                <div className="form-group col">
                                    <label>Track Distance (in mtrs)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="trackDistance"
                                        value={trackDistance}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group col">
                                    <label>Track Difficulty</label>
                                    <Form.Control as="select" value={difficulty} custom onChange={this.handleChange} name='difficulty'>
                                        <option value={1}>Easy</option>
                                        <option value={2}>Moderate</option>
                                        <option value={3}>Hard</option>
                                    </Form.Control>
                                </div>
                                <div className="form-group col">
                                    <label>Track Elevation (in ft)</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="elevation"
                                        value={elevation}
                                        onChange={this.onHandleChangeDecimal}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col">
                                    <label>Track Tags</label>
                                    <Select
                                        isMulti
                                        isSearchable
                                        options={outdoorTrackTags}
                                        value={tags}
                                        placeholder="Select track tags"
                                        onChange={(val) => this.selectTrackTags(val)}
                                    />
                                </div>
                                <div className="form-group col">
                                    <label>Route Type</label>
                                    <Form.Control as="select" value={routeType} custom onChange={this.handleChange} name='routeType'>
                                        <option disabled selected >Select route type</option>
                                        <option value={1}>Loop</option>
                                        <option value={2}>Out and Back</option>
                                        <option value={3}>Point to Point</option>
                                    </Form.Control>
                                </div>
                            </div>
                            <div className="row">
                                <FormGroup className='col-6'>
                                    <FormLabel>State</FormLabel>  
                                    <Select
                                        className='cm_min_width'
                                        onChange={(e) => this.handleSteteChange(e.value, "tackState", 0)}
                                        placeholder="Select State"
                                        options={allstates}
                                        value={this.state.tackState?allstates.find(element => element.value === this.state.tackState):null}
                                    />  
                                </FormGroup>
                                <FormGroup className='col-6'>
                                    <FormLabel>City</FormLabel>  
                                    <Select
                                        className='cm_min_width'
                                        onChange={(e) => this.handleCityChange(e.value, "tackCity")}
                                        placeholder="Select City"
                                        options={citys}
                                        value={this.state.tackCity?citys.find(element => element.value === this.state.tackCity):null}
                                    />
                                </FormGroup>
                            </div>
                             <div className="row">
                                <div className="form-group col">
                                    <label>Lat</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="lat"
                                        value={lat}
                                        onChange={this.onHandleChangeDecimal}
                                    />
                                </div>
                                <div className="form-group col">
                                    <label>Long</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="long"
                                        value={long}
                                        onChange={this.onHandleChangeDecimal}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Track Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="description"
                                    value={description}
                                    onChange={this.handleChange}
                                />
                            </div>


                            <div className="form-group">
                                <label>Track's Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="address"
                                    onChange={this.handleChange}
                                    value={address}
                                />
                            </div>

                            <div className="form-group">
                                <label>Track Coordinates (upload only gpx file) </label>
                                <input
                                    type="file"
                                    className="form-control"
                                    onChange={this.handleGpxUpload}
                                />
                                {this.state.coordinatesFile?this.state.coordinatesFile:null}
                            </div>

                            <div className="form-group">
                                <label>Track Images</label>
                                <input
                                    type="file"
                                    multiple
                                    className="form-control"
                                    onChange={this.handleImgPreivew}
                                />
                            </div>

                            {
                                imagePreview.map(imageURI =>
                                    (<img className="tracks_img" src={imageURI.thumbnail} alt="Photo uploaded" />))
                            }

                            <div className="text-center">
                                {validationError && <h6 className="text-center mb-2 mt-2 error_message">{validationError}</h6>}
                                <button className="btn btn-primary" disabled={validationError || buttonLoader ? false : false}
                                    type="submit">{buttonLoader ? 'Updating Track...' : 'Update Track'}</button>
                            </div>
                        </form>
                    </div>
                </Popup>



                <Modal
                    show={viewTrackPopup}
                    onHide={this.closeModal}
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Track Coordinates</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="danger" onClick={this.closeModal}>
                            Close
                            </Button>
                    </Modal.Footer>
                </Modal>



                <Modal
                    show={bulkUploadPopup}
                    onHide={this.closeModal}
                    centered>
                    <Modal.Body>
                        <Dropzone
                            multiple={false}
                            onDrop={acceptedFiles => this.handleFileDrop(acceptedFiles)}
                        >
                            {({ getRootProps, getInputProps, isDragActive, acceptedFiles }) => (
                                <section>
                                    <div {...getRootProps()} className="dropzone text-center">
                                        <input {...getInputProps()} />
                                        <i class="fa fa-cloud-upload" />
                                        {isDragActive ? <p>Drop it like it's hot!</p> : acceptedFiles.length ? <p>{acceptedFiles[0].name}</p> : <p>Drag file or click to upload</p>}
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </Modal.Body>

                    <Modal.Footer>
                        {validationError && <h6 className="text-center mb-2 mt-2 error_message">{validationError}</h6>}
                        <Button variant="info" onClick={this.submitBulkUpload} disabled={validationError || buttonLoader ? true : false} > {buttonLoader ? 'Uploading.....' : 'Upload'} </Button>

                        <Button variant="danger" onClick={this.closeModal}> Close </Button>
                    </Modal.Footer>
                </Modal>


            </HOC>
        )
    }
}



export default RewardProducts;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { getAllSessionsByAdmin, changeRequestStatus } from '../../config/services/CallSchedules';
import { getServices } from "../../config/services/serviceCategoriesService";
import { getAllTrainers } from '../../config/services/trainerService';
import { CallStatuses, InvoiceFilters } from './Constants';
import { getUserWithTrainerNotes } from '../../config/services/usersService'
import ExportExcel from "./ExportExcel";
import moment from 'moment'
import Select from 'react-select'
import { toast } from 'react-toastify';
import { Dropdown, Modal } from 'react-bootstrap';
import { DatePicker, InputNumber, Button, Tooltip } from 'antd';
const { RangePicker } = DatePicker;

class RewardTransactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            openViewTrainerNotes: false,
            callSchedules: [],
            search: '',
            count: 10,
            totalScheduledCalls: 15,
            pageNo: 1,
            sortType: -1,
            sortField: "created",
            selectedDate: moment().valueOf(),
            dateFrom: moment().startOf('day').valueOf(),
            dateTo: moment().endOf('day').valueOf(),
            trainersList: [],
            servicesList: [],
            expertId: '',
            serviceId: '',
            filterStatus: null,
            filterByInvoice: null,

            selectedFilterExpert: '',
            selectedFilterService: '',
            selectedFilterStatus: null,
            selectedFilterInvoice: null,
            userLogs: [],
        }
    }

    componentDidMount() {
        this.getAllSessions();
        this.getTrainersList();
        this.getServicesList();
    }

    getTrainersList() {
        let self = this;
        let params = {
            params: { count: 200 }
        }
        getAllTrainers(params)
            .then(res => {
                const response = res.data;
                if (response.statusCode == 1) {
                    const { result } = response.responseData
                    let trainersList = []
                    trainersList.push({ label: 'All', value: '' })
                    result.map(expertDetails => {
                        trainersList.push({ label: `${expertDetails.firstName} ${expertDetails.lastName}`, value: expertDetails._id })
                    })

                    self.setState({ trainersList })
                }
                else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
    }

    getServicesList() {
        let self = this;
        let params = { params: {} }
        getServices(params)
            .then(res => {
                const response = res.data;
                if (response.statusCode == 1) {
                    const { result } = response.responseData
                    let servicesList = []
                    servicesList.push({ label: 'All', value: '' })
                    result.map(serviceDetails => {
                        servicesList.push({ label: `${serviceDetails.serviceName}`, value: serviceDetails._id })
                    })
                    self.setState({ servicesList })
                }
                else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
    }




    getAllSessions = () => {
        let { search, count, pageNo, sortType, sortField, dateFrom, dateTo, expertId, serviceId, filterStatus, filterByInvoice } = this.state;
        let paramsObj = {
            params: {
                search, count, pageNo: pageNo - 1, sortType, sortField, dateFrom, dateTo, expertId, serviceId, filterStatus, filterByInvoice
            }
        }
        getAllSessionsByAdmin(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.setState({
                        callSchedules: response.responseData.result,
                        totalScheduledCalls: response.responseData.totalCount,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    closeModal = () => this.setState({
        openViewTrainerNotes: false,
    });

    handleChange = e => {
        let { name, value } = e.target
        this.setState({ [name]: value })
    }


    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? -1 : 1 }, () => this.getAllSessions());

    handleSearch = e => this.setState({ search: e.target.value, pageNo: 1 }, () => this.getAllSessions());

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getAllSessions() });

    parseCallStatus = (callStatus) => {
        switch (callStatus) {
            case 0:
                return 'Pending';
            case 1:
                return 'Accepted';
            case 2:
                return 'Rejected';
            case 3:
                return 'Canceled';
            case 4:
                return 'Done';
            case 5:
                return 'Not Done';
            case 6:
                return 'On Going';
        }
    }

    filterByDate = (date) => {
        let dateFrom
        let dateTo

        if (!date) {
            dateFrom = null
            dateTo = null
        }
        else {
            dateFrom = moment(date[0]).startOf('day').valueOf()
            dateTo = moment(date[1]).endOf('day').valueOf()
        }

        this.setState({ dateFrom, dateTo, loader: true, pageNo: 1 }, () => { this.getAllSessions() })
    }


    filterList = (filterValue, filterBy) => {
        switch (filterBy) {
            case 'trainer':
                this.setState({ expertId: filterValue.value, selectedFilterExpert: filterValue }, () => this.getAllSessions())
                break
            case 'service':
                this.setState({ serviceId: filterValue.value, selectedFilterService: filterValue }, () => this.getAllSessions())
                break
            case 'call':
                this.setState({ filterStatus: filterValue.value, selectedFilterStatus: filterValue }, () => this.getAllSessions())
                break
            case 'invoice':
                this.setState({ filterByInvoice: filterValue.value, selectedFilterInvoice: filterValue }, () => this.getAllSessions())
                break
            case 'listCount':
                if (filterValue > 0) {
                    this.setState({ count: filterValue, pageNo: 1 }, () => this.getAllSessions())
                }
                break
        }
    }

    updateCallStatus = (schedule, status) => {
        let paramsObj = {
            requestId: schedule._id,
            requestStatus: status
        }
        this.setState({ loader: true })
        this.updateCallStatusApi(paramsObj)
    }

    updateCallStatusApi = (paramsObj) => {
        changeRequestStatus(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.setState({ loader: false })
                    toast.success(response.responseData.message)
                    this.getAllSessions()
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }


    calculateFinalAmount = (scheduleDetails) => {
        let { expertDetails, videoSessionType } = scheduleDetails
        let finalAmount = 0

        if (videoSessionType == 1) {
            finalAmount = expertDetails.price - ((expertDetails.discount * expertDetails.price) / 100)
        }
        else {
            finalAmount = expertDetails.groupSessionPrice - ((expertDetails.discount * expertDetails.groupSessionPrice) / 100)
        }

        //Round off to nearest 2 decimal place 
        return Math.round(finalAmount * 100)/100
    }

    resetFilters = () => {
        this.setState({ serviceId: '', expertId: '', filterStatus: null, selectedFilterExpert: '', selectedFilterService: '', selectedFilterStatus: null, loader: true, filterByInvoice: null, selectedFilterInvoice: null }, () => this.getAllSessions())
    }

    viewTrainerNotes = (details) => {
        this.setState({ openViewTrainerNotes: true, userLogs: [] }, function() {
          let paramsObj = {
            params: { notesOfUserId: details.userDetails._id, dieticianId: details.expertDetails._id }
        }
        getUserWithTrainerNotes(paramsObj)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.setState({ 
                        userLogs: response.responseData.result, 
                        loader: false 
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            }) 
        })      
    }

    render() {
        const {
            loader,
            callSchedules,
            search,
            pageNo,
            count,
            totalScheduledCalls,
            dateFrom,
            dateTo,
            selectedDate,
            trainersList,
            servicesList,
            selectedFilterExpert,
            selectedFilterService,
            selectedFilterStatus,
            selectedFilterInvoice,
            openViewTrainerNotes,
            userLogs
        } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item"><Link to="/trainers">Trainers</Link></li>
                            <li className="breadcrumb-item">Trainer's Scheduled Calls</li>
                        </ol>

                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4">
                                        <h4 className="cm_page_heading">Call Schedules</h4>
                                        {callSchedules.length ? <ExportExcel callSchedules={callSchedules} /> : null}

                                        <Tooltip title="Reset Filters" className='ml-1'>
                                            <Button type="primary" shape="circle" icon={<i className="fa fa-refresh" />} onClick={this.resetFilters} />
                                        </Tooltip>

                                    </div>
                                    <div className="col-md-2">
                                        <div className="row">
                                            No. of rows :
                                            <div className="col">
                                                <InputNumber min={1} placeholder="Ex : 10" onChange={(val) => this.filterList(val, 'listCount')} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="row">
                                            Filter By Call Date :
                                            <div className="col">
                                                <RangePicker
                                                    onChange={(val) => this.filterByDate(val)}
                                                    defaultValue={[moment(dateFrom), moment(dateTo)]}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3 text-right cm_search">
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search By Name Or Email"
                                            onChange={this.handleSearch}
                                            value={search}
                                        />
                                        <i className="fa fa-search" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive" style={{ minHeight: 'calc(100vh - 220px)' }}>
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('firstName')}>User's Name &nbsp;  <i className="fa fa-sort" /></th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('email')}>User's Email &nbsp;  <i className="fa fa-sort" /></th>
                                        <th scope="col" >Mobile No.</th>
                                        <th scope="col" className="filter_text">
                                            <Select isSearchable options={servicesList} placeholder="Service" onChange={(val) => this.filterList(val, 'service')} className="cm_min_width" value={selectedFilterService} /> </th>
                                        <th scope="col" className="filter_text">Session Type</th>
                                        <th scope="col" className="filter_text">Billing</th>
                                        <th scope="col" className="filter_text">Amount</th>
                                        <th scope="col" className="filter_text">Discount</th>
                                        <th scope="col" className="filter_text">Final payment</th>
                                        <th scope="col" className="filter_text">
                                            <Select isSearchable options={trainersList} placeholder="Expert's Name" onChange={(val) => this.filterList(val, 'trainer')} className="cm_min_width" value={selectedFilterExpert} /> </th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('callDate')}>Call Date &nbsp;  <i className="fa fa-sort" /></th>
                                        <th scope="col" className="filter_text" >Call Time Slot </th>
                                        <th scope="col" className="filter_text">
                                            <Select isSearchable options={CallStatuses} placeholder="Call Status" onChange={(val) => this.filterList(val, 'call')} className="cm_min_width"
                                                value={selectedFilterStatus} /> </th>
                                        <th scope="col" className="filter_text">
                                            <Select isSearchable options={InvoiceFilters} placeholder="Invoice #" onChange={(val) => this.filterList(val, 'invoice')} className="cm_min_width"
                                                value={selectedFilterInvoice} />
                                        </th>
                                        <th scope="col">Notes</th>
                                        <th scope="col" className="filter_text">Report</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        callSchedules.map((schedule, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                                                    <td><span className="cm_table_three_dots">
                                                        {schedule.userDetails.firstName + " " +
                                                            schedule.userDetails.lastName}</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {schedule.userDetails.email}</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {schedule.userDetails.mobileNo}</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {schedule.serviceDetails.serviceName}</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {schedule.videoSessionType == 1 ? 'Solo' : 'Group'}</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {schedule.isRedeemedFree ? 'Free' : 'Paid'}</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {schedule.videoSessionType == 1 ?
                                                            schedule.expertDetails.price : schedule.expertDetails.groupSessionPrice} ₹</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {schedule.expertDetails.discount} %</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {this.calculateFinalAmount(schedule)} ₹</span>
                                                    </td>
                                                    <td>
                                                        <span className="cm_table_three_dots">
                                                            {schedule.expertDetails.firstName + " " +
                                                                schedule.expertDetails.lastName}</span>
                                                    </td>
                                                    <td>
                                                        <span className="cm_table_three_dots">
                                                            {moment(schedule.callDate).format('Do MMM YYYY')}
                                                        </span>
                                                    </td>

                                                    <td>
                                                        <span className="cm_table_three_dots">
                                                            {schedule.callTimeSlot}
                                                        </span>
                                                    </td>

                                                    <td>
                                                        <Dropdown className="cm_dropdown">
                                                            <Dropdown.Toggle className="cm_trackingId" variant="outline-primary" id="dropdown-basic">
                                                                {this.parseCallStatus(schedule.status)}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => this.updateCallStatus(schedule, 0)}>Pending
                                                                </Dropdown.Item>
                                                                <Dropdown.Item onClick={() => this.updateCallStatus(schedule, 1)}>Accepted
                                                                </Dropdown.Item>
                                                                <Dropdown.Item onClick={() => this.updateCallStatus(schedule, 2)}>Rejected
                                                                </Dropdown.Item>
                                                                <Dropdown.Item onClick={() => this.updateCallStatus(schedule, 3)}>Canceled
                                                                </Dropdown.Item>
                                                                <Dropdown.Item onClick={() => this.updateCallStatus(schedule, 4)}>Done
                                                                </Dropdown.Item>
                                                                <Dropdown.Item onClick={() => this.updateCallStatus(schedule, 5)}>Not Done
                                                                </Dropdown.Item>
                                                                <Dropdown.Item onClick={() => this.updateCallStatus(schedule, 6)}>On Going
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                    <td>
                                                        <span className="cm_table_three_dots">
                                                            {schedule.invoiceNo ? schedule.invoiceNo : 'No Invoice'}
                                                            &nbsp;
                                                            {schedule.invoiceUrl ?
                                                                <a href={schedule.invoiceUrl} download="invoice.pdf" title='Download'>
                                                                    <Button type="primary" shape="circle" icon={<i className="fa fa-download" />} />
                                                                </a>
                                                                : null}
                                                        </span>
                                                    </td>

                                                    <td>
                                                        <button className="btn btn-primary btn-xs" onClick={() => this.viewTrainerNotes(schedule)}>
                                                        View Notes
                                                        </button>
                                                    </td>

                                                    <td>
                                                        <span className="cm_table_three_dots" title={schedule.isReported ? schedule.reportDetails && schedule.reportDetails.complaint : 'Not Reported'} >
                                                            {schedule.isReported ? schedule.reportDetails && schedule.reportDetails.complaint : 'Not Reported'}
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalScheduledCalls}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!callSchedules.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}

                        </div>

                    </div>
                </div>

                <Modal
                    show={openViewTrainerNotes}
                    onHide={this.closeModal}
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Trainer Notes</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="card card_style">
                        <div className="card-body ">
                            
                            {userLogs.length > 0?              
                            <ul className="cm_questions">                      
                                    {userLogs.map((notes, i) => (
                                    <div className="alert alert-dark log_box" key={i}>
                                        <h4 className="alert-heading">{notes.note}</h4>
                                        <p className="usr_log">Recorded by: {notes.recordedBy.email}</p>
                                        <p className="mb-0 usr_log">Recorded on: {dateFormat(notes.created, "d mmmm yyyy")}</p>
                                    </div>))}
                                    
                            </ul>       
                            : "not found notes"
                            }
                            
                        </div>
                        </div>
                    </Modal.Body>
                    </Modal>

            </HOC>
        )
    }
}



export default RewardTransactions;
var ServiceTypes = [
    { value: 1, label: "INSTALLATION", },
    { value: 2, label: "SERVICE / REPAIR", },
    { value: 3, label: "UNINSTALLATION", },
];
//1=Intallation, 2=Service/Repair, 3=Uninstallation,


var SearchTypes = [
    { value: '', label: "All", },
    { value: 'fullName', label: "Customer Name", },
    { value: 'lastName', label: "Last Name", },
    { value: 'orderId', label: "Order Id", },
    { value: 'email', label: "Email", },
    { value: 'fullMobileNo', label: "Contact No", },
    { value: 'requestId', label: "Request Id", }
];

module.exports = {
    ServiceTypes,
    SearchTypes
}

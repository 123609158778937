import React, { Component } from "react";
import { addProduct } from "../../config/services/ProductsService";
import { LoaderSvg } from "../Reusable";
import { Modal, Button, Alert } from "react-bootstrap";
import { productCategories, tyreSizes, frameMaterials, gearTypes } from "./ProductCategories";
import Select from "react-select";
import { toast } from "react-toastify";
import { productSpecifications } from "./ProductSpecifications";
import ReactQuill from 'react-quill';

class AddProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validationError: "",
      productSaveLoader: false,
      productName: "",
      productCode: "",
      productLink: "",
      productASIN: "",
      productWebsite: "0",
      productCategory: "",
      selectedCategory: "",
      selectedSubCategory: "",
      gender: null,
      color_code: '#000000',
      color_name: '',
      size: '',
      productCategoryIndex: "",
      productImagePreview: "",
      productSaveLoader: false,
      productDescription: "",
      productPrice: "",
      productType: 0,
      specifications: [{ label: "", value: "" }],
      brandName: "",
      productPriceDiscount: 0,
      multipleProductImages: [],
      multipleProductImagesPreview: [],
      onefitplusChoiceImage: "",
      onefitplusChoiceImagePreview: "",
      skuIdforGuranteedPlan: "",
      skuIdforDirectPlan: "",
      planDetailsforGuranteedPlan: [],
      planDetailsforDirectPlan: [],
      planIdforDiscountGuranteedPlan: "",
      planIdforDiscountDirectPlan: "",
      productMRP: "",
      productReview: "",
      productRating: "",
      productDisplayOrder: "",
      isFitwarzCompatible: false,
      isUrbanCompatible: false,
      isLiveSessionCompatible:false,
      tyre_size: '',
      frame_material: '',
      gear_type: '',
      metaTitle: '',
      metaDescription:'',
      productService:''
    };
  }

  closeProductModal = () => {
    this.props.closeProductModal();
    this.setState({
      productName: "",
      productCode: "",
      productLink: "",
      productASIN: "",
      productWebsite: "0",
      productCategory: null,
      selectedCategory: "",
      selectedSubCategory: "",
      gender: null,
      color: "",
      size: '',
      productCategoryIndex: "",
      productImagePreview: "",
      productSaveLoader: false,
      productDescription: "",
      productPrice: "",
      productType: 0,
      specifications: {},
      brandName: "",
      productPriceDiscount: 0,
      multipleProductImages: [],
      multipleProductImagesPreview: [],
      onefitplusChoiceImage: "",
      onefitplusChoiceImagePreview: "",
      skuIdforGuranteedPlan: "",
      skuIdforDirectPlan: "",
      planDetailsforGuranteedPlan: [],
      planDetailsforDirectPlan: [],
      planIdforDiscountGuranteedPlan: "",
      planIdforDiscountDirectPlan: "",
      productMRP: "",
      productReview: "",
      productRating: "",
      productDisplayOrder: "",
      isFitwarzCompatible: false,
      isUrbanCompatible: false,
      isLiveSessionCompatible:false,
      tyre_size: '',
      frame_material: '',
      gear_type: '',
      metaTitle: '',
      metaDescription:'',
      productService:''
    });
  };

  handleAddSpecification = () => {
    let specifications = Array.from(this.state.specifications);
    specifications.push({ label: "", value: "" });
    this.setState({ specifications });
  };

  handleSpecsChange = (e, index) => {
    let { value } = e.target;
    let specifications = Array.from(this.state.specifications);
    specifications[index]["value"] = value;
    this.setState({ specifications });
  };

  selectProductSpecification = (val, index) => {
    let specifications = Array.from(this.state.specifications);
    specifications[index]["label"] = val.label;
    this.setState({ specifications });
  };

  removeSpecification = (index) => {
    let specifications = Array.from(this.state.specifications);
    specifications.splice(index, 1);
    this.setState({ specifications });
  };

  selectProductCategory = (category) => {
    this.setState({ productCategory: category.value });
    this.setState({ selectedCategory: category });
    if (category.subCategories.length == 0) {
      this.setState({ selectedSubCategory: '' });
    }
  };

  selectProductSubCategory = (subcategory) => {
    this.setState({ selectedSubCategory: subcategory.value });
  };

  handleMultipleImgPreivew = (e) => {
    let { multipleProductImagesPreview } = this.state;
    let files = Array.from(e.target.files);
    if (files) {
      files.map((image) => {
        multipleProductImagesPreview.push(URL.createObjectURL(image));
      });
      this.setState({
        multipleProductImages: files,
        multipleProductImagesPreview,
      });
    }
  };

  onHandleChangeNumeric = (e) => {
    let value = e.target.value;
    value = value.replace(/[\D]+/g, "");
    if (!Number(value) && value != "" && value != "0") {
      return;
    }
    this.setState({ [e.target.name]: value });
  };

  onHandleChangeDecimal = (e) => {
    let value = e.target.value;
    value = value.replace(" ", "");
    if (!Number(value) && value != "" && value != "0" && value != "0.") {
      return;
    }
    this.setState({ [e.target.name]: value });
  };

  handleChange = (e) => {
    if (e.target.id == "productImage") {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          productImagePreview: reader.result,
        });
      };
      reader.readAsDataURL(file);
      this.setState({
        productImagePreview: file,
      });

      this.setState({
        productImage: e.target.files[0],
      });
    } else if (e.target.id == "onefitplusChoiceImage") {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          onefitplusChoiceImagePreview: reader.result,
        });
      };
      reader.readAsDataURL(file);
      this.setState({
        onefitplusChoiceImagePreview: file,
      });

      this.setState({
        onefitplusChoiceImage: e.target.files[0],
      });
    } else if (e.target.type == "checkbox") {
      let name = e.target.name;
      let value = e.target.checked;
      this.setState({ [name]: value });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        errors: false,
      });
    }

  };

  handleDescription = (data) => {
    this.setState({ productDescription: data })
  }

  isValid = () => {
    let {
      productName,
      productCode,
      productLink,
      productASIN,
      productWebsite,
      productImage,
      productDescription,
      productPrice,
      productMRP,
      productType,
      specifications,
      brandName,
      productPriceDiscount,
      multipleProductImages,
      skuIdforGuranteedPlan,
      skuIdforDirectPlan,
      planDetailsforGuranteedPlan,
      planDetailsforDirectPlan,
      planIdforDiscountGuranteedPlan,
      planIdforDiscountDirectPlan,
      productCategory,
      color_code,
      color_name,
      size,
      gender,
      tyre_size,
      frame_material,
      gear_type,
      isUrbanCompatible,
      metaTitle,
      metaDescription
    } = this.state;

    if (!productName) {
      this.setState({ validationError: "Product name cannot be blank!" });
      return false;
    } else if (!productCode) {
      this.setState({ validationError: "Product code cannot be blank!" });
      return false;
    } else if (!productLink) {
      this.setState({ validationError: "Product link cannot be blank!" });
      return false;
    } else if (!productASIN) {
      this.setState({ validationError: "Product ASIN cannot be blank!" });
      return false;
    } else if (!productWebsite) {
      this.setState({ validationError: "Product Website cannot be blank!" });
      return false;
    } 
    else if (productCategory == null) {
      this.setState({ validationError: "Product category cannot be blank!" });
      return false;
    }

    else if (!metaTitle) {
      this.setState({ validationError: "Meta Title cannot be blank!" });
      return false;
    }
    else if (!metaDescription) {
      this.setState({ validationError: "Meta Description cannot be blank!" });
      return false;
    }
    // else if (!productImage) {
    //     this.setState({ validationError: 'Product image cannot be blank!' })
    //     return false
    // }
    else if (!productDescription) {
      this.setState({
        validationError: "Product description cannot be blank!",
      });
      return false;
    } else if (!productPrice) {
      this.setState({ validationError: "Product price cannot be blank!" });
      return false;
    } else if (productPrice > productMRP) {
      this.setState({ validationError: "Product price cannot be les from product MRP!" });
      return false;
    } else if (!specifications) {
      this.setState({ validationError: "Specifications cannot be blank!" });
      return false;
    } else if (!brandName) {
      this.setState({ validationError: "Brand Name cannot be blank!" });
      return false;
    } else if (!productPriceDiscount) {
      this.setState({ validationError: "Discount cannot be blank!" });
      return false;
    } else if (!multipleProductImages) {
      this.setState({
        validationError: "Other product images cannot be blank!",
      });
      return false;
    } else if (!skuIdforGuranteedPlan) {
      this.setState({
        validationError: "SkuId for guranteed plan cannot be blank!",
      });
      return false;
    } else if (!skuIdforDirectPlan) {
      this.setState({
        validationError: "SkuId for direct plan cannot be blank!",
      });
      return false;
    } else if (!planDetailsforGuranteedPlan.length) {
      this.setState({
        validationError: "Plan details for guranteed plan cannot be blank!",
      });
      return false;
    } else if (!planDetailsforDirectPlan.length) {
      this.setState({
        validationError: "Plan details for direct plan cannot be blank!",
      });
      return false;
    } else if (!planIdforDiscountGuranteedPlan) {
      this.setState({
        validationError: "PlanId for discount guaranteed plan cannot be blank!",
      });
      return false;
    } else if (!planIdforDiscountDirectPlan) {
      this.setState({
        validationError: "PlanId for discount direct plan cannot be blank!",
      });
      return false;
    }

    else if(isUrbanCompatible){

      if (!color_code) {
        this.setState({ validationError: "Color Code cannot be blank!" });
        return false;
      }
      else if (!size) {
        this.setState({ validationError: "Product Size cannot be blank!" });
        return false;
      }
      else if (!color_name) {
        this.setState({ validationError: "Color Name cannot be blank!" });
        return false;
      }
      else if (!gender) {
        this.setState({ validationError: "Gender cannot be blank!" });
        return false;
      }
      else if (!tyre_size) {
        this.setState({ validationError: "Tyre Size cannot be blank!" });
        return false;
      }
      else if (!frame_material) {
        this.setState({ validationError: "Frame Material cannot be blank!" });
        return false;
      }
      else if (!gear_type) {
        this.setState({ validationError: "Gear Type cannot be blank!" });
        return false;
      }
    }
    return true
  };

  addProduct = (e) => {
    e.preventDefault();
    let {
      productName,
      productCode,
      productLink,
      productASIN,
      productWebsite,
      productCategory,
      productImage,
      productDescription,
      productPrice,
      productType,
      specifications,
      brandName,
      productPriceDiscount,
      multipleProductImages,
      skuIdforGuranteedPlan,
      skuIdforDirectPlan,
      planDetailsforGuranteedPlan,
      planDetailsforDirectPlan,
      planIdforDiscountGuranteedPlan,
      planIdforDiscountDirectPlan,
      onefitplusChoiceImage,
      productMRP,
      productReview,
      productRating,
      productDisplayOrder,
      isFitwarzCompatible,
      isUrbanCompatible,
      isLiveSessionCompatible,
      selectedSubCategory,
      gender,
      color,
      size,
      color_code,
      color_name,
      tyre_size,
      frame_material,
      gear_type,
      metaTitle,
      metaDescription,
      productService
    } = this.state;

    let params = new FormData();
    params.append("productName", productName);
    params.append("productCode", productCode);
    params.append("productLink", productLink);
    params.append("productASIN", productASIN);
    params.append("productWebsite", productWebsite);
    params.append("productCategory", productCategory);
    params.append("subcategory", selectedSubCategory);
    params.append("gender", gender);
    params.append("color", JSON.stringify({ code: color_code, name: color_name }));
    params.append("size", size);
    params.append("productImage", productImage);
    params.append("onefitplusChoiceImage", onefitplusChoiceImage);
    params.append("productDescription", productDescription);
    params.append("productPrice", productPrice);
    params.append("productType", productType);
    params.append("specifications", JSON.stringify(specifications));
    params.append("brandName", brandName);
    params.append("productPriceDiscount", productPriceDiscount);
    params.append("skuIdforGuranteedPlan", skuIdforGuranteedPlan);
    params.append("skuIdforDirectPlan", skuIdforDirectPlan);
    params.append(
      "planIdforDiscountGuranteedPlan",
      planIdforDiscountGuranteedPlan
    );
    params.append("planIdforDiscountDirectPlan", planIdforDiscountDirectPlan);
    params.append(
      "planDetailsforGuranteedPlan",
      JSON.stringify(planDetailsforGuranteedPlan)
    );
    params.append(
      "planDetailsforDirectPlan",
      JSON.stringify(planDetailsforDirectPlan)
    );
    params.append("productMRP", productMRP);
    params.append("productReview", productReview);
    params.append("productRating", productRating);
    params.append("productDisplayOrder", productDisplayOrder);
    params.append("isFitwarzCompatible", isFitwarzCompatible);
    params.append("isUrbanCompatible", isUrbanCompatible);
    params.append("isLiveSessionCompatible", isLiveSessionCompatible);

    params.append("tyreSize", tyre_size.id);
    params.append("frameMaterial", frame_material.id);
    params.append("gearType", gear_type.id);

    params.append("metaTitle", metaTitle);
    params.append("metaDescription", metaDescription);
    params.append("productService", productService);

    multipleProductImages.map((productImage) => {
      params.append("image", productImage);
    });

    if (this.isValid()) {
      this.setState({ Loader: true, productSaveLoader: true });

      addProduct(params).then((res) => {
        if (res.data.statusCode === 1) {
          this.setState({ Loader: false, productSaveLoader: false });
          this.closeProductModal();
          toast.success(res.data.responseData.message);
          this.props.showProductsList();
        } else {
          this.setState({
            validationError: res.data.error.errorMessage,
            productSaveLoader: false,
            Loader: false,
          });
        }
      });
    }
  };

  handleAddPlanDetails = (field) => {
    let tempField = Array.from(this.state[field]);
    tempField.push({});
    this.setState({ [field]: tempField });
  };

  handlePlanDetails = (e, field, index) => {
    let { name, value } = e.target;
    let tempField = Array.from(this.state[field]);

    tempField[index][name] = value;
    this.setState({ [field]: tempField });
  };

  updateData = (value , key) => {
    this.setState({ [key]: value });
  }

  render() {
    let { openAddProductPopup } = this.props;
    let {
      validationError,
      productSaveLoader,
      specifications,
      multipleProductImagesPreview,
      planDetailsforGuranteedPlan,
      planDetailsforDirectPlan,
      onefitplusChoiceImage,
      onefitplusChoiceImagePreview,
      productPrice,
      productMRP,
      productReview,
      productRating,
      productDisplayOrder,
      productCategory,
      selectedCategory,
      color_code,
      color_name,
      metaTitle,
      metaDescription
    } = this.state;

    let genders = [
      { label: "male", value: 1 },
      { label: "female", value: 2 },
    ];


    return (
      <Modal
        show={openAddProductPopup}
        onHide={this.closeProductModal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Product</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form onSubmit={this.addProduct} className="mb-5">
            <div className="form-group">
              <label>Name of Product</label>
              <input
                type="text"
                className="form-control"
                onChange={this.handleChange}
                name="productName"
                id="productName"
              />
            </div>

            <div className="form-group">
              <label>Product Service</label>
              <ReactQuill theme="snow" value={this.state.productService} onChange={(value) => {this.updateData(value , "productService")}}
                height={250}
                modules={{
                  toolbar: [
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    ['link', 'image', 'video'],
                    ['code-block'],
                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                    ['blockquote', 'code-block'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                    [{ 'direction': 'rtl' }],                         // text direction
                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                    [{ 'font': [] }],
                    [{ 'align': [] }],
                    ['clean']
                  ]
                }}
              />
            </div>

            <div className="row">
              <div className="form-group col">
                <label>Meta Title*</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                  name="metaTitle"
                  id="metaTitle"
                />
              </div>

              <div className="form-group col">
                <label>Meta Description*</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                  name="metaDescription"
                  id="metaDescription"
                />
              </div>
              </div>

            <div className="row">
              <div className="form-group col">
                <label>Product Code</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                  name="productCode"
                  id="productCode"
                />
              </div>

              <div className="form-group col">
                <label>Product ASIN</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                  name="productASIN"
                  id="productASIN"
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col">
                <label>Product Price (₹)</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.onHandleChangeDecimal}
                  name="productPrice"
                  id="productPrice"
                  value={productPrice}
                />
              </div>

              <div className="form-group col">
                <label>Product Type</label>
                <select
                  type="number"
                  className="form-control"
                  onChange={this.handleChange}
                  name="productType"
                  id="productType"
                >
                  <option value={0}>Rent</option>
                  <option value={1}>Sale</option>
                </select>
              </div>
            </div>

            <div className="form-group mt-4">
              <div className="row">
                <div className="form-group col-3">
                  <label>MRP (₹)</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.onHandleChangeDecimal}
                    name="productMRP"
                    id="productMRP"
                    value={productMRP}
                  />
                </div>

                <div className="form-group col-3">
                  <label>Review</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.onHandleChangeNumeric}
                    name="productReview"
                    id="productReview"
                    value={productReview}
                    placeholder="000"
                  />
                </div>

                <div className="form-group col-3">
                  <label>Rating</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.onHandleChangeDecimal}
                    placeholder="0.0"
                    name="productRating"
                    id="productRating"
                    value={productRating}
                  />
                </div>
                <div className="form-group col-3">
                  <label>Display Order</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.onHandleChangeNumeric}
                    name="productDisplayOrder"
                    id="productDisplayOrder"
                    value={productDisplayOrder}
                    placeholder="1"
                  />
                </div>
              </div>
            </div>

            <div className="form-group mt-4">
              <div className="row">
                <div className="form-group col-4">
                  <div className="custom-control custom-checkbox cm_mt">
                    <input
                      name="isFitwarzCompatible"
                      id="isFitwarzCompatible"
                      type="checkbox"
                      checked={this.state.isFitwarzCompatible}
                      onChange={this.handleChange}
                      className="custom-control-input"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="isFitwarzCompatible"
                    >
                      isFitwarzCompatible
                    </label>
                  </div>
                </div>
                <div className="form-group col-4">
                  <div className="custom-control custom-checkbox cm_mt">
                    <input
                      name="isUrbanCompatible"
                      id="isUrbanCompatible"
                      type="checkbox"
                      checked={this.state.isUrbanCompatible}
                      onChange={this.handleChange}
                      className="custom-control-input"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="isUrbanCompatible"
                    >
                      isUrbanCompatible
                    </label>
                  </div>
                </div>


                <div className="form-group col-4">
                  <div className="custom-control custom-checkbox cm_mt">
                    <input
                      name="isLiveSessionCompatible"
                      id="isLiveSessionCompatible"
                      type="checkbox"
                      checked={this.state.isLiveSessionCompatible}
                      onChange={this.handleChange}
                      className="custom-control-input"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="isLiveSessionCompatible"
                    >
                      isLiveSessionCompatible
                    </label>
                  </div>
                </div>


              </div>
            </div>

            <div className="form-group">
              <label>Product Link</label>
              <input
                type="text"
                className="form-control"
                onChange={this.handleChange}
                name="productLink"
                id="productLink"
              />
            </div>

            <div className="form-group">
              <label>Product Description</label>
              <ReactQuill theme="snow" value={this.state.productDescription} onChange={this.handleDescription}
                height={250}
                modules={{
                  toolbar: [
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    ['link', 'image', 'video'],
                    ['code-block'],
                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                    ['blockquote', 'code-block'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                    [{ 'direction': 'rtl' }],                         // text direction
                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                    [{ 'font': [] }],
                    [{ 'align': [] }],
                    ['clean']
                  ]
                }}
              />
            </div>
            <div className="form-group">
              <label>Specifications</label>
              {specifications.map((specs, index) => (
                <div key={index} className="row">
                  <h4 className="cm_pos_rel col-12">
                    Spec {index + 1}
                    <span
                      className="btn btn-danger btn-sm"
                      onClick={() => this.removeSpecification(index)}
                    >
                      <i className="fa fa-trash" />
                    </span>
                  </h4>
                  <div className="form-group col">
                    <label>Select Label</label>
                    <Select
                      isSearchable
                      options={productSpecifications}
                      placeholder="Select a supported specification..."
                      onChange={(val) =>
                        this.selectProductSpecification(val, index)
                      }
                      value={specs}
                    />
                  </div>

                  <div className="form-group col">
                    <label>Enter Value</label>
                    <input
                      type="text"
                      placeholder="Enter value for selected specification......"
                      className="form-control"
                      onChange={(e) => this.handleSpecsChange(e, index)}
                      value={specs.value}
                    />
                  </div>
                </div>
              ))}
              <div>
                <Button
                  variant="info"
                  size="sm"
                  onClick={this.handleAddSpecification}
                >
                  {" "}
                  <i className="fa fa-plus-square mr-1" />
                  Add Specs
                </Button>
              </div>
            </div>

            <div className="form-group">
              <label>Brand Name</label>
              <input
                type="text"
                className="form-control"
                onChange={this.handleChange}
                name="brandName"
                id="brandName"
                placeholder="Ex: Rpm Fitness......"
              />
            </div>
            <div className="form-group">
              <label>Actual Price(₹)</label>
              <input
                type="number"
                className="form-control"
                onChange={this.handleChange}
                name="productPriceDiscount"
                id="productPriceDiscount"
                placeholder="Ex: 1000......."
              />
            </div>

            <div className="form-group">
              <label>Product Color Code</label>
              <input
                type="color"
                className="form-control color_input"
                value={color_code}
                onChange={(e) => {
                  this.setState({ color_code: e.target.value });
                }}
                name="Product Color Code"
                id="Product Color Code"
                placeholder="Select Product Color Code"
              />
            </div>

            <div className="form-group">
              <label>Product Color Name</label>
              <input
                type="text"
                className="form-control color_input"
                onChange={(e) => {
                  this.setState({ color_name: e.target.value });
                }}
                name="Product Color Name"
                id="Product Color Name"
                placeholder="Select Product Color Name"
              />
            </div>

            <div className="form-group">
              <label>Product Size</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  this.setState({ size: e.target.value });
                }}
                name="Product Size"
                id="Product Size"
                placeholder="Select Product Size"
              />
            </div>

            <div className="form-group">
              <label>Product Category</label>
              <Select
                isSearchable
                options={productCategories}
                placeholder="Select a product category"
                onChange={(val) => this.selectProductCategory(val)}
              />
            </div>
            {selectedCategory && selectedCategory.subCategories.length ? (
              <div className="form-group">
                <label>Product Sub Category</label>
                <Select
                  isSearchable
                  options={selectedCategory.subCategories}
                  placeholder="Select a product sub category"
                  onChange={(val) => this.selectProductSubCategory(val)}
                />
              </div>
            ) : (
                ""
              )}

            <div className="form-group">
              <label>Tyre Size</label>
              <Select
                isSearchable
                options={tyreSizes}
                placeholder="Select tyre size"
                onChange={(val) => this.setState({ tyre_size: val })}
              />
            </div>
            <div className="form-group">
              <label>Frame Material</label>
              <Select
                isSearchable
                options={frameMaterials}
                placeholder="Select frame Materials"
                onChange={(val) => this.setState({ frame_material: val })}
              />
            </div>
            <div className="form-group">
              <label>Gear Type</label>
              <Select
                isSearchable
                options={gearTypes}
                placeholder="Select gear Type"
                onChange={(val) => this.setState({ gear_type: val })}
              />
            </div>

            <div className="form-group">
              <label>Gender</label>
              <Select
                isSearchable
                options={genders}
                placeholder="Select Gender"
                onChange={(event) => this.setState({ gender: event.value })}
              />
            </div>

            <div className="form-group mt-5 mb-5">
              <label className="d-block">Select Product Website</label>
              <div className="radio_wrapper text-center">
                <label>
                  Amazon
                  <input
                    type="radio"
                    defaultChecked={true}
                    onChange={this.handleChange}
                    value={0}
                    name="productWebsite"
                  />
                  <span></span>
                </label>
                <label>
                  Flipkart
                  <input
                    type="radio"
                    value={1}
                    onChange={this.handleChange}
                    name="productWebsite"
                  />
                  <span></span>
                </label>
                <label>
                  One Fitplus
                  <input
                    type="radio"
                    value={2}
                    onChange={this.handleChange}
                    name="productWebsite"
                  />
                  <span></span>
                </label>
              </div>
            </div>

            <div className="form-group mt-5 mb-5">
              <label className="d-block">Upload Product's Main Image</label>
              <div className="radio_wrapper text-center">
                <input
                  type="file"
                  onChange={this.handleChange}
                  id="productImage"
                  name="productImage"
                />
                <span></span>
                <img width="400px" src={this.state.productImagePreview} />
              </div>
            </div>

            <div className="form-group mt-5 mb-5">
              <label className="d-block">One Fitplus Choice Image</label>
              <div className="radio_wrapper text-center">
                <input
                  type="file"
                  onChange={this.handleChange}
                  id="onefitplusChoiceImage"
                  name="onefitplusChoiceImage"
                />
                <span></span>
                <img width="400px" src={onefitplusChoiceImagePreview} />
              </div>
            </div>

            <div className="form-group">
              <label className="d-block">Product's Other Images</label>
              <div className="radio_wrapper text-center">
                <input
                  type="file"
                  multiple
                  onChange={this.handleMultipleImgPreivew}
                />
              </div>
            </div>

            {multipleProductImagesPreview.map((imageURI) => (
              <img
                key={imageURI}
                className="tracks_img"
                src={imageURI}
                alt="Photo uploaded"
              />
            ))}

            <div className="form-group mt-4">
              <label>Guaranteed Plan Details</label>
              {planDetailsforGuranteedPlan.map((detail, i) => (
                <div className="row" key={i}>
                  <div className="form-group col-2">
                    <label>Plan Tenure {i + 1}</label>
                    <select
                      type="number"
                      className="form-control"
                      name="planTenure"
                      id="planTenure"
                      onChange={(val) =>
                        this.handlePlanDetails(
                          val,
                          "planDetailsforGuranteedPlan",
                          i
                        )
                      }
                      value={detail.planTenure}
                    >
                      <option value={6}>6 Months</option>
                      <option value={9}>9 Months</option>
                      <option value={12}>12 Months</option>
                      <option value={15}>15 Months</option>
                      <option value={18}>18 Months</option>
                    </select>
                  </div>

                  <div className="form-group col-2">
                    <label>Plan Amount (₹)</label>
                    <input
                      type="number"
                      className="form-control"
                      name="planAmount"
                      onChange={(val) =>
                        this.handlePlanDetails(
                          val,
                          "planDetailsforGuranteedPlan",
                          i
                        )
                      }
                      value={detail.planAmount}
                    />
                  </div>

                  <div className="form-group col-2">
                    <label>Deposit (₹)</label>
                    <input
                      type="number"
                      className="form-control"
                      name="deposit"
                      onChange={(val) =>
                        this.handlePlanDetails(
                          val,
                          "planDetailsforGuranteedPlan",
                          i
                        )
                      }
                      value={detail.deposit}
                    />
                  </div>
                  <div className="form-group col-2">
                    <label>Actual Price(₹)</label>
                    <input
                      type="number"
                      className="form-control"
                      name="discount"
                      onChange={(val) =>
                        this.handlePlanDetails(
                          val,
                          "planDetailsforGuranteedPlan",
                          i
                        )
                      }
                      value={detail.discount}
                    />
                  </div>
                  <div className="form-group col-4">
                    <label>Plan Id For Guranteed Plan</label>
                    <input
                      type="text"
                      className="form-control"
                      name="planIdforGuranteedPlan"
                      onChange={(val) =>
                        this.handlePlanDetails(
                          val,
                          "planDetailsforGuranteedPlan",
                          i
                        )
                      }
                      value={detail.planIdforGuranteedPlan}
                    />
                  </div>
                </div>
              ))}
            </div>
            <i
              className="fa fa-plus-square cm_pointer"
              onClick={() =>
                this.handleAddPlanDetails("planDetailsforGuranteedPlan")
              }
            >
              {" "}
              Add Guaranteed Plan Detail
            </i>

            <div className="form-group mt-4">
              <label>Direct Plan Details</label>
              {planDetailsforDirectPlan.map((detail, i) => (
                <div className="row" key={i}>
                  <div className="form-group col-2">
                    <label>Plan Tenure {i + 1}</label>

                    <select
                      type="number"
                      className="form-control"
                      name="planTenure"
                      id="planTenure"
                      onChange={(val) =>
                        this.handlePlanDetails(
                          val,
                          "planDetailsforDirectPlan",
                          i
                        )
                      }
                      value={detail.planTenure}
                    >
                      <option value={6}>6 Months</option>
                      <option value={9}>9 Months</option>
                      <option value={12}>12 Months</option>
                      <option value={15}>15 Months</option>
                      <option value={18}>18 Months</option>
                    </select>
                  </div>

                  <div className="form-group col-2">
                    <label>Plan Amount (₹)</label>
                    <input
                      type="number"
                      className="form-control"
                      name="planAmount"
                      onChange={(val) =>
                        this.handlePlanDetails(
                          val,
                          "planDetailsforDirectPlan",
                          i
                        )
                      }
                      value={detail.planAmount}
                    />
                  </div>

                  <div className="form-group col-2">
                    <label>Deposit (₹)</label>
                    <input
                      type="number"
                      className="form-control"
                      name="deposit"
                      onChange={(val) =>
                        this.handlePlanDetails(
                          val,
                          "planDetailsforDirectPlan",
                          i
                        )
                      }
                      value={detail.deposit}
                    />
                  </div>

                  <div className="form-group col-2">
                    <label>Discounted Price (₹)</label>
                    <input
                      type="number"
                      className="form-control"
                      name="discount"
                      onChange={(val) =>
                        this.handlePlanDetails(
                          val,
                          "planDetailsforDirectPlan",
                          i
                        )
                      }
                      value={detail.discount}
                    />
                  </div>
                  <div className="form-group col-4">
                    <label>Plan Id For Direct Plan</label>
                    <input
                      type="text"
                      className="form-control"
                      name="planIdforDirectPlan"
                      onChange={(val) =>
                        this.handlePlanDetails(
                          val,
                          "planDetailsforDirectPlan",
                          i
                        )
                      }
                      value={detail.planIdforDirectPlan}
                    />
                  </div>
                </div>
              ))}
            </div>
            <i
              className="fa fa-plus-square cm_pointer"
              onClick={() =>
                this.handleAddPlanDetails("planDetailsforDirectPlan")
              }
            >
              {" "}
              Add Direct Plan Detail
            </i>

            <div className="form-group">
              <label>SkuId For Guranteed Plan</label>
              <input
                type="text"
                className="form-control"
                onChange={this.handleChange}
                name="skuIdforGuranteedPlan"
                id="skuIdforGuranteedPlan"
                placeholder="Enter SkuId For Guranteed Plan"
              />
            </div>
            <div className="form-group">
              <label>SkuId For Direct Plan</label>
              <input
                type="text"
                className="form-control"
                onChange={this.handleChange}
                name="skuIdforDirectPlan"
                id="skuIdforDirectPlan"
                placeholder="Enter SkuId For Direct Plan"
              />
            </div>
            <div className="form-group">
              <label>PlanId For Discounted Guranteed Plan</label>
              <input
                type="text"
                className="form-control"
                onChange={this.handleChange}
                name="planIdforDiscountGuranteedPlan"
                id="planIdforDiscountGuranteedPlan"
                placeholder="Enter PlanId For Discounted Guranteed Plan"
              />
            </div>
            <div className="form-group">
              <label>PlanId For Discount Direct Plan</label>
              <input
                type="text"
                className="form-control"
                onChange={this.handleChange}
                name="planIdforDiscountDirectPlan"
                id="planIdforDiscountDirectPlan"
                placeholder="Enter PlanId For Discount Direct Plan"
              />
            </div>

            <button
              className="btn btn-primary btn-block mt-4"
              disabled={productSaveLoader}
            >
              {!productSaveLoader ? (
                <span>Save Product</span>
              ) : (
                  <div>
                    {" "}
                    <i className="fa fa-spinner fa-pulse fa-2x fa-fw margin-bottom" />
                    <span>Saving Product....</span>
                  </div>
                )}
            </button>
            {validationError && (
              <Alert variant="danger"> {validationError} </Alert>
            )}
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AddProduct;

import React, { Component } from 'react';
import dateFormat from 'dateformat';
import Pagination from "react-js-pagination";
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable'
import { Link } from 'react-router-dom';
import { getV2Services } from "../../config/services/serviceCategoriesService";
import { addTrainer, getAllTrainers, changeTrainerStatus, editProfileByAdmin, uploadTrainerImages } from '../../config/services/trainerService';
import { getConfigs, getLeaderboardConfig } from '../../config/services/usersService';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import { Button, Modal, Badge, Accordion, Card, ProgressBar } from 'react-bootstrap';
import Select from 'react-select';
import { CONFIG_VERSIONS } from '../../utils/constants';

/**
 *This module is same as Dietician module, keys name can be same  as dietician module as the module is reused.
 Because Trainer/Dietician are saved in same collection at backend 
 */
class Trainers extends Component {
  constructor(props) {
    super(props);

    this.searchTimeOut = null;
    this.state = {
      openAddTrainerPopup: false,
      openViewTrainer: false,
      viewTrainerDetails: {},
      trainerList: [],
      searchTimeOut: 0,
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "",
      mobileNo: "",
      experience: "",
      loader: false,
      validationError: "",
      sortType: 1,
      sortField: '',
      pageNo: 1,
      countsPerPage: 15,
      totalTrainers: 20,
      services: [],
      selectedServices: [],
      discount: 0,
      price: 0,
      groupSessionPrice: 0,
      editTrainerPopup: false,
      trainersServices: [],
      tempTrainerId: '',
      profilePic: '',
      profilePicPreview: '',
      speciality: '',
      serviceCategories: [],
      selectServiceCategory: '',
      serviceCategoryId: '',
      AllTypes: [],
      selectType: '',
      devicesSupported: [],
      about: '',
      otherSpeciality: '',
      appImages: { liveSessionsListImages: [], nonConnectedSessionImages: [] },
      websiteImages: {},
      submitLoader: false,
      profilePic: '',
      profilePicThumb: '',
      metaTitle: '',
      metaDescription: '',
      LiveSessionDevices: []
    }
  }

  handleAddTrainerPopup = () => {
    this.setState({ selectServiceCategory: '', serviceCategoryId: '', trainersServices: [], selectedServices: [], openAddTrainerPopup: !this.state.openAddTrainerPopu })
  }

  getDefaultConfigs = () => {
    let params = { version: CONFIG_VERSIONS.ADMIN_CONFIG }
    getLeaderboardConfig(params)
      .then(res => {
        const response = res.data;
        if (response.statusCode == 1) {
          let metaData = response.responseData.result.metaData
          let LiveSessionDevices = metaData && JSON.parse(metaData) ? JSON.parse(metaData).STREAMING_TYPES : []
          this.setState({ LiveSessionDevices })
        }
        else if (response.statusCode == 0)
          toast.success(response.error.errorMessage)
      })
  }

  closeModal = () => this.setState({
    openAddTrainerPopup: false,
    openViewTrainer: false,
    viewTrainerDetails: {},
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    mobileNo: "",
    validationError: "",
    experience: "",
    discount: 0,
    price: 0,
    editTrainerPopup: false,
    trainersServices: [],
    selectedServices: [],
    tempTrainerId: '',
    groupSessionPrice: 0,
    speciality: '',
    selectServiceCategory: '',
    serviceCategoryId: '',
    selectType: '',
    devicesSupported: [],
    about: '',
    otherSpeciality: '',
    appImages: { liveSessionsListImages: [{}], nonConnectedSessionImages: [{}] },
    websiteImages: {},
    submitLoader: false,
    profilePic: '',
    profilePicThumb: '',
    metaTitle: '',
    metaDescription: ''
  })

  getServices = (val) => {
    let paramsobj = { params: { count: 0, pageNo: 0, search: val, parentCategory: this.state.serviceCategoryId } }
    return getV2Services(paramsobj)
      .then(res => {
        if (res.data.statusCode == 1) {
          let { result } = res.data.responseData
          let services = []
          result.map(service => {
            services.push({ label: service.serviceName, value: service._id })
          })
          this.setState({ services })
          return services
        }
        else if (res.data.statusCode == 0) {
          console.log(res.data.error.errorMessage)
        }
      })
  }

  componentDidMount() {
    let params = {
      params: {
        count: this.state.countsPerPage,
        sortField: 'created',
        sortType: -1,
      }
    }

    var allcategory = [
      { value: 1, label: 'Doctor' },
      { value: 2, label: 'Dietician' },
      { value: 3, label: 'Home workout' }
    ]
    var AllTypes = [
      { value: 'Live', label: 'Live' },
      { value: 'On-Demand', label: 'On-Demand' }
    ]
    this.setState({ serviceCategories: allcategory, AllTypes: AllTypes })

    this.getAllTrainers(params);
    this.getServices();
    this.getDefaultConfigs();
  }

  handlePagination = (pageNumber) => this.setState({ pageNo: pageNumber }, () => {
    let params = {
      params: {
        pageNo: this.state.pageNo - 1,
        count: this.state.countsPerPage
      }
    }

    this.getAllTrainers(params)
  });

  getAllTrainers(params) {
    let self = this;
    this.setState({ loader: true })

    getAllTrainers(params)
      .then(function (res) {
        const response = res.data
        if (response.statusCode == 1) {
          self.setState({
            trainerList: response.responseData.result,
            totalTrainers: response.responseData.totalCount, loader: false
          })
        }
      })
  }


  handleChange = (e) => {
    const { name, value, type } = e.target
    this.setState({ [name]: value })
  }

  onHandleCategorySelect = (key, selectedOptions) => {
    this.setState({ [key]: selectedOptions, trainersServices: [], serviceCategoryId: selectedOptions.value, selectedServices: [] }, function () {
      this.getServices();
    });
  }

  handleSelectType = (type) => {
    this.setState({ 'selectType': type, 'validationError': '' });
  }

  submitAddTrainer = async (e) => {
    e.preventDefault()
    const { selectType, firstName, lastName, email, countryCode, mobileNo, experience, selectedServices, discount, price, groupSessionPrice, speciality, serviceCategoryId, devicesSupported, about, otherSpeciality, appImages, websiteImages, validationError, profilePic, metaTitle, metaDescription } = this.state;
    var pattern = /(([a-zA-Z0-9\-?\.?]+)@(([a-zA-Z0-9\-_]+\.)+)([a-z]{2,3}))+$/;
    let validEmail = new RegExp(pattern).test(email);
    let imageValidation = await this.validateImages()


    let params = {
      firstName,
      lastName,
      email,
      countryCode,
      mobileNo,
      experience,
      services: selectedServices,
      discount, price,
      groupSessionPrice,
      speciality,
      serviceCategoryId: serviceCategoryId ? serviceCategoryId : 0,
      trainerType: selectType ? selectType.value : 'On-Demand',
      about,
      otherSpeciality,
      metaTitle,
      metaDescription
    }

    if (devicesSupported && devicesSupported.length) {
      params.devicesSupported = devicesSupported.map(device => device.value)
    }

    let validExperience = new RegExp(/^(\d+(\.\d{0,1})?|\.?\d{1,2})$/).test(experience)

    if (selectType == "" || firstName == "" || lastName == "" || email == "" || speciality == "" || countryCode == "" || mobileNo == "" || experience == "" || !metaTitle || !metaDescription) {
      this.setState({ validationError: "Fields marked (*) are mandatory." })
    }
    else if (!validEmail) {
      this.setState({ validationError: "Enter valid email!" })
    }
    else if (!validExperience) {
      this.setState({ validationError: "Enter valid experience. It can be upto one decimal place. Ex:9.9 " })
    }
    else if (experience > 80) {
      this.setState({ validationError: "Experience can only be upto 80 yrs." })
    }
    else if (selectType && selectType.value != 'Live' && (!price || !discount || !groupSessionPrice)) {
      this.setState({ validationError: "With Price & Discount Is Mandatory!" })
    }
    else if (!serviceCategoryId) {
      this.setState({ validationError: "Service category Is Mandatory!" })
    }
    else if (selectedServices.length == 0) {
      this.setState({ validationError: "Service Is Mandatory!" })
    }
    else if (selectType && selectType.value === 'Live' && (!devicesSupported.length)) {
      this.setState({ validationError: "Device support can't be empty" })
    }
    else if (selectType && selectType.value === 'Live' && !imageValidation) {
      console.error(validationError, ":::ERROR")
    }
    else {
      this.setState({ submitLoader: true })

      if (profilePic)
        params.profilePic = await this.handleUploadTrainerImages(profilePic, `trainerImages/${firstName}_${lastName}/profilePic`)


      if (selectType && selectType.value === 'Live') {
        websiteImages.trainerListingImage = await this.handleUploadTrainerImages(websiteImages.trainerListingImage, `trainerImages/${firstName}_${lastName}/trainerListingImage`)
        websiteImages.trainerDetailImage = await this.handleUploadTrainerImages(websiteImages.trainerDetailImage, `trainerImages/${firstName}_${lastName}/trainerDetailImage`)

        delete websiteImages['trainerListingImageThumb'];
        delete websiteImages['trainerDetailImageThumb'];

        appImages.liveSessionDetailImage = await this.handleUploadTrainerImages(appImages.liveSessionDetailImage, `trainerImages/${firstName}_${lastName}/liveSessionDetailImage`)
        delete appImages['liveSessionDetailImageThumb'];

        await Promise.all(appImages.liveSessionsListImages.map(async imgObj => {
          imgObj.imageUrl = await this.handleUploadTrainerImages(imgObj.image, `trainerImages/${firstName}_${lastName}/liveSessionsListImages`)
          delete imgObj['imageThumb'];
          delete imgObj['image'];
        }))

        await Promise.all(appImages.nonConnectedSessionImages.map(async imgObj => {
          imgObj.imageUrl = await this.handleUploadTrainerImages(imgObj.image, `trainerImages/${firstName}_${lastName}/nonConnectedSessionImages`)
          delete imgObj['imageThumb'];
          delete imgObj['image'];
        }))

        params.websiteImages = websiteImages
        params.appImages = appImages
      }

      this.setState({ validationError: "" })
      this.submitAddTrainerApi(params)
    }
  }


  validateImages = async () => {
    let { appImages, websiteImages, selectType } = this.state

    if (selectType && selectType.value === 'Live') {
      if (!appImages.liveSessionDetailImage) {
        this.setState({ validationError: 'Please Select Live Session Detail Image ' })
        return false
      }
      else if (appImages && !appImages.liveSessionsListImages || !appImages.liveSessionsListImages.length) {
        this.setState({ validationError: 'Please Select Live Session List Images' })
        return false
      }
      // else if (appImages && appImages.liveSessionsListImages && appImages.liveSessionsListImages.length) {
      //   return appImages.liveSessionsListImages.map(imgObj => {
      //     if (!imgObj.image || !imgObj.imageCategory) {
      //       this.setState({ validationError: 'Live session image and device type are mandatory' })
      //       return false
      //     }
      //     else if (appImages.liveSessionsListImages.findIndex(imgData => imgData.imageCategory === imgObj.imageCategory) >= 0) {
      //       this.setState({ validationError: "Live session device types can't be duplicate" })
      //       return false
      //     }
      //   })
      // }
      else if (!websiteImages.trainerListingImage) {
        this.setState({ validationError: 'Please Select Website Listing Images' })
        return false
      }
      else if (!websiteImages.trainerDetailImage) {
        this.setState({ validationError: 'Please Select Website Detail Images' })
        return false
      }
      else {
        return true
      }
    }
    else {
      return true
    }
  }


  submitAddTrainerApi = (params) => {
    addTrainer(params)
      .then((res) => {
        this.setState({ submitLoader: false })
        const response = res.data;
        if (response.statusCode == 1) {
          toast.success(response.responseData.message)
          this.closeModal();
          this.getAllTrainers();
        } else if (response.statusCode == 0) {
          this.setState({ validationError: response.error.errorMessage, submitLoader: false })
        }
      })
  }

  loadServices = (val) => {
    return this.getServices(val)
  }


  handleTrainerSearch = (e) => {
    let params = {
      params: {
        count: this.state.countsPerPage,
        search: e.target.value
      }
    }

    if (this.searchTimeOut)
      clearTimeout(this.searchTimeOut);

    this.searchTimeOut = setTimeout(
      function () {
        this.getAllTrainers(params);
      }
        .bind(this),
      400
    );
  }


  handleSortList = (sortField) => {
    const { sortType } = this.state;
    let sortOrder = sortType == 1 ? -1 : 1;

    let self = this;
    this.setState({ sortField: sortField, loader: true, sortType: sortOrder }, () => {
      let params = {
        params: {
          sortField: sortField,
          sortType: sortType
        }
      }
      self.getAllTrainers(params);
    })
  }



  handleChangeStatus(id, status) {
    this.setState({ [id]: true, progress: 0.5, })

    let self = this;
    let obj = {
      dieticianId: id,
      dieticianStatus: status === 1 ? 2 : 1
    }

    changeTrainerStatus(obj)
      .then((res) => {
        if (res.data.statusCode === 1) {
          self.getAllTrainers();
          self.setState({ [id]: false })
        }
      })
  }

  viewTrainer = (details) => {
    this.setState({ openViewTrainer: true, viewTrainerDetails: details })
  }



  serviceSelect = (services) => {
    let selectedServices = []
    let trainersServices = []

    services && services.map(service => {
      trainersServices.push(service)
      selectedServices.push(service.value)
    })
    this.setState({ selectedServices, trainersServices })
  }

  handleEditTrainer = (trainer) => {
    let { firstName, lastName, email, countryCode, mobileNo, experience, price, discount, services, _id, groupSessionPrice, speciality, serviceCategoryId, trainerType, devicesSupported, about, otherSpeciality, appImages, websiteImages, profilePic, metaTitle, metaDescription } = trainer
    let { LiveSessionDevices } = this.state
    let trainersServices = []
    let selectedServices = []

    services && services.map(service => {
      if (service.parentCategory == serviceCategoryId) {
        trainersServices.push({ label: service.serviceName, value: service._id })
        selectedServices.push(service._id)
      }
    })

    this.state.serviceCategories && this.state.serviceCategories.map(category => {
      if (category.value == serviceCategoryId) {
        this.setState({ selectServiceCategory: category });
      }
    });

    let getTrainerType = {};

    if (trainerType) {
      getTrainerType.value = trainerType;
      getTrainerType.label = trainerType;
    }

    devicesSupported = LiveSessionDevices.filter(item => devicesSupported.includes(item.value))

    this.setState({
      editTrainerPopup: !this.state.editTrainerPopup, firstName, lastName, email, countryCode, mobileNo, experience, price, discount, trainersServices, tempTrainerId: _id, selectedServices, groupSessionPrice, speciality, serviceCategoryId, 'selectType': getTrainerType, devicesSupported, about, otherSpeciality, metaTitle, metaDescription,
      appImages: appImages ? appImages : {},
      websiteImages: websiteImages ? websiteImages : {},
      profilePic: profilePic || ''
    }, () => { this.getServices() })
  }

  submitEditTrainer = async (e) => {
    this.setState({ validationError: "" })
    e.preventDefault()

    let { selectType, tempTrainerId, firstName, lastName, price, discount, selectedServices, experience, groupSessionPrice, speciality, serviceCategoryId, devicesSupported, about, otherSpeciality, appImages, websiteImages, profilePic, profilePicThumb, validationError, metaTitle, metaDescription } = this.state

    let params = { trainerType: selectType ? selectType.value : 'On-Demand', trainerId: tempTrainerId, firstName, lastName, price, discount, services: selectedServices, experience, groupSessionPrice, speciality, serviceCategoryId, about, otherSpeciality, metaTitle, metaDescription }

    let validExperience = new RegExp(/^(\d+(\.\d{0,1})?|\.?\d{1,2})$/).test(experience)

    let imageValidation = await this.validateImages()


    if (!tempTrainerId || !firstName || !lastName || !experience || speciality == "" || !metaTitle || !metaDescription) {
      this.setState({ validationError: "Fields marked with '*' are mandatory!" })
    }
    else if (!validExperience) {
      this.setState({ validationError: "Enter valid experience. It can be upto one decimal place. Ex:9.9 " })
    }
    else if (experience > 80) {
      this.setState({ validationError: "Experience can only be upto 80 yrs." })
    }
    else if (selectType && selectType.value != 'Live' && (!price || !discount || !groupSessionPrice)) {
      this.setState({ validationError: "With Price & Discount Is Mandatory!" })
    }
    else if (!serviceCategoryId) {
      this.setState({ validationError: "Service category Is Mandatory!" })
    }
    else if (selectedServices.length == 0) {
      this.setState({ validationError: "Service Is Mandatory!" })
    }
    else if (selectType && selectType.value === 'Live' && (!devicesSupported.length)) {
      this.setState({ validationError: "Device support can't be empty" })
    }
    else if (selectType && selectType.value === 'Live' && !imageValidation) {
      console.error(validationError, ":::ERROR")
    }
    else {
      this.setState({ submitLoader: true })
      var current_Date = new Date();
      var dateFrom = Date.parse(current_Date);
      params.dateFrom = dateFrom;

      if (params.trainerType == 'Live') {
        params.price = 0;
        params.discount = 0;
        params.groupSessionPrice = 0;
        params.devicesSupported = devicesSupported.map(device => device.value)
      }

      if (profilePicThumb && profilePic)
        params.profilePic = await this.handleUploadTrainerImages(profilePic, `trainerImages/${firstName}_${lastName}/profilePic`)


      if (selectType && selectType.value === 'Live') {

        if (websiteImages.trainerListingImageThumb)
          websiteImages.trainerListingImage = await this.handleUploadTrainerImages(websiteImages.trainerListingImage, `trainerImages/${firstName}_${lastName}/trainerListingImage`)

        if (websiteImages.trainerDetailImageThumb)
          websiteImages.trainerDetailImage = await this.handleUploadTrainerImages(websiteImages.trainerDetailImage, `trainerImages/${firstName}_${lastName}/trainerDetailImage`)

        delete websiteImages['trainerListingImageThumb'];
        delete websiteImages['trainerDetailImageThumb'];

        if (appImages.liveSessionDetailImageThumb)
          appImages.liveSessionDetailImage = await this.handleUploadTrainerImages(appImages.liveSessionDetailImage, `trainerImages/${firstName}_${lastName}/liveSessionDetailImage`)
        delete appImages['liveSessionDetailImageThumb'];

        await Promise.all(appImages.liveSessionsListImages.map(async imgObj => {
          if (imgObj.image)
            imgObj.imageUrl = await this.handleUploadTrainerImages(imgObj.image, `trainerImages/${firstName}_${lastName}/liveSessionsListImages`)
          delete imgObj['imageThumb'];
          delete imgObj['image'];
        }))

        await Promise.all(appImages.nonConnectedSessionImages.map(async imgObj => {
          if (imgObj.image)
            imgObj.imageUrl = await this.handleUploadTrainerImages(imgObj.image, `trainerImages/${firstName}_${lastName}/nonConnectedSessionImages`)
          delete imgObj['imageThumb'];
          delete imgObj['image'];
        }))

        params.websiteImages = websiteImages
        params.appImages = appImages
      }

      editProfileByAdmin(params)
        .then(res => {
          this.setState({ submitLoader: false })
          const response = res.data;
          if (response.statusCode == 1) {
            toast.success(response.responseData.message)
            this.closeModal();
            this.getAllTrainers();
          } else if (response.statusCode == 0) {
            this.setState({ validationError: response.error.errorMessage })
          }
        })
    }
  }

  handleMultiSelect = (key, val) => {
    this.setState({ [key]: val ? val : [] })
  }

  handleImageDeviceType = (name, val, index) => {
    let { appImages } = this.state
    appImages[name][index].imageCategory = val.value
    this.setState({ appImages })
  }

  handleTrainerImages = (e, deviceKey, index) => {
    let { appImages, websiteImages } = this.state
    let { name, files } = e.target
    let file = e.target.files[0];
    let reader = new FileReader();

    if (name == 'liveSessionsListImages') {
      appImages.liveSessionsListImages = Array.from(appImages.liveSessionsListImages)

      let imageThumb
      reader.onloadend = () => {
        imageThumb = reader.result
        appImages.liveSessionsListImages[index] = { ...appImages.liveSessionsListImages[index], image: files[0], imageThumb }
        this.setState({ appImages })
      }
      reader.readAsDataURL(file)
    }
    else if (name == 'nonConnectedSessionImages') {
      let imageThumb
      appImages[name] = Array.from(appImages[name])
      reader.onloadend = () => {
        imageThumb = reader.result
        appImages[name][index] = { ...appImages[name][index], image: files[0], imageThumb }
        this.setState({ appImages })
      }
      reader.readAsDataURL(file)
    }
    else if (name === 'liveSessionsDetailmage') {
      reader.onloadend = () => {
        appImages.liveSessionDetailImageThumb = reader.result
        appImages.liveSessionDetailImage = file
        this.setState({ appImages })
      }
      reader.readAsDataURL(file)
    }
    else if (name === 'trainerListingImage' || name === 'trainerDetailImage') {
      reader.onloadend = () => {
        websiteImages[name + 'Thumb'] = reader.result
        websiteImages[name] = file
        this.setState({ websiteImages })
      }
      reader.readAsDataURL(file)
    }
    else {
      reader.onloadend = () => this.setState({ [name + 'Thumb']: reader.result, [name]: file })
      reader.readAsDataURL(file)
    }
  }

  addMoreLiveListImages = (key, val) => {
    let { appImages } = this.state
    appImages[key] = Array.from(appImages[key])
    appImages[key].push({ image: '', imageThumb: '', imageCategory: 0 })
    this.setState({ appImages })

  }

  deleteAppImage = (key, val, i) => {
    let { appImages } = this.state
    appImages[key] = Array.from(appImages[key])
    appImages[key].splice(i, 1);
    this.setState({ appImages })
  }

  handleUploadTrainerImages = (image, imageS3Path) => {
    let params = new FormData()
    params.append('image', image)
    params.append('pathToUpload', imageS3Path)

    if (!image) {
      this.setState({ validationError: 'Image cannot be empty' })
    }
    else if (!imageS3Path) {
      this.setState({ validationError: 'Image Path cannot be empty' })
    }
    else {
      return uploadTrainerImages(params)
        .then(res => {
          let response = res.data
          if (response.statusCode == 1) {
            let { result } = response.responseData
            return result.Location
          }
          else if (response.statusCode == 0) {
            this.setState({ validationError: response.error.errorMessage, submitLoader: false })
            return image
          }
        })
    }
  }

  // AllTypes
  render() {
    const { trainerList, loader, validationError, sortType, openViewTrainer, viewTrainerDetails, countryCode, mobileNo, experience, pageNo, countsPerPage, totalTrainers, services, price, discount, editTrainerPopup, firstName, lastName, email, trainersServices, openAddTrainerPopup, groupSessionPrice, speciality, selectType, devicesSupported, about, otherSpeciality, appImages, websiteImages,
      submitLoader, profilePic, profilePicThumb, metaTitle, metaDescription, LiveSessionDevices } = this.state;

    return (
      <HOC>
        <div className="body-container-wrapper">
          <div className="body-container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
              <li className="breadcrumb-item">Fitness Trainers</li>
            </ol>
            {
              loader
                ? <div className="loader_wrapper"><LoaderSvg /></div>
                : null
            }

            <div className="users_header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-4">
                    <h4 className="cm_page_heading">Fitness Trainers</h4>
                  </div>

                  <div className="col-md-2">
                    <Link to="/trainers/callSchedules">View Call Schedules</Link>
                  </div>

                  <div className="col-md-3 text-right cm_search">
                    <input
                      type="text"
                      className="form-control m-b-md-15"
                      placeholder="Search by Name or E-mail"
                      name="search"
                      onChange={this.handleTrainerSearch}
                    />
                    <i className="fa fa-search" />
                  </div>

                  <div className="col-md-3  m-b-md-15">
                    <button onClick={() => this.handleAddTrainerPopup()} className="btn btn-primary btn-block">Add Trainer</button>
                  </div>

                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered table-striped text-center">
                <thead>
                  <tr>
                    <th scope="col">Sr. No</th>
                    <th scope="col" className="filter_text">Name
                    <i className={`fa fa-sort-alpha-${sortType == 1 ? 'asc' : 'desc'}`}></i><button onClick={() => this.handleSortList('firstName')}></button>
                    </th>

                    <th scope="col" className="filter_text">E-mail
                    <i className={`fa fa-sort-alpha-${sortType == 1 ? 'asc' : 'desc'}`}></i><button onClick={() => this.handleSortList('email')}></button>
                    </th>

                    <th>Phone No.</th>
                    <th scope="col">Price (Rs)</th>
                    <th scope="col">Group Session Price (Rs)</th>
                    <th scope="col">Discount (%)</th>
                    <th scope="col">Schedule</th>
                    <th scope="col">Leaves</th>
                    <th scope="col">Type</th>
                    <th scope="col" className="filter_text">Created Date
                    <i className={`fa fa-sort-alpha-${sortType == 1 ? 'asc' : 'desc'}`}></i><button onClick={() => this.handleSortList('created')}></button>
                    </th>

                    <th scope="col">Action</th>
                  </tr>
                </thead>

                <tbody>

                  {trainerList.map((trainer, index) => (
                    <tr key={index}>
                      <td>{((index + 1) + ((pageNo - 1) * countsPerPage))}</td>
                      <td className="cm_pos_realtive more_padding">
                        <Link className="cm_rating" to={trainer.trainerType && trainer.trainerType != "Live" ? "/trainers/user-ratings/" + trainer._id : "/trainers/user-live-ratings/" + trainer._id}>
                          <i className="fa fa-star" title="See Trainer's ratings" />
                        </Link>

                        {trainer.firstName + " " + trainer.lastName}
                        <button className="btn btn-info btn-sm cm__btn" onClick={() => this.viewTrainer(trainer)}>View</button>
                      </td>
                      <td>{trainer.email}</td>
                      <td>{trainer.mobileNo}</td>
                      <td>{trainer.price ? trainer.price : '-'}</td>
                      <td>{trainer.groupSessionPrice ? trainer.groupSessionPrice : '-'}</td>
                      <td>{trainer.discount ? trainer.discount : '-'}</td>
                      <td><Link to={"/trainers/schedule/" + trainer._id}>View Schedule</Link></td>
                      <td><Link to={"/trainers/leaves/" + trainer._id}>View Leaves</Link></td>
                      <td>{trainer.trainerType ? trainer.trainerType : 'On-Demand'}</td>
                      <td>{dateFormat(trainer.created, "d mmmm yyyy")}</td>
                      <td>
                        <button className="btn btn-primary btn-xs" onClick={() => this.handleEditTrainer(trainer)}>
                          <i className="fa fa-pencil-square-o" />
                        </button>

                        <span className={`cm_ckeckbox_wrapper ${trainer.status === 1 ? 'cm_active' : 'cm_inactive'}`}
                          onClick={() => this.handleChangeStatus(trainer._id, trainer.status)}
                        >
                          <span className="cm_ckeckbox_btn"></span>
                        </span>
                      </td>
                    </tr>
                  ))}

                </tbody>


              </table>
              {!trainerList.length ? <h3 className="empty_error">Sorry, We couldn't find any content! <span>{this.state.deviceSearch}</span></h3> : null}

            </div>

            <Pagination
              activePage={pageNo}
              itemsCountPerPage={countsPerPage}
              totalItemsCount={totalTrainers}
              pageRangeDisplayed={4}
              onChange={this.handlePagination}
            />

          </div>
        </div>



        {openAddTrainerPopup ? <Modal
          show={openAddTrainerPopup}
          onHide={this.closeModal}
          centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Add Trainer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.submitAddTrainer} className="mb-5">
              <div className="row">
                <div className="form-group col-12">
                  <label>Service Type *</label>
                  < Select
                    require={true}
                    name="selectType"
                    placeholder="Select Type"
                    options={this.state.AllTypes}
                    value={selectType}
                    onChange={(val) => this.handleSelectType(val)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col">
                  <label>Meta Title *</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    name="metaTitle"
                  />
                </div>

                <div className="form-group col">
                  <label>Meta Description *</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    name="metaDescription"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col">
                  <label>First Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    name="firstName"
                  />
                </div>

                <div className="form-group col">
                  <label>Last Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    name="lastName"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-6">
                  <label>About</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    name="about"
                  />
                </div>
                <div className="form-group col-6">
                  <label>Other Speciality</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    name="otherSpeciality"
                  />
                </div>
              </div>

              {(selectType && selectType.value != 'Live') || !selectType ?
                <div className="row">
                  <div className="form-group col">
                    <label>Price per session</label>
                    <input
                      type="number"
                      className="form-control"
                      onChange={this.handleChange}
                      name="price"
                      onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                    />
                  </div>
                  <div className="form-group col">
                    <label>Price per group session</label>
                    <input
                      type="number"
                      className="form-control"
                      onChange={this.handleChange}
                      name="groupSessionPrice"
                      onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                    />
                  </div>
                  <div className="form-group col">
                    <label>Discount per session (%)</label>
                    <input
                      type="number"
                      className="form-control"
                      onChange={this.handleChange}
                      name="discount"
                      onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                    />
                  </div>

                </div>
                : null}


              <div className="row">
                <div className="form-group col-4">
                  <label>Speciality *</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    name="speciality"
                  />
                </div>

                <div className="form-group col-3">
                  <label>Country Code *</label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={this.handleChange}
                    name="countryCode"
                    value={countryCode}
                    onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                  />
                </div>

                <div className="form-group col-5">
                  <label>Phone number *</label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={this.handleChange}
                    name="mobileNo"
                    value={mobileNo}
                    onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-5">
                  <label>Service Category *</label>
                  < Select
                    require={true}
                    name="selectServiceCategory"
                    placeholder="Select Category"
                    options={this.state.serviceCategories}
                    value={this.state.selectServiceCategory}
                    onChange={(val) => this.onHandleCategorySelect('selectServiceCategory', val)}
                  />
                </div>

                <div className="form-group col-7">
                  <label>Service *</label>
                  <AsyncSelect
                    cacheOptions
                    isMulti
                    loadOptions={this.loadServices}
                    defaultOptions={this.state.selectServiceCategory ? services : null}
                    value={trainersServices}
                    onChange={(val) => this.serviceSelect(val)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-3">
                  <label>Experience (in years) *</label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={this.handleChange}
                    name="experience"
                    value={experience}
                    step=".01"
                    onKeyDown={e => (e.keyCode === 69) && e.preventDefault()}
                  />
                </div>

                <div className="form-group col-4">
                  <label>E-mail *</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    name="email"
                  />
                </div>


                {(selectType && selectType.value === 'Live') ?
                  <div className="form-group col-5">
                    <label>Device Support</label>
                    <Select
                      name="devicesSupported"
                      placeholder="Select Devices"
                      options={LiveSessionDevices}
                      value={devicesSupported}
                      isMulti
                      onChange={(val) => this.handleMultiSelect('devicesSupported', val)}
                    />
                  </div> : null}


                <div className="form-group col-12">
                  <label className="d-block">Upload Profile Pic</label>
                  <div className="radio_wrapper text-center">
                    <input className='upload_button' type="file" onChange={(e) => this.handleTrainerImages(e)} name="profilePic" />
                    {profilePic ? <img src={profilePicThumb || profilePic} className="mt-2 width_trainer_image" /> : null}
                  </div>
                </div>



                {(selectType && selectType.value === 'Live') ?
                  <div className="form-group col-12">
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="0"> App Images </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>


                            <div className="form-group row">
                              <div className="form-group col-12">
                                <label className="d-block">Live Session Schedule Images</label>
                                {appImages && appImages.liveSessionsListImages.length ? appImages.liveSessionsListImages.map((imageobj, i) => (
                                  <div className="form-group row" key={i}>
                                    <div className="form-group col-6">
                                      <label className="d-block">Device Type</label>
                                      <Select
                                        name="liveSessionsListImages"
                                        placeholder="Select Device"
                                        options={devicesSupported}
                                        value={devicesSupported.find(dev => dev.value === imageobj.imageCategory)}
                                        onChange={(val) => this.handleImageDeviceType('liveSessionsListImages', val, i)}
                                      />
                                    </div>

                                    <div className="form-group col-6">
                                      <i className="fa fa-trash cm_del btn btn-danger" onClick={(val) => this.deleteAppImage('liveSessionsListImages', val, i)} />
                                      <label className="d-block">Upload Session Image</label>
                                      <input type="file" onChange={(e) => this.handleTrainerImages(e, 1, i)} className="form-control" name="liveSessionsListImages" />
                                      {imageobj.imageThumb ? <img src={imageobj.imageThumb} className="mt-2 width_trainer_image" /> : null}
                                    </div>
                                  </div>
                                )) : null}

                                {appImages && appImages.liveSessionsListImages.length >= devicesSupported.length ? null :
                                  <div className="mb-5">
                                    <span className="btn btn-info" onClick={(val) => this.addMoreLiveListImages('liveSessionsListImages', val)}>
                                      <i className="fa fa-plus-square" /> Add Device Type Images </span>
                                  </div>}
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="form-group col-12">
                                <label className="d-block">Home Screen Session Images</label>
                                {appImages && appImages.nonConnectedSessionImages.length ? appImages.nonConnectedSessionImages.map((imageobj, i) => (
                                  <div className="form-group row" key={i}>
                                    <div className="form-group col-6">
                                      <label className="d-block">Device Type</label>
                                      <Select
                                        name="nonConnectedSessionImages"
                                        placeholder="Select Device"
                                        options={devicesSupported}
                                        value={devicesSupported.find(dev => dev.value === imageobj.imageCategory)}
                                        onChange={(val) => this.handleImageDeviceType('nonConnectedSessionImages', val, i)}
                                      />
                                    </div>

                                    <div className="form-group col-6">
                                      <i className="fa fa-trash cm_del btn btn-danger" onClick={(val) => this.deleteAppImage('nonConnectedSessionImages', val, i)} />
                                      <label className="d-block">Upload Session Image</label>
                                      <input type="file" onChange={(e) => this.handleTrainerImages(e, 1, i)} className="form-control" name="nonConnectedSessionImages" />
                                      {imageobj.imageThumb ? <img src={imageobj.imageThumb} className="mt-2 width_trainer_image" /> : null}
                                    </div>
                                  </div>
                                )) : null}

                                {appImages && appImages.nonConnectedSessionImages.length >= devicesSupported.length ? null :
                                  <div className="mb-5">
                                    <span className="btn btn-info" onClick={(val) => this.addMoreLiveListImages('nonConnectedSessionImages', val)}>
                                      <i className="fa fa-plus-square" /> Add Device Type Images </span>
                                  </div>}
                              </div>
                            </div>


                            <div className="form-group row">
                              <div className="form-group col-6">
                                <label className="d-block">Live/Non-Connected Session Detail Image</label>
                                <input type="file" onChange={(e) => this.handleTrainerImages(e)} className="form-control" name="liveSessionsDetailmage" />
                                {appImages.liveSessionDetailImageThumb ? <img src={appImages.liveSessionDetailImageThumb} className="mt-2 width_trainer_image" /> : null}
                              </div>
                            </div>

                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="1"> Website Images </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            <div className="form-group row">
                              <div className="form-group col-6">
                                <label className="d-block">Live Session Listing Image</label>
                                <input type="file" onChange={(e) => this.handleTrainerImages(e)} className="form-control" name="trainerListingImage" />
                                {websiteImages && websiteImages.trainerListingImageThumb ? <img src={websiteImages.trainerListingImageThumb} className="mt-2 width_trainer_image" /> : null}
                              </div>

                              <div className="form-group col-6">
                                <label className="d-block">Live Session Detail Image</label>
                                <input type="file" onChange={(e) => this.handleTrainerImages(e)} className="form-control" name="trainerDetailImage" />
                                {websiteImages && websiteImages.trainerDetailImageThumb ? <img src={websiteImages.trainerDetailImageThumb} className="mt-2 width_trainer_image" /> : null}
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div> : null}
              </div>
            </form>
          </Modal.Body>

          {validationError && <h6 className="text-danger text-center">{validationError}</h6>}
          {submitLoader && <ProgressBar animated now={100} />}
          <Modal.Footer>
            <Button variant="info" type='submit' onClick={this.submitAddTrainer} disabled={submitLoader}> {submitLoader ? 'Submiting Data....' : 'Submit'}</Button>
          </Modal.Footer>
        </Modal> : null}


        {openViewTrainer ? <Modal
          show={openViewTrainer}
          onHide={this.closeModal}
          centered>
          <Modal.Header closeButton>
            <Modal.Title>Trainer Info</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="card card_style">
              <div className="card-body ">
                <div className="row mt-4 mb-4">
                  <div className="col-md-12">
                    <img className="img-fluid" src={viewTrainerDetails.profilePic ? viewTrainerDetails.profilePic : ''} alt="Expert's image" />
                  </div>
                </div>


                <h4 className="card-title">Name: {viewTrainerDetails.firstName + " " + viewTrainerDetails.lastName}</h4>
                <p className="card-text">Meta Title: {viewTrainerDetails.metaTitle}</p>
                <p className="card-text">Meta Description: {viewTrainerDetails.metaDescription}</p>
                <p className="card-text">Email: {viewTrainerDetails.email}</p>
                <p className="card-text">Contact: {viewTrainerDetails.countryCode + " " + viewTrainerDetails.mobileNo}</p>
                <p className="card-text">Experience: {viewTrainerDetails.experience} yrs</p>
                <p className="card-text">Price: Rs. {viewTrainerDetails.price}/Session</p>
                <p className="card-text">Discount: {viewTrainerDetails.discount}%</p>
                <p className="card-text">About: {viewTrainerDetails.about}%</p>
                <p className="card-text">Speciality: {viewTrainerDetails.speciality}%</p>

                <p className="card-text">Services:
                {viewTrainerDetails.services && viewTrainerDetails.services.map((service, i) => (<Badge pill variant="dark" key={i}> {service.serviceName}</Badge>))}</p>
              </div>
            </div>
          </Modal.Body>
        </Modal> : null}


        {editTrainerPopup ? <Modal show={editTrainerPopup} onHide={this.closeModal} centered size='lg'>
          <Modal.Header closeButton> <Modal.Title>Update Info</Modal.Title> </Modal.Header>
          <Modal.Body>
            <form className="mb-5">
              <div className="row">
                <div className="form-group col-12">
                  <label>Service Type *</label>
                  < Select
                    require={true}
                    name="selectType"
                    placeholder="Select Type"
                    options={this.state.AllTypes}
                    value={selectType}
                    onChange={(val) => this.handleSelectType(val)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col">
                  <label>Meta Title *</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    name="metaTitle"
                    value={metaTitle}
                  />
                </div>

                <div className="form-group col">
                  <label>Meta Description *</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    name="metaDescription"
                    value={metaDescription}
                  />
                </div>
              </div>


              <div className="row">
                <div className="form-group col">
                  <label>First Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    name="firstName"
                    value={firstName}
                  />
                </div>

                <div className="form-group col">
                  <label>Last Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    name="lastName"
                    value={lastName}
                  />
                </div>
              </div>

              {(selectType && selectType.value != 'Live') || !selectType ?
                <div className="row">
                  <div className="form-group col">
                    <label>Price per session</label>
                    <input
                      type="number"
                      className="form-control"
                      onChange={this.handleChange}
                      name="price"
                      value={price}
                      onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                    />
                  </div>

                  <div className="form-group col">
                    <label>Price per group session</label>
                    <input
                      type="number"
                      className="form-control"
                      onChange={this.handleChange}
                      name="groupSessionPrice"
                      value={groupSessionPrice}
                      onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                    />
                  </div>

                  <div className="form-group col">
                    <label>Discount per session (%)</label>
                    <input
                      type="number"
                      className="form-control"
                      onChange={this.handleChange}
                      name="discount"
                      value={discount}
                      onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                    />
                  </div>
                </div>
                : null}

              <div className="row">
                <div className="form-group col-6">
                  <label>About</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    name="about"
                    value={about}
                  />
                </div>
                <div className="form-group col-6">
                  <label>Other Speciality</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    name="otherSpeciality"
                    value={otherSpeciality}
                  />
                </div>
              </div>


              <div className="row">
                <div className="form-group col-4">
                  <label>Speciality *</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    name="speciality"
                    value={speciality}
                  />
                </div>


                <div className="form-group col-3">
                  <label>Country Code *</label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={this.handleChange}
                    name="countryCode"
                    value={countryCode}
                    onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                    disabled
                  />
                </div>


                <div className="form-group col-5">
                  <label>Phone number *</label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={this.handleChange}
                    name="mobileNo"
                    value={mobileNo}
                    onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                    disabled
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-5">
                  <label>Service Category *</label>
                  < Select
                    require={true}
                    name="selectServiceCategory"
                    placeholder="Select Category"
                    options={this.state.serviceCategories}
                    value={this.state.selectServiceCategory}
                    onChange={(val) => this.onHandleCategorySelect('selectServiceCategory', val)}
                  />
                </div>

                <div className="form-group col-7">
                  <label>Service *</label>
                  <AsyncSelect
                    cacheOptions
                    isMulti
                    loadOptions={this.loadServices}
                    defaultOptions={services}
                    value={trainersServices}
                    onChange={(val) => this.serviceSelect(val)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-3">
                  <label>Experience (in years) *</label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={this.handleChange}
                    name="experience"
                    value={experience}
                    onKeyDown={e => (e.keyCode === 69) && e.preventDefault()}
                  />
                </div>

                <div className="form-group col-4">
                  <label>E-mail *</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    name="email"
                    value={email}
                    disabled
                  />
                </div>

                {(selectType && selectType.value === 'Live') ?
                  <div className="form-group col-5">
                    <label>Device Support</label>
                    <Select
                      name="devicesSupported"
                      placeholder="Select Devices"
                      options={LiveSessionDevices}
                      value={devicesSupported}
                      isMulti
                      onChange={(val) => this.handleMultiSelect('devicesSupported', val)}
                    />
                  </div> : null}


                <div className="form-group col-12">
                  <label className="d-block">Profile Pic</label>
                  <div className="radio_wrapper text-center">
                    <input className='upload_button' type="file" onChange={(e) => this.handleTrainerImages(e)} name="profilePic" />
                    {profilePic ? <img src={profilePicThumb || profilePic} className="mt-2 width_trainer_image" alt='profilePic' /> : null}
                  </div>
                </div>

                {(selectType && selectType.value === 'Live') ?
                  <div className="form-group col-12">
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="0"> App Images </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <div className="form-group row">
                              <div className="form-group col-12">
                                <label className="d-block">Live Session Schedule Images</label>
                                {appImages && appImages.liveSessionsListImages.length ? appImages.liveSessionsListImages.map((imageobj, i) => (
                                  <div className="form-group row" key={i}>
                                    <div className="form-group col-6">
                                      <label className="d-block">Device Type</label>
                                      <Select
                                        name="liveSessionsListImages"
                                        placeholder="Select Device"
                                        options={devicesSupported}
                                        value={devicesSupported.find(dev => dev.value === imageobj.imageCategory)}
                                        onChange={(val) => this.handleImageDeviceType('liveSessionsListImages', val, i)}
                                      />
                                    </div>

                                    <div className="form-group col-6">
                                      <i className="fa fa-trash cm_del btn btn-danger" onClick={(val) => this.deleteAppImage('liveSessionsListImages', val, i)} />
                                      <label className="d-block">Upload Session Image</label>
                                      <input type="file" onChange={(e) => this.handleTrainerImages(e, 1, i)} className="form-control" name="liveSessionsListImages" />
                                      {imageobj.imageThumb || imageobj.imageUrl ? <img src={imageobj.imageThumb || imageobj.imageUrl} className="mt-2 width_trainer_image" /> : null}
                                    </div>
                                  </div>
                                )) : null}

                                {appImages && appImages.liveSessionsListImages.length >= devicesSupported.length ? null :
                                  <div className="mb-5">
                                    <span className="btn btn-info" onClick={(val) => this.addMoreLiveListImages('liveSessionsListImages', val)}>
                                      <i className="fa fa-plus-square" /> Add Device Type Images </span>
                                  </div>}
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="form-group col-12">
                                <label className="d-block">Home Screen Session Images</label>
                                {appImages && appImages.nonConnectedSessionImages.length ? appImages.nonConnectedSessionImages.map((imageobj, i) => (
                                  <div className="form-group row" key={i}>
                                    <div className="form-group col-6">
                                      <label className="d-block">Device Type</label>
                                      <Select
                                        name="nonConnectedSessionImages"
                                        placeholder="Select Device"
                                        options={devicesSupported}
                                        value={devicesSupported.find(dev => dev.value === imageobj.imageCategory)}
                                        onChange={(val) => this.handleImageDeviceType('nonConnectedSessionImages', val, i)}
                                      />
                                    </div>

                                    <div className="form-group col-6">
                                      <i className="fa fa-trash cm_del btn btn-danger" onClick={(val) => this.deleteAppImage('nonConnectedSessionImages', val, i)} />
                                      <label className="d-block">Upload Session Image</label>
                                      <input type="file" onChange={(e) => this.handleTrainerImages(e, 1, i)} className="form-control" name="nonConnectedSessionImages" />
                                      {imageobj.imageThumb || imageobj.imageUrl ? <img src={imageobj.imageThumb || imageobj.imageUrl} className="mt-2 width_trainer_image" /> : null}
                                    </div>
                                  </div>
                                )) : null}

                                {appImages && appImages.nonConnectedSessionImages.length >= devicesSupported.length ? null :
                                  <div className="mb-5">
                                    <span className="btn btn-info" onClick={(val) => this.addMoreLiveListImages('nonConnectedSessionImages', val)}>
                                      <i className="fa fa-plus-square" /> Add Device Type Images </span>
                                  </div>}
                              </div>
                            </div>


                            <div className="form-group row">
                              <div className="form-group col-6">
                                <label className="d-block">Live/Non-Connected Session Detail Image</label>
                                <input type="file" onChange={(e) => this.handleTrainerImages(e)} className="form-control" name="liveSessionsDetailmage" />
                                {appImages.liveSessionDetailImageThumb || appImages.liveSessionDetailImage ? <img src={appImages.liveSessionDetailImageThumb || appImages.liveSessionDetailImage} className="mt-2 width_trainer_image" /> : null}
                              </div>
                            </div>

                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="1"> Website Images </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            <div className="form-group row">
                              <div className="form-group col-6">
                                <label className="d-block">Live Session Listing Image</label>
                                <input type="file" onChange={(e) => this.handleTrainerImages(e)} className="form-control" name="trainerListingImage" />
                                {websiteImages && (websiteImages.trainerListingImageThumb || websiteImages.trainerListingImage) ? <img src={websiteImages.trainerListingImageThumb || websiteImages.trainerListingImage} className="mt-2 width_trainer_image" /> : null}
                              </div>

                              <div className="form-group col-6">
                                <label className="d-block">Live Session Detail Image</label>
                                <input type="file" onChange={(e) => this.handleTrainerImages(e)} className="form-control" name="trainerDetailImage" />
                                {websiteImages && (websiteImages.trainerDetailImageThumb || websiteImages.trainerDetailImage) ? <img src={websiteImages.trainerDetailImageThumb || websiteImages.trainerDetailImage} className="mt-2 width_trainer_image" /> : null}
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div> : null}
              </div>
            </form>
          </Modal.Body>

          <h6 className="text-danger text-center">{validationError}</h6>
          {submitLoader && <ProgressBar animated now={100} />}
          <Modal.Footer> <Button variant="info" onClick={this.submitEditTrainer} disabled={submitLoader}> {submitLoader ? 'Submiting Data....' : 'Submit'}</Button> </Modal.Footer>
        </Modal> : null}
      </HOC>
    );
  }
}

export default Trainers;
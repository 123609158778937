import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Popup from "reactjs-popup";
import { DatePicker } from 'antd';
import Select from "react-select";
import { toast } from 'react-toastify';
import { updateOrderHistory,postOrderHistory } from '../../config/services/OrderHistory';
import { orderStatusOptions } from "../../utils/commonFunctions";

const AddEditHistoryForm = (props) => {

  const { editMode, addEditPopup, updateFormValues, setAddEditPopup, getList,isMyFirstHistory } = props;
  const intialValues = {
    orderId:'',
    orderHistoryId:'',
    notes:'',
    isDisplayForUser:true,
    orderStatus:'',
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingLoader, setIsSubmittingLoader] = useState(false);
  const [apiErrorMSG, setApiErrorMSG] = useState({});

  // SUBMIT  FORM  
  const handleSubmit = (e) => {
    e.preventDefault();
    setApiErrorMSG({});
    setIsSubmitting(true);
    if (validate(formValues)) {
      setIsSubmittingLoader(true);

      //For Update
      if (formValues._id) {
        updateOrderHistory(formValues)
          .then((response) => {
            setIsSubmittingLoader(false);
            if (response.data.statusCode === 0) {
              setApiErrorMSG({ status: 'error', msg: response.data.error.errorMessage });
            } else {
              toast.success(response.data.responseData.message);
              getList();
            }
          })
          .catch((error) => {
            console.log('error', error);
            setApiErrorMSG({ status: 'error', msg: error });
            setIsSubmittingLoader(false);
          });
      } else {

//=======
  //For Add
  postOrderHistory(formValues)
  .then((response) => {
    setIsSubmittingLoader(false);
    if (response.data.statusCode === 0) {
      setApiErrorMSG({ status: 'error', msg: response.data.error.errorMessage });
    } else {
      if (Object.keys(response.data.responseData.result).length > 0) {
        toast.success(response.data.responseData.message);
        getList();
        setFormValues(intialValues);
        setAddEditPopup(false);
        setApiErrorMSG({});
        setIsSubmittingLoader(false);
        // setApiErrorMSG({ status: 'success', msg: response.data.responseData.message });
      } else {
        setApiErrorMSG({ status: 'error', msg: response.data.responseData.message });
      }
    }
  })
  .catch((error) => {
    console.log('error', error);
    setApiErrorMSG({ status: 'error', msg: error });
    setIsSubmittingLoader(false);
  });


        //========
      }
    }
  };
  // HANDLE CHANGE FORM
  const handleChange = (event) => {
    let formValuesSubmited = formValues;
    const { name, value } = event.target;
    formValuesSubmited[name] = value;
    if (isSubmitting) {
      validate(formValuesSubmited);
    }
    setFormValues({ ...formValues, [name]: value });
  };

  //set only for select type 
  const handelSelectChange = (name, value) => {

    let formValuesSubmited = formValues;
    formValuesSubmited[name] = value;
    if (isSubmitting) {
      validate(formValuesSubmited);
    }
    setFormValues({ ...formValues, [name]: value });
  }

  //set isDisplayForUser options for input type select 
const isDisplayForUserOptions = () => {
  let setOptions = [
    { label: 'True', value: true },
    { label: 'False', value: false },

  ];
  return setOptions;
}

  // VALIDATION FORM
  const validate = (values) => {
    let errors = {};
  
    if (formValues._id) {
        if (!values.orderHistoryId) {
          errors.orderHistoryId = 'Required';
        }
  }else{
    //For Add 
    if (!values.orderId) {
      errors.orderId = 'Required';
    }
  }

    if (!values.orderStatus) {
      errors.orderStatus = 'Required';
    }

    if (!values.notes) {
      errors.notes = 'Required';
    }
    
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (editMode) {
      setFormValues((prevState) => ({
        ...prevState,
        _id:updateFormValues._id,
        orderId:updateFormValues.orderId,
        orderHistoryId:updateFormValues._id,
        notes:updateFormValues.notes ? updateFormValues.notes : '',
        isDisplayForUser:updateFormValues.isDisplayForUser==true ? updateFormValues.isDisplayForUser : false,
        orderStatus: updateFormValues.orderStatus,
      }));

    } else {
      //for add mode
      intialValues.orderId=updateFormValues.orderId;
      setFormValues(intialValues);
    }
  }, [editMode, updateFormValues]);

  return (
    <Popup open={addEditPopup} closeOnDocumentClick onClose={() => {
      setFormValues(intialValues);
      setFormErrors({});
      setApiErrorMSG({});
      setIsSubmittingLoader(false);
      setAddEditPopup(false);
    }}>
      <div className="cm_modal pl-5 pr-5 text-left">
        <span className="cm_modal_close" onClick={() => {
          setFormValues(intialValues);
          setFormErrors({});
          setApiErrorMSG({});
          setIsSubmittingLoader(false);
          setAddEditPopup(false);
        }}>&times; </span>
        <h3 className="text-center mb-4 mt-5">{formValues._id ? 'Update History' : 'Add new History'} </h3>
        <div className="text-danger text-center h3"> </div>
        <form onSubmit={handleSubmit}>
          <p style={{ color: 'red' }} className={apiErrorMSG.status === 'error' ? 'error' : 'success'}>{apiErrorMSG.msg}</p>
         
          <div className="form-group">
            <label>Order Notes (*)</label>
         <textarea 
          className="form-control"
          rows={3}
          cols={50} 
          name="notes"
          maxlength="200"
          onChange={handleChange}
          value={formValues.notes}
          ></textarea>
            {formErrors.notes && <label style={{ color: 'red' }}>{formErrors.notes}</label>}
          </div>

          {/* disable tracking options (false) for first history (orderd) */}
          <div className="form-group">
            <label>Is Display For User </label>
            <Select
              isSearchable
              options={isDisplayForUserOptions()}
              placeholder="Select Status"
              onChange={(e) => handelSelectChange("isDisplayForUser", e.value)}
              value={isDisplayForUserOptions().find(element => element.value == formValues.isDisplayForUser)}
              isDisabled={isMyFirstHistory}
            />
            {formErrors.isDisplayForUser && <label style={{ color: 'red' }}>{formErrors.isDisplayForUser}</label>}

          </div>

         
          <div className="form-group">
            <label>Order Status (*)</label>
            <Select
              isSearchable
              options={orderStatusOptions("Select Status")}
              placeholder="Order Status"
              onChange={(e) => handelSelectChange("orderStatus", e.value)}
              value={orderStatusOptions("Select Status").find(element => element.value == formValues.orderStatus)}
              defaultValue={{ label: "Select Status", value: "" }}
            />
            {formErrors.orderStatus && <label style={{ color: 'red' }}>{formErrors.orderStatus}</label>}

          </div>
      

       

          <div className="form-group">
            <button type="submit" className="btn btn-primary btn-block mt-4" disabled={isSubmittingLoader}>
              {isSubmittingLoader && (<i className="fa fa-spinner fa-pulse fa-2x fa-fw margin-bottom"></i>)} Save
						</button>
          </div>

          <div className="form-group">
            <button type="button" className="btn btn-secondary btn-block mt-4" onClick={() => {
          setFormValues(intialValues);
          setFormErrors({});
          setApiErrorMSG({});
          setIsSubmittingLoader(false);
          setAddEditPopup(false);
        }}>
              Cancel
						</button>
          </div>
        </form>
      </div>
    </Popup>
  );
};

export default AddEditHistoryForm;
import React, { useEffect, useState } from 'react';
import Popup from "reactjs-popup";
import { ORDER_RATING_OPTIONS } from "../../utils/constants";
import {showLimitWords } from "../../utils/commonFunctions";
import {  Tooltip } from 'antd';

let  desingCLass = 'shadow-sm p-3 mb-4 rounded';


const OrderProducts = (props) => {
    const {orderDetails}= props;
    const [loader, setLoader] = useState(false);
    const [aboutOrderDetail, setAboutOrderDetail] = useState({});


  //set value after changing props
  const setOrePropsValue = ()=>{
   
    setAboutOrderDetail(orderDetails);
  } 

//set order detail after changing props
  useEffect(()=>{
      setOrePropsValue();
  },[props.orderDetails]);

  return (
    <div className="body-container cm_profile_wrapper cm_profile_wrapper_bg" style={{minHeight:"100px"}}>
    {/* <TabTitle title="User Profile" /> */}
    <div className="row text-center">
    
 
        <div className="col-xl-12 col-lg-12 mb-5">
            <div className="row">

            <div className='table-responsive mt-2'>
              <table className='table table-bordered table-striped text-center'>
                <thead>
                  <tr>
                    <th scope="col" style={{width:"500px"}}>Name</th>
                    <th scope="col">Image</th>
                    <th scope="col">Code</th>
                    <th scope="col">Price</th>
                    <th scope="col">Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetails.products && orderDetails.products.length > 0 && orderDetails.products.map((data, index) => (
                    <tr key={index}>
                      <td className="text-truncate" style={{maxWidth:"500px"}}>
                          
                                <Tooltip placement="topLeft"
                                      title={data.productName}>
                                    {data.productName}
                                </Tooltip>
                          
                        
                        </td>
                      <td><img src={data.productImage} width="100px" /></td>
                      <td>{data.productCode}</td>
                      <td>{data.productPrice}</td>
                      <td>{data.quantity}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {orderDetails.products && orderDetails.products.length <1 ? (
                <h3 className="empty_error">
                  Sorry, We couldn't find any content!{" "}
                  {/* <span>{this.state.deviceSearch}</span> */}
                </h3>
              ) : null}
            </div>
  
         
            </div>
        </div>
    </div>
</div>
 );
};

export default OrderProducts;
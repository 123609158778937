import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import StarRatings from "react-star-ratings";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import { getStreamDetails } from "../../config/services/streamingService";
import { LoaderSvg } from "../Reusable";
import { ADMIN_ROLES } from "../../utils/constants";
import { downloadUserList } from "../../config/services/streamingService";
import Countdown from "react-countdown";
import FileSaver from "file-saver";
import SocketContext from "../../config/contextapi/sfxconnectionContext";
import { ReactComponent as BirthdayIcon } from "../../../src/assets/images/birthday.svg";
import { ReactComponent as RemoveFilter } from "./../../assets/images/remove-filter.svg";
import { ReactComponent as DownloadIcon } from "./../../assets/images/download.svg";
import { ReactComponent as ConnectedIcon } from "./../../assets/images/connected.svg";
import { ReactComponent as FinishedIcon } from "./../../assets/images/finish-line.svg";
import EmojiIcon from "./../../assets/images/emoji-icon.svg";
import { Tooltip, Spin, Checkbox,Modal } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import SendEmoji from "./SendEmoji";
import ViewAllMessages from "./ViewAllMessage";
import LatestMessages from "./LatestMessage";
import ListUserEmoji from "./ListEmoji";
import _ from "underscore";
import { GetEmojiByPlatform } from "../../config/services/emojiService";
import {calculateAge,getQueryStringParams,secondsToMinHours,getValidatedRowData,getGender,categoryName,calulcateChatsEmojiCount,checkConnected,millisToMinutesAndSeconds} from "./sfxutilityFunctions";

import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { FirebaseContext } from '../../index';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { error } = Modal;



/** */




/***
 * Default Data Sets
 */


const streamingDetails = (props) => {
  const firebase = useContext(FirebaseContext)
  firebase.analytics().logEvent('sfs leaderboard rendered', { date: moment().format("DD-MMM-YYYY HH:mm:ss") });




  let _params = {
    count: 80,
    sortType: 1,
    sortField: "rank",
    sortSfsParentField: null,
    pageNo: 0,
    search: "",
    birthdayToday: false,
    gender: "",
    ageRange: "",
    streamingId: "",
    expertId: "",
    showInactiveUsers:true
  };

  let gender = [
    { id: 1, label: "Male", key: 1 },
    { id: 2, label: "Female", key: 2 },
  ];
  let categories = [
    { id: 1, name: "Bike" },
    { id: 2, name: "Tread" },
    { id: 3, name: "Others" },
  ];

  let ageRange = [
    { id: 1, label: "20-30", key: { min: 20, max: 30 } },
    { id: 2, label: "30-40", key: { min: 30, max: 40 } },
    { id: 3, label: "40-50", key: { min: 40, max: 50 } },
    { id: 4, label: "50-60", key: { min: 50, max: 60 } },
  ];

  let sfxParams = {
    distanceCovered: 'DISTANCE',
    calories: 'CALORIES',
    timeElapsed: 'TIME_ELAPSED',
    loginTime: 'LOGIN_TIME',
    speed: 'SPEED',
    rank: 'RANK',
    signUpTime: 'SIGNUP_TIME',
    gender: 'GENDER',
    firstName: 'FIRST_NAME',
    lastName: 'LAST_NAME',
    rideCount: 'RIDE_COUNT',
    location: 'LOCATION',
    ageRange: 'AGR_RANGE',
    birthday: 'BIRTHDAY',
    height: 'HEIGHT',
    weight: 'WEIGHT',
    rank: 'RANK'


  }

  /**
   * States Declaration
   */

  const smartFoxServer = useContext(SocketContext);
  let history = useHistory();
  const [loggedIn, setLoggedIn] = useState(false);
  let [streamData, setStreamData] = useState([]);
  let [params, setParams] = useState(_params);
  let [loggedInRole, setLoggedInRole] = useState("");
  let [_fetch, setFetch] = useState(0);
  let [countdown, setCountDown] = useState(0);
  let [loading, setLoading] = useState(true);
  let [trainerData, setTrainerData] = useState({});
  let [isdownload, setIsdownload] = useState(false);
  let [selectkey, setselectkey] = useState(1);
  let [totalCount, setTotalCount] = useState(0);
  const [startIndex, setstartIndex] = useState(0);
  const [limit, setLimit] = useState(10);
  let [isfetching, setIsFetching] = useState(false);
  const [exitSessionState, setExitSessionState] = useState(false);
  const [sessionEndTime, setSessionEndtime] = useState(null);
  const [sendEmojiState, setSendEmojiState] = useState(false);
  const [allMessageState, setAllMessageState] = useState(false);
  const [individualEmojies, setIndividualEmojies] = useState(false);
  const [roomChat, setRoomChat] = useState(null);
  const [emojiList, setEmojiList] = useState([]);
  const [individualChatData, setIndividualChatData] = useState([]);
  const [toSendDetails, setToSendDetails] = useState({ id: "", name: "" });
  const [isFilterActive, setIsFilterActive] = useState({
    fiterDropdown: false,
    filterBirthDay: false,
  });

  /**For Windowing */

  const { numItems, itemHeight, renderItem, windowHeight } = props;
  const [scrollTop, setScrollTop] = useState(0);

  const innerHeight = numItems * itemHeight;
  const startIndex2 = Math.floor(scrollTop / itemHeight);
  const endIndex = Math.min(
    numItems - 1, // don't render past the end of the list
    Math.floor((scrollTop + windowHeight) / itemHeight)
  );
// ui changes

  useScrollPosition(({ prevPos, currPos }) => {
    // setScrollTop(e.currentTarget.scrollTop);
    //console.log(currPos.x)
    // console.log(currPos)
  })

  /**For Windowing */

  /**
   * Utility Methods
   */

  let toggleIndividaulEmojies = (chatData) => {
    /// modifyting the data to produce key value pair
    let chats = chatData["chats"];
    if (chats) {
      let emojiObj = Object.entries(chats).map(([_id, array]) => {
        let emoji = emojiList.find((ele) => ele._id === _id);
        return {
          id: _id,
          count: array.length,
          emojiImageUrl: emoji ? emoji.emojiImageUrl : "",
        };
      });


      setIndividualChatData(emojiObj);
    }
    setIndividualEmojies(!individualEmojies);
  };

  let toggleSendEmojiPopup = (id, name) => {
    // setting in state tosendId and toSendname
    setToSendDetails({ id, name });
    setSendEmojiState(!sendEmojiState);
  };

  let toggleAllMessagePopup = () => {
    setAllMessageState(!allMessageState);
  };

  let _downloadUserList = async () => {
    try {
      setIsdownload(true);
      downloadUserList({params:{ streamingId: _query.streamingId }})
      .then(async (response) => {
        let resp = response.data
        if (resp.statusCode == 1) {
            let { result } = resp.responseData
            FileSaver.saveAs(result.fileFullUrl)
            toast.success(resp.responseData.message)
        }
        else if (resp.statusCode == 0) {
            toast.error(resp.error.errorMessage)
        }
    })
      setIsdownload(false);
    } catch (error) {
      setIsdownload(false);
    }
  };

  let getSessionStatus = () => {
    if (
      moment(trainerData.startDate) < moment() &&
      moment(trainerData.endTime) < moment()
    ) {
      return "Completed";
    }
    if (moment() < moment(trainerData.startTime)) {
      return "Yet to start";
    } else if (moment() > moment(trainerData.endTime)) {
      return "Completed";
    } else if (
      moment() >= moment(trainerData.startTime) &&
      moment() <= moment(trainerData.endTime)
    ) {
      return "Started";
    }
  };


  let filterData = (value, key) => {

    if(_query.isEnable==0)
    {
      setParams({ ...params, [key]: value.key, pageNo: 0 });
      setFetch(_fetch + 1);
      setIsFilterActive({
        ...isFilterActive,
        fiterDropdown: true,
      });
    }

    else{
     
      setParams({ ...params, [key]: value.key, pageNo: 0 });
      setIsFilterActive({
        ...isFilterActive,
        
        fiterDropdown: true,
      });
      setLoading(true);
      smartFoxServer.sendShowInactiveUsersRequest({...params,[key]: value.key})
      // Will be removed once we will recieve the call back from the sfs for this key
      setTimeout(()=>{
        setLoading(false);

      },1500)


    }
    
  };

  let filterByBirthday = () => {
    setParams({
      ...params,
      birthdayToday: !params.birthdayToday,
      pageNo: 0,
    });
    setFetch(_fetch + 1);
    if (!params.birthdayToday) {
      setIsFilterActive({
        ...isFilterActive,
        filterBirthDay: true,
      });
    } else {
      setIsFilterActive({
        ...isFilterActive,
        filterBirthDay: false,
      });
    }
  };

  const Completionist = () => <span></span>;
  let _countDown = () => {
    let end = moment(trainerData.endTime);
    return Date.now() + moment.duration(end.diff(moment()));
  };

  const roomNotAvailableChangePath=()=>{
    history.push("/streaming2")
  }


  let _query = getQueryStringParams(props.location.search);
  let paramsObj = {
    params: {
      ...params,
      streamingId: _query.streamingId,
      expertId: _query.expertId,
      filterActive: _query.isEnable,
    },
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state

      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          Time Left - {hours} : {minutes} : {seconds}
        </span>
      );
    }
  };



  let checkBirthday = (dob) => {
    let _dob = JSON.stringify([
      moment(dob).format("M"),
      moment(dob).format("D"),
    ]);

    let today = JSON.stringify([moment().format("M"), moment().format("D")]);

    if (_dob == today) {
      return <BirthdayIcon className="birthday_icon" />;
    } else {
      return "-";
    }
  };

  let handleSortList = (event, data) => {
    if (parseInt(_query.isEnable) === 0) {
      let _sortType = params.sortType == 1 ? -1 : 1;
      setParams({ ...params, sortType: _sortType, sortField: data, pageNo: 0 });
      setFetch(_fetch + 1);
    } else {
      if (sfxParams[data]) {
        let _sortType = params.sortType == 1 ? -1 : 1;

        setParams({ ...params, sortType: _sortType, sortField: data, pageNo: 0 });

        setLoading(true);
        smartFoxServer.sendSortRequest(data, _sortType);
      }

    }
    // Removing the class --->


  };

  let clearFilters = () => {
   
    if(_query.isEnable==1)
    {
      setLoading(true);
      setParams(_params);

      setIsFilterActive({
        ...isFilterActive,
        fiterDropdown: false,
        filterBirthDay: false,
      });
      setTimeout(()=>{
        setLoading(false);

      },1500)
      smartFoxServer.removeAllFilters()
    }
    else{
      setParams(_params);
      setselectkey(selectkey + 1);
      setFetch(_fetch + 1);
      setIsFilterActive({
        ...isFilterActive,
        fiterDropdown: false,
        filterBirthDay: false,
      });
    }
  };

  let createLeaderBoard = (evtParams) => {
    const duration = moment.duration(
      moment(sessionEndTime).diff(moment())
    )._milliseconds;
  
    if (duration > 0) {
      if (evtParams.cmd === "LeaderBoardInfo") {
        const responseParams = evtParams.params;
        const leaderBoard = responseParams["_dataHolder"].get("leaderboard");
        const totalRecords = responseParams["_dataHolder"].get("totalRecords");
        const startIndex = responseParams["_dataHolder"].get("startIndex"); // for current data and start Index of next set of data
        const roomChat = responseParams["_dataHolder"].get("roomChat");
        
        if (roomChat.value !== "null") {
          setRoomChat(JSON.parse(roomChat.value))
        }
        else{
          setRoomChat("stat")
        }
        let leaderBoardArray = JSON.parse(leaderBoard.value);

        setTotalCount(totalRecords.value);

        if (typeof params.gender === "number") {
          leaderBoardArray = leaderBoardArray.filter(
            (ele) =>
              parseInt(ele.demographicData.gender) === parseInt(params.gender)
          );

          setStreamData(leaderBoardArray);
          setTotalCount(leaderBoardArray.length);
        }

        if (typeof params.ageRange === "object") {
          leaderBoardArray = leaderBoardArray.filter(
            (ele) => ele.demographicData.ageRange === `${params.ageRange.min}s`
          );

          setStreamData(leaderBoardArray);
          setTotalCount(leaderBoardArray.length);
        }
        if (params.birthdayToday) {
          // Birthday count
          checkBirthday();
          leaderBoardArray = leaderBoardArray.filter(
            (ele) => checkBirthday(ele.demographicData.dob) !== "-"
          );

          // setStreamData([... getSortedData(params,leaderBoardArray)])
          setStreamData(leaderBoardArray);
        } else {
          //setStreamData([... getSortedData(params,leaderBoardArray)])
          setStreamData(leaderBoardArray);
        }

        setstartIndex(startIndex.value);
      } else if (evtParams.cmd === "SortedLeaderboardKey") {
        setLoading(false);
      } else {
        //console.log(evtParams);
      }
    } else {
      setExitSessionState(true);
    }
  };

  let _getStreamDetails1 = () => {
    //debugger;
    setIsFetching(true);
    let paramsObj = {
      params: {
        ...params,
        streamingId: _query.streamingId,
        expertId: _query.expertId,
        filterActive: _query.isEnable,
      },
    };

    if (params.pageNo == 0) {
      setLoading(true);
    }

    getStreamDetails(paramsObj)
      .then((response) => {
   
        if (response.data.statusCode == 1) {
          if (params.pageNo != 0) {
            setStreamData([
              ...streamData,
              ...response.data.responseData.result.userList,
            ]);
          } else {
            setStreamData(response.data.responseData.result.userList);
          }

          setTrainerData({
            ...response.data.responseData.result.streamDetails,
            ...response.data.responseData.result.expertAvgRating,
          });

          setSessionEndtime(
            response.data.responseData.result.streamDetails.endTime
          );

          if (!countdown) {
            if (
              moment(
                response.data.responseData.result.streamDetails.startDate
              ).format("D") == moment().format("D")
            ) {
              if (
                moment() >=
                moment(
                  response.data.responseData.result.streamDetails.startTime
                ) &&
                moment() <=
                moment(
                  response.data.responseData.result.streamDetails.endTime
                )

                //   &&
                // response.data.responseData.result.streamDetails.isEnable
              ) {
                let end = moment(
                  response.data.responseData.result.streamDetails.endTime
                );

                var duration = moment.duration(end.diff(moment()));

                setCountDown(duration._milliseconds);
              }
            }
          } else {
            setExitSessionState(true);
          }

          setTotalCount(response.data.responseData.result.totalCount);
          setLoading(false);
          // setIsFetching(false);
        } else if (response.statusCode == 0) {
          toast.error(response.data.error.errorMessage);
        }
      })
      .catch((err) => {
      });
  };



  const sendEmojiNotification = (emojiId) => {
    let expertId = localStorage.getItem("expertId");
    smartFoxServer.sendIndividualChat(
      emojiId,
      toSendDetails.id,
      toSendDetails.name,
      expertId,
      `Trainer ${trainerData.expertDetails.firstName}`
    );
  };

  const fetchData = () => {

    if (parseInt(_query.isEnable) == 1) {
      if (totalCount - 1 === startIndex) {
        smartFoxServer.sendPaginationRequest(0, 2);
      } else {
        smartFoxServer.sendPaginationRequest(startIndex, limit + 2);
      }
      setLimit(limit + 10);
    } else {
      // Sending pagination request to api
      setParams({ ...params, pageNo: params.pageNo + 1 });
      setFetch(_fetch + 1);
    }
  };

  useState(() => {
    setLoggedInRole(Number(localStorage.getItem("role")));
  }, []);

  const ChangeParams = useCallback((data) => {
    setParams(data);
  }, []);

  // This refreshes the component so that filters can be applied to sfs leaderboard data
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed && loggedIn) {
      smartFoxServer.addExtension(createLeaderBoard);
    }
    return () => {
      // smartFoxServer.sfsDisConnect()
      isSubscribed = false; // Cleanup function to prevent memory leaks
    };
  }, [params]);

  // Joins the room once login process is success with sfs
  useEffect(() => {
    if(!loggedIn)
    {
      return;
    }
    let isSubscribed = true;
    if (loggedIn) {
      // getting user details from sfs server
      if (isSubscribed) {
        smartFoxServer
          .joinRoom(_query.streamingId)
          .then((data) => {
            firebase.analytics().logEvent('Room Joined in SFS', { date: moment().format("DD-MMM-YYYY HH:mm:ss"), data: data });
            smartFoxServer.addExtension(createLeaderBoard);
            setTimeout(() => {
              setLoading(false);
            }, 2000);
          })
          .catch((err) => {
            // alert(err);
            error({
              title: <p>Please Join The Room 1 Minute Before The Start Time</p>,
              onOk() {
                roomNotAvailableChangePath()
              }
              // onCancel() {
              // },
            });
            setLoading(false);
          });
      }
    }
    return () => {
      //smartFoxServer.sfsDisConnect()
      isSubscribed = false; // Cleanup function to prevent memory leaks
    };
  }, [loggedIn]);


  useEffect(() => {
    if (parseInt(_query.isEnable)==0) {
      return;
    }

    let isSubscribed = true;
    let initSocket = () => {
      let accessToken = localStorage.getItem("accessToken");
      smartFoxServer
        .sfsConnect()
        .then((res) => {
          firebase.analytics().logEvent('Connected With  SmartFox Server', { date: moment().format("DD-MMM-YYYY HH:mm:ss"), data: res });

          smartFoxServer
            .loginWithAccessToken(accessToken)
            .then((res) => {
              firebase.analytics().logEvent('Logged in SmartFox Server', { date: moment().format("DD-MMM-YYYY HH:mm:ss"), data: res });

              // Getting the trainer data--
              let paramsObj = {
                params: {
                  ...params,
                  streamingId: _query.streamingId,
                  expertId: _query.expertId,
                  filterActive: _query.isEnable,
                },
              };

              getStreamDetails(paramsObj)
                .then((response) => {
                  if (isSubscribed) {
                    setTrainerData({
                      ...response.data.responseData.result.streamDetails,
                      ...response.data.responseData.result.expertAvgRating,
                    });

                    setSessionEndtime(
                      response.data.responseData.result.streamDetails.endTime
                    );

                    if (!countdown) {
                      if (
                        moment(
                          response.data.responseData.result.streamDetails
                            .startDate
                        ).format("D") == moment().format("D")
                      ) {
                        if (
                          moment() >=
                          moment(
                            response.data.responseData.result.streamDetails
                              .startTime
                          ) &&
                          moment() <=
                          moment(
                            response.data.responseData.result.streamDetails
                              .endTime
                          )

                          //   &&
                          // response.data.responseData.result.streamDetails.isEnable
                        ) {
                          let end = moment(
                            response.data.responseData.result.streamDetails
                              .endTime
                          );

                          var duration = moment.duration(end.diff(moment()));

                          setCountDown(duration._milliseconds);
                        }
                      }
                    }

                    setLoggedIn(true);
                  }
                })
                .catch((err) => { });
            })
            .catch((err) => {
              // alert(err);
              error({
                title: <p>There is Some Technical Issue ,Please Connect with Tech Team</p>,
                onOk() {
                  roomNotAvailableChangePath()
                }
                // onCancel() {
                //   console.log('Cancel');
                // },
              });
              if (isSubscribed) {
                setLoading(false);
              }
            });
        })
        .catch((err) => {
          ////console.log(err);
          setLoading(false);
        });
    };
    initSocket();
    return () => {
      smartFoxServer.sfsDisConnect();
      firebase.analytics().logEvent('Disconnected From SmartFox Server,Due To Page Close', { date: moment().format("DD-MMM-YYYY HH:mm:ss"), data: null });

      isSubscribed = false; // Cleanup function to prevent memory leaks
    };
  }, []);

  useEffect(() => {

    if (parseInt(_query.isEnable)==1) {
      return;
    }
    else{
      _getStreamDetails1();

    }
  }, [_fetch]);

  /// This useEffect is for loading emojis and saving them to localstorage
  //as the chat data comes up with the emoji id only

  useEffect(() => {
    let isSubscribe = true;

    if (isSubscribe) {
      let params = { emojiPlatform: 1, sortField: "displayOrder", sortType: 1 };

      GetEmojiByPlatform(params)
        .then((data) => {
          let emojisList = data["data"].responseData.result;
          setEmojiList(emojisList);
        })
        .catch((err) => {
         // console.log(err);
        });
    }

    return () => {
      isSubscribe = false;
    };
  }, []);

  let filterStatus =
    isFilterActive.fiterDropdown === true ||
    isFilterActive.filterBirthDay === true;

  const ActiveUser = () => (
    <div className="d-flex align-items-center">
      <span className="mr-1">Active</span>
      <i className="fa fa-users"></i>
    </div>
  )

  const InActiveUser = () => (
    <div className="d-flex align-items-center">
      <span className="mr-1">Inactive</span>
      <i className="fa fa-users"></i>
    </div>
  )


  return (
    <>
      <div className="body-container-wrapper padding-class" >
        <div className="body-container">
          <div className="d-sm-flex justify-content-between border-bottom custom-list">
            <ol
              className="breadcrumb breadcrumb-custom"
              style={{ alignItems: "center" }}
            >
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/streaming2">Streaming management</Link>
              </li>
              <li className="breadcrumb-item">
                <b>
                  {trainerData && trainerData.title ? trainerData.title : ""}
                </b>
              </li>
            </ol>

            {countdown ? (
              <div className="list-inline-item timer">
                <Countdown
                  date={_countDown()}
                  renderer={renderer}
                  onComplete={() => {
                    /*
                    history.push(
                      "/streamingDetails2?expertId=" +
                        _query.expertId +
                        "&&streamingId=" +
                        _query.streamingId +
                        "&&isEnable=0"
                    );
                    */
                    setCountDown(0);
                    _query.isEnable = 0;
                    window.location.href =
                      "/streamingDetails2?expertId=" +
                      _query.expertId +
                      "&&streamingId=" +
                      _query.streamingId +
                      "&&isEnable=0"



                    // _getStreamDetails1();
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          {loading && (
            <div className="loader_wrapper">
              <LoaderSvg />
            </div>
          )}

          {isdownload && (
            <div className="loader_wrapper">
              <LoaderSvg />
            </div>
          )}

          <div className="stream-detail-header d-flex flex-wrap">
            <div
              className={`filter-and-details ${_query.isEnable == 0 ? "w-100" : ""
                }`}
            >
              <div className="filter-wrapper">
                <div className={`clear-filter ${filterStatus ? "active" : ""}`}>
                  <Tooltip
                    placement="topLeft"
                    onClick={() => {
                      filterStatus && clearFilters();
                    }}
                    className="d-flex align-items-center"
                    title={`${filterStatus ? "Clear Filter" : "No filter applied yet"
                      }`}
                    arrowPointAtCenter
                  >
                    {filterStatus ? (
                      <RemoveFilter className="remove-filter" />
                    ) : (
                      <i className="fa fa-filter"></i>
                    )}
                    <span className="text-filter-mobile d-block d-sm-none ml-3">
                      Clear Filter
                    </span>
                  </Tooltip>
                </div>
                <div className="filters">
                  <div className="filter-header">
                    <Select
                      key={selectkey + "gender"}
                      className="custom-filter-select"
                      onChange={(val) => filterData(val, "gender")}
                      placeholder="Gender"
                      options={gender}
                      getOptionValue={(option) => option.id + "gender"}
                      value={gender.find(
                        (element) => element.value === params.gender
                      )}
                      classNamePrefix="custom-filter-select"
                    />

                    <div className="birthday-filter">
                      <input
                        type="checkbox"
                        className="imput-box"
                        value=""
                        id="flexCheckChecked"
                        checked={params.birthdayToday}
                        onChange={_query.isEnable==0?filterByBirthday:()=>filterData({key :!params.birthdayToday},"birthdayToday")}
                      />
                      <label className="label">Birthday</label>
                    </div>

                    <Select
                      className="custom-filter-select"
                      key={selectkey + "ageRange"}
                      onChange={(val) => filterData(val, "ageRange")}
                      placeholder="Age Range"
                      options={ageRange}
                      getOptionValue={(option) => option.id + "ageRange"}
                      classNamePrefix="custom-filter-select"
                    />

                    {getSessionStatus() == "Completed" &&
                      loggedInRole === ADMIN_ROLES.ADMIN &&
                      totalCount > 0 ? (
                      <div className="download-user-list d-flex align-items-center">
                        <a
                          onClick={() => {
                            _downloadUserList();
                          }}
                          className="hover btn button-primary mx-auto"
                        >
                          <DownloadIcon />{" "}
                          <span className="ml-2">User List</span>
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                    <Checkbox
                      className="active-inactive-user"
                      onChange={()=>filterData({key:!params.showInactiveUsers},"showInactiveUsers")}
                      defaultChecked
                    >
                      Show In-Active Users
                    </Checkbox>
                  </div>
                </div>
              </div>
              <div className="stream-detail">
                <ul className="list-unstyled detail-list">
                  <li>
                    <span className="label">Trainer Name : </span>
                    <span className="value">
                      {trainerData.expertDetails
                        ? trainerData.expertDetails.firstName +
                        " " +
                        trainerData.expertDetails.lastName
                        : ""}
                    </span>
                  </li>

                  <li className="customer-count">
                    <span className="label">Customer Count :</span>
                    <span className="value value-count">
                      {" " + totalCount}
                    </span>
                  </li>

                  <li>
                    <span className="label">Status : </span>
                    <span className="value">{getSessionStatus()}</span>
                  </li>

                  <li>
                    <span className="label">Category : </span>
                    <span className="value">
                      {trainerData.categoryId
                        ? categoryName(trainerData.categoryId, categories)
                        : "N/A"}
                    </span>
                  </li>

                  {loggedInRole === ADMIN_ROLES.TRAINER ? (
                    ""
                  ) : moment(trainerData.startDate) < moment() &&
                    moment(trainerData.endTime) < moment() ? (
                    <li>
                      <span className="label">Avg Rating: </span>
                      <span className="value star">
                        {trainerData.avgRating ? (
                          <StarRatings
                            rating={trainerData.avgRating}
                            starDimension="14px"
                            starSpacing="0"
                            starRatedColor="rgba(255,108,11,1)"
                            ver
                          />
                        ) : (
                          "N/A"
                        )}
                      </span>
                    </li>
                  ) : (
                    ""
                  )}

                  <li>
                    <span className="label">Start Date : </span>
                    <span className="value">
                      {trainerData.startDate
                        ? moment(trainerData.startDate).format("DD-MMM-YYYY")
                        : ""}
                    </span>
                  </li>

                  <li>
                    <span className="label">Start Time : </span>
                    <span className="value">
                      {trainerData.startTime
                        ? moment(trainerData.startTime).format("h:mm a")
                        : ""}
                    </span>
                  </li>

                  <li>
                    <span className="label">End Time : </span>
                    <span className="value">
                      {trainerData.endTime
                        ? moment(trainerData.endTime).format("h:mm a")
                        : ""}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            {/* start */}
            <LatestMessages
              toggleSendEmojiPopup={toggleSendEmojiPopup}
              toggleAllMessagePopup={toggleAllMessagePopup}
              messageData={roomChat}
              emojiList={emojiList}
              countdown={countdown}
            />
            {/* ends */}
          </div>

          <div className="table-responsive custom-table-view stream-detail">
            {streamData.length > 0 ? (
              <InfiniteScroll
                dataLength={streamData.length} //This is important field to render the next data
                next={fetchData}
                hasMore={totalCount !== streamData.length}
                isfetching={true}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <FinishedIcon className="icon-finish" />
                    <b className="d-block text-center">You have seen it all</b>
                  </p>
                }
                //scrollableTarget="scrollableDiv"
                loader={
                  totalCount != streamData.length ? (
                    <div className="text-center p-3">
                      <Spin tip="Loading..."></Spin>
                    </div>
                  ) : (
                    ""
                  )
                }
              >
                <table className="table table-bordered text-center">
                  <thead>
                    <tr>
                      <th scope="col" className={params.sortField == "rank" ? 'filter_text active' : 'filter_text'}>
                        Rank <i
                          className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                            }`}
                        ></i>
                          <button
                            onClick={(event) => {
                              handleSortList(event, "rank");
                            }}
                          ></button>


                      </th>
                      <th scope="col" className={params.sortField == "firstName" ? 'filter_text active' : 'filter_text'}>
                        Name
                        <i
                          className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                            }`}
                        ></i>
                        <button
                          onClick={(event) => {
                            handleSortList(event, "firstName");
                          }}
                        ></button>
                      </th>
                      {_query.isEnable == 1 ? (
                        <th scope="col" colSpan="2" className="filter_text">
                          Emoji
                        </th>
                      ) : (
                        ""
                      )}

                      <th scope="col" className={params.sortField == "speed" ? 'filter_text active' : 'filter_text'}>
                        Speed
                        <i
                          className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                            }`}
                        ></i>
                        <button
                          onClick={(event) => {
                            handleSortList(event, "speed");
                          }}
                        ></button>
                      </th>

                      <th scope="col" className={params.sortField.includes("distance") ? 'filter_text active' : 'filter_text'}>
                        Dist
                        <i
                          className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                            }`}
                        ></i>
                        <button
                          onClick={(event) => {
                            handleSortList(event,
                              _query.isEnable == 1
                                ? "distanceCovered"
                                : "distance"
                            );
                          }}
                        ></button>
                      </th>
                      {/* <th scope="col" className="filter_text active">
                        Cal
                        <i
                          className={`fa fa-sort-alpha-${
                            params.sortType == -1 ? "asc" : "desc"
                          }`}
                        ></i>
                        <button
                          onClick={(event) => {
                            handleSortList(event,"calories");
                          }}
                        ></button>
                      </th> */}
                      <th scope="col" className="filter_text">
                        Connected
                        <i
                          className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                            }`}
                        ></i>
                        <button
                          onClick={(event) => {
                            handleSortList(event, "connected");
                          }}
                        ></button>
                      </th>
                      <th scope="col" className={params.sortField == "timeElapsed" ? 'filter_text active' : 'filter_text'}>
                        Live Session Time
                        <i
                          className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                            }`}
                        ></i>
                        <button
                          onClick={(event) => {
                            handleSortList(event, "timeElapsed");
                          }}
                        ></button>
                      </th>
                      {_query.isEnable==0 ?<th scope="col" className={params.sortField == "timeElapsed" ? 'filter_text active' : 'filter_text'}>
                       Attended Duration
                       
                      </th>:""}
                      
                      <th scope="col" className={params.sortField == "weight" ? 'filter_text active' : 'filter_text'}>
                        Wt
                        <i
                          className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                            }`}
                        ></i>
                        <button
                          onClick={(event) => {
                            handleSortList(event, "weight");
                          }}
                        ></button>
                      </th>

                      <th scope="col" className={params.sortField == "height" ? 'filter_text active' : 'filter_text'}>
                        Ht
                        <i
                          className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                            }`}
                        ></i>
                        <button
                          onClick={(event) => {
                            handleSortList(event, "height");
                          }}
                        ></button>
                      </th>

                      <th scope="col" className={params.sortField == "birthday" ? 'filter_text active' : 'filter_text'}>
                        B'day
                        <i
                          className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                            }`}
                        ></i>
                        <button
                          onClick={(event) => {
                            handleSortList(event, "birthday");
                          }}
                        ></button>
                      </th>

                      <th scope="col" className={params.sortField.includes("ride") ? 'filter_text active' : 'filter_text'}>
                        Rides
                        <i
                          className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                            }`}
                        ></i>
                        <button
                          onClick={(event) => {
                            handleSortList(event,
                              _query.isEnable === 0 ? "totalRides" : "rideCount"
                            );
                          }}
                        ></button>
                      </th>

                      <th scope="col" className={params.sortField == "location" ? 'filter_text active' : 'filter_text'}>
                        <i
                          className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                            }`}
                        ></i>
                        <button
                          onClick={(event) => {
                            handleSortList(event, "location");
                          }}
                        ></button>
                        Loc
                      </th>

                      <th scope="col" className={params.sortField == "ageRange" || params.sortField == "dob" ? 'filter_text active' : 'filter_text'}>
                        Age
                        <i
                          className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                            }`}
                        ></i>
                        <button
                          onClick={(event) => {
                            handleSortList(event,
                              _query.isEnable==1 ? "ageRange" : "'demographicData.ageRange'"
                            );
                          }}
                        ></button>
                      </th>
                      <th scope="col" className={params.sortField == "gender" ? 'filter_text active' : 'filter_text'}>
                        G
                        <i
                          className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                            }`}
                        ></i>
                        <button
                          onClick={(event) => {
                            handleSortList(event, "gender");
                          }}
                        ></button>
                      </th>



                      <th scope="col" className={params.sortField == "loginTime" || params.sortField == "sessionStartTime" ? 'filter_text active' : 'filter_text'}>
                        Login
                        <i
                          className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                            }`}
                        ></i>
                        <button
                          onClick={(event) => {
                            handleSortList(event,
                              _query.isEnable==1 ? "loginTime" : "sessionStartTime"
                            );
                          }}
                        ></button>
                      </th>


                      {loggedInRole === ADMIN_ROLES.TRAINER ? (
                        ""
                      ) : (
                        <th scope="col" className="filter_text">
                          Rating
                          <i
                            className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                              }`}
                          ></i>
                          <button
                            onClick={(event) => {
                              handleSortList(event, "rating");
                            }}
                          ></button>
                        </th>
                      )}
                      {loggedInRole === ADMIN_ROLES.TRAINER ? (
                        ""
                      ) : (
                        <th scope="col">Review</th>
                      )}

                      <th scope="col" className={params.sortField == "created" || params.sortField == "signUpTime" ? 'filter_text active' : 'filter_text'}>
                        Sign Up Date
                        <i
                          className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                            }`}
                        ></i>
                        <button
                          onClick={(event) => {
                            handleSortList(event,
                              _query.isEnable == 0 ? "created" : "signUpTime"
                            );
                          }}
                        ></button>
                      </th>
                    </tr>
                  </thead>

                  <tbody className="display-bold">
                    {streamData.map((data, index) => {
                      return (
                        <tr key={index} className={data.state == "INACTIVE" ? "inactive" : ""}>
                          <td >
                            {data.rank}
                          </td>
                          {/* name */}
                          <td >
                            <span className="cm_table_three_dots">
                              {data.demographicData
                                ? data.demographicData.firstName +
                                " " +
                                data.demographicData.lastName
                                : `${data.userDetails.firstName} ${data.userDetails.lastName}`}
                            </span>
                          </td>
                          {/* emoji */}
                          {_query.isEnable == 1 ? (
                            <>
                              {" "}
                              <td >
                                <div className="individual-emoji">
                                  <Tooltip
                                    placement="topLeft"
                                    className="d-flex align-items-center"
                                    title="Send emoji"
                                    arrowPointAtCenter
                                  >
                                    <div
                                      className="add-emoji"
                                      onClick={() =>
                                        toggleSendEmojiPopup(
                                          data.userId,
                                          data.demographicData.firstName
                                        )
                                      }
                                    >
                                      <img
                                        src={EmojiIcon}
                                        className="emoji-add-icon"
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              </td>{" "}

                              {/* emoji count */}
                              <td className={data.state == "ACTIVE" ? "active" : ""}>
                                <div className="individual-emoji">
                                  <Tooltip
                                    placement="topLeft"
                                    className="d-flex align-items-center"
                                    title="List Emoji"
                                    arrowPointAtCenter
                                  >
                                    <span
                                      className={`count ${calulcateChatsEmojiCount(data.chatData) > 0 ? "" : 'inactive'}`}
                                      onClick={() =>
                                        toggleIndividaulEmojies(data.chatData)
                                      }
                                    >
                                      {calulcateChatsEmojiCount(data.chatData)}
                                    </span>
                                  </Tooltip>
                                </div>
                              </td>
                            </>
                          ) : (
                            ""
                          )}

                          {/* speed */}
                          <td >
                            <span>
                              {data.exerciseData 
                                ? data.exerciseData.speed.toFixed(2)
                                : "-"}{" "}
                            </span>
                          </td>
                          {/* distance */}
                          <td >
                            <span>
                              {data.exerciseData 
                                ? data.exerciseData.distanceCovered.toFixed(2)
                                : "-"}
                            </span>
                          </td>



                          {/* calories */}
                          {/* <td >
                            <span>
                              {data.exerciseData
                                ? data.exerciseData.calories.toFixed(2)
                                : "-"}
                            </span>
                          </td> */}

                          {/* connected */}
                          <td >
                            {data.deviceId && _query.isEnable==1 ? (
                              <ConnectedIcon className="icons" />
                            ) : (
                              data.deviceConnected || data.nonLiveDeviceConnected? <ConnectedIcon className="icons" />:"-"
                            )}
                          </td>

                          <td className="cm_no_wrap transform">
                            {data.exerciseData 
                              ? (secondsToMinHours(data.exerciseData.timeElapsed))
                              : "-"}
                          </td>

                          {
                            _query.isEnable==0? <td className="cm_no_wrap transform">
                            {data.attendedDuration 
                              ? ((millisToMinutesAndSeconds(data.attendedDuration)))
                              : "-"}
                          </td>:""
                          }

                          {/* weight */}
                          <td >
                            <span className="">
                              {_query.isEnable == 1
                                ? data.demographicData.weight? data.demographicData.weight.value.toFixed(2):"-"
                                : data.userDetails.weight?data.userDetails.weight.value.toFixed(2):"-"}
                            </span>
                          </td>
                          <td >
                            <span className="">
                              {_query.isEnable == 1
                                ? data.demographicData.height
                                  ? data.demographicData.height.value.toFixed(2)
                                  : "-"
                                : data.userDetails.height
                                  ? data.userDetails.height.value
                                  : "-"}
                            </span>
                          </td>

                          {/* birthday */}
                          <td >
                            <span className="">
                              {data.demographicData && _query.isEnable==1
                                ? checkBirthday(data.demographicData.dob)
                                : checkBirthday(data.userDetails.dob)}
                            </span>
                          </td>

                          {/* total ride */}
                          <td className="cm_no_wrap">
                            {data.demographicData && _query.isEnable==1
                              ? data.demographicData.rideCount ?data.demographicData.rideCount:"-"
                              : data.totalRides
                                ? data.totalRides.ridesCount
                                : "-"}
                          </td>

                          {/* Location */}
                          <td className="cm_no_wrap">
                            {
                              getValidatedRowData(data, _query, "location") ? getValidatedRowData(data, _query, "location").substring(0, 30) : "-"
                            }

                          </td>

                          {/* age */}
                          <td >
                            <span className="">
                              {data.demographicData && data.demographicData.ageRange
                                ? data.demographicData.ageRange
                                : "-"}
                            </span>
                          </td>

                          {/* gender */}
                          <td >
                            <span className="">
                              {data.demographicData
                                ? getGender(data.demographicData.gender)
                                : getGender(data.userDetails.gender)}
                            </span>
                          </td>



                          {/* <td >
                              <span className="">
                                {data.userDetails && data.userDetails.weight && data.userDetails.weight.value
                                  ? data.userDetails.weight.value +" Kg"
                                  : ""}
                              </span>
                            </td> */}

                          <td className="cm_no_wrap transform">
                            {data.demographicData && _query.isEnable==1
                              ? moment(data.loginTime).format(
                                "DD-MMM-YYYY HH:mm:ss"
                              )
                              : moment(data.sessionStartTime).format(
                                "DD-MMM-YYYY HH:mm:ss"
                              )}
                          </td>



                          {/* <td className="cm_no_wrap">
                            {`${dateFormat(data.startDate, "d/mm/yyyy")}`}{" "}
                            <br />
                            {`${moment(data.startTime).format("LT")} - ${moment(
                              data.endTime
                            ).format("LT")}`}
                          </td> */}

                          {loggedInRole === ADMIN_ROLES.TRAINER ? (
                            ""
                          ) : (
                            <td className="cm_no_wrap">
                              {data.rating ? (
                                <StarRatings
                                  rating={data.rating}
                                  starDimension="14px"
                                  starSpacing="0"
                                  starRatedColor="rgba(255,108,11,1)"
                                />
                              ) : (
                                "-"
                              )}
                            </td>
                          )}
                          {loggedInRole === ADMIN_ROLES.TRAINER ? (
                            ""
                          ) : (
                            <td className="">
                              {data.review ? data.review : "-"}
                            </td>
                          )}

                          <td className="cm_no_wrap">
                            {data.demographicData && _query.isEnable==1
                              ? moment(data.demographicData.signUpTime).format(
                                "DD-MMM-YYYY"
                              )
                              : moment(data.userDetails.created).format(
                                "DD-MMM-YYYY"
                              )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                {!streamData.length ? (
                  <h3 className="empty_error">
                    Sorry, we couldn't find any content{" "}
                    {params.search ? "for" : "!"} <span>{params.search}</span>
                  </h3>
                ) : null}
              </InfiniteScroll>
            ) : (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ borderTop: "2px solid #dedede", height: "150px" }}
              >
                <h5>No Online User In the Room</h5>
              </div>
            )}
          </div>
        </div>
      </div>

      <SendEmoji
        showSendPopup={toggleSendEmojiPopup}
        isSendEmojiVisible={sendEmojiState}
        emojiList={emojiList}
        sendEmojiNotification={sendEmojiNotification}
      />

      <ViewAllMessages
        showMessagePopup={toggleAllMessagePopup}
        isMessagesVisible={allMessageState}
        roomChat={roomChat}
        emojiList={emojiList}
      />

      <ListUserEmoji
        showListEmoji={toggleIndividaulEmojies}
        isListEmojiVisible={individualEmojies}
        individualChatData={individualChatData}
      />
    </>
  );
};

export default streamingDetails;

import React, { Component } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';

const getTableHeader = (columnDetails) => {

    let tableHeading = []
    {
        columnDetails.map((item) => {
            tableHeading.push(<th scope="col" key={item.fieldName} className="filter_text" > {item.fieldName} </th>)

        })
    }
    return <thead><tr> {tableHeading} </tr></thead>
}
const statusOptions = [
    { value: "", label: 'ALL' },
    { value: 1, label: 'Open' },
    { value: 2, label: 'Close' },
    { value: 3, label: 'Pending with spare' },
    // { value: 4, label: 'Urgent' },
    { value: 5, label: 'Non Contactable' },
    // { value: 6, label: 'Self_installed' },
    { value: 7, label: 'Cancelled' },
    // { value: 8, label: 'Returned' },
    // { value: 9, label: 'Not delivered ' },
    // { value: 10, label: 'Un-assigned' },
    { value: 11, label: 'Done' },
    { value: 12, label: 'Completed' },
    // { value: 13, label: 'Rescheduled' },   
    { value: 14, label: "Pending with reason" }

];

const serviceTypeOptions = [
    { value: "", label: 'All' },
    { value: 1, label: 'Installation' },
    { value: 2, label: 'Service/Repair' },
    { value: 3, label: 'Uninstallation' }

];

const statusFilterOptions = [
    { value: "", label: 'All' },
    { value: 1, label: 'OPEN' },
    { value: 2, label: 'CLOSED' },
    { value: 3, label: 'ASSIGNED' },
    { value: 4, label: 'CANCELLED' },
    { value: 5, label: 'ON-HOLD' },
    { value: 6, label: 'PENDING ASSIGNMENT' }
];
const sourceFilterOptions = [
    { value: "", label: 'All' },
    { value: 0, label: 'Amazon' },
    { value: 1, label: 'Flipkart' },
    { value: 2, label: 'Onefitplus' }
];

const productTypeOptions = [
    { value: "Treadmill", label: 'Treadmill' },
    { value: "Elliptical", label: 'Elliptical' },
    { value: "Urban Terrain", label: 'Urban Terrain' },
    { value: "Exercise Bike", label: 'Exercise Bike ' }

];
const skillTypeOption = [
    { value: "Installation", label: 'Installation' },
    { value: "Repair", label: 'Repair' },
    { value: "Dis-assemble", label: 'Dis-assemble' }]

const genderOption = [
    { value: "0", label: 'Male' },
    { value: "1", label: 'Female' }

];
const TatOption = [
    { value: 0, label: 'All' },
    { value: 1, label: 'In Tat' },
    { value: 2, label: 'Out Tat' }

]
const getValue = (type, value) => {
    let toReturn = "";
    switch (type) {
        case "string":
            toReturn = value ? value : "";
            break;
        case "number":
            toReturn = value ? Number(value) : 0;
            break;
        case "date":
            toReturn = value ? moment(value).format('Do MMM YY') : "-";
            break;
        case "dateTime":
            toReturn = value ? moment(value).format('Do MMM YY, h:mm:ss a') : "-";
            break;
        case "dateInMil":
            toReturn = value ? moment(value.toString()).format('Do MMM YY') : "-";
            break;
        default:
            toReturn = value ? value : "";
            break;
    }
    return toReturn;
}

const getSortingIcon = (value, type) => {
    let toReturn = "fa fa-sort ml-1";
    if (type === "Number") {
        switch (value) {
            case "-1":
            case -1:
                toReturn = "fa fa-sort-numeric-desc"
                break;
            case "1":
            case 1:
                toReturn = "fa fa-sort-numeric-asc"
                break;
        }
    }
    if (type === "Text") {
        switch (value) {
            case "-1":
            case -1:
                toReturn = "fa fa-sort-alpha-desc"

                break;
            case "1":
            case 1:
                toReturn = "fa fa-sort-alpha-asc"

                break;
        }

    }

    return toReturn;

}
const validEmail = (value) => {
    var reg = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+\.([A-Za-z]{2,4})$/i;
    return reg.test(value);
}


const validCountryCode = (value) => {
    if(value === 91 || value === '91'){
        return true
    }else{
        return false
    }
 }

const validPhoneNumber = (value) => {
    var reg = /^([0-9\(\)\/\+ \-]*)$/;
    return reg.test(value);
}


function removeDuplicates(originalArray, objKey) {
    var trimmedArray = [];
    var values = [];
    var value;

    for (var i = 0; i < originalArray.length; i++) {
        value = originalArray[i][objKey];

        if (values.indexOf(value) === -1) {
            trimmedArray.push(originalArray[i]);
            values.push(value);
        }
    }
    return trimmedArray;
}
function handleChangeStatus(id, status, callerMethod, scope, callBackMethod) {
    let obj = {
        dieticianId: id,
        dieticianStatus: status === 1 ? 2 : 1
    }
    //Same Api being used to Deactivate/Activate  Sub Admins Or  CRM Agent
    callerMethod(obj)
        .then((res) => {
            let resp = res.data
            if (resp.statusCode === 1) {
                toast.success(resp.responseData.message)
                if (!callBackMethod) {
                    scope.getAllAdmins();
                }
                else {
                    callBackMethod();
                }

            }
        })
}

export {
    getTableHeader,
    statusOptions,
    getValue,
    serviceTypeOptions,
    sourceFilterOptions,
    statusFilterOptions,
    getSortingIcon,
    validEmail,
    validPhoneNumber,
    validCountryCode,
    productTypeOptions,
    skillTypeOption,
    genderOption,
    removeDuplicates,
    TatOption,
    handleChangeStatus
}


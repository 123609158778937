import _ from 'lodash';
import moment from 'moment';
import Popup from "reactjs-popup";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { LoaderSvg } from '../Reusable';
import { Link } from 'react-router-dom';
import AddEditForm from './AddEditForm';
import Pagination from 'react-js-pagination';
import React, { useEffect, useState } from 'react';
import { getWarehouse } from '../../config/services/servicePincodes';
import { getOrderList } from '../../config/services/OrderManagementService';
import { Modal, Button, Form, Alert, Dropdown, FormCheck, ProgressBar } from 'react-bootstrap';
import { orderStatusOptions,priceFormat,paymentStatusOptions } from "../../utils/commonFunctions";
import { ORDER_STATUS,ORDER_PAYMENT_STATUS } from "../../utils/constants";
import {ReactComponent as ViewIcon} from './../../assets/images/view.svg'

import ShippingAddress from './ShippingAddress';
import BillingAddress from './BillingAddress';
import OrderProducts from './OrderProducts';





import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

const Index = (props) => {

  const [list, setList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [wareHouseList, setWarehouseList] = useState([]);
  const [addEditPopup, setAddEditPopup] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateFormValues, setUpdateFormValues] = useState({});



  //for poup model (shipping /billing /product)
  let _commonObj={
    shippingShow: false, 
    shippingDataView: {},
    billingShow: false, 
    billingDataView: {},
    productShow: false, 
    productDataView: {},
    Popup: false, 
}

const  [showModelInfo,setShowModelInfo]= useState(_commonObj)

// initialize basic variable (not for api)
  const [initialState, setInitialState] = useState({
    totalRecords: 0,
    pageRangeDisplayed: 5,
    confirmDeactivate: false,
    tempId: '',
    tempStatus: '',
  });


  //convert to timestamp
  const convertToTimestamp = (date)=>{
    return new Date(date).getTime();
  }

  // Filter for search index
  let paramObj = {
    page: 0,
    limit: 25,
    sortField: 'created',
    sortType: -1,  //1=ASC, -1=DESC
    orderStatus:'',
    paymentStatus:'',
    startDate:'',
    endDate:'',
   /*  startDate:moment().startOf('day').valueOf(),
    endDate:moment().endOf('day').valueOf(), */
    warehouse:'',
    search: '',
  };
  const [param, setParam] = useState(paramObj);

  const getOrdersListing = () => {
    try {
      setLoader(true);
      let _params = {
        params: param,
      }
      //get order list 
      getOrderList(_params).then(res => {
        let response = res.data
        if (response.statusCode == 1) {
          setList(response.responseData.result[0]);
          //set total records
          if(response.responseData.result.length>1 && response.responseData.result[1]){
            setInitialState((prevState) => ({
              ...prevState,
              totalRecords: response.responseData.result[1].totalCount
            }));
          }
        } else if (response.statusCode == 0) {
          toast.error(response.error.errorMessage)
        }

        setLoader(false);
      });

    
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  }

  //handel filter / serach input
  const handelParam = (name, value) => {
    setParam((prevParam) => ({
      ...prevParam,
      page:0,
      [name]: value
    }));
  }

  //get search after stop input change (mention time)
  const delayedQuery = _.debounce((q) => handelParam("search",q), 800);

  const handelSearch = (e) => {
    delayedQuery(e.target.value);
  };

//get warehouse list
  const getWarehouseList = () => {
    getWarehouse()
      .then(res => {
        let response = res.data
        if (response.statusCode == 1) {
          let data = res.data.responseData;

          if (data.result && data.result.length > 0) {
            let allWarehouse = [];
            
            allWarehouse.push({
              label: "All",
              value: ""
            })

           
            data.result.forEach(key => {
              allWarehouse.push({
                label: key.name,
                value: key._id,
              })

    
            })

            setWarehouseList(allWarehouse);
           
          }
        } else if (response.statusCode == 0) {
          toast.error(response.error.errorMessage)
        }
      })
  }

  //convert date and set to timestamp for filter 
  const filterByDate = (date) => {
    let dateFrom = null, dateTo = null;
    if (date) {
        dateFrom = moment(date[0]).startOf('day').valueOf()
        dateTo = moment(date[1]).endOf('day').valueOf()
    }

    setParam((prevParam) => ({
      ...prevParam,
      startDate:dateFrom,
       endDate:dateTo,
    }));
}

//filter warehouse name of list 
const filterWarehouseName= (id="")=>{

   let warehouseInfo= wareHouseList.find(obj=> obj.value==id);
   if(warehouseInfo && warehouseInfo.label){
     return warehouseInfo.label;
   }else{
     return "";
   }

}


//Close Model 
let closeModal= ()=>{
 
  setShowModelInfo(_commonObj);
}


  useEffect(() => {
    getOrdersListing();
  }, [param]);

  useEffect(() => {
    getWarehouseList();
  }, []);


  return (
    <div className='body-container-wrapper'>
      {/* {state.apiCalled && <Loader />} */}
      <div className='body-container'>
        <ol className='breadcrumb'>
        <li className='breadcrumb-item'><Link to='/dashboard'>Dashboard</Link></li>
          <li className='breadcrumb-item'>Order</li>
        </ol>
        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}
        <div className='users_header'>
          <div className='container-fluid'>
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-12">
                <h4 className="cm_page_heading">Orders</h4>
              </div>

              <div style={{ display: 'block' }} className='row col-md-6 text-right col-sm-12'>
                
                <Link to='/orders/import-export' className="btn btn-info mt-2 ml-2">
                  Import / Export
               </Link>
              </div>
            </div>

            <div className='container-fluid'>
              <div className="row align-items-center">

                <ul className="col-md-12">

                <li className="list-inline-item mt-2">
                    <label inline className="mr-2">Filter By Date:</label>
                    <RangePicker
                        style={{ height: "2.8em" }}
                        size={"middle"}
                        onChange={(val) => filterByDate(val)}
                        value={param.startDate && param.endDate && [moment(param.startDate), moment(param.endDate)]}
                        defaultValue={[moment(param.startDate), moment(param.endDate)]}
                    />
                </li>
                  <li className="list-inline-item mt-2">

                    <Select
                      className='cm_min_width'
                      onChange={(e) => handelParam('warehouse', e.value)}
                      placeholder='Warehouse'
                      options={wareHouseList}
                      value={param.warehouse ? wareHouseList.find(element => element.value === param.warehouse) : ""}
                    />
                  </li>

        <li className="list-inline-item mt-2">
        <Select
        className='cm_min_width'
        isSearchable
        options={orderStatusOptions()}
        placeholder='Order Status'
        onChange={(e) => handelParam('orderStatus', e.value)}
        value={param.orderStatus ? orderStatusOptions().find(element => element.value === param.orderStatus) : ""}
        defaultValue={{ label: "All", value: "" }}
        />
        </li>

        <li className="list-inline-item mt-2">
                  <Select
                    className='cm_min_width'
                    isSearchable
                    options={paymentStatusOptions()}
                    placeholder='Payment Status'
                    onChange={(e) => handelParam('paymentStatus', e.value)}
                    value={param.paymentStatus ? paymentStatusOptions().find(element => element.value === param.paymentStatus) : ""}
                    defaultValue={{ label: "All", value: "" }}
                  />
                </li>

          

                  <li className="list-inline-item mt-6">

                    <input
                      type="text"
                      className="form-control m-b-md-15"
                      placeholder="Search"
                      onChange={handelSearch}
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className='table-responsive mt-2'>
              <table className='table table-bordered table-striped text-center'>
                <thead>
                <tr>
                    <th scope="col" className="SrNo">Sr. No</th>
                    <th scope="col">Order Id</th>
                    <th scope="col">Order Date <br/>& Time</th>
                    <th scope="col">Source Id</th>
                    <th scope="col">Coupon <br/>Applied</th>
                    <th scope="col">Coupon <br/>Amount (₹)</th>
                    <th scope="col">Final Amount <br/>Paid (₹)</th>
                    <th scope="col">Payment <br/>Confirmation Id </th>
                    <th scope="col">Expected Time <br/>Of Delivery</th>
                    <th scope="col">Invoice <br/>Number</th>
                    <th scope="col">Products</th>
                    <th scope="col">Delivery <br/> address</th>
                    <th scope="col">Billing <br/>address</th>
                    <th scope="col"> Warehouse <br/> Assigned </th>
                    <th scope="col">AWB Number</th>
                    <th scope="col">Eway Bill</th>
                    <th scope="col">Courier Partner</th>
                    <th scope="col">Order <br/>Status </th>
                    <th scope="col">Payment <br/>Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {list && list.length > 0 && list.map((data, index) => (
                    <tr key={index}>
                       <td className="SrNo"> {param.limit * param.page + (index + 1)}</td>

          <td>
          <Link className="d-block text-truncate" style={{width: '200px'}} to={'/orders/' + data.orderId} target="_blank">{data.orderId }</Link>
          </td>
                      <td>{moment(convertToTimestamp(data.created)).format("DD MMM YYYY hh:mm a")} 
                        </td>
                      <td>Website</td>
                    
    
                      <td>{data.couponDetails  && data.couponDetails.couponName? data.couponDetails.couponName : ''}</td>
                      <td>{data.totalDiscount ? priceFormat(data.totalDiscount) : ''}</td>
                      <td>{data.totalPrice ? priceFormat(data.totalPrice) : ''}</td>
                      <td>{data.paymentId ? data.paymentId : ''}</td>
                      <td>{data.ETA ? data.ETA : ''}</td>
                      <td>{data.invoice ? <a href={data.invoice} target="_blank">{data.invoiceNumber}</a> : ''}</td>
                      <td>  <ViewIcon  onClick={ ()=>{setShowModelInfo({
                        productShow: true, 
                        productDataView: data,
                        Popup: true, 
                      })}}/></td>
                      <td>  <ViewIcon  onClick={ ()=>{setShowModelInfo({
                        ..._commonObj,
                        shippingShow: true, 
                        shippingDataView: data,
                        Popup: true, 
                      })}}/></td>
                    
                    <td>  <ViewIcon  onClick={ ()=>{setShowModelInfo({
                        billingShow: true, 
                        billingDataView: data,
                        Popup: true, 
                      })}}/></td>

                     
                      <td>{data.warehouse ? filterWarehouseName(data.warehouse) : ''}</td>
                      <td>{data.awsNumber ? data.awsNumber : ''}</td>
                      <td>{data.ewayBill ? data.ewayBill : ''}</td>
                      <td>{data.courierPartner ? data.courierPartner : ''}</td>
                      
                      <td> {ORDER_STATUS[data.orderStatus] }</td>
                      <td>{ORDER_PAYMENT_STATUS[data.paymentStatus]}</td>
                      
                     
                      <td>
                        <button
                          className="btn btn-dark btn-sm"
                          onClick={() => {
                            setEditMode(true);
                            setUpdateFormValues(data);
                            setAddEditPopup(true);      
                          }}
                        >
                          Field to add note
                        </button>
                        &nbsp;
                        

                        {/* <Link className="d-block text-truncate" style={{width: '200px'}} to={'/profile/' + user._id} target="_blank">{`${user.firstName} ${user.lastName}`}</Link> */}

                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                  // pagination still under dev
                  activePage={param.page + 1}
                  itemsCountPerPage={param.limit}
                  totalItemsCount={initialState.totalRecords}
                  pageRangeDisplayed={initialState.pageRangeDisplayed}
                  onChange={(value) => setParam({...param,page:value - 1})}
                />
            </div>

           
          </div>
             <AddEditForm
            editMode={editMode}
            addEditPopup={addEditPopup} 
            updateFormValues={updateFormValues} 
            setAddEditPopup={(val) => {
              setEditMode(false);
              setAddEditPopup(val);
            }}
            getList={() => { getOrdersListing() }}
          />

        </div>
      </div>

{/* For Shipping Address */}

      <Modal show={showModelInfo.shippingShow} onHide={closeModal} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Shipping Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ShippingAddress orderDetails={showModelInfo.shippingDataView} />
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={closeModal}> Close </Button>
          </Modal.Footer>
        </Modal>


{/* For billing Address */}
        <Modal show={showModelInfo.billingShow} onHide={closeModal} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Billing Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BillingAddress orderDetails={showModelInfo.billingDataView} />
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={closeModal}> Close </Button>
          </Modal.Footer>
        </Modal>


        {/* For Order products */}
        <Modal show={showModelInfo.productShow} onHide={closeModal} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Product Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <OrderProducts orderDetails={showModelInfo.productDataView} />
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={closeModal}> Close </Button>
          </Modal.Footer>
        </Modal>

     
    </div>
  );
};

export default Index;
import React, { Component } from 'react';
import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';
import { addService, getServices, updateService, deleteService } from "../../config/services/serviceCategoriesService";
import { LoaderSvg } from '../Reusable';
import { toast } from 'react-toastify';
import Pagination from "react-js-pagination";
import { Button, Modal } from 'react-bootstrap';
import { Switch } from 'antd';

let image;

class ServiceCategories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            addCategoryPopup: false,
            deleteCategoryPopup: false,
            services: [],
            serviceName: '',
            categoryDescription: '',
            serviceImagePreview: '',
            serviceNameErrorMsg: '',
            editCategoryPopup: false,
            tempCategoryId: "",
            validationError: "",
            search: '',
            count: 20,
            pageNo: 1,
            totalServices: 50,
            sortType: '',
            sortField: '',
            services: [],
            isGroupSessionAllowed: true,
            isVideoEnabled: true,
            parentCategory: '',
        }
    }


    openCategoryEdit = ({ _id, serviceName, categoryDescription, serviceImage }) => {
        this.setState({
            openCategoryEditPoup: !this.state.openCategoryEditPoup,
            categoryId: _id,
            serviceName,
            categoryDescription,
            serviceImagePreview: serviceImage,
        })
    }

    openAddCategoryPopup = () => this.setState({ addCategoryPopup: !this.state.addCategoryPopup });

    openDeleteCategoryPopup = (category) => {
        this.setState({
            deleteCategoryPopup: !this.state.deleteCategoryPopup,
            tempCategoryId: category._id,
        })
    }



    handlePageChange = (pageNumber) => {
        this.setState({ pageNo: pageNumber }, () => { this.getServicesList(); });
    }

    deleteCategory = (e) => {
        e.preventDefault();
        this.setState({ loader: true })
        const { tempCategoryId } = this.state;
        deleteService({ serviceId: tempCategoryId })
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.getServicesList()
                    this.closeModal()
                    this.setState({ loader: false })
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false, validationError: res.data.error.errorMessage })
                }
            })
    }

    openEditCategoryPopup = (category) => {
        this.setState({
            editCategoryPopup: !this.state.editCategoryPopup,
            tempCategoryId: category._id,
            serviceName: category.serviceName,
            serviceImagePreview: category.serviceImage,
            isGroupSessionAllowed: category.isGroupSessionAllowed,
            isVideoEnabled: category.isVideoEnabled,
            parentCategory: category.parentCategory,
        })
    };

    submitEditCategory = (e) => {
        e.preventDefault();
        let { serviceName, serviceImagePreview, tempCategoryId, isGroupSessionAllowed, isVideoEnabled, parentCategory } = this.state;

        let paramsObj = new FormData();
        paramsObj.append('serviceId', tempCategoryId);
        paramsObj.append('serviceName', serviceName);
        paramsObj.append('serviceImage', image ? image : "");
        paramsObj.append('isGroupSessionAllowed', isGroupSessionAllowed);
        paramsObj.append('isVideoEnabled', isVideoEnabled);
        paramsObj.append('parentCategory', parentCategory);



        if (!serviceName) {
            this.setState({ validationError: "Service name can't be empty" })
        }
        else if (!parentCategory) {
            this.setState({ validationError: "Parent category can't be empty" })
        }
        else if (!serviceImagePreview) {
            this.setState({ validationError: "Please select an image for the service" })
        }
        else {
            this.setState({ validationError: "" })
            this.editCategoryApi(paramsObj);
        }
    }

    editCategoryApi = (params) => {
        this.setState({ loader: true })
        updateService(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.getServicesList()
                    this.closeModal()
                    this.setState({ loader: false })
                    toast.success(res.data.responseData.message)

                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false, validationError: res.data.error.errorMessage })
                }
            })
    }

    closeModal = () => {
        this.setState({
            addCategoryPopup: false, deleteCategoryPopup: false, editCategoryPopup: false, serviceName: "",
            openEditCategoryPopup: false,
            categoryDescription: "",
            serviceImagePreview: "",
            tempCategoryId: "",
            serviceNameErrorMsg: '',
            validationError: '',
            isGroupSessionAllowed: true,
            isVideoEnabled: true,
            parentCategory: '',
        })

        image = '' //this is global variable
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value, serviceNameErrorMsg: '' })
    }




    handleImgPreivew = e => {
        let reader = new FileReader();
        let file = e.target.files[0];
        image = e.target.files[0];

        this.setState({ serviceImagePreview: e.target.files[0] })
        reader.addEventListener("load", () => {
            this.setState({ serviceImagePreview: reader.result })
        }, false);
        if (file) { reader.readAsDataURL(file) }
    }


    handleAddService = e => {
        e.preventDefault();
        let { serviceName, isGroupSessionAllowed, isVideoEnabled, parentCategory } = this.state;
        let params = new FormData();
        params.append('serviceName', serviceName);
        params.append('serviceImage', image);
        params.append('isGroupSessionAllowed', isGroupSessionAllowed);
        params.append('isVideoEnabled', isVideoEnabled);
        params.append('parentCategory', parentCategory);

        if (!serviceName) {
            this.setState({ validationError: "Service name can't be empty" })
        }
        else if (!parentCategory) {
            this.setState({ validationError: "Parent category can't be empty" })
        }
        else if (!image) {
            this.setState({ validationError: "Please select an image for the service" })
        }
        else {
            this.setState({ validationError: "" })
            this.addAddServiceApi(params);
        }
    }

    addAddServiceApi = (params) => {
        this.setState({ loader: true })
        addService(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false }, () => this.getServicesList())
                    this.closeModal();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({
                        validationError: res.data.error.errorMessage,
                        loader: false
                    })
                }
            })
    }



    getServicesList = () => {
        let { search, count, pageNo, sortType, sortField } = this.state;
        let paramsObj = {
            params: {
                search
                , count
                , pageNo: pageNo - 1
                , sortType
                , sortField
            }
        }
        getServices(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({
                        services: res.data.responseData.result,
                        totalServices: res.data.responseData.totalCount,
                        loader: false
                    })
                } else {
                    this.setState({ loader: false })
                }
            })
    }

    handleSearch = e => this.setState({ search: e.target.value }, () => this.getServicesList());

    componentDidMount() {
        this.getServicesList();
    }

    handleSwitch = (value, field) => {
        this.setState({ [field]: value })
    }


    render() {
        const { services, loader, addCategoryPopup, serviceName, serviceImagePreview, deleteCategoryPopup, editCategoryPopup, validationError, pageNo, count, totalServices, isGroupSessionAllowed
            , isVideoEnabled,parentCategory } = this.state;
        return (
            <div className="body-container-wrapper">
                <div className="body-container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                        <li className="breadcrumb-item">Service Category</li>
                    </ol>
                    {loader && <div className="loader_wrapper"><LoaderSvg /></div>}

                    <div className="users_header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-9">
                                    <h4 className="cm_page_heading">Service Category</h4>
                                </div>
                                <div className="col-md-3 text-right cm_search">
                                    <input
                                        type="text"
                                        className="form-control m-b-md-15"
                                        placeholder="Search by service name"
                                        onChange={this.handleSearch}
                                    />
                                    <i className="fa fa-search" />
                                </div>
                                <div className="col-md-3 text-right">
                                    <button className="btn btn-primary btn-block" onClick={this.openAddCategoryPopup}>
                                        Add New</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row mt-3">
                        {services.map((category, i) => (
                            <div className="col-sm-2" key={category._id}>
                                <div className="card card_content">
                                    {
                                        category.serviceImage ? <img className="card-img-top" src={category.serviceImage} alt="Service image" /> : <div className="card-img-top">No Image</div>
                                    }

                                    <div className="card-body">
                                        <h6 className="card-title">{category.serviceName}</h6>
                                        <p>Created: {dateFormat(category.created, "d mmmm yyyy")}</p>

                                        <div className="row">
                                            <div className="col">
                                                <button className="btn btn-primary btn-block" onClick={() => this.openEditCategoryPopup(category)} >Edit</button>
                                            </div>
                                            <div className="col">
                                                <button className="btn btn-danger btn-block" onClick={() => this.openDeleteCategoryPopup(category)}>Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>

                    <Pagination
                        activePage={pageNo}
                        itemsCountPerPage={count}
                        totalItemsCount={totalServices}
                        pageRangeDisplayed={6}
                        onChange={this.handlePageChange}
                    />

                    {!services.length ? <h3 className="empty_error">Sorry, we couldn't find any content!</h3> : null}


                </div>


                <Modal
                    show={addCategoryPopup}
                    onHide={this.closeModal}
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Service Category</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <h6 className="text-center error_message">{validationError}</h6>
                        <form>
                            <div className="form-group">
                                <label>Service Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="serviceName"
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>Service Category Image</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    onChange={this.handleImgPreivew}
                                />
                            </div>

                            <div className="form-group">
                                <label>Parent Category</label>
                                <select className="form-control" name="parentCategory" id="parentCategory" onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={1}>Doctor</option>
                                    <option value={2}>Dietician</option>
                                    <option value={3}>Home workout</option>
                                </select>
                            </div>


                            <div className="form-group">
                                <label>Group Session</label>
                                <Switch checked={isGroupSessionAllowed} onChange={(e) => this.handleSwitch(e, 'isGroupSessionAllowed')} />
                            </div>

                            <div className="form-group">
                                <label>Default Video Display</label>
                                <Switch checked={isVideoEnabled} onChange={(e) => this.handleSwitch(e, 'isVideoEnabled')} />
                            </div>


                            {serviceImagePreview && <img className="exercise_category_img" src={serviceImagePreview} />}
                        </form>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="info" onClick={this.handleAddService}>
                            Submit
                            </Button>
                    </Modal.Footer>
                </Modal>



                <Modal
                    show={editCategoryPopup}
                    onHide={this.closeModal}
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Service Category</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <h6 className="text-center error_message">{validationError}</h6>
                        <form>
                            <div className="form-group">
                                <label>Service Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="serviceName"
                                    value={serviceName}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>Service Category Image</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    onChange={this.handleImgPreivew}
                                />
                            </div>
                            <div className="form-group">
                                <label>Parent Category</label>
                                <select className="form-control" name="parentCategory" id="parentCategory" onChange={this.handleChange} value={parentCategory}>
                                    <option selected disabled>Select....</option>
                                    <option value={1}>Doctor</option>
                                    <option value={2}>Dietician</option>
                                    <option value={3}>Home workout</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label>Group Session</label>
                                <Switch checked={isGroupSessionAllowed} onChange={(e) => this.handleSwitch(e, 'isGroupSessionAllowed')} />
                            </div>

                            <div className="form-group">
                                <label>Default Video Display</label>
                                <Switch checked={isVideoEnabled} onChange={(e) => this.handleSwitch(e, 'isVideoEnabled')} />
                            </div>

                            {serviceImagePreview && <img className="exercise_category_img" src={serviceImagePreview} />}
                        </form>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="info" onClick={this.submitEditCategory}>
                            Submit
                            </Button>
                    </Modal.Footer>
                </Modal>




                <Modal
                    show={deleteCategoryPopup}
                    onHide={this.closeModal}
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure, you want to delete this service?</Modal.Title>
                    </Modal.Header>

                    <h6 className="text-center error_message">{validationError}</h6>

                    <Modal.Footer>
                        <Button variant="danger" onClick={this.deleteCategory}>Delete</Button>
                        <Button variant="primary" onClick={this.closeModal}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default ServiceCategories;
import React, { Component } from 'react'
import { QuestionaireService } from '../../config/services/QuestionaireService';
import { toast } from 'react-toastify';
import Popup from "reactjs-popup"
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { Dropdown } from 'react-bootstrap';


class Questionaire extends Component {


    constructor(props) {
        super(props)

        this.state = {
            loader: true,
            questionsList: [],
            addQuestionPopup: false,
            error: "",
            addQuestionOptions: [],
            answers: [],
            questionType: 1,
            pageNo: 1,
            totalQuestionsCount: 100,
            countsPerPage: 10,
            search: '',
            questionFor: 1, //1=App and Dietician panel 2=Dietician panel only,
            filterStatus:1,
        }
    }

    componentDidMount() {
        this.getAllQuestions();
    }

    getAllQuestions = (params) => {
        const { pageNo, search,filterStatus } = this.state;
        params = {
            params: {
                pageNo: pageNo - 1,
                search,
                filterStatus
            }
        }

        let self = this;
        QuestionaireService.getAllQuestions(params)
            .then(function (res) {
                let response = res.data;
                if (response.statusCode == 1) {
                    self.setState({ questionsList: response.responseData.result, totalQuestionsCount: response.responseData.count, loader: false })
                } else if (response.statusCode == 0) {
                    self.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    handleQuestionSearch = (e) => {
        const { id, value } = e.target;
        this.setState({ search: value }, () => {
            this.getAllQuestions();
        })
    }

    handleAddQuestionPopup = () => {
        this.setState({ addQuestionPopup: true })
    }

    closeModal = () => {
        this.setState({ addQuestionPopup: false, error: "", question: "", answers: [], addQuestionOptions: [] })
    }

    addMoreOptions = () => {
        const { addQuestionOptions } = this.state
        addQuestionOptions.push({ addQuestionOptions: "" });
        this.setState({ addQuestionOptions })
    }

    handleChange = (e) => {
        const { id, value } = e.target;
        this.setState({
            [id]: value,
        })
    }


    onOptionChange = (index, e) => {

        const { answers } = this.state;
        const { id, value } = e.target;

        answers[index] = {
            option: value,
        }
        this.setState({ answers })
    }

    submitAddQuestion = (e) => {
        e.preventDefault();
        const { question, answers, questionType, questionFor } = this.state;


        let paramsObj = {
            question,
            answers: JSON.stringify(answers),
            type: parseInt(questionType),
            questionFor
        }
        if (question == "" || (!answers.length && questionType != 3)) {
            this.setState({ error: "All fields are mandatory" })
        } else {
            this.setState({ error: "" })
            this.callAddQuestionApi(paramsObj);
        }
    }

    callAddQuestionApi = (params) => {
        let self = this;

        QuestionaireService.createQuestion(params)
            .then(function (res) {
                let response = res.data;
                if (response.statusCode == 1) {
                    self.setState({ Loader: false })
                    self.closeModal();
                    self.getAllQuestions();
                    toast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    self.setState({ Loader: false, error: response.error.errorMessage })
                }
            })
    }


    handleChangeStatus(id, status) {
        this.setState({ [id]: true, progress: 0.5, })

        let self = this;
        let obj = {
            questionId: id,
            questionStatus: status === 1 ? 0 : 1
        }

        QuestionaireService.changeQuestionStatus(obj)
            .then((res) => {
                if (res.data.statusCode === 1) {
                    toast.success(res.data.responseData.message)
                    self.getAllQuestions();
                    self.setState({ [id]: false })
                } else if (res.data.statusCode === 1) {
                    toast.error(res.data.error.errorMessage)
                }
            })
    }


    handlePagination = (pageNumber) => {
        this.setState({ pageNo: pageNumber, loader: true }, () => {
            this.getAllQuestions();
        })
    }

    handleFilter=(e)=>{
        let { value } = e.target        
        this.setState({filterStatus:value},()=> this.getAllQuestions())
    }


    render() {
        const { questionsList, addQuestionPopup, error, addQuestionOptions, loader, pageNo, totalQuestionsCount, countsPerPage, questionFor,filterStatus } = this.state;
        return (
            <div className="body-container-wrapper">


                {loader
                    ? <div className="loader_wrapper"><LoaderSvg /></div>
                    : null}

                <div className="body-container">
                    <div className="users_header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4">
                                    <h4 className="cm_page_heading">Questions</h4>
                                </div>

                                <div className="col-md-2">
                                <select className="form-control" onChange={this.handleFilter} id="filterStatus" value={filterStatus}>
                                        <option value=''> All </option>
                                        <option value={1}> Active </option>
                                        <option value={0}> Inactive </option>
                                    </select>
                                </div>

                                <div className="col-md-3 text-right cm_search">
                                    <input
                                        type="text"
                                        className="form-control m-b-md-15"
                                        placeholder="Search by question"
                                        name="search"
                                        id="search"
                                        onChange={this.handleQuestionSearch}
                                    />
                                    <i className="fa fa-search"></i>
                                </div>

                                <div className="col-md-3 ">
                                    <button onClick={this.handleAddQuestionPopup} className="btn btn-primary btn-block">Add Question</button>
                                </div>

                            </div>
                        </div>
                    </div>

                    {!questionsList.length ? <h3 className="empty_error">Sorry, We couldn't find any content!</h3> : null}

                    <div className="cm_quition_list">
                        <ol>
                            {questionsList.map((question, index) => (
                                <li key={index}>
                                    <span className={`cm_ckeckbox_wrapper ${question.status === 1 ? 'cm_active' : 'cm_inactive'}`}
                                        onClick={() => this.handleChangeStatus(question._id, question.status)}>

                                        <span className="ques_for_tag">{question.questionFor == 1 ? "App" : "Dietician"}</span>

                                        <span className="cm_ckeckbox_btn"></span>
                                    </span>
                                    <p className="mb-3">{((index + 1) + ((pageNo - 1) * countsPerPage))}, {question.question}</p>



                                    {question.answers ? question.answers.map((answer, index) => (
                                        <p key={index} className="cm_options"> {index + 1}.  {answer.option}</p>
                                    )) : null}

                                </li>
                            ))}

                        </ol>
                    </div>

                    <Pagination
                        activePage={pageNo}
                        itemsCountPerPage={countsPerPage}
                        totalItemsCount={totalQuestionsCount}
                        pageRangeDisplayed={4}
                        onChange={this.handlePagination}
                    />
                </div>


                <Popup
                    open={addQuestionPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>
                            &times;
            </span>
                        <h4 className="text-center mb-4 mt-5 error_message">{error}</h4>

                        <h3 className="text-center mb-4">Add new question</h3>

                        <form onSubmit={this.submitAddQuestion}>
                            <div className="btn_group">
                                <div className="form-group">
                                    <label>Question :</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="question"
                                        id="question"
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="questionType">Queation Type :</label>
                                    <select className="form-control" id="questionType" name="questionType" onChange={this.handleChange}>
                                        <option value={1}>Single choice</option>
                                        <option value={2}>Multiple choice</option>
                                        <option value={3}>Text</option>
                                    </select>
                                </div>


                                <div className="form-group">
                                    <label className="mb-2">Options</label>
                                    <br />
                                    {addQuestionOptions.map((option, index) => (
                                        <input
                                            type="text"
                                            className="form-control mb-2"
                                            onChange={this.onOptionChange.bind(this, index)}
                                            name="option"
                                            id="option"
                                        />
                                    ))}


                                    <i className="fa fa-plus-square-o cm_pointer" aria-hidden="true" onClick={this.addMoreOptions}>
                                        Add more options
                                    </i>
                                </div>


                                <div className="form-group">
                                    <label htmlFor="questionFor">Queation For :</label>
                                    <select className="form-control" id="questionFor" name="questionFor" onChange={this.handleChange}>
                                        <option value={1}>App Question</option>
                                        <option value={2}>Dietician Question</option>
                                    </select>
                                </div>

                                <button className="btn btn-warning btn-sm btn-block" type="submit">Save</button>

                            </div>
                        </form>
                    </div>
                </Popup>
            </div>
        )
    }
}

export default Questionaire
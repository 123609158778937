import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup"
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import { getTimeSlotList, updateTimeSlot, deleteTimeSlot } from '../../config/services/adminTimeSlotService';
import { toast } from 'react-toastify';


class categoriesTimeslot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            titleErrorMsg: '',
            categories: [],
            addAlreadyCategories: [],
            timeSlots: [],                     
            addTimeSlotOption: false,
            editTimeSlotPopup: false,
            deleteTimeSlotPopup: false,
            catId: '',
            categoryId: '',
            categoryName: '',
            startTime: '',
            endTime: '',
            slot: '',
        }
    }

    componentDidMount() {
        var allcategory = [
            {id:1, name:'Doctor'},
            {id:2, name:'Dietician'},
            {id:3, name:'Home workout'}
        ]
        this.setState({categories: allcategory})
        this.getTimeSlotLists();
    }

    closeModal = () => this.setState({
        editTimeSlotPopup: false, 
        deleteTimeSlotPopup: false, 
        catId: '',
        categoryId: '',
        categoryName: '',
        startTime: '',
        endTime: '',
        slot: ''
    });


    getTimeSlotLists = () => {
        let paramsObj = {}
        getTimeSlotList(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    if(res.data.responseData.result && res.data.responseData.result.categorytimeSlots) {
                        let categorytimeSlots = res.data.responseData.result.categorytimeSlots;
                        categorytimeSlots.map(e => {
                            if(!this.state.addAlreadyCategories.includes(parseInt((e.categoryId)))) {
                                this.state.addAlreadyCategories.push(parseInt(e.categoryId));
                            }                             
                        })
                        this.setState({
                            timeSlots: res.data.responseData.result.categorytimeSlots
                        })
                    }                    
                } 
                this.setState({ loader: false })
            })
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value, titleErrorMsg: '' })
    }
    
    handleCategoryChange = e => {
        let catName = '';
        this.state.categories.map((category, index) => {            
            if(category.id == e.target.value) {
                catName = category.name
            }
        })
        this.setState({ [e.target.name]: e.target.value, categoryName: catName, titleErrorMsg: '' })
    }

    onHandleChangeNumeric = e => {
        let value = e.target.value;   
        value = value.replace(/[\D]+/g, '');
        if (!Number(value) && value != '' && value != '0') {
            return;
        }     
        this.setState({ [e.target.name]: value });
    };

    handleValidation = () => {
        let validate = true;
        let { categoryId, categoryName, startTime, endTime, slot } = this.state;

        if (!categoryId) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please select category' })
        } else if(!categoryName) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please select category' })
        } else if(!startTime) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please select start time' })
        } else if(!endTime) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please select end time' })
        } else if(!slot) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please Select Slot' })
        } else {
           this.setState({ titleErrorMsg: '' })
        }
        return validate
    }

    openAddTimeSlotPopup = (e) => {
        e.preventDefault();
        this.setState({
            addTimeSlotOption: true,
            editTimeSlotPopup: true,
            titleErrorMsg: '',
            categoryId: '',
            categoryName: '',
            startTime: '',
            endTime: '',
            slot: ''
        })
    }

    openEditTimeSlotPopup = (data, timeslot) => {
        this.setState({
            addTimeSlotOption: false,
            editTimeSlotPopup: true,
            titleErrorMsg: '',
            categoryId: timeslot.categoryId,
            categoryName: timeslot.categoryName,
            startTime: timeslot.startTime,
            endTime: timeslot.endTime,
            slot: timeslot.slot
        })
    }
    
    editTimeSlotSubmit = (e) => {       
        e.preventDefault();
        if (this.handleValidation()) {
            var current_Date = new Date();
            var dateFrom = Date.parse(current_Date);            

            let { categoryId, categoryName, startTime, endTime, slot } = this.state;
            let params = {};            
            params.categoryId = categoryId;
            params.categoryName = categoryName;
            params.startTime = startTime;
            params.endTime = endTime;
            params.slot = slot;
            params.dateFrom = dateFrom;

            this.editTimeSlotApi(params);
        }
    }

    editTimeSlotApi = (params) => {
        this.setState({ loader: true })
        updateTimeSlot(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    // this.getTimeSlotLists();
                    this.setState({ loader: false, editTimeSlotPopup: false })
                    this.closeModal();
                    toast.success(res.data.responseData.message)
                    this.getTimeSlotLists();
                } else if (res.data.statusCode == 0) {
                    this.setState({
                        titleErrorMsg: res.data.error.errorMessage,
                        loader: false
                    })
                }                
            })
    }

    openDeleteTimeSlotPopup = (data, timeslot) => {
        this.setState({
            deleteTimeSlotPopup: true,
            catId: timeslot.categoryId,
        })
    }

    deleteTimeSlot = (e) => {       
        e.preventDefault();
        let { catId } = this.state;
        if(catId) {
            let params = {};            
            params.categoryId = catId;
            this.setState({ loader: true })
            deleteTimeSlot(params)
                .then(res => {
                    if (res.data.statusCode == 1) {
                        this.getTimeSlotLists();
                        this.setState({ loader: false, deleteTimeSlotPopup: false })
                        this.closeModal();
                        toast.success(res.data.responseData.message)
                    } else  {
                        this.setState({
                            titleErrorMsg: res.data.error.errorMessage,
                            loader: false
                        })
                    }                
                })
        }         
    }
  
    
    render() {
        const {
            loader,
            timeSlots,
            titleErrorMsg,
            addTimeSlotOption,
            editTimeSlotPopup,
            deleteTimeSlotPopup,
            addAlreadyCategories,
            categories,
            catId,
            categoryId,
            categoryName,
            startTime,
            endTime,
            slot,
        } = this.state;

        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Category Time Slot</li>
                        </ol>
                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}
                        
                        {categories.length != addAlreadyCategories.length?
                            <div className="text-center">
                                <button className="btn btn-primary" onClick={this.openAddTimeSlotPopup} >Add Time Slot</button>
                            </div>
                        :null}

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="cm_page_heading">Category Time Slot</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col" className="filter_text" >Category Id &nbsp;  </th>
                                        <th scope="col" className="filter_text" >Category Name &nbsp;  </th>
                                        <th scope="col" className="filter_text" >Start Time &nbsp; </th>           
                                        <th scope="col" className="filter_text" >End Time &nbsp; </th>           
                                        <th scope="col" className="filter_text" >Time Slot &nbsp; </th>           
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        timeSlots.map((timeslot, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td><span className="cm_table_three_dots">{timeslot.categoryId}</span></td>
                                                    <td><span className="cm_table_three_dots">{timeslot.categoryName}</span></td>
                                                    <td className="cm_no_wrap">{timeslot.startTime} </td>
                                                    <td className="cm_no_wrap">{timeslot.endTime} </td>
                                                    <td className="cm_no_wrap">{timeslot.slot} Min</td>
                                                    <td className="cm_no_wrap">
                                                        <button className="btn btn-dark btn-sm" onClick={(data) => this.openEditTimeSlotPopup(data, timeslot)} >Edit</button>
                                                        {/* 
                                                        &nbsp;
                                                        <button className="btn btn-dark btn-sm" onClick={(data) => this.openDeleteTimeSlotPopup(data, timeslot)} >Delete</button> 
                                                        */}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }                                   
                                </tbody>
                            </table>

                        </div>

                    </div>
                </div>



                
                <Popup
                    open={editTimeSlotPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <form onSubmit={this.editTimeSlotSubmit}>

                            
                            <div className="form-group">
                                <label>Category Name</label>
                                {addTimeSlotOption?
                                    <select className="form-control height-200" onChange={this.handleCategoryChange} name="categoryId">  
                                        <option value="" > Select Category</option>
                                        {categories.map((category, index) => (
                                            <>
                                            {!addAlreadyCategories.includes(category.id)?
                                            <React.Fragment>
                                                <option selected={categoryId === `${category.id}` ? true : false} value={category.id} > {category.name} </option>
                                            </React.Fragment>
                                            :null}
                                            </>
                                        ))}
                                    </select>
                                :
                                    <select disabled="disabled" className="form-control height-200" onChange={this.handleCategoryChange} name="categoryId"> 
                                        <option value="" > Select Category</option> 
                                        {categories.map((category, index) => (
                                            <React.Fragment>
                                                <option selected={categoryId === `${category.id}` ? true : false} value={category.id} > {category.name} </option>
                                            </React.Fragment>
                                        ))}
                                    </select>
                                }
                            </div>

                            <div className="form-group">
                                <label>Start Time</label>
                                <div className="row">
                                    <div className="col-md-12">
                                        <select className="form-control height-200" onChange={this.handleChange} name="startTime">
                                            <option value="" > Select Start Time </option>
                                            <option selected={startTime === '01:00' ? true : false} value="01:00" > 01:00 </option>
                                            <option selected={startTime === '02:00' ? true : false} value="02:00" > 02:00 </option>
                                            <option selected={startTime === '03:00' ? true : false} value="03:00" > 03:00 </option>
                                            <option selected={startTime === '04:00' ? true : false} value="04:00" > 04:00 </option>
                                            <option selected={startTime === '05:00' ? true : false} value="05:00" > 05:00 </option>
                                            <option selected={startTime === '06:00' ? true : false} value="06:00" > 06:00 </option>
                                            <option selected={startTime === '07:00' ? true : false} value="07:00" > 07:00 </option>
                                            <option selected={startTime === '08:00' ? true : false} value="08:00" > 08:00 </option>
                                            <option selected={startTime === '09:00' ? true : false} value="09:00" > 09:00 </option>
                                            <option selected={startTime === '10:00' ? true : false} value="10:00" > 10:00 </option>
                                            <option selected={startTime === '11:00' ? true : false} value="11:00" > 11:00 </option>
                                            <option selected={startTime === '12:00' ? true : false} value="12:00" > 12:00 </option>
                                            <option selected={startTime === '13:00' ? true : false} value="13:00" > 13:00 </option>
                                            <option selected={startTime === '14:00' ? true : false} value="14:00" > 14:00 </option>
                                            <option selected={startTime === '15:00' ? true : false} value="15:00" > 15:00 </option>
                                            <option selected={startTime === '16:00' ? true : false} value="16:00" > 16:00 </option>
                                            <option selected={startTime === '17:00' ? true : false} value="17:00" > 17:00 </option>
                                            <option selected={startTime === '18:00' ? true : false} value="18:00" > 18:00 </option>
                                            <option selected={startTime === '19:00' ? true : false} value="19:00" > 19:00 </option>
                                            <option selected={startTime === '20:00' ? true : false} value="20:00" > 20:00 </option>
                                            <option selected={startTime === '21:00' ? true : false} value="21:00" > 21:00 </option>
                                            <option selected={startTime === '22:00' ? true : false} value="22:00" > 22:00 </option>
                                            <option selected={startTime === '23:00' ? true : false} value="23:00" > 23:00 </option>                                            
                                        </select>
                                    </div>                                    
                                </div>
                            </div>

                            <div className="form-group">
                                <label>End Time</label>
                                <div className="row">
                                    <div className="col-md-12">
                                        <select className="form-control height-200" onChange={this.handleChange} name="endTime">
                                        <option value="" > Select End Time </option>
                                            <option selected={endTime === '01:00' ? true : false} value="01:00" > 01:00 </option>
                                            <option selected={endTime === '02:00' ? true : false} value="02:00" > 02:00 </option>
                                            <option selected={endTime === '03:00' ? true : false} value="03:00" > 03:00 </option>
                                            <option selected={endTime === '04:00' ? true : false} value="04:00" > 04:00 </option>
                                            <option selected={endTime === '05:00' ? true : false} value="05:00" > 05:00 </option>
                                            <option selected={endTime === '06:00' ? true : false} value="06:00" > 06:00 </option>
                                            <option selected={endTime === '07:00' ? true : false} value="07:00" > 07:00 </option>
                                            <option selected={endTime === '08:00' ? true : false} value="08:00" > 08:00 </option>
                                            <option selected={endTime === '09:00' ? true : false} value="09:00" > 09:00 </option>
                                            <option selected={endTime === '10:00' ? true : false} value="10:00" > 10:00 </option>
                                            <option selected={endTime === '11:00' ? true : false} value="11:00" > 11:00 </option>
                                            <option selected={endTime === '12:00' ? true : false} value="12:00" > 12:00 </option>
                                            <option selected={endTime === '13:00' ? true : false} value="13:00" > 13:00 </option>
                                            <option selected={endTime === '14:00' ? true : false} value="14:00" > 14:00 </option>
                                            <option selected={endTime === '15:00' ? true : false} value="15:00" > 15:00 </option>
                                            <option selected={endTime === '16:00' ? true : false} value="16:00" > 16:00 </option>
                                            <option selected={endTime === '17:00' ? true : false} value="17:00" > 17:00 </option>
                                            <option selected={endTime === '18:00' ? true : false} value="18:00" > 18:00 </option>
                                            <option selected={endTime === '19:00' ? true : false} value="19:00" > 19:00 </option>
                                            <option selected={endTime === '20:00' ? true : false} value="20:00" > 20:00 </option>
                                            <option selected={endTime === '21:00' ? true : false} value="21:00" > 21:00 </option>
                                            <option selected={endTime === '22:00' ? true : false} value="22:00" > 22:00 </option>
                                            <option selected={endTime === '23:00' ? true : false} value="23:00" > 23:00 </option>                                      
                                        </select>
                                    </div>                                    
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Time Slot</label>
                                <select className="form-control" onChange={this.handleChange} name="slot">
                                    <option value="" > Select Time Slot </option>
                                    <option selected={slot === '5' ? true : false} value={5} > 5 Min </option>
                                    <option selected={slot === '10' ? true : false} value={10} > 10 Min </option>
                                    <option selected={slot === '15' ? true : false} value={15} > 15 Min </option>
                                    <option selected={slot === '20' ? true : false} value={20} > 20 Min </option>
                                    <option selected={slot === '30' ? true : false} value={30} > 30 Min </option>                                    
                                </select>
                            </div>


                            <div className="text-center">
                                {titleErrorMsg && <h6 className="text-center mb-2 mt-2 error_message">{titleErrorMsg}</h6>}
                                <button className="btn btn-primary" type="submit">
                                    {addTimeSlotOption?'Add Time Slot':'Update Time Slot'}
                                </button>
                            </div>
                        </form>
                    </div>
                </Popup>

                <Popup
                    open={deleteTimeSlotPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <div className="row">
                            Do you want to remove this time slot ?
                        </div>
                        <div className="text-center">
                            <button className="btn btn-primary" onClick={this.deleteTimeSlot} >Delete Time Slot</button>
                        </div>
                        {titleErrorMsg && <h6 className="text-center mb-2 mt-2 error_message">{titleErrorMsg}</h6>}
                    </div>
                </Popup>

            </HOC>
        )
    }
}



export default categoriesTimeslot;
import React, { Component } from 'react';
import dateFormat from 'dateformat';
import Pagination from "react-js-pagination";
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable'
import Popup from "reactjs-popup"
import { Link } from 'react-router-dom';
import { DieticianService } from '../../config/services/dieticianService';
import { toast } from 'react-toastify';


class Dieticians extends Component {
  constructor(props) {
    super(props);

    this.searchTimeOut = null;
    this.state = {
      openAddDieticiansPopup: false,
      openViewDietician: false,
      viewDieticianDetails: {},
      dietciansList: [],
      searchTimeOut: 0,
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "",
      mobileNo: "",
      experience: "",
      loader: false,
      validationError: "",
      sortType: 1,
      sortField: '',
      pageNo: 1,
      countsPerPage: 15,
      totalDieticians: 20,
    }
  }

  handleAddDieticiansPopup = () => this.setState({ openAddDieticiansPopup: !this.state.openAddDieticiansPopup })
  closeModal = () => this.setState({
    openAddDieticiansPopup: false,
    openViewDietician: false,
    viewDieticianDetails: {},
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    mobileNo: "",
    validationError: "",
    experience: "",
  })


  componentDidMount() {
    
    
    let params = {
      params: {
        count: this.state.countsPerPage,
        sortField:'created',
        sortType:-1,
      }
    }
    this.getAllDieticians(params);
  }

  handlePagination = (pageNumber) => this.setState({ pageNo: pageNumber }, () => {

    let params = {
      params: {
        pageNo: this.state.pageNo - 1,
        count:this.state.countsPerPage
      }
    }

    this.getAllDieticians(params)
  });

  getAllDieticians(params) {
    let self = this;
    this.setState({ loader: true })

    DieticianService.getAllDieticians(params)
      .then(function (res) {
        const response = res.data

        if (response.statusCode == 1) {
          self.setState({ dietciansList: response.responseData.dieticiansList,totalDieticians:response.responseData.totalCount , loader: false })
        }
      })
  }



  handleChange = (e) => {
    const { name, value, type } = e.target
    this.setState({ [name]: value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { firstName, lastName, email, countryCode, mobileNo, experience } = this.state;

    var pattern = /(([a-zA-Z0-9\-?\.?]+)@(([a-zA-Z0-9\-_]+\.)+)([a-z]{2,3}))+$/;
    let validEmail = new RegExp(pattern).test(email);


    let params = {
      firstName,
      lastName,
      email,
      countryCode,
      mobileNo,
      experience,
    }

    if (firstName == "" || lastName == "" || email == "" || countryCode == "" || mobileNo == "" || experience == "") {
      this.setState({ validationError: "All fields are mandatory *" })
    } else if (!validEmail) {
      this.setState({ validationError: "Enter valid email!" })
    } else {
      this.setState({ validationError: "" })
      this.addDietician(params);
    }
  }


  addDietician = (params) => {
    let self = this;
    this.setState({ loader: true })
    DieticianService.addDietician(params)
      .then(function (res) {
        self.setState({ loader: false })
        const response = res.data;
        if (response.statusCode == 1) {
          toast.success(response.responseData.message)
          self.closeModal();
          self.getAllDieticians();
        } else if (response.statusCode == 0) {
          self.setState({ validationError: response.error.errorMessage })
        }
      })
  }




  handleDieticianSearch = (e) => {
    let params = {
      params: {
        count: this.state.countsPerPage,
        search: e.target.value
      }
    }

    if (this.searchTimeOut)
      clearTimeout(this.searchTimeOut);

    this.searchTimeOut = setTimeout(
      function () {
        this.getAllDieticians(params);
      }
        .bind(this),
      400
    );
  }


  handleSortList = (sortField) => {

    const { sortType } = this.state;

    let sortOrder = sortType == 1 ? -1 : 1;

    let self = this;
    this.setState({ sortField: sortField, loader: true, sortType: sortOrder }, () => {
      let params = {
        params: {
          sortField: sortField,
          sortType: sortType
        }
      }
      self.getAllDieticians(params);
    })
  }



  handleChangeStatus(id, status) {
    this.setState({ [id]: true, progress: 0.5, })

    let self = this;
    let obj = {
      dieticianId: id,
      dieticianStatus: status === 1 ? 2 : 1
    }

    DieticianService.changeDieticianStatus(obj)
      .then((res) => {
        if (res.data.statusCode === 1) {
          self.getAllDieticians();
          self.setState({ [id]: false })
        }
      })
  }

  viewDietician = (dieticianDetails) => {
    this.setState({ openViewDietician: true, viewDieticianDetails: dieticianDetails })
  }

  render() {

    const { dietciansList, loader, validationError, sortFieldIcon, sortType, openViewDietician, viewDieticianDetails, countryCode, mobileNo, experience,
      pageNo, countsPerPage, totalDieticians } = this.state;

    return (
      <HOC>
        <div className="body-container-wrapper">
          <div className="body-container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
              <li className="breadcrumb-item">Dieticians</li>
            </ol>

            {
              loader
                ? <div className="loader_wrapper"><LoaderSvg /></div>
                : null
            }

            <div className="users_header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="cm_page_heading">Dieticians</h4>
                  </div>
                  <div className="col-md-3 text-right cm_search">
                    <input
                      type="text"
                      className="form-control m-b-md-15"
                      placeholder="Search by Name or E-mail"
                      name="search"
                      onChange={this.handleDieticianSearch}
                    />
                    <i className="fa fa-search"></i>
                  </div>

                  <div className="col-md-3  m-b-md-15">
                    <button onClick={this.handleAddDieticiansPopup} className="btn btn-primary btn-block">Add Dietician</button>
                  </div>

                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered table-striped text-center">
                <thead>
                  <tr>
                    <th scope="col">Sr. No</th>

                    <th scope="col" className="filter_text">Name

                    <i className={`fa fa-sort-alpha-${sortType == 1 ? 'asc' : 'desc'}`}></i><button onClick={() => this.handleSortList('firstName')}></button>

                    </th>

                    <th scope="col" className="filter_text">E-mail
                    <i className={`fa fa-sort-alpha-${sortType == 1 ? 'asc' : 'desc'}`}></i><button onClick={() => this.handleSortList('email')}></button>
                    </th>

                    <th>Phone No.</th>

                    <th scope="col" className="filter_text">Created Date
                    <i className={`fa fa-sort-alpha-${sortType == 1 ? 'asc' : 'desc'}`}></i><button onClick={() => this.handleSortList('created')}></button>
                    </th>

                    <th scope="col">Action</th>
                  </tr>
                </thead>

                <tbody>

                  {dietciansList.map((dietician, index) => (
                    <tr key={index}>
                      <td>{((index + 1) + ((pageNo-1) * countsPerPage))}</td>
                      <td className="cm_pos_realtive more_padding">
                      <Link className="cm_rating" to={"/dieticians/user-ratings/"+dietician._id}>
                      <i className="fa fa-star" title="See Dietician's ratings"/>
                      </Link>

                      {dietician.firstName + " " + dietician.lastName}
                        <button className="btn btn-info btn-sm cm__btn" onClick={() => this.viewDietician(dietician)}>View</button>
                      </td>
                      <td>{dietician.email}</td>
                      <td>{dietician.mobileNo}</td>
                      <td>{dateFormat(dietician.created, "d mmmm yyyy")}</td>

                      <td>
                        <span className={`cm_ckeckbox_wrapper ${dietician.status === 1 ? 'cm_active' : 'cm_inactive'}`}

                          onClick={() => this.handleChangeStatus(dietician._id, dietician.status)}
                        >
                          <span className="cm_ckeckbox_btn"></span>
                        </span>
                      </td>
                    </tr>
                  ))}


                </tbody>


              </table>
              {!dietciansList.length ? <h3 className="empty_error">Sorry, We couldn't find any content! <span>{this.state.deviceSearch}</span></h3> : null}

            </div>

            <Pagination
              activePage={pageNo}
              itemsCountPerPage={countsPerPage}
              totalItemsCount={totalDieticians}
              pageRangeDisplayed={4}
              onChange={this.handlePagination}
            />


          </div>
        </div>



        <Popup
          open={this.state.openAddDieticiansPopup}
          closeOnDocumentClick
          onClose={this.closeModal}
        >
          <div className="cm_modal pl-5 pr-5">
            <span className="cm_modal_close" onClick={this.closeModal}>
              &times;
            </span>
            <h3 className="text-center mb-4 mt-5">Add New Dietician</h3>
            <div className="text-danger text-center">{validationError}</div>

            <form onSubmit={this.handleSubmit} className="mb-5">

              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                  name="firstName"
                />
              </div>

              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                  name="lastName"
                />
              </div>

              <div className="form-group">
                <label>Country Code</label>
                <input
                  type="number"
                  className="form-control"
                  onChange={this.handleChange}
                  name="countryCode"
                  value={countryCode}
                  onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                />
              </div>


              <div className="form-group">
                <label>Phone number</label>
                <input
                  type="number"
                  className="form-control"
                  onChange={this.handleChange}
                  name="mobileNo"
                  value={mobileNo}
                  onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                />
              </div>

              <div className="form-group">
                <label>E-mail</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                  name="email"
                />
              </div>

              <div className="form-group">
                <label>Experience (in years)</label>
                <input
                  type="number"
                  className="form-control"
                  onChange={this.handleChange}
                  name="experience"
                  value={experience}
                  onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                />
              </div>

              <button className="btn btn-primary btn-block mt-4">Save</button>

            </form>
            {
              this.state.errors
                ? <div className="loader_wrapper"><LoaderSvg /></div>
                : null
            }


          </div>
        </Popup>

        <Popup
          open={openViewDietician}
          onClose={this.closeModal}
        >
          <div className="card card_style">
            <div className="card-body text-center">
              <h4 className="card-title">Name: {viewDieticianDetails.firstName + " " + viewDieticianDetails.lastName}</h4>
              <p className="card-text">Email: {viewDieticianDetails.email}</p>
              <p className="card-text">Contact: {viewDieticianDetails.countryCode + " " + viewDieticianDetails.mobileNo}</p>
              <p className="card-text">Experience: {viewDieticianDetails.experience} yrs</p>


            </div>
          </div>

        </Popup>

      </HOC>



    );
  }
}

export default Dieticians;
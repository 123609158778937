import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { LoaderSvg } from '../Reusable';
import { updateVendor } from '../../config/services/vendorService';
import { getAllPincodes, getAllCitys, getAllStates } from '../../config/services/servicePincodes'
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
const { removeDuplicates } = require("../utils/Util.js");

const EditVendor = ({ hide, editVendorModal, selectedVendor, getVendor }) => {

  const selectedPincodes = selectedVendor.pincode && removeDuplicates(selectedVendor.pincode, 'pincode').map(item => ({
    label: item.pincode, value: item._id, state: item.state,
    city: item.city
  }));
  const selectedCity =  selectedVendor.pincode && removeDuplicates(selectedVendor.pincode.filter((item => selectedVendor.city.includes(item.city))), "city").map(item => ({ label: item.city, value: item.city, state: item.state }));
  const selectedState = selectedVendor && selectedVendor.state.map(item => ({ label: item, value: item }));

  let initialState = {
    firstName: selectedVendor.firstName,
    lastName: selectedVendor.lastName,
    city: selectedCity,
    state: selectedState,
    tat: selectedVendor.tat,
    email: selectedVendor.email,
    mobileNo: selectedVendor.mobileNo,
    selectedPincode: {},
    states: [],
    city: [],
    pincode: [],
    service: {},
    demoGraphic: [],
    error: '',
  }

  const [state, setState] = useState({ ...initialState });
  const [pincodes, setPincodes] = useState([]);
  const [selectedStateValue, setSelectedState] = useState(selectedState);
  const [selectedCityValue, setselectedCity] = useState(selectedCity);
  const [selectedPincodesValue, setselectedPincode] = useState(selectedPincodes);
  const [newError, setNewError] = useState({});
  const [renderValue, setRenderValue] = useState({});


  const handleChange = (e, value, selector) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    switch (selector) {
      case 'firstName': newError.firstName = value.length < 3 ? 'Firstname must be 3 characters long!' : '';
        break;
      case 'lastName': newError.lastName = value.length < 3 ? 'Lastname must be 3 characters long!' : '';
        break;
      case 'mobileNo': newError.mobileNo = value.length > 9 && value.length < 11 ? '' : 'Please enter a valid mobile!'
        break;
      default:
        break;
    }
    setState({ ...state, [selector]: value })
    setNewError(newError)
  }

  useEffect(() => {
    if (selectedStateValue && selectedStateValue.length === 0) {
      setselectedCity([]);
      setselectedPincode([]);
    }
  }, [selectedStateValue])

  useEffect(() => {
    if (selectedCityValue && selectedCityValue.length === 0) {
      console.log('test')
      setselectedPincode([]);
    }
  }, [selectedCityValue])

  useEffect(() => {
    if (selectedStateValue && selectedStateValue.length > 1 && selectedCityValue && selectedCityValue.length === 0 && selectedPincodesValue && selectedPincodesValue.length > 0) {
      console.log("text to check the pincode effect")
      setselectedPincode([]);
    }
  }, [selectedPincodesValue])

  const handleSubmit = () => {

    const matcherForFeilds = {
      "firstName": "First name",
      "lastName": "Last name",
      "mobileNo": "Mobile",
    }


    // this part of the validation is for the plain text and number feilds 
    let feildsToValidate = ["firstName", "lastName", "mobileNo"]
    feildsToValidate.forEach(feild => {
      if (state[feild] === '' || state[feild] === null || state[feild] === undefined) {
        newError[feild] = `${matcherForFeilds[feild]} cannot be left blank`
      }
    })


    // this part of validation is for the dropdowns
    let dropDownFeilds = ["selectedCityValue", "selectedStateValue", "selectedPincodesValue"];
    dropDownFeilds.forEach(feild => {
      if (feild === "selectedCityValue") {
        newError[feild] = selectedCityValue && selectedCityValue.length > 0 ? "" : 'City Cannot be blank'
      } else if (feild === "selectedStateValue") {
        newError[feild] = selectedStateValue && selectedStateValue.length > 0 ? "" : 'State Cannot be blank'
      } else {
        newError[feild] = selectedPincodesValue && selectedPincodesValue.length > 0 ? "" : 'Pincode Cannot be blank'
      }
    })

    for (var key in newError) {
      if (newError[key] === "") {
        delete newError[key]
      }
    }

    setRenderValue(!renderValue)

    if (Object.keys(newError).length < 1) {
      const params = {
        firstName: state.firstName,
        lastName: state.lastName,
        mobileNo: state.mobileNo,
        countryCode: state.countycode,
        city: selectedCityValue.map(city => city.label),
        state: selectedStateValue.map(state => state.label),
        pincode: selectedPincodesValue.map(pincode => pincode.value),
        email: state.email,
        countryCode: selectedVendor.countryCode,
        user_id: selectedVendor._id
      }
      updateVendor(params).then(response => {
        if (response && response.data.statusCode === 1) {
          hide();
          getVendor();
          toast.success(`${params.firstName + params.lastName} Edited successfull!`)
        } else {
          setState({ ...state, error: 'Vendor Not updated!' })
          toast.error("Vendor Not updated !")
        }
      })
    }
  }

  // for state 
  const getStateData = (val) => {

    let params = { search: val, sortField: "_id", sortType: 1 }

    return getAllStates({ params }).then(res => {
      if (res && res.data.statusCode === 1) {
        let data = res.data.responseData
        let states = []
        if (data.result && data.result.length > 0) {
          data.result.forEach(state => {
            states.push({
              label: state._id,
              value: state._id
            })
          })
        }
        setState({
          ...state,
          "states": states
        })
        return states
      } else if (res.data.statusCode == 0) {
        console.log(res.data.error.errorMessage)
      }
    })
  }

  const loadStateServices = (key) => {
    return getStateData(key);
  }

  const serviceStateSelect = (service) => {
    let city = [];
    let selectedState = []
    service && service.map(item => {
      selectedState.push(item);
      city.push(item.label)
    })
    if (city.length > 0) { getCityData("", city) }
    setSelectedState(selectedState);
    setNewError({ ...newError, "selectedStateValue": '' })
  }

  // for city
  const getCityData = (val, cities) => {

    let params = { search: val, sortField: "_id", sortType: 1 }

    let selectedStates = selectedStateValue.map(state => state.label)

    if (selectedState) {
      params.states = selectedStates.map(city => city).join()
    }

    if (val) {
      params.citySearch = val
    }
    cities && setselectedCity(selectedCityValue.filter(item => cities.includes(item.state)));
    cities && setselectedPincode(selectedPincodesValue.filter(item => cities.includes(item.state)));
    return getAllCitys({ params }).then(res => {
      if (res && res.data.statusCode === 1) {
        let data = res.data.responseData
        let citys = []
        if (data.result && data.result.length > 0) {
          data.result.forEach(state => {
            citys.push({
              label: state._id,
              value: state._id,
              state: state.state.state,
            })
          })
        }
        setState({ ...state, "citys": citys })
        return citys
      } else if (res.data.statusCode == 0) {
        console.log(res.data.error.errorMessage)
      }
    })
  }

  const loadCityServices = (key) => {
    return getCityData(key);
  }

  const serviceCitySelect = (service) => {
    let pincodes = [];
    let selectedCity = []
    service && service.map(item => {
      selectedCity.push(item);
      pincodes.push(item.label);
    });
    console.log({ pincodes })
    if (pincodes.length > 0) { getPincodeData("", pincodes, selectedCity) }
    setselectedCity(selectedCity);
    setNewError({ ...newError, "selectedCityValue": '' })
  }


  // for pincode

  const getPincodeData = (val, pincodes, selectedCity) => {
    setselectedPincode(selectedPincodesValue.filter(item => pincodes.includes(item.city)));
    let citys = pincodes && pincodes.length > 0 ? pincodes.concat(selectedCity.map(city => city.label).filter(item => pincodes.indexOf(item) < 0)) : selectedCityValue.map(city => city.label)
    let states = selectedStateValue.length > 0 ? selectedStateValue.map(item => item.label).concat(selectedState.map(state => state.label).filter(item => selectedStateValue.map(item => item.label).indexOf(item) < 0)) : selectedState.map(state => state.label);

    let params = {     
      "pincodeSearch": '',
      "pincodes": [],
      "citys": []
    }

    if (val) {
      params["pincodeSearch"] = val
    }

    if (citys && citys.length > 0) {
      params.citys = states.join();
    }

    if (states && states.length > 0) {
      params.pincodes = citys.join()
    }
    // console.log(params);
    return getAllPincodes({ params }).then(res => {
      if (res && res.data.statusCode === 1) {
        let data = res.data.responseData
        let result = data[0].result
        let pincodes = []
        result.map(service => {
          pincodes.push({
            label: service.pincode,
            value: service._id,
            state: service.state,
            city: service.city
          })
        })
        setState({ ...state, pincode: pincodes })
        setselectedPincode(removeDuplicates([...pincodes], "label"))
        return pincodes
      } else if (res.data.statusCode == 0) {
        console.log(res.data.error.errorMessage)
      }
    })
  }

  const loadServicesPincode = (key) => { return getPincodeData(key); }

  const servicePincodeSelect = (service) => {
    let items = service && service.map((item) => (item))
    setselectedPincode(items);
    setNewError({ ...newError, "selectedPincodesValue": '' })
  }

  return (
    <Popup
      open={editVendorModal}
      closeOnDocumentClick={false}
      onClose={hide}
      closeOnEscape
      lockScroll={true}
    >
      <div className="cm_modal pl-5 pr-5">
        <span className="cm_modal_close" onClick={() => hide()}>
          &times;
            </span>
        <h3 className="text-center mb-4 mt-5">Edit Vendor</h3>
        {state && state.error &&
          <div
            className="text-danger text-center"
          >
            {state.error}
          </div>}

        <div className="form-group">
          <label>First Name</label>
          <input
            type="text"
            className="form-control"
            onChange={(e) => handleChange(e, e.target.value, 'firstName')}
            value={state.firstName}
            placeholder="firstName"
          />
          {newError && newError["firstName"] && <label style={{ color: "red" }}>{newError["firstName"]}</label>}
        </div>

        <div className="form-group">
          <label>Last Name</label>
          <input
            type="text"
            className="form-control"
            onChange={(e) => handleChange(e, e.target.value, 'lastName')}
            value={state.lastName}
            placeholder="lastName"
          />
          {newError && newError["lastName"] && <label style={{ color: "red" }}>{newError["lastName"]}</label>}
        </div>

        <div className="form-group">
          <label>Email</label>
          <input
            type="text"
            className="form-control"
            onChange={(e) => handleChange(e.target.value, 'email')}
            value={state.email}
            placeholder="email"
            disabled
          />
          {newError && newError["email"] && <label style={{ color: "red" }}>{newError["email"]}</label>}
        </div>

        <div className="form-group">
          <label>Mobile</label>
          <input
            type="number"
            className="form-control"
            onChange={(e) => handleChange(e, e.target.value, 'mobileNo')}
            value={state.mobileNo}
            placeholder="Mobile No"
          />
          {newError && newError["mobileNo"] && <label style={{ color: "red" }}>{newError["mobileNo"]}</label>}
        </div>

        <div className="form-group">
          <label>State</label>
          <AsyncSelect
            isMulti
            isDisabled={false}
            value={selectedStateValue}
            cacheOptions
            loadOptions={loadStateServices}
            // defaultOptions={selectedStateValue}
            onChange={(val) => serviceStateSelect(val)}
            placeholder="state"
          />
          {newError && newError["selectedStateValue"] && <label style={{ color: "red" }}>{newError["selectedStateValue"]}</label>}
        </div>

        <div className="form-group">
          <label>City</label>
          <AsyncSelect
            isMulti
            isDisabled={Array.isArray(selectedStateValue) && selectedStateValue.length > 0 ? false : true}
            value={selectedCityValue}
            cacheOptions
            loadOptions={loadCityServices}
            // defaultOptions={selectedCityValue}
            onChange={(val) => serviceCitySelect(val)}
            placeholder="city"
          />
          {newError && newError["selectedCityValue"] && <label style={{ color: "red" }}>{newError["selectedCityValue"]}</label>}
        </div>

        <div className="form-group">
          <label>Pincode</label>
          <AsyncSelect
            isMulti
            isDisabled={Array.isArray(selectedCityValue) && selectedCityValue.length > 0 ? false : true}
            value={selectedPincodesValue}
            loadOptions={loadServicesPincode}
            // defaultOptions={selectedPincodesValue}
            onChange={(val) => servicePincodeSelect(val)}
            placeholder="pincodes"
          />
          {newError && newError["selectedPincodesValue"] && <label style={{ color: "red" }}>{newError["selectedPincodesValue"]}</label>}
        </div>
        <button onClick={handleSubmit} className="btn btn-primary btn-block mt-4">Save</button>
      </div>
    </Popup>
  )
}

export default EditVendor;

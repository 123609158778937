import React, { Component } from 'react';
import Popup from "reactjs-popup"
import { forgotPassword } from '../../config/services/changePasswordService';

import Logo from '../../assets/images/fitplusLogo.png';

class ForgetPassword extends Component {
    constructor(props){
        super(props);

        this.state = {
            email: '',
            showScssesMsg: false,
            errorMsg: '',
            responseMessage:''
        }
    }

    handleChange = event => this.setState({email: event.target.value, errorMsg: ''});

    handleSubmit = event => {
        event.preventDefault();
        if(this.state.email === '' || this.state.email === undefined) {
            this.setState({errorMsg: 'Email is Require'})
            return
        }
            let obj = {
                email: this.state.email
            }

            forgotPassword(obj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({showScssesMsg: true,responseMessage:res.data.responseData.message });
                    this.openModal();
                }else if(res.data.statusCode == 0){
                    this.setState({showScssesMsg: true,responseMessage:res.data.error.errorMessage });
                }
              })
    }


    openModal = () => this.setState({showScssesMsg: true});
    
    closeModal = () => {
        this.setState({showScssesMsg: false});
        this.props.history.push('/login/');
    }


  render() {

    if(localStorage.getItem('accessToken')){
        this.props.history.push('/dashboard')
    }
    return (
        <div className="login-wrapper">
            <div className="container h-100">
                <div className="row h-100">
                    <div className="col-md-6 m-auto">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-title">
                                    <img src={Logo} alt="Fitstar" />
                                    <h3>Forgot Password</h3>
                                </div>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <input
                                            onChange={this.handleChange}
                                            className={`form-control mb-4 ${this.state.errorMsg ? 'is-invalid': ''}`}
                                            type="email" name="email"
                                            id="exampleEmail"
                                            placeholder="Enter Email" />

                                        {this.state.errorMsg ? <div className="cm_alert_danger">{this.state.errorMsg}</div> : null}
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col"><button className="btn btn-primary">Send</button></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <Popup
                open={this.state.showScssesMsg}
                closeOnDocumentClick
                onClose={this.closeModal}
            >
                <div className="cm_modal">
                    <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                    <h3 className="text-center"> {this.state.email}</h3>
                    <p className="text-center">{this.state.responseMessage}</p>
                </div>
            </Popup>


        </div>
    );
  }
}

export default ForgetPassword;
export default (BMR, questionAnswer=[]) => {
    if( BMR != 'No Data') {
        let recommendedCalories;
        const result = questionAnswer.find(({question})=> question === 'How could you describe your daily activity levels?')
    
        if (result) {
            // given by garima
            // Caloric requirement for wt maintenance
            // BMR * 1.2 for sedentary activity
            // BMR * 1.375 for light activity
            // BMR * 1.55 for moderate activity
            // BMR * 1.75 for heavy activity

            switch (result.answer[0]) {
                case 'Sedentary':
                    recommendedCalories = BMR * 1.2
                    break;
                case 'Moderate':
                    recommendedCalories = BMR * 1.55
                    break;
                case 'Active':
                    recommendedCalories = BMR * 1.375                    
                    break;
                case 'Very Active':
                    recommendedCalories = BMR * 1.75
                    break;
                default:
                    break
            }
            return (recommendedCalories.toFixed(2) + " Calories/Day")
        } else {
            return ('-')
        }
    } else {
        return ('-')
    }
}

import React, { Component } from 'react';
import { getUserDietPlan } from '../../config/services/dietPlanService';
import { getAllFoods } from '../../config/services/FoodService';
import { toast } from 'react-toastify';
import FoodPlaceholder from '../../assets/images/FoodPlaceholder.jpg';
import EditDietPlan from './EditDietPlan';
import HOC from '../../HOC';
import { Link } from 'react-router-dom';
import { LoaderSvg } from '../Reusable'
import Chip from '@material-ui/core/Chip';
import dateFormat from 'dateformat';

const CardWrapper = props => <div className="col mb-5">{props.children}</div>;

class ViewDietPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.match.params.id,
            dietPlanDetails: {},
            morningSnacksList: [],
            breakfastList: [],
            lunchList: [],
            dinnerList: [],
            midEveningDietList: [],
            midMorningDietList: [],
            bedTimeDietList: [],
            openEditPopUp: false,
            foodItems: [],
            loader: false,
        }
    }

    componentDidMount() {
        this.getUserDietPlanFunc();
        this.getAllFoodItems();
    }



    getAllFoodItems = () => {
        let self = this;
        getAllFoods()
            .then(function (res) {
                let response = res.data;
                if (response.statusCode == 1) {
                    var foodItems = [];
                    response.responseData.result.map((food, index) => {
                        foodItems.push({
                            label: food.foodName + " (" + food.calorieContent + " cal)",
                            value: food._id,
                            calorie: food.calorieContent,
                            protein: food.proteinContent,
                            fat: food.fatContent,
                            carb: food.carbContent,
                            fibre: food.fibreContent,
                            foodUnit: food.foodUnit
                        });
                    });
                    self.setState({ foodItems })
                } else if (response.statusCode == 0) {
                    console.log(res, "Getting Food items");
                }
            })
    }


    getUserDietPlanFunc = () => {
        let param = {
            params: { dietOfUserId: this.props.match.params.id }
        }

        let self = this;
        this.setState({ loader: true })
        getUserDietPlan(param)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    const { result } = response.responseData;
                    self.setState({
                        morningSnacksList: result ? result.morningSnacksOptions : [],
                        breakfastList: result ? result.breakfastOptions : [],
                        lunchList: result ? result.lunchOptions : [],
                        dinnerList: result ? result.dinnerOptions : [],
                        midEveningDietList: result ? result.midEveningOptions : [],
                        midMorningDietList: result ? result.midMorningOptions : [],
                        bedTimeDietList: result ? result.bedTimeOptions : [],
                        dietPlanDetails: {
                            dietPlanId: result ? result._id : "",
                            perDayCalories: result ? result.perDayCalories : "",
                            recommendedMorningSnacksCalories: result ? result.recommendedMorningSnacksCalories : 0,
                            recommendedBreakfastCalories: result ? result.recommendedBreakfastCalories : 0,
                            recommendedMidMorningCalories: result ? result.recommendedMidMorningCalories : 0,
                            recommendedLunchCalories: result ? result.recommendedLunchCalories : 0,
                            recommendedMidEveningCalories: result ? result.recommendedMidEveningCalories : 0,
                            recommendedDinnerCalories: result ? result.recommendedDinnerCalories : 0,
                            recommendedBedTimeCalories: result ? result.recommendedBedTimeCalories : 0,
                            morningSnacksDietTime: result ? result.morningSnacksDietTime : "00:00",
                            breakfastDietTime: result ? result.breakfastDietTime : "00:00",
                            midMorningDietTime: result ? result.midMorningDietTime : "00:00",
                            lunchDietTime: result ? result.lunchDietTime : "00:00",
                            midEveningDietTime: result ? result.midEveningDietTime : "00:00",
                            dinnerDietTime: result ? result.dinnerDietTime : "00:00",
                            bedTimeDietTime: result ? result.bedTimeDietTime : "00:00",
                            savedAsTemplate: result ? result.savedAsTemplate : '',
                            created: result ? result.created : '',
                            updated: result ? result.updated : '',
                        },
                        loader: false,
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    handleOpenEditPoup = () => this.setState({ openEditPopUp: !this.state.openEditPopUp });
    handleClose = () => {
        this.setState({ openEditPopUp: false })
    }


    render() {

        const {
            loader,
            morningSnacksList,
            breakfastList,
            lunchList,
            dinnerList,
            midEveningDietList,
            midMorningDietList,
            bedTimeDietList,
            dietPlanDetails,
        } = this.state;

        return (
            <HOC>

                {loader && <div className="loader_wrapper"><LoaderSvg /></div>}


                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dietPlans">Diet Plans</Link></li>
                        </ol>

                        {morningSnacksList.length || breakfastList.length || midMorningDietList.length || lunchList.length || midEveningDietList.length || dinnerList.length || bedTimeDietList.length ?
                            <div className="col mb-4">
                                <div className='align_date'>
                                    <Chip label={"Assigned On " + dateFormat(dietPlanDetails.created, "dddd, mmmm dS, yyyy")} color="secondary" variant="outlined" />

                                    <Chip label={"Updated On " + dateFormat(dietPlanDetails.updated, "dddd, mmmm dS, yyyy")} color="secondary" variant="outlined"/>
                                </div>


                                <h6 className="cm_pos_realtive">Total calorie target: {dietPlanDetails.perDayCalories}/day
                                   <button className="btn btn-primary btn-sm __btn_abs" onClick={this.handleOpenEditPoup} >Edit Diet plan<i className="fa fa-pencil-square-o ml-1" />
                                    </button>
                                </h6>
                            </div> : null}



                        <CardWrapper>
                            <div className="mb-4 cm_patti">
                                <h3 className="cm_pos_rel">Pre-Breakfast</h3>
                                <h6>Calorie target- {dietPlanDetails.recommendedMorningSnacksCalories} cal
                                &nbsp;&nbsp;&nbsp;  Timing - {dietPlanDetails.morningSnacksDietTime}</h6>
                            </div>


                            {!morningSnacksList.length ?
                                <p className="text-danger">No food allotted</p> :
                                <div className="row">
                                    {morningSnacksList.map((options, i) => (
                                        <div className="col-sm-12" key={i} >
                                            <span className="pull_center"> Option {i + 1} &nbsp;&nbsp;&nbsp; Total Calories- {options.morningSnacksDietCalorie ? options.morningSnacksDietCalorie : ""} cal</span>

                                            <div className="row">
                                                {options.morningSnacksDiet.map((foodItem, index) => (
                                                    <div className="p-3" key={index}>
                                                        <div className="about_food">
                                                            <img src={foodItem.foodImage ? foodItem.foodImage : FoodPlaceholder} alt="" />

                                                            <div className="food_content_bg">
                                                                <h4>{foodItem.foodName}</h4>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <h6>{foodItem.calorieContent}cal</h6></div>
                                                                    <div className="col"> <h6>{foodItem.quantity}.0 {foodItem.unit}</h6></div>
                                                                </div>


                                                                <div className="row">
                                                                    <div className="col">
                                                                        <div className=""> <p>Protein</p></div>
                                                                        <div className=""> <p>{foodItem.proteinContent} gm</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className=""> <p>Fat</p></div>
                                                                        <div className=""> <p>{foodItem.fatContent} gm</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className=""> <p>carbs</p></div>
                                                                        <div className=""> <p>{foodItem.carbContent} gm</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className="">
                                                                            <p>Fibre</p></div>
                                                                        <div className=""> <p>{foodItem.fibreContent} gm</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>}
                        </CardWrapper>


                        <CardWrapper>

                            <div className="mb-4 cm_patti">
                                <h3 className="cm_pos_rel">Breakfast</h3>
                                <h6>Calorie target- {dietPlanDetails.recommendedBreakfastCalories} cal&nbsp;&nbsp;&nbsp;  Timing - {dietPlanDetails.breakfastDietTime}</h6>
                            </div>

                            {!breakfastList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="row">
                                    {breakfastList.map((options, i) => (
                                        <div className="col-sm-12" key={i} >
                                            <span className="pull_center"> Option {i + 1} &nbsp;&nbsp;&nbsp; Total Calories- {options.breakfastDietCalorie ? options.breakfastDietCalorie : ""} cal</span>
                                            <div className="row">
                                                {options.breakfastDiet.map((foodItem, index) => (
                                                    <div className="p-3" key={index}>
                                                        <div className="about_food">
                                                            <img src={foodItem.foodImage ? foodItem.foodImage : FoodPlaceholder} alt="" />
                                                            <div className="food_content_bg">
                                                                <h4>{foodItem.foodName}</h4>
                                                                <div className="row">
                                                                    <div className="col"> <h6>{foodItem.calorieContent} cal</h6></div>
                                                                    <div className="col"> <h6>Qty. {foodItem.quantity}.0 {foodItem.unit}</h6></div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col">
                                                                        <div className=""> <p>Protein</p></div>
                                                                        <div className=""> <p>{foodItem.proteinContent} gm</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className=""> <p>Fat</p></div>
                                                                        <div className=""> <p>{foodItem.fatContent} gm</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className=""> <p>carbs</p></div>
                                                                        <div className=""> <p>{foodItem.carbContent} gm</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className=""> <p>Fibre</p></div>
                                                                        <div className=""> <p>{foodItem.fibreContent} gm</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>}
                        </CardWrapper>


                        <CardWrapper>

                            <div className="mb-4 cm_patti">
                                <h3 className="cm_pos_rel">Mid Morning</h3>
                                <h6>Calorie target- {dietPlanDetails.recommendedMidMorningCalories} cal&nbsp;&nbsp;&nbsp;  Timing - {dietPlanDetails.midMorningDietTime}</h6>
                            </div>

                            {!midMorningDietList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="row">
                                    {midMorningDietList.map((options, i) => (
                                        <div className="col-sm-12" key={i} >

                                            <span className="pull_center">Option {i + 1}  &nbsp;&nbsp;&nbsp; Total Calories- {options.midMorningDietCalorie ? options.midMorningDietCalorie : ""} cal</span>

                                            <div className="row">
                                                {options.midMorningDiet.map((foodItem, index) => (
                                                    <div className="p-3" key={index}>
                                                        <div className="about_food">
                                                            <img src={foodItem.foodImage ? foodItem.foodImage : FoodPlaceholder} alt="" />
                                                            <div className="food_content_bg">
                                                                <h4>{foodItem.foodName}</h4>
                                                                <div className="row">
                                                                    <div className="col"> <h6>{foodItem.calorieContent} cal</h6></div>
                                                                    <div className="col"> <h6>Qty. {foodItem.quantity}.0 {foodItem.unit}</h6></div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col">
                                                                        <div className=""> <p>Protein</p></div>
                                                                        <div className=""> <p>{foodItem.proteinContent} gm</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className=""> <p>Fat</p></div>
                                                                        <div className=""> <p>{foodItem.fatContent} gm</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className=""> <p>carbs</p></div>
                                                                        <div className=""> <p>{foodItem.carbContent} gm</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className=""> <p>Fibre</p></div>
                                                                        <div className=""> <p>{foodItem.fibreContent} gm</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></div>))}
                                            </div>
                                        </div>
                                    ))}
                                </div>}
                        </CardWrapper>


                        <CardWrapper>

                            <div className="mb-4 cm_patti">
                                <h3 className="cm_pos_rel">Lunch</h3>  <h6>Calorie target- {dietPlanDetails.recommendedLunchCalories} cal&nbsp;&nbsp;&nbsp;  Timing - {dietPlanDetails.lunchDietTime}</h6>
                            </div>

                            {!lunchList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="row">
                                    {lunchList.map((options, i) => (
                                        <div className="col-sm-12" key={i} >

                                            <span className="pull_center">Option {i + 1}&nbsp;&nbsp;&nbsp; Total Calories- {options.lunchDietCalorie ? options.lunchDietCalorie : ""} cal</span>

                                            <div className="row">
                                                {options.lunchDiet.map((foodItem, index) => (
                                                    <div className="p-3" key={index}>
                                                        <div className="about_food">
                                                            <img src={foodItem.foodImage ? foodItem.foodImage : FoodPlaceholder} alt="" />
                                                            <div className="food_content_bg">
                                                                <h4>{foodItem.foodName}</h4>
                                                                <div className="row">
                                                                    <div className="col"> <h6>{foodItem.calorieContent} cal</h6></div>
                                                                    <div className="col"> <h6>Qty. {foodItem.quantity}.0 {foodItem.unit}</h6></div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col">
                                                                        <div className=""> <p>Protein</p></div>
                                                                        <div className=""> <p>{foodItem.proteinContent} gm</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className=""> <p>Fat</p></div>
                                                                        <div className=""> <p>{foodItem.fatContent} gm</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className=""> <p>carbs</p></div>
                                                                        <div className=""> <p>{foodItem.carbContent} gm</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className=""> <p>Fibre</p></div>
                                                                        <div className=""> <p>{foodItem.fibreContent} gm</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>}
                        </CardWrapper>

                        <CardWrapper>


                            <div className="mb-4 cm_patti">
                                <h3 className="cm_pos_rel">Mid Evening</h3>
                                <h6>Calorie target- {dietPlanDetails.recommendedMidEveningCalories} cal&nbsp;&nbsp;&nbsp;  Timing - {dietPlanDetails.midEveningDietTime}</h6>
                            </div>


                            {!midEveningDietList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="row">
                                    {midEveningDietList.map((options, i) => (
                                        <div className="col-sm-12" key={i} >

                                            <span className="pull_center">Option {i + 1}&nbsp;&nbsp;&nbsp; Total Calories- {options.midEveningDietCalorie ? options.midEveningDietCalorie : ""} cal</span>

                                            <div className="row">
                                                {options.midEveningDiet.map((foodItem, index) => (
                                                    <div className="p-3" key={index}>
                                                        <div className="about_food">
                                                            <img src={foodItem.foodImage ? foodItem.foodImage : FoodPlaceholder} alt="" />
                                                            <div className="food_content_bg">
                                                                <h4>{foodItem.foodName}</h4>
                                                                <div className="row">
                                                                    <div className="col"> <h6>{foodItem.calorieContent} cal</h6></div>
                                                                    <div className="col"> <h6>Qty. {foodItem.quantity}.0 {foodItem.unit}</h6></div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <div className=""> <p>Protein</p></div>
                                                                        <div className=""> <p>{foodItem.proteinContent} gm</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className=""> <p>Fat</p></div>
                                                                        <div className=""> <p>{foodItem.fatContent} gm</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className=""> <p>carbs</p></div>
                                                                        <div className=""> <p>{foodItem.carbContent} gm</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className=""> <p>Fibre</p></div>
                                                                        <div className=""> <p>{foodItem.fibreContent} gm</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>}
                        </CardWrapper>


                        <CardWrapper>

                            <div className="mb-4 cm_patti">
                                <h3 className="cm_pos_rel">Dinner</h3>
                                <h6>Calorie target- {dietPlanDetails.recommendedDinnerCalories} cal&nbsp;&nbsp;&nbsp;  Timing - {dietPlanDetails.dinnerDietTime}</h6>
                            </div>

                            {!dinnerList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="row">
                                    {dinnerList.map((options, i) => (
                                        <div className="col-sm-12" key={i} >

                                            <span className="pull_center">Option {i + 1}&nbsp;&nbsp;&nbsp; Total Calories- {options.dinnerDietCalorie ? options.dinnerDietCalorie : ""} cal</span>

                                            <div className="row">
                                                {options.dinnerDiet.map((foodItem, index) => (
                                                    <div className="p-3" key={index}>
                                                        <div className="about_food">                                      <img src={foodItem.foodImage ? foodItem.foodImage : FoodPlaceholder} alt="" />
                                                            <div className="food_content_bg">
                                                                <h4>{foodItem.foodName}</h4>
                                                                <div className="row">
                                                                    <div className="col"> <h6>{foodItem.calorieContent} cal</h6></div>
                                                                    <div className="col"> <h6>Qty. {foodItem.quantity}.0 {foodItem.unit}</h6></div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <div className=""> <p>Protein</p></div>
                                                                        <div className=""> <p>{foodItem.proteinContent} gm</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className=""> <p>Fat</p></div>
                                                                        <div className=""> <p>{foodItem.fatContent} gm</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className=""> <p>carbs</p></div>
                                                                        <div className=""> <p>{foodItem.carbContent} gm</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className=""> <p>Fibre</p></div>
                                                                        <div className=""> <p>{foodItem.fibreContent} gm</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></div>))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }
                        </CardWrapper>



                        <CardWrapper>
                            <div className="mb-4 cm_patti">
                                <h3 className="cm_pos_rel">Bed Time</h3>
                                <h6>Calorie target- {dietPlanDetails.recommendedBedTimeCalories} cal&nbsp;&nbsp;&nbsp;  Timing - {dietPlanDetails.bedTimeDietTime}</h6>
                            </div>

                            {!bedTimeDietList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="row">
                                    {bedTimeDietList.map((options, i) => (
                                        <div className="col-sm-12" key={i} >

                                            <span className="pull_center">Option {i + 1}&nbsp;&nbsp;&nbsp; Total Calories- {options.bedTimeDietCalorie ? options.bedTimeDietCalorie : ""} cal</span>

                                            <div className="row">
                                                {options.bedTimeDiet.map((foodItem, index) => (
                                                    <div className="p-3" key={index}>
                                                        <div className="about_food">
                                                            <img src={foodItem.foodImage ? foodItem.foodImage : FoodPlaceholder} alt="" />
                                                            <div className="food_content_bg">
                                                                <h4>{foodItem.foodName}</h4>
                                                                <div className="row">
                                                                    <div className="col"> <h6>{foodItem.calorieContent} cal</h6></div>
                                                                    <div className="col"> <h6>Qty. {foodItem.quantity}.0 {foodItem.unit}</h6></div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <div className=""> <p>Protein</p></div>
                                                                        <div className=""> <p>{foodItem.proteinContent} gm</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className=""> <p>Fat</p></div>
                                                                        <div className=""> <p>{foodItem.fatContent} gm</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className=""> <p>carbs</p></div>
                                                                        <div className=""> <p>{foodItem.carbContent} gm</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className=""> <p>Fibre</p></div>
                                                                        <div className=""> <p>{foodItem.fibreContent} gm</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>}
                        </CardWrapper>

                    </div>
                </div>

                <EditDietPlan handleClose={this.handleClose} getUserDietPlan={this.getUserDietPlanFunc} {...this.state} />

            </HOC>
        )
    }


}

export default ViewDietPlan;


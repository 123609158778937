import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import Login from './components/login/Login';
import ForgetPassword from './components/forgetPassword/ForgetPassword';
import Layout from './components/Layout';
import reportWebVitals from './reportWebVitals';


const history = createBrowserHistory();
history.listen((location, action) => {
  window.scrollTo(0, 0)

function sendToAnalytics(metric) {
  console.log("metric" , metric);
}

reportWebVitals(sendToAnalytics);
})


class Routers extends Component {
  render() {
    let accessToken = localStorage.getItem('accessToken')
    axios.defaults.headers.common['accessToken'] = `${accessToken}`;
    axios.defaults.headers.common['Authorization'] = `${'Basic Zml0c3Rhcl9hZG1pbjphZG1pbkBmaXRzdGFy'}`;
    axios.defaults.headers.common['platform'] = 3;

    return (
      <Router history={history}>
        <div className="app">
          <Switch>
          <Route path="/login" component={Login} />
          <Route path="/forget-password" component={ForgetPassword} />
          <Route exact path="/" component={Layout} />
          <Route path="/dashboard" component={Layout} />
          <Route exact path="/users" component={Layout} />
          <Route exact path="/users/Graphs/:id" component={Layout} />
          <Route path="/profile/:id" component={Layout} />
          <Route path="/answers/:id" component={Layout} />
          <Route path="/edit-device/:id" component={Layout} />
          <Route path="/edit-profile" component={Layout} />
          <Route path="/change-password" component={Layout} />
          <Route path="/device" component={Layout} />
          <Route path="/coupons" component={Layout} />
          <Route path="/products" component={Layout} />
          <Route path="/edit-product/:id" component={Layout} />
          <Route path="/chart/:id" component={Layout} />
          <Route path="/dieticians" component={Layout} />
          <Route path="/food-category" component={Layout} />
          <Route path="/helpVideoCategory" component={Layout} />
          <Route path="/helpVideos" component={Layout} />
          <Route exact path="/foods" component={Layout} />
          <Route exact path="/foods/foodRequests" component={Layout} />
          <Route path="/questionaire" component={Layout} />
          <Route path="/exercise-category" component={Layout} />
          <Route path="/exercise" component={Layout} />
          <Route exact path="/dieticians/user-ratings/:id" component={Layout} />
          <Route exact path="/dietPlanTempelate" component={Layout} />
          <Route exact path="/fitnessPlanTemplate" component={Layout} />
          <Route exact path="/dietPlanTempelate/viewTempelate/:id" component={Layout} />
          <Route exact path="/fitnessPlanTemplate/viewTemplate/:id" component={Layout} />
          <Route exact path="/dietPlans" component={Layout} />
          <Route exact path="/dietPlans/viewDietPlan/:id" component={Layout} />
          <Route exact path="/smartdietPlans" component={Layout} />
          <Route exact path="/fitnessPlan/viewFitnessPlan/:id" component={Layout} />
          <Route exact path="/subAdmins" component={Layout} />
          <Route exact path="/roles" component={Layout} />
          <Route exact path="/coins" component={Layout} />
          <Route exact path="/streaming" component={Layout} />
          <Route exact path="/streaming2" component={Layout} />

          <Route exact path="/streamingDetails" component={Layout} />

          <Route exact path="/streamingDetails2" component={Layout} />
          <Route exact path="/streaming-details-old" component={Layout} />

          <Route exact path="/rewardProducts" component={Layout} />
          <Route exact path="/rewardTransactions" component={Layout} />
          <Route exact path="/transactionDetails" component={Layout} />
          <Route exact path="/outdoorTracks" component={Layout} />
          <Route exact path="/trainers" component={Layout} />
          <Route exact path="/categoriesTimeslot" component={Layout} />
          <Route exact path="/serviceCategories" component={Layout} />
          <Route exact path="/serviceBanners" component={Layout} />
          <Route exact path="/trainers/callSchedules" component={Layout} />
          <Route exact path="/holidays" component={Layout} />
          <Route exact path="/customerTypes" component={Layout} />
          <Route exact path="/customerServiceTypes" component={Layout} />
          <Route exact path="/servicePolicy" component={Layout} />
          <Route exact path="/deliveryRiders" component={Layout} />
          <Route exact path="/stations" component={Layout} />
          <Route exact path="/videoSessions" component={Layout} />
          <Route exact path="/guaranteedPlanFeatures" component={Layout} />
          <Route exact path="/guaranteedPlanFeatures/ratings/:id" component={Layout} />
          <Route exact path="/guaranteedPlanFeatures/questions/:id" component={Layout} />
          <Route exact path="/blogs" component={Layout} />
          <Route exact path="/blogs/addBlog" component={Layout} />
          <Route exact path="/blogs/editBlog/:id" component={Layout} />
          <Route exact path="/stories" component={Layout} />
          <Route exact path="/testimonial" component={Layout} />
          <Route exact path="/orderManagement" component={Layout} />
          <Route exact path="/whyChooseUs" component={Layout} />
          <Route exact path="/locationsAvailable" component={Layout} />
          <Route exact path="/trainers/user-ratings/:id" component={Layout} />
          <Route exact path="/trainers/user-live-ratings/:id" component={Layout} />
          <Route exact path="/trainers/schedule/:id" component={Layout} />
          <Route exact path="/trainers/leaves/:id" component={Layout} />
          <Route path="/adminDashboard" component={Layout} />
          <Route exact path="/productJob" component={Layout} />
          <Route exact path="/adminService" component={Layout} />
          <Route exact path='/pincode' component={Layout} />
           {/* set first  "deliverablePincode" segment in to url for  deliverable pincode module*/}
          <Route exact path='/deliverablePincode' component={Layout} />
          <Route exact path='/deliverablePincode/import-export' component={Layout} />
          <Route exact path="/vendors" component={Layout} />
          <Route exact path="/engineers/:id" component={Layout} />
          <Route exact path="/userJobDetail" component={Layout} />        
          <Route path="/service/:id/:selectedTab" component={Layout} />   
          <Route path="/job/:id/:selectedTab/:requestId" component={Layout} />   
          <Route exact path="/productServiceIncident" component={Layout} />  
          <Route exact path="/crmAgent" component={Layout} />  

          <Route exact path="/ads" component={Layout} />
          <Route exact path="/careers" component={Layout} />
          <Route exact path="/emoji" component={Layout}/>
          <Route exact path="/emoji/:id" component={Layout}/>
          <Route exact path="/inventory" component={Layout} />
          <Route exact path="/coupon" component={Layout} />
          <Route exact path="/stores" component={Layout}/>
           {/* set first "orders" segment  in to url for  orders module*/}
          <Route exact path="/orders" component={Layout}/>
          <Route exact path='/orders/import-export' component={Layout} />
          <Route exact path='/orders/:id' component={Layout} />
            {/* for Order Feedback product  */}
            <Route exact path="/order-feedback" component={Layout}/>
          
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Routers;
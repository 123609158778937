import axios from 'axios';
import API from '../Api';


export function addTrainer(params) {
	return axios.post(API.ADD_TRAINER,params)
}

export function getAllTrainers(params) {
	return axios.get(API.GET_ALL_TRAINERS,params)
}

export function getExpertsByServiceId(params) {
	return axios.get(API.GET_EXPERTS_BY_SERVICE_ID,params)
}

export function assignTrainer(params) {
	return axios.post(API.ASSIGN_TRAINER,params)
}

export function changeTrainerStatus(params) {
	return axios.post(API.CHANGE_DIETICIAN_STATUS,params)
}

export function editProfileByAdmin(params) {
	return axios.post(API.EDIT_TRAINER_BY_ADMIN,params)
}

export function uploadTrainerImages(params) {
	return axios.post(API.UPLOAD_TRAINER_IMG,params)
}

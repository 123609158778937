import React, { Component } from 'react';
import dateFormat from 'dateformat';
import ReactImageFallback from "react-image-fallback";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import HOC from '../../HOC';
import { getSelectedTimeSlots, sendOtp } from '../../config/services/usersService';
import { editUserProfile } from '../../config/services/adminService';
import profilePicPlaceholder from '../../assets/images/profilePicPlaceholder.png'
import TabTitle from './TabTitle'
import calculator from '../../utils/calculator'
import { convertCmToFt, convertFtToCm, convertKgToLbs, convertLbsToKg } from '../../utils/converters'
import { nameRegex, mobileRegex, emailRegex } from '../../utils/regexUtils'
import recommendedCalories from '../../utils/recommendedCalories'
import Button from 'react-bootstrap/Button'
import UpdateUserModule from '../users/UpdateUserPopup'

class PersonalDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: '',
            selectedTimeSlots: [],
            profile: {
                weight: {},
                height: {},
                preferences: {},
                beforePic: [],
                afterPic: []
            },
            questionAnswer: [],
            loader: true,
            updateUserPopup: false,
            selectedUserData: {},
            otpActive: false,
            editLoader: false,
            validationError: ''
        }
    }

    getSelectedTimeSlots = () => {
        let param = {
            params: { reqOfUserId: this.props.userId }
        }
        getSelectedTimeSlots(param)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    const { selectedTimeSlots } = response.responseData.result

                    selectedTimeSlots.map(slot => {
                        var t = this.convertTime12to24(slot.timeSlot);
                        slot.timeSlotMilliseconds = Number(t.split(':')[0]) * 60 + Number(t.split(':')[1]) * 1000;
                    })
                    selectedTimeSlots.sort((a, b) => (a.timeSlotMilliseconds > b.timeSlotMilliseconds) ? 1 : ((b.timeSlotMilliseconds > a.timeSlotMilliseconds) ? -1 : 0));
                    this.setState({ selectedTimeSlots })
                }
                else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
    }


    convertTime12to24 = (time12h) => {
        const [time, modifier] = time12h.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'pm') {
            hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}`;
    }

    componentDidMount() {
        const { userId, dieticianDetails, profile } = this.props
        this.setState({ userId, dieticianDetails, profile })
        this.getSelectedTimeSlots();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.profile !== state.profile || props.questionAnswer !== state.questionAnswer) {
            return {
                profile: props.profile,
                questionAnswer: props.questionAnswer
            };
        }
        return null;
    }

    handleEditUserOnChange = e => {
        let { name, value, checked } = e.target
        let { selectedUserData } = this.state

        switch (name) {
            case 'height':
            case 'weight':
                selectedUserData[name]['value'] = Number(value)
                break;

            case 'heightUnit':
                selectedUserData['height']['unit'] = Number(value)
                break;

            case 'weightUnit':
                selectedUserData['weight']['unit'] = Number(value)
                break;

            case 'isSmoker':
            case 'isDiabetes':
            case 'isHyperTension':
                selectedUserData['preferences'][name] = checked
                break;

            default:
                selectedUserData[name] = value
                break
        }
        this.setState({ selectedUserData, validationError: '' })
    }


    sendOtpFunc = e => {
        let { selectedUserData } = this.state
        let params = {
            countryCode: String(selectedUserData.countryCode),
            mobileNo: String(selectedUserData.mobileNo)
        }

        if (this.validateUserData()) {
            this.setState({ editLoader: true })
            sendOtp(params)
                .then(res => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        toast.success(response.responseData.message)
                        this.setState({ otpActive: true, editLoader: false })
                    }
                    else if (response.statusCode == 0) {
                        this.setState({ validationError: response.error.errorMessage, editLoader: false })
                    }
                })
        }
    }

    validateUserData = () => {
        let { firstName, lastName, mobileNo, email, dob, countryCode } = this.state.selectedUserData
        if (!nameRegex(firstName).success) {
            this.setState({ validationError: nameRegex(firstName).message })
            return false
        }
        else if (!nameRegex(lastName).success) {
            this.setState({ validationError: nameRegex(lastName).message })
            return false
        }
        else if (!mobileRegex(mobileNo).success) {
            this.setState({ validationError: mobileRegex(mobileNo).message })
            return false
        }
        else if (countryCode !== 91 && countryCode !== '91') {
            this.setState({ validationError: "Country code should be '91'" })
            return false
        }
        else if (!emailRegex(email).success) {
            this.setState({ validationError: emailRegex(email).message })
            return false
        }
        else {
            return true
        }
    }

    submitEditUser = (e) => {
        e.preventDefault()
        let { selectedUserData, profile } = this.state

        if (this.validateUserData()) {
            if (selectedUserData.height && selectedUserData.height.unit === 2) {
                selectedUserData.height.value = convertFtToCm(selectedUserData.height.value)
            }

            if (selectedUserData.weight && selectedUserData.weight.unit === 2) {
                selectedUserData.weight.value = convertLbsToKg(selectedUserData.weight.value)
            }

            let isMobileEdited = false

            if (profile.mobileNo != selectedUserData.mobileNo) {
                isMobileEdited = true
            }

            let params = {
                firstName: selectedUserData.firstName,
                lastName: selectedUserData.lastName,
                countryCode: String(selectedUserData.countryCode),
                mobileNo: String(selectedUserData.mobileNo),
                email: selectedUserData.email,
                otp: selectedUserData.otp,
                reqOfUserId: selectedUserData._id,
                height: selectedUserData.height,
                weight: selectedUserData.weight,
                preferences: selectedUserData.preferences,
                gender: selectedUserData.gender,
                dob: selectedUserData.dob,
                isMobileEdited
            }

            this.setState({ editLoader: true })
            editUserProfile(params)
                .then(res => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        this.props.getUserProfile()
                        toast.success(response.responseData.message)
                        this.closeModal()
                    }
                    else if (response.statusCode == 0) {
                        this.setState({ validationError: response.error.errorMessage, editLoader: false })
                    }
                })
        }
    }


    openEditUserPopup = () => {
        let { profile } = this.state
        let selectedUserData = JSON.parse(JSON.stringify(profile))

        if (profile.height && profile.height.unit === 2) {
            selectedUserData.height.value = convertCmToFt(selectedUserData.height.value)
        }

        if (profile.weight && profile.weight.unit === 2) {
            selectedUserData.weight.value = convertKgToLbs(selectedUserData.weight.value)
        }

        this.setState({ updateUserPopup: true, selectedUserData })
    }

    closeModal = () => {
        this.setState({ updateUserPopup: false, selectedUserData: {}, otpActive: false, editLoader: false, validationError: '' })
    }

    handleSelectDate = (date, key) => {
        if (key === 'dob') {
            let { selectedUserData } = this.state
            selectedUserData.dob = new Date(date).getTime()
            this.setState({ selectedUserData })
        }
        else {
            this.setState({ [key]: date })
        }
    }

    render() {
        let desingCLass = 'shadow-sm p-3 mb-4 rounded';
        const { email, status, countryCode, profilePic, firstName, dob, gender, lastName, mobileNo, fitnessCouponCode, dietCouponCode, couponAppliedOn, height, weight, preferences, bmr, bmi } = this.state.profile;
        const { selectedTimeSlots, profile, questionAnswer, updateUserPopup, selectedUserData, otpActive, editLoader, validationError } = this.state;


        const { dieticianDetails } = profile
        let dietician
        if (dieticianDetails && dieticianDetails.length > 0) {
            dietician = dieticianDetails[0]
        }
        const calculate = calculator(height, weight, dob, gender)
        // const caloriesRecommended = recommendedCalories(calculate('BMR'), questionAnswer)
        const caloriesRecommended = recommendedCalories(bmr, questionAnswer)

        return (
            <HOC>
                <div className="body-container cm_profile_wrapper cm_profile_wrapper_bg">
                    {/* <TabTitle title="User Profile" /> */}
                    <div className="row text-center">
                        <div className="col-xl-2 col-lg-3 mb-5">
                            <div className="user_profile_img shadow">
                                <ReactImageFallback
                                    src={profilePic}
                                    fallbackImage={profilePicPlaceholder}
                                />
                            </div>
                            <div className="text-center">
                                <span className={`text-white  badge   ${status === 1 ? 'badge-success' : 'badge-danger'}`}>{status === 1 ? 'Active' : 'Inactive'}</span>
                            </div>
                            
                            <div className="d-flex justify-content-between">
                                <Link to={'/users/Graphs/' + this.state.userId} className="btn btn-primary mt-3">View Stats</Link>
                                <Button className="btn btn-primary mt-3" onClick={this.openEditUserPopup}> Edit User</Button>
                            </div>
                            <div className="mt-3">
                                <span className="widget_label">Available Time Slots:</span> &nbsp;
                                    <div className="">
                                    {selectedTimeSlots.length ? selectedTimeSlots.map((timeSlot, index) => (
                                        <span className="badge badge-pill badge-dark cm_slots" data-toggle="tooltip" data-placement="top" title="Time slots" key={index}>{timeSlot.timeSlot}</span>
                                    )) : "No slots selected"}
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-10 col-lg-9 mb-5">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>First Name:</b> {firstName}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Mobile Number:</b> {`+${countryCode} - ${mobileNo}`}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Gender:</b> {gender === 1 ? 'Male' : gender === 2 ? 'Female' : 'Not Set'}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Weight:</b> {`${weight.value} Kg`}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>BMI:</b> {bmi} </h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Diet Coupon:</b> {dietCouponCode ? dietCouponCode : 'Not Used yet'}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Dietician Assigned:</b> {dietician ? dietician.firstName : 'Not assigned yet'} </h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Recommended Calories:</b> {caloriesRecommended} </h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Coupon Applied On:</b>{
                                        couponAppliedOn ? dateFormat(couponAppliedOn, "d mmmm yyyy") : "-"}</h5>
                                </div>

                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Last Name:</b>{lastName}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Email:</b> {email}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Date of Birth:</b>{dob ? dateFormat(dob, "d mmmm yyyy") : 'Not Set'}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Height:</b> {`${height.value} Cm`}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>BMR:</b> {bmr} </h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Smoker:</b> {preferences.isSmoker ? 'Yes' : 'No'}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>HyperTension:</b> {preferences.isHyperTension ? 'Yes' : 'No'}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Diabetes:</b> {preferences.isDiabetes ? 'Yes' : 'No'}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Fitness Coupon:</b> {fitnessCouponCode ? fitnessCouponCode : 'Not Used yet'}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {updateUserPopup ? <UpdateUserModule show={updateUserPopup} selectedUserData={selectedUserData} onHide={this.closeModal} onChange={this.handleEditUserOnChange}
                    sendOtpFunc={this.sendOtpFunc} otpActive={otpActive} submitEditUser={this.submitEditUser} validationError={validationError} editLoader={editLoader} handleSelectDate={this.handleSelectDate} userDetails={profile} /> : null}
            </HOC>
        );
    }
}

export default PersonalDetails;
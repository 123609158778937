import React from 'react';
import moment from 'moment';
import {  Button,  Card, Accordion } from 'react-bootstrap';
const PolicyBody = ({duration, quantum, eligbilityFromStartDate})=>{
    return <Card.Body>
       <p> <b>Duration</b>: {duration} Months</p>
       <p> <b>Quantum</b>: {quantum} /Times </p>
       <p> <b>Eligbility</b>: {eligbilityFromStartDate} Months  </p>
     </Card.Body>
}
   
const ViewPolicyPopupBody = ({policiesView }) => {
    return (
        <>
            {policiesView && policiesView.serviceStartDate ?
                <div> 
                    <h4 className="cm_pos_rel cm_orange mt-3">Policy</h4>
                    <p>Service Start Date: {moment(policiesView.serviceStartDate).format('Do MMM YYYY')} </p>
                    <p>Policy Name: {policiesView.versionName} </p>
                    <p>User Type: {policiesView.customerTypeId.customerType} </p>
                    <h5>Services Offered:</h5>
                <div>

                  <Accordion defaultActiveKey="0">
                    {policiesView.customerTypeId && policiesView.installation && policiesView.customerTypeId.installation ?
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey={1}> Installation </Accordion.Toggle></Card.Header>
                          <Accordion.Collapse eventKey={1}>
                            <PolicyBody 
                              duration = {policiesView.installation.duration}
                              quantum = {policiesView.installation.quantum}
                              eligbilityFromStartDate = {policiesView.installation.eligbilityFromStartDate}
                            />
                          </Accordion.Collapse>
                      </Card> : null}


                    {policiesView.customerTypeId && policiesView.maintenance && policiesView.customerTypeId.maintenance ?
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey={2}> Maintenance </Accordion.Toggle></Card.Header>
                        <Accordion.Collapse eventKey={2}>
                            <PolicyBody 
                              duration = {policiesView.maintenance.duration}
                              quantum = {policiesView.maintenance.quantum}
                              eligbilityFromStartDate = {policiesView.maintenance.eligbilityFromStartDate}
                            />
                        </Accordion.Collapse>
                      </Card> : null}

                    {policiesView.customerTypeId && policiesView.healthCoins && policiesView.customerTypeId.healthCoins ?
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey={3}> Health Coins </Accordion.Toggle></Card.Header>
                        <Accordion.Collapse eventKey={3}>
                        <PolicyBody 
                              duration = {policiesView.healthCoins.duration}
                              quantum = {policiesView.healthCoins.quantum}
                              eligbilityFromStartDate = {policiesView.healthCoins.eligbilityFromStartDate}
                            />
                        </Accordion.Collapse>
                      </Card> : null}

                    {policiesView.customerTypeId && policiesView.outdoor && policiesView.customerTypeId.outdoor ?
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey={4}> Outdoor </Accordion.Toggle></Card.Header>
                            <Accordion.Collapse eventKey={4}>
                            <PolicyBody 
                              duration = {policiesView.outdoor.duration}
                              quantum = {policiesView.outdoor.quantum}
                              eligbilityFromStartDate = {policiesView.outdoor.eligbilityFromStartDate}
                            />
                        </Accordion.Collapse>
                      </Card> : null}

                    {policiesView.customerTypeId && policiesView.dieticianConsultation && policiesView.customerTypeId.dieticianConsultation ?
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey={5}> Dietician Consultation </Accordion.Toggle></Card.Header>
                        <Accordion.Collapse eventKey={5}>
                        <PolicyBody 
                              duration = {policiesView.dieticianConsultation.duration}
                              quantum = {policiesView.dieticianConsultation.quantum}
                              eligbilityFromStartDate = {policiesView.dieticianConsultation.eligbilityFromStartDate}
                            />
                        </Accordion.Collapse>
                      </Card> : null}

                    {policiesView.customerTypeId && policiesView.trainerConsultation && policiesView.customerTypeId.trainerConsultation ?
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey={6}> Trainer Consultation </Accordion.Toggle></Card.Header>
                        <Accordion.Collapse eventKey={6}>
                        <PolicyBody 
                              duration = {policiesView.trainerConsultation.duration}
                              quantum = {policiesView.trainerConsultation.quantum}
                              eligbilityFromStartDate = {policiesView.trainerConsultation.eligbilityFromStartDate}
                            />
                        </Accordion.Collapse>
                      </Card> : null}

                    {policiesView.customerTypeId && policiesView.doctorConsultation && policiesView.customerTypeId.doctorConsultation ?
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey={7}>Doctor Consultation </Accordion.Toggle></Card.Header>
                        <Accordion.Collapse eventKey={7}>
                        <PolicyBody 
                              duration = {policiesView.doctorConsultation.duration}
                              quantum = {policiesView.doctorConsultation.quantum}
                              eligbilityFromStartDate = {policiesView.doctorConsultation.eligbilityFromStartDate}
                            />
                        </Accordion.Collapse>
                      </Card> : null}

                    {policiesView.customerTypeId && policiesView.exercisePlan && policiesView.customerTypeId.exercisePlan ?
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey={8}>Exercise Plan </Accordion.Toggle></Card.Header>
                        <Accordion.Collapse eventKey={8}>
                        <PolicyBody 
                              duration = {policiesView.exercisePlan.duration}
                              quantum = {policiesView.exercisePlan.quantum}
                              eligbilityFromStartDate = {policiesView.exercisePlan.eligbilityFromStartDate}
                            />
                        </Accordion.Collapse>
                      </Card> : null}

                    {policiesView.customerTypeId && policiesView.sessions && policiesView.customerTypeId.sessions ?
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey={9}>Sessions </Accordion.Toggle></Card.Header>
                        <Accordion.Collapse eventKey={9}>
                        <PolicyBody 
                              duration = {policiesView.sessions.duration}
                              quantum = {policiesView.sessions.quantum}
                              eligbilityFromStartDate = {policiesView.sessions.eligbilityFromStartDate}
                            />
                        </Accordion.Collapse>
                      </Card> : null}


                    {policiesView.customerTypeId && policiesView.dietPlan && policiesView.customerTypeId.dietPlan ?
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey={10}>Diet Plan </Accordion.Toggle></Card.Header>
                        <Accordion.Collapse eventKey={10}>
                        <PolicyBody 
                              duration = {policiesView.dietPlan.duration}
                              quantum = {policiesView.dietPlan.quantum}
                              eligbilityFromStartDate = {policiesView.dietPlan.eligbilityFromStartDate}
                            />
                        </Accordion.Collapse>
                      </Card> : null}


                    {policiesView.customerTypeId && policiesView.gaming&& policiesView.customerTypeId.gaming && policiesView.gaming?
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey={11}>Gaming</Accordion.Toggle></Card.Header>
                        <Accordion.Collapse eventKey={11}>
                        <PolicyBody 
                              duration = {policiesView.gaming.duration}
                              quantum = {policiesView.gaming.quantum}
                              eligbilityFromStartDate = {policiesView.gaming.eligbilityFromStartDate}
                            />
                        </Accordion.Collapse>
                      </Card> : null}
                  </Accordion>
                </div>
              </div> : 'No Policy Assigned'}
        </>
    );
};

export default ViewPolicyPopupBody;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup"
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { addCustomerType, getCustomerTypesList, updateCustomerType, deleteCustomerType } from '../../config/services/CustomerTypesService';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Alert } from 'react-bootstrap';


class CustomerTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            customerTypesList: [],
            customerType: '',
            installationTime: '',
            installationMonth: '',
            maintenance: '',
            dieticianConsultationTime: '',
            dieticianConsultationMonth: '',
            exercisePlanTime: '',
            exercisePlanMonth: '',
            trainerConsultationTime: '',
            trainerConsultationMonth: '',
            doctorConsultationTime: '',
            doctorConsultationMonth: '',
            healthCoins: null,
            gaming: '',
            outdoor: '',
            voiceControl: '',
            standaloneSessions: '',
            search: '',
            count: 10,
            totalCustomerTypes: 15,
            pageNo: 1,
            sortType: -1,
            sortField: 'created',
            AddCustomerTypePopup: false,
            deleteCustomerTypePopup: false,
            title: '',
            validationError: '',
            updateCustomerTypePopup: false,
            customerTypesId: "",
            installation: null,
            maintenance: null,
            dieticianConsultation: null,
            trainerConsultation: null,
            doctorConsultation: null,
            exercisePlan: null,
            dietPlan: null,
            sessions: null
        }
    }


    componentDidMount() {
        this.getCustomerTypesList();
    }


    handlePaths = (paths) => {
        this.setState({ accessiblePaths: paths })
    }


    getCustomerTypesList = () => {
        let { search, count, pageNo, sortType, sortField } = this.state;
        let paramsObj = {
            params: {
                search, count, pageNo: pageNo - 1, sortType, sortField
            }
        }
        getCustomerTypesList(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.setState({
                        customerTypesList: response.responseData.result.customerTypes,
                        totalCustomerTypes: response.responseData.result.customerTypesCount,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    openAddCustomerTypePopup = () => this.setState({ AddCustomerTypePopup: !this.state.AddCustomerTypePopup });

    closeModal = () => this.setState({
        AddCustomerTypePopup: false,
        customerType: '',
        customerTypeId: '',
        healthCoins: null,
        gaming: '',
        outdoor: '',
        voiceControl: '',
        deleteCustomerTypePopup: false,
        updateCustomerTypePopup: false,
        validationError: ''

    });

    handleChange = e => {
        let { name, value } = e.target
        this.setState({ [name]: value, validationError: '' })
    }


    handleSubmitCustomerType = (e) => {
        e.preventDefault();
        let { customerType, healthCoins, gaming, outdoo, installation, maintenance, dieticianConsultation, trainerConsultation, doctorConsultation, exercisePlan, sessions, dietPlan } = this.state;
        let params = {
            customerType, healthCoins, gaming, outdoo, installation, maintenance, dieticianConsultation, trainerConsultation, doctorConsultation, exercisePlan, sessions, dietPlan
        }

        if (this.isValid()) {
            this.addCustomerTypeApi(params)
        }
    }

    isValid = () => {
        let { customerType, healthCoins, gaming, outdoor, installation, maintenance, dieticianConsultation, trainerConsultation, doctorConsultation, exercisePlan, sessions, dietPlan } = this.state;

        if (!customerType) {
            this.setState({ validationError: 'Enter the customer type' })
            return 0
        }

        else if (installation == null) {
            this.setState({ validationError: 'Select the installation access' })
            return 0
        }
        else if (maintenance == null) {
            this.setState({ validationError: 'Select the maintenance access' })
            return 0
        }
        else if (dieticianConsultation == null) {
            this.setState({ validationError: 'Select the dietician consultation access' })
            return 0
        }
        else if (trainerConsultation == null) {
            this.setState({ validationError: 'Select the trainer consultation access' })
            return 0
        }
        else if (doctorConsultation == null) {
            this.setState({ validationError: 'Select the doctor consultation access' })
            return 0
        }
        else if (exercisePlan == null) {
            this.setState({ validationError: 'Select the exercise plan access' })
            return 0
        }
        else if (sessions == null) {
            this.setState({ validationError: 'Select the sessions access' })
            return 0
        }
        else if (healthCoins == null) {
            this.setState({ validationError: 'Select the health Coins' })
            return 0
        }
        else if (gaming == null) {
            this.setState({ validationError: 'Select the gaming' })
            return 0
        }
        else if (outdoor == null) {
            this.setState({ validationError: 'Select the outdoor access' })
            return 0
        }
        else if (dietPlan == null) {
            this.setState({ validationError: 'Select the Diet Plan access' })
            return 0
        } else {
            this.setState({ validationError: '' })
            return 1
        }
    }

    addCustomerTypeApi = (params) => {
        addCustomerType(params)
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.getCustomerTypesList()
                    this.setState({ loader: false })
                    toast.success(response.responseData.message)
                    this.closeModal()
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false, validationError: response.error.errorMessage })
                }
            })
    }


    openDeleteCustomerTypePopup = (data, customerTypes) => this.setState({ deleteCustomerTypePopup: !this.state.deleteCustomerTypePopup, customerTypeId: customerTypes._id });


    deleteCustomerType = (e) => {
        e.preventDefault();
        const { customerTypeId } = this.state
        let paramsObj = {
            customerTypeId: customerTypeId
        }
        deleteCustomerType(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    this.closeModal();
                    this.getCustomerTypesList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }

    openUpdateCustomerTypePopup = (data, customerTypes) => {
        let { customerType, healthCoins, gaming, outdoor, installation, maintenance, dieticianConsultation, trainerConsultation, doctorConsultation, exercisePlan, dietPlan, sessions, _id } = customerTypes;

        this.setState({
            updateCustomerTypePopup: true, customerTypeId: _id, customerType, healthCoins, gaming, outdoor, installation, maintenance, dieticianConsultation, trainerConsultation, doctorConsultation, exercisePlan, sessions, dietPlan
        })
    }


    updateCustomerTypeSubmit = (e) => {
        e.preventDefault();
        const { customerTypeId, customerType, healthCoins, gaming, outdoor, installation, maintenance, dieticianConsultation, trainerConsultation, doctorConsultation, exercisePlan, sessions, dietPlan } = this.state

        let paramsObj = {
            customerTypeId, customerType, healthCoins, gaming, outdoor, installation, maintenance, dieticianConsultation, trainerConsultation, doctorConsultation, exercisePlan, sessions, dietPlan
        }
        if (this.isValid()) {
            this.updateCustomerTypeApi(paramsObj)
        }
    }

    updateCustomerTypeApi = (params) => {
        updateCustomerType(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    this.closeModal();
                    this.getCustomerTypesList();
                    toast.success(res.data.responseData.message)
                }
                else if (res.data.statusCode == 0) {
                    this.setState({ loader: false, validationError: res.data.error.errorMessage })
                }
            })
    }


    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? 2 : 1 }, () => this.getCustomerTypesList());

    handleSearch = e => this.setState({ search: e.target.value }, () => this.getCustomerTypesList());

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getCustomerTypesList() });

    render() {
        const {
            loader, customerTypesList, search, AddCustomerTypePopup, validationError, deleteCustomerTypePopup, pageNo, updateCustomerTypePopup, count, totalCustomerTypes, customerType, healthCoins, gaming, outdoor, voiceControl, installation, maintenance, dieticianConsultation, trainerConsultation, doctorConsultation, exercisePlan, sessions, dietPlan
        } = this.state;
        // console.log("statussssssssssssssssss", healthCoins)
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Customer Types</li>
                        </ol>
                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="cm_page_heading">Customer Types</h4>
                                    </div>
                                    <div className="col-md-3 text-right cm_search">
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search by customer type"
                                            onChange={this.handleSearch}
                                            value={search}
                                        />
                                        <i className="fa fa-search" ></i>
                                    </div>
                                    <div className="col-md-3 text-right">
                                        <button className="btn btn-primary btn-block" onClick={this.openAddCustomerTypePopup}>Add New Customer Type</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('customerType')}>Customer Type   <i className={`fa fa-sort-alpha-asc`} />
                                        </th>
                                        <th scope="col" >Installation </th>
                                        <th scope="col" >Maintenance </th>
                                        <th scope="col" >Dietician's Consultation </th>
                                        <th scope="col" >Trainer's Consultation </th>
                                        <th scope="col" >Doctor's Consultation </th>
                                        <th scope="col" >Exercise Plan </th>
                                        <th scope="col" >Sessions </th>
                                        <th scope="col" >Health Coins </th>
                                        <th scope="col" >Gaming </th>
                                        <th scope="col" >Outdoor </th>
                                        <th scope="col" >Diet Plan </th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('created')}>Created &nbsp;  <i className={`fa fa-sort-numeric-asc`} />
                                        </th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        customerTypesList.map((customerTypes, i) => {
                                            return (

                                                <tr key={customerTypes._id}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                                                    <td><span className="cm_table_three_dots">{customerTypes.customerType}</span></td>
                                                    <td className="cm_no_wrap">{customerTypes.installation == 1 ? "Access" : "No Access"}</td>
                                                    <td className="cm_no_wrap">{customerTypes.maintenance == 1 ? "Access" : "No Access"}</td>
                                                    <td className="cm_no_wrap">{customerTypes.dieticianConsultation == 1 ? "Access" : "No Access"}</td>
                                                    <td className="cm_no_wrap">{customerTypes.trainerConsultation == 1 ? "Access" : "No Access"}</td>
                                                    <td className="cm_no_wrap">{customerTypes.doctorConsultation == 1 ? "Access" : "No Access"}</td>
                                                    <td className="cm_no_wrap">{customerTypes.exercisePlan == 1 ? "Access" : "No Access"}</td>
                                                    <td className="cm_no_wrap">{customerTypes.sessions == 1 ? "Access" : "No Access"}</td>
                                                    <td className="cm_no_wrap">{customerTypes.healthCoins == 1 ? "Flipkart deal" : "No Coin"}</td>
                                                    <td className="cm_no_wrap">{customerTypes.gaming == 0 ? 'No Access' : customerTypes.gaming == 1 ? "full access with full functionalities" : "full access with limited functionalities"}</td>
                                                    <td className="cm_no_wrap">{customerTypes.outdoor == 0 ? 'No Access' : customerTypes.outdoor == 1 ? 'Full Access' : '-'}</td>
                                                    <td className="cm_no_wrap">{customerTypes.dietPlan == 0 ? 'No Access' : customerTypes.dietPlan == 1 ? 'Full Access' : '-'}</td>
                                                    <td className="cm_no_wrap">{moment(customerTypes.created).format('Do MMM YYYY')}</td>
                                                    <td className="cm_no_wrap">
                                                        &nbsp;&nbsp;
                                                        <button className="btn btn-dark btn-sm" onClick={(data) => this.openUpdateCustomerTypePopup(data, customerTypes)} >Edit</button>

                                                        &nbsp;&nbsp;
                                                        <button className="btn btn-danger btn-sm" onClick={(data) => this.openDeleteCustomerTypePopup(data, customerTypes)}>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalCustomerTypes}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />
                            {!customerTypesList.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}

                        </div>
                    </div>
                </div>



                <Popup
                    open={AddCustomerTypePopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Add Customer Type</h3>
                        <form onSubmit={this.handleSubmitCustomerType}>
                            <div className="form-group">
                                <label>Customer Type</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="customerType"
                                    placeholder="Enter cutomer type here...."
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Installation</label>
                                <select className="form-control" name="installation" id="installation" onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Access</option>
                                    <option value={1}>Full Access</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Maintenance</label>
                                <select className="form-control" name="maintenance" id="maintenance" onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Access</option>
                                    <option value={1}>Full Access</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Dietician's Consultation</label>
                                <select className="form-control" name="dieticianConsultation" id="dieticianConsultation" onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Access</option>
                                    <option value={1}>Full Access</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Trainer's Consultation </label>
                                <select className="form-control" name="trainerConsultation" id="trainerConsultation" onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Access</option>
                                    <option value={1}>Full Access</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Doctor's Consultation</label>
                                <select className="form-control" name="doctorConsultation" id="doctorConsultation" onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Access</option>
                                    <option value={1}>Full Access</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Exercise Plan</label>
                                <select className="form-control" name="exercisePlan" id="exercisePlan" onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Access</option>
                                    <option value={1}>Full Access</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Diet Plan</label>
                                <select className="form-control" name="dietPlan" id="dietPlan" onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Access</option>
                                    <option value={1}>Full Access</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Sessions</label>
                                <select className="form-control" name="sessions" id="sessions" onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Access</option>
                                    <option value={1}>Full Access</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Health coins</label>
                                <select className="form-control" name="healthCoins" id="healthCoins" onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Coin</option>
                                    <option value={1}>Flipkart deal</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Gaming</label>
                                <select className="form-control" name="gaming" id="gaming" onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Access</option>
                                    <option value={1}>Full access with all functionalities</option>
                                    <option value={2}>Full access with limited functionalities</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Outdoor</label>
                                <select className="form-control" name="outdoor" id="outdoor" onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Access</option>
                                    <option value={1}>Full Access</option>
                                </select>
                            </div>



                            {validationError && <Alert variant="danger"> {validationError} </Alert>}
                            <div className="text-center">
                                <button className="btn btn-primary" type="submit">Add</button>
                            </div>
                        </form>
                    </div>
                </Popup>



                <Popup
                    open={updateCustomerTypePopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Edit Customer Type</h3>
                        <form onSubmit={this.updateCustomerTypeSubmit}>
                            <div className="form-group">
                                <label>Customer Type</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="customerType"
                                    value={customerType}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Installation</label>
                                <select className="form-control" value={installation} name="installation" id="installation" onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Access</option>
                                    <option value={1}>Full Access</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Maintenance</label>
                                <select className="form-control" value={maintenance} name="maintenance" id="maintenance" onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Access</option>
                                    <option value={1}>Full Access</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Dietician's Consultation</label>
                                <select className="form-control" value={dieticianConsultation} name="dieticianConsultation" id="dieticianConsultation" onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Access</option>
                                    <option value={1}>Full Access</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Trainer's Consultation </label>
                                <select className="form-control" value={trainerConsultation} name="trainerConsultation" id="trainerConsultation" onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Access</option>
                                    <option value={1}>Full Access</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Doctor's Consultation</label>
                                <select className="form-control" value={doctorConsultation} name="doctorConsultation" id="doctorConsultation" onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Access</option>
                                    <option value={1}>Full Access</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Exercise Plan</label>
                                <select className="form-control" value={exercisePlan} name="exercisePlan" id="exercisePlan" onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Access</option>
                                    <option value={1}>Full Access</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Diet Plan</label>
                                <select className="form-control" name="dietPlan" value={dietPlan} id="dietPlan" onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Access</option>
                                    <option value={1}>Full Access</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Sessions</label>
                                <select className="form-control" value={sessions} name="sessions" id="sessions" onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Access</option>
                                    <option value={1}>Full Access</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Health coins</label>
                                <select className="form-control" name="healthCoins" id="healthCoins" value={healthCoins} onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Coin</option>
                                    <option value={1}>Flipkart deal</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Gaming</label>
                                <select className="form-control" name="gaming" id="gaming" value={gaming} onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Access</option>
                                    <option value={1}>Full access with all functionalities</option>
                                    <option value={2}>Full access with limited functionalities</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Outdoor</label>
                                <select className="form-control" name="outdoor" id="outdoor" value={outdoor} onChange={this.handleChange} >
                                    <option selected disabled>Select....</option>
                                    <option value={0}>No Access</option>
                                    <option value={1}>Full Access</option>
                                </select>
                            </div>
                            {validationError && <Alert variant="danger"> {validationError} </Alert>}
                            <div className="text-center">
                                <button className="btn btn-primary" type="submit">Update</button>
                            </div>
                        </form>
                    </div>
                </Popup>


                <Popup
                    open={deleteCustomerTypePopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h5 className="text-center mb-4 mt-5">Are you sure, you want to delete this CustomerType?</h5>
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-danger btn-sm btn-block" onClick={this.deleteCustomerType}>Delete</button>
                            </div>

                            <div className="col">
                                <button className="btn btn-warning btn-sm btn-block" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Popup>

            </HOC>
        )
    }
}



export default CustomerTypes;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup"
import dateFormat from 'dateformat';
import DatePicker from "react-datepicker";
import moment from 'moment';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { createFreeSessionByAdmin, getFreeSessionsByAdmin, deleteFreeSessionByAdmin } from '../../config/services/videoSessionsService';
import { getServicesForSession } from '../../config/services/serviceCategoriesService';
import { getAllTrainers, getExpertsByServiceId } from '../../config/services/trainerService';
import { toast } from 'react-toastify';
import Select from 'react-select';

class VideoSessions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            freeSessionsList: [],
            serviceList: [],
            serviceId: '',
            trainersList: [],
            expertList: [],
            expertId: '',
            callDate: '',
            callTimeSlot: '',
            search: '',
            count: 10,
            totalSessions: 15,
            pageNo: 1,
            sortType: '',
            sortField: '',
            AddSessionPopup: false,
            deleteSessionPopup: false,
            title: '',
            titleErrorMsg: '',
            updateStationPopup: false,
            dateFrom: '',
            dateTo: '',
            selectedDate: ''
        }
    }


    componentDidMount() {
        this.getFreeSessionsByAdmin();
        this.getServicesForSession();
        this.getAllTrainers();
    }

    getFreeSessionsByAdmin = () => {
        let { search, count, pageNo, sortType, sortField, dateFrom, dateTo } = this.state;
        let paramsObj = {
            params: {
                search, count, pageNo: pageNo - 1, sortType, sortField, dateFrom, dateTo
            }
        }
        getFreeSessionsByAdmin(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.setState({
                        freeSessionsList: response.responseData.result.getFreeSessions,
                        totalSessions: response.responseData.result.sessionsCount,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    getServicesForSession = () => {
        getServicesForSession()
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {

                    response.responseData.result.map((data, i) => {
                        data.label = data.serviceName
                        data.value = data._id
                    })
                    this.setState({
                        serviceList: response.responseData.result,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    getAllTrainers() {
        let self = this;
        getAllTrainers()
            .then(function (res) {
                const response = res.data;
                if (response.statusCode == 1) {
                    res.data.responseData.result.map((data, i) => {
                        data.label = data.firstName + ' ' + data.lastName
                        data.value = data._id
                    })
                    const { result } = res.data.responseData
                    self.setState({ trainersList: result })
                } else
                    if (response.statusCode == 0) {
                        toast.error(response.error.errorMessage)
                    }
            })
    }

    openAddStationPopup = () => this.setState({ AddSessionPopup: !this.state.AddSessionPopup });

    closeModal = () => this.setState({
        AddSessionPopup: false,
        customerServiceType: '',
        deleteSessionPopup: false,
        updateStationPopup: false,
        serviceId: '',
        expertId: '',
        callTimeSlot: '',
        callDate: '',
        titleErrorMsg: '',
        selectedCallTimeSlot: [],
        expertList: []
    });

    getDataByDate = (date) => {

        let dateFrom = date ? moment(date).startOf('day').valueOf() : ''
        let dateTo = date ? moment(date).endOf('day').valueOf() : ''
        let selectedDate = date ? moment(date).startOf('day').valueOf() : ''

        this.setState({ dateFrom, dateTo, selectedDate, loader: true }, () => {
            this.getFreeSessionsByAdmin()
        })
    }

    handleChange = (e) => {

        let { name, value } = e.target
        let sec = value * 60
        this.setState({ [name]: sec, titleErrorMsg: '' })
    }

    handleSelectService = (e) => {
        this.setState({ serviceId: e.value })
        let paramsObj = {
            params: {
                serviceId: e.value
            }
        }
        getExpertsByServiceId(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    response.responseData.result.map((data, i) => {
                        data.label = data.firstName + ' ' + data.lastName
                        data.value = data._id
                    })
                    this.setState({
                        expertList: response.responseData.result,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    handleSelectExpert = (e) => {
        let { day } = this.state
        let callTimeSlotArray = []
        if (day) {
            let callTimeSlots = e.callTimeSlots[day]

            callTimeSlots.map((data, i) => {
                callTimeSlotArray.push({ label: data, value: data })
            })
        }

        this.setState({ expertId: e.value, selectedCallTimeSlot: callTimeSlotArray, titleErrorMsg: '' })
    }


    handleSelectTimeSlot = (e) => {
        this.setState({ callTimeSlot: e.value, titleErrorMsg: '' })
    }

    handleDateChange = (date, dateString) => {
        let day = moment(date).day() + 1
        this.setState({
            day,
            callDate: new Date(date).getTime(),
            titleErrorMsg: '',
        })
    }


    handleSubmitStation = (e) => {
        e.preventDefault();
        let { serviceId, expertId, callDate, callTimeSlot } = this.state;
        let params = {
            serviceId, expertId, callDate, callTimeSlot
        }

        if (!serviceId) {
            this.setState({ titleErrorMsg: 'Select the service name' })
        } else if (!callDate) {
            this.setState({ titleErrorMsg: 'Select the call date' })
        } else if (!expertId) {
            this.setState({ titleErrorMsg: 'Select the expert name' })
        } else if (!callTimeSlot) {
            this.setState({ titleErrorMsg: 'Select the call time slot' })
        } else {
            this.addSessionApi(params)
        }
    }

    addSessionApi = (params) => {
        createFreeSessionByAdmin(params)
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.getFreeSessionsByAdmin()
                    this.setState({ loader: false })
                    toast.success(response.responseData.message)
                    this.closeModal()
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }


    openDeleteStationPopup = (data, sessions) => this.setState({ deleteSessionPopup: !this.state.deleteSessionPopup, videoSessionId: sessions._id });


    deleteFreeSessionByAdmin = (e) => {
        e.preventDefault();
        const { videoSessionId } = this.state
        let paramsObj = {
            videoSessionId: videoSessionId
        }
        deleteFreeSessionByAdmin(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    this.closeModal();
                    this.getFreeSessionsByAdmin();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }


    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? 2 : 1 }, () => this.getFreeSessionsByAdmin());

    // handleSearch = e => this.setState({ search: e.target.value }, () => this.getFreeSessionsByAdmin());

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getFreeSessionsByAdmin() });


    render() {
        const {
            loader,
            freeSessionsList,
            serviceList,
            expertList,
            search,
            AddSessionPopup,
            selectedDate,
            selectedCallTimeSlot,
            titleErrorMsg,
            deleteSessionPopup,
            pageNo,
            count,
            totalSessions,
        } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Video Sessions</li>
                        </ol>


                        {loader
                            ? <div className="loader_wrapper"><LoaderSvg /></div>
                            : null}

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="cm_page_heading">Video Sessions</h4>
                                    </div>
                                    <div className="col">
                                        <DatePicker
                                            className="form-control"
                                            onChange={this.getDataByDate}
                                            selected={selectedDate}
                                            isClearable={true}
                                            placeholderText={"Go to particular date"}
                                            dateFormat="MMMM d, yyyy"
                                        />
                                    </div>
                                    <div className="col-md-3 text-right">
                                        <button className="btn btn-primary btn-block" onClick={this.openAddStationPopup}>Add New Session</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col">Service Name</th>
                                        <th scope="col">Call Date</th>
                                        <th scope="col">Expert Name</th>
                                        <th scope="col">Time Slot</th>
                                        <th scope="col">Call Duration</th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('created')}>Created &nbsp;  <i className={`fa fa-sort-numeric-asc`} />
                                        </th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        freeSessionsList.map((sessions, i) => {
                                            return (
                                                <tr key={sessions._id}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                                                    <td><span className="cm_table_three_dots">{sessions.serviceId.serviceName}</span></td>
                                                    <td><span className="cm_table_three_dots">{dateFormat(sessions.callDate, "d mmmm yyyy")}</span></td>
                                                    <td><span className="cm_table_three_dots">{sessions.expertId.firstName + ' ' + sessions.expertId.lastName}</span></td>
                                                    <td><span className="cm_table_three_dots">{sessions.callTimeSlot}</span></td>
                                                    <td><span className="cm_table_three_dots">{sessions.callDuration / (60 * 1000) + " Mins"}</span></td>
                                                    <td><span className="cm_table_three_dots">{dateFormat(sessions.created, "d mmmm yyyy")}</span></td>

                                                    <td className="cm_no_wrap">
                                                        <button className="btn btn-danger btn-sm" onClick={(data) => this.openDeleteStationPopup(data, sessions)}>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })

                                    }
                                </tbody>
                            </table>

                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalSessions}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!freeSessionsList.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}

                        </div>

                    </div>
                </div>



                <Popup
                    open={AddSessionPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Add New Session</h3>
                        <form onSubmit={this.handleSubmitStation}>
                            <div className="form-group">
                                <label>Service Name</label>
                                <Select
                                    isSearchable
                                    onChange={(val) => this.handleSelectService(val)}
                                    placeholder="Select..."
                                    options={serviceList}
                                />
                            </div>
                            <div className="form-group">
                                <label>Call Date</label>
                                <DatePicker
                                    selected={this.state.callDate}
                                    placeholderText="Call Date"
                                    onChange={this.handleDateChange}
                                    className="form-control"
                                    dateFormat="MMMM d, yyyy"
                                />
                            </div>
                            <div className="form-group">
                                <label>Expert Name</label>
                                <Select
                                    isSearchable
                                    onChange={(val) => this.handleSelectExpert(val)}
                                    placeholder="Select..."
                                    options={expertList}
                                />
                            </div>
                            <div className="form-group">
                                <label>Call TimeSlot</label>
                                <Select
                                    onChange={(val) => this.handleSelectTimeSlot(val)}
                                    placeholder="Select..."
                                    options={selectedCallTimeSlot}
                                />
                            </div>

                            {titleErrorMsg ? <h6 className="text-center mb-4 mt-5 error_message">{titleErrorMsg}</h6> : null}
                            <div className="text-center">
                                <button className="btn btn-primary" type="submit">Add</button>
                            </div>
                        </form>
                    </div>
                </Popup>


                <Popup
                    open={deleteSessionPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h5 className="text-center mb-4 mt-5">Are you sure, you want to delete this Station?</h5>
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-danger btn-sm btn-block" onClick={this.deleteFreeSessionByAdmin}>Delete</button>
                            </div>

                            <div className="col">
                                <button className="btn btn-warning btn-sm btn-block" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Popup>

            </HOC>
        )
    }
}



export default VideoSessions;
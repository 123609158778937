import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LoaderSvg } from '../Reusable';
import Pagination from 'react-js-pagination';
import Loader from '../loader';
import dateFormat from 'dateformat';
import { Tag, Switch } from 'antd';
import { changeDieticianStatus, getAllSubAdmins } from '../../config/services/adminService';
import { handleChangeStatus } from '../utils/Util';
import Popup from "reactjs-popup";
import SubAdminForm from './SubAdminForm';
const { getSortingIcon } = require("../utils/Util.js");



export const SubAdmins = () => {
    const initialState = {
        loading: true,
        adminList: [],
        countPerPage: 15,
        totalItemCount: 0,
        pageRangeDisplayed: 3,
        error: '',
        apiCalled: false,
        sortInfo: { sortField: "created", sortType: -1 }
    }

    let tableHeaders = [
        { fieldName: "S.No." },
        { fieldName: "Name", isSortable: true, dataIndex: "firstName", type: "Text" },
        { fieldName: "E-mail", isSortable: true, dataIndex: "email", type: "Text" },
        { fieldName: "Phone No." },
        { fieldName: "Role" },
        { fieldName: "Created Date", isSortable: true, dataIndex: "created", type: "Number" },
        { fieldName: "Status" },
        { fieldName: "Action " },
    ];

    const [state, setState] = useState({ ...initialState });
    const [pageNo, setPageNo] = useState(1)
    const [search, setSearch] = useState("");
    const [error, setError] = useState({});
    const [hideShowAdd, setHideShowAddModal] = useState(false);
    const [hideShowEdit, setHideShowEdit] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState("");
    const [openViewAdmin, setViewAdmin] = useState(false);


    const getAllAdmins = () => {
        let params = {
            params: {
                count: state.countPerPage,
                pageNo: pageNo - 1,
                sortType: state.sortInfo.sortType,
                sortField: state.sortInfo.sortField,
                search: search
            }
        }
        getAllSubAdmins(params).then(response => {
            let data;
            if (response.data && response.data.statusCode === 1) {
                data = response.data.responseData
                setState({
                    ...state,
                    adminList: response.data.responseData.adminList,
                    loading: false,
                    totalItemCount: response.data.responseData.totalCount,
                    apiCalled: false
                })
            } else {
                setState({ ...state, apiCalled: false })
            }
        })
    }



    const handlePageChange = (pageNum) => {
        setState({ ...state, loading: true })
        setPageNo(pageNum)
        // getPincode();
    }
    const handleSearch = (e) => {
        setState({
            ...state,
            apiCalled: true
        })
        setSearch(e.target.value);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            getAllAdmins();
        }, 1000);
        return () => clearTimeout(timer);

    }, [pageNo, search, state.sortInfo]);

    const hideAddPop = () => setHideShowAddModal(false);
    const showAddPop = (key) => {
        setHideShowAddModal(true);
    }
    const handleEditAdminPopup = (data) => {
        setHideShowEdit(true); setSelectedAdmin(data)
    };
    const hideEditPop = () => {
        setHideShowEdit(false); setSelectedAdmin("")
    };
    const handleCRMStatus = (id, status, stateName) => {
        handleChangeStatus(id, status, changeDieticianStatus, this, getAllAdmins, stateName);
    }
    const handleSort = (field) => {

        let { sortType, sortField } = state.sortInfo;
        let orderType = 1;
        if (sortType && sortField === field) {
            orderType = sortType < 0 ? '' : -1;
        }
        let sortData = {
            sortField: field,
            sortType: orderType
        }
        setState({ ...state, sortInfo: sortData });
    }
    const viewAdmin = (dieticianDetails) => {
        setViewAdmin(true);
        setSelectedAdmin(dieticianDetails);
    }
    const closeModal = () => {
        setViewAdmin(false);
        setSelectedAdmin("");
    }


    return (
        <div className='body-container-wrapper'>
            {state.apiCalled && <Loader />}
            <div className='body-container'>
                <ol className='breadcrumb'>
                    <li className='breadcrumb-item'><Link to='/dashboard'>Dashboard</Link></li>
                    <li className='breadcrumb-item'>Sub Admins</li>
                </ol>
                {state && state.loading && <div className="loader_wrapper"><LoaderSvg /></div>}
                <div className='users_header'>
                    <div className='container-fluid'>
                        <div className="row align-items-center">
                            <div className="col-md-7 col-sm-12">
                                <h4 className="cm_page_heading">Sub Admins</h4>
                            </div>

                            <div className="col-md-3 col-sm-12 text-right cm_search mr-2">
                                <input
                                    type="text"
                                    className="form-control m-b-md-15"
                                    placeholder="Search by Name or Email Address"
                                    onChange={handleSearch}
                                    value={search}
                                />
                                <i className="fa fa-search" />
                            </div>
                            <div className='row col-md-2 col-sm-12'>
                                <button
                                    onClick={() => showAddPop()}
                                    className="btn btn-info btn-sm btn_sm"
                                > Add Sub-Admins </button>
                            </div>

                        </div>
                        <div className='table-responsive'>
                            <table className='table table-bordered table-striped text-center'>
                                <thead>
                                    <tr>{tableHeaders.map((item, index) => {
                                        let { isSortable, dataIndex, type } = item;
                                        return (<th key={index} scope="col" onClick={() => handleSort(dataIndex)}>{item.fieldName}{isSortable && <i className={getSortingIcon(dataIndex == state.sortInfo.sortField && state.sortInfo.sortType, type)} />}</th>)
                                    })}</tr>
                                </thead>
                                <tbody>
                                    {state.adminList.map((adminData, index) => (
                                        <tr key={index}>
                                            <td> {state.countPerPage * (pageNo - 1) + (index + 1)}</td>
                                            <td className="cm_pos_realtive more_padding">
                                                {adminData.firstName + " " + adminData.lastName}
                                                <button className="btn btn-info btn-sm cm__btn" onClick={() => viewAdmin(adminData)}>View</button>
                                            </td>
                                            <td>{adminData.email}</td>
                                            <td>{adminData.mobileNo}</td>
                                            <td>{
                                                adminData.rolesAllowed && adminData.rolesAllowed.length ? adminData.rolesAllowed.map((item, i) => <span key={i}><Tag color="geekblue">{item.roleName}
                                                </Tag></span>) : adminData.roleId ? <span ><Tag color="geekblue">{adminData.roleId.roleName}</Tag></span> : ''}</td>

                                            <td>{dateFormat(adminData.created, "d mmmm yyyy")}</td>
                                            <td>
                                                <Switch className={`cm_ckeckbox_wrapper cm_ckeckbox_wrapper_height ${adminData.status === 1 ? 'cm_active' : 'cm_inactive'}`}
                                                    onClick={() => handleCRMStatus(adminData._id, adminData.status, "statusLoading")}
                                                    checked={adminData.status === 1}
                                                >
                                                    {/* <span className="cm_ckeckbox_btn"></span> */}
                                                </Switch>
                                            </td>
                                            <td>
                                                <button className="btn btn-dark btn-sm" onClick={() => handleEditAdminPopup(adminData)} >Edit</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={state.countsPerPage}
                                totalItemsCount={state.totalItemCount}
                                pageRangeDisplayed={state.pageRangeDisplayed}
                                onChange={handlePageChange}
                            />
                            <Popup
                                open={openViewAdmin}
                                onClose={closeModal}
                            >
                                <div className="card card_style">
                                    <div className="card-body text-center">
                                        <h4 className="card-title">Name: {selectedAdmin.firstName + " " + selectedAdmin.lastName}</h4>
                                        <p className="card-text">Email: {selectedAdmin.email}</p>
                                        <p className="card-text">Contact: {selectedAdmin.countryCode + " " + selectedAdmin.mobileNo}</p>
                                    </div>
                                </div>
                            </Popup>
                            {hideShowAdd &&
                                <SubAdminForm
                                    formOpen={hideShowAdd}
                                    hide={hideAddPop}
                                    getCRMAgent={getAllAdmins}
                                />}
                            {hideShowEdit &&
                                <SubAdminForm
                                    formOpen={hideShowEdit}
                                    hide={hideEditPop}
                                    getCRMAgent={getAllAdmins}
                                    selectedAgent={selectedAdmin}
                                />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SubAdmins;
import React, { useEffect, useState } from 'react';
import Popup from "reactjs-popup";
import { addInventory, editInventory } from '../../config/services/serviceInventory';
import Select from 'react-select';

const AddEditForm = (props) => {
	const { addEditPopup, setAddEditPopup, editMode, updateFormValues, warehouseList, /* getInventoryList */ } = props;
  const intialValues = { 
    productCode: '',
    warehouse: '',
    qty: '',
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingLoader, setIsSubmittingLoader] = useState(false);
  const [apiErrorMSG, setApiErrorMSG] = useState({});
	
  // SUBMIT CHECKOUT FORM
  const handleSubmit = (e) => {
    e.preventDefault();
    setApiErrorMSG({});
    setIsSubmitting(true);
    if (validate(formValues)) {
      setIsSubmittingLoader(true);
      formValues.qty = formValues.qty+'';
      if(formValues._id) {
        editInventory(formValues)
        .then((response) => {
          setIsSubmittingLoader(false);
          if (response.data.statusCode === 0) {
            setApiErrorMSG({ status: 'error', msg: response.data.error.errorMessage });
          } else {              
            if (Object.keys(response.data.responseData.result).length > 0) {
              // getInventoryList();
              setApiErrorMSG({ status: 'success', msg: 'Update record successfully' });
            } else {                
              setApiErrorMSG({ status: 'error', msg: response.data.responseData.message });
            }
          }
        })
        .catch((error) => {
          console.log('error', error);
          setApiErrorMSG({ status: 'error', msg: error});
          setIsSubmittingLoader(false);
        });
      } else {
        addInventory(formValues)
          .then((response) => {
            setIsSubmittingLoader(false);
            if (response.data.statusCode === 0) {
              setApiErrorMSG({ status: 'error', msg: response.data.error.errorMessage });
            } else {
              if (Object.keys(response.data.responseData.result).length > 0) {
                // getInventoryList();
                setFormValues(intialValues);
                setAddEditPopup(false, true, true);
								setApiErrorMSG({}); 
								setIsSubmittingLoader(false);
              } else {
                setApiErrorMSG({ status: 'error', msg: response.data.responseData.message });
              }
            }
          })
          .catch((error) => {
            console.log('error', error);
            setApiErrorMSG({ status: 'error', msg: error});
            setIsSubmittingLoader(false);
          });
      }
    }
  };
  // HANDLE CHANGE FORM
  const handleChange = (event) => {
    let formValuesSubmited = formValues;
    const { name, value } = event.target;
    formValuesSubmited[name] = value;
    if (isSubmitting) {
      validate(formValuesSubmited);
    }
    setFormValues({ ...formValues, [name]: value });
  };
  const onHandleSelect = (name, value) => {
    let formValuesSubmited = formValues;
    formValuesSubmited[name] = value;
    if (isSubmitting) {
      validate(formValuesSubmited);
    }
    setFormValues({ ...formValues, [name]: value });
  }
  // VALIDATION FORM
  const validate = (values) => {
    let errors = {};
    if (!values.productCode) {
      errors.productCode = 'Required';
    }
    if (!values.warehouse && values.warehouse === '') {
      errors.warehouse = 'Required';
    }
    if (values.qty === '') {
      errors.qty = 'Required';
    }
    if (!/^[0-9]+$/.test(values.qty)) {
      errors.qty = 'Number only';
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };
	
	useEffect(() => {
		if(editMode) {
      updateFormValues.warehouse = updateFormValues.warehouse._id;
    	setFormValues(updateFormValues);
		} else {
			setFormValues(intialValues);
		}
  }, [editMode, updateFormValues, warehouseList]);

  return (
    <Popup open={addEditPopup} closeOnDocumentClick onClose={() => {
			setFormValues(intialValues);
			setFormErrors({}); 
			setApiErrorMSG({}); 
			setIsSubmittingLoader(false);
			setAddEditPopup(false, editMode, false);
		}}>
			<div className="cm_modal pl-5 pr-5">
				<span className="cm_modal_close" onClick={() => {
					setFormValues(intialValues); 
					setFormErrors({}); 
					setApiErrorMSG({}); 
					setIsSubmittingLoader(false);
					setAddEditPopup(false, editMode, false);
				}}>&times; </span>
				<h3 className="text-center mb-4 mt-5">{formValues._id ? 'Update record' : 'Add new record'} </h3>
				<div className="text-danger text-center h3"> </div>
				<form onSubmit={handleSubmit}>
					<p className={apiErrorMSG.status === 'error' ? 'error' : 'success'}>{apiErrorMSG.msg}</p>
					<div className="form-group">
						<label>SKU ID (*)</label>
						<input
              disabled={editMode ? true : false}
							type="text"
							className="form-control"
							placeholder="SKU"
							name="productCode"
							onChange={handleChange}
							value={formValues.productCode}
						/>
						{formErrors.productCode && <label style={{color: 'red'}}>{formErrors.productCode}</label>}
					</div>
					<div className="form-group">
						<label>Warehouse name (*)</label>
						{/* <input
							type="text"
							className="form-control"
							placeholder="Warehouse name"
							name="warehouse"
							onChange={handleChange}
							value={formValues.warehouse}
						/> */} 
            <Select
              isDisabled={editMode ? true : false}
              name="warehouse"
              placeholder="Select Warehouse"
              options={warehouseList}
              value={warehouseList.find(element => element.value === formValues.warehouse)}
              onChange={(val) => onHandleSelect('warehouse', val.value)}
            />
						{formErrors.warehouse && <label style={{color: 'red'}}>{formErrors.warehouse}</label>}
					</div>
					<div className="form-group">
						<label>Inventory stock (*) </label>
						<input
							type="text"
							className="form-control"
							placeholder="Inventory stock"
							name="qty"
							onChange={handleChange}
							value={formValues.qty}
						/>
						{formErrors.qty && <label style={{color: 'red'}}>{formErrors.qty}</label>}
					</div>
					<div className="form-group">
						<button type="submit" className="btn btn-primary btn-block mt-4" disabled={isSubmittingLoader}>
							{isSubmittingLoader && (<i className="fa fa-spinner fa-pulse fa-2x fa-fw margin-bottom"></i>)} {formValues._id ? 'Update' : 'Save'}
						</button>
					</div>
				</form>
			</div>
		</Popup>
  );
};

export default AddEditForm;
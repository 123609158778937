import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { LoaderSvg } from "../Reusable";
import {
  getAllVendors,
  removeVendor,
} from "../../config/services/vendorService";
import { toast } from "react-toastify";
// the above module is not installed
// its already used in the project
import Pagination from "react-js-pagination";
import AddVendors from "./addVendors";
import EditVendor from "./editVendors";
import VendorForm from "./vendorForm";
// import EditVendors from './editVendors';
import { Tag, Divider } from "antd";

import PopUpModal from "../../components/common/PopUpModal";
import Loader from "../loader";
const {  removeDuplicates, } = require("../utils/Util.js");

const Vendors = () => {
  let tableHeaders = [
    "Sr. No",
    "Name",
    "Email",
    "State",
    "City",
    "Pincodes",
    "Actions",
  ];
  const initialState = {
    loading: true,
    vendors: [],
    countPerPage: 8,
    sortType: -1,
    sortField: 'created',
    totalVendorCount: 0,
    pageRangeDisplayed: 3,
    error: "",
    activePage: 15,
    pageNo: 0,
    actionType: "",
    selectedVendor: {},
    search: "",
    apiCalled: false,
  };

  const [state, setState] = useState({ ...initialState });
  const [addVendorModal, setAddVendorModal] = useState(false);
  const [editVendorModal, setEditVendorModal] = useState(false);
  const [search, setSearch] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [deletePopup, setdeletePopup] = useState(false);

  const getVendors = () => {
    setState({ ...state, apiCalled: true });
    let params = {
      count: state.countPerPage,
      pageNo: pageNo - 1,
      sortType: state.sortType,
      sortField: state.sortField,
      search: search,
    };
    getAllVendors({ params }).then((res) => {
      let data;
      if (res && res.data && res.data.statusCode === 1) {
        data = res.data;
        setState({
          ...state,
          vendors: data.responseData.vendors,
          totalVendorCount: data.responseData.vendorCount,
          loading: false,
          apiCalled: false,
        });
      } else {
        setState({ ...state, apiCalled: false });
      }
    });
  };

  const removeVendors = (param) => {
    let params = { vendorId: param._id, mobileNo: param.mobileNo };
    removeVendor(params).then((response) => {
      let data = response.data;
      if (data && data.statusCode === 1) {
        setdeletePopup(false);
        getVendors();
        toast.success(data.responseData.message);
      } else {
        setState({ ...state, error: "Vendor Not Removed !" });
        toast.success("Vendor Not Removed !");
      }
    });
  };

  const handlePageChange = (pageNo) => {
    setState({ ...state, loading: true });
    setPageNo((pageNum) => pageNo);
  };

  const hide = () => {
    setAddVendorModal(false);
    setEditVendorModal(false);
  };

  const hideEdit = () => {
    setEditVendorModal(false);
  };

  const showEdit = (vendor) => {
    setState({ ...state, selectedVendor: { ...vendor } });
    setEditVendorModal(true);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getVendors();
    }, 1000);
    return () => clearTimeout(timer);
  }, [search, pageNo]);

  const hideDelete = () => setdeletePopup(false);
  const showDelete = (vendor) => {
    setState({ ...state, selectedVendor: { ...vendor } });
    setdeletePopup(true);
  };

  return (
    <div className="body-container-wrapper">
      {state.apiCalled && <Loader />}
      <div className="body-container">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">Vendor</li>
        </ol>
        {state && state.loading && (
          <div className="loader_wrapper">
            <LoaderSvg />
          </div>
        )}
        <div className="users_header">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h4 className="cm_page_heading">Vendor</h4>
              </div>

              <div className="col-md-3 text-right cm_search">
                <input
                  type="text"
                  className="form-control m-b-md-15"
                  placeholder="Search by Name, Email or Phone"
                  onChange={handleSearch}
                  value={search}
                />
                <i className="fa fa-search" />
              </div>
              <div className="row col-md-1">
                <button
                  onClick={(e) => setAddVendorModal(true)}
                  className="btn btn-info btn-sm btn_sm"
                >
                  Add Vendor
                </button>
              </div>
            </div>
            {state && state.vendors.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-bordered table-striped text-center">
                  <thead>
                    <tr>
                      {tableHeaders.map((item, index) => (
                        <th key={index} scope="col">
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {state.vendors && state.vendors.length > 0
                      ? state.vendors.map((vendor, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {" "}
                                {state.countPerPage * (pageNo - 1) +
                                  (index + 1)}
                              </td>
                              <td>
                                <Link
                                  to={`/engineers/${vendor._id}`}
                                >{`${vendor.firstName} ${vendor.lastName}`}</Link>
                              </td>
                              <td>{vendor.email}</td>
                              <td>
                                {/* {vendor.state &&
                                  vendor.state.map((state, index) => (
                                    <span key={index}>
                                      <Tag color="geekblue">{state}</Tag>
                                    </span>
                                  ))} */}

                                  {vendor.pincode && removeDuplicates(vendor.pincode, "state").map((pincode, i) => (
                                    <span key={i}>
                                      <Tag color="geekblue">{pincode.state}</Tag>
                                    </span>
                                  ))}
                              </td>
                              <td>
                                <div className="wrapper-overflow">
                                  {/* {vendor.city &&
                                    vendor.city.map((city, index) => (
                                      <span key={index}>
                                        <Tag color="geekblue">{city}</Tag>
                                      </span>
                                    ))} */}

                                  {vendor.pincode && removeDuplicates(vendor.pincode, "city").map((pincode, i) => (
                                    <span key={i}>
                                      <Tag color="geekblue">{pincode.city}</Tag>
                                    </span>
                                  ))}
                                </div>
                              </td>
                              <td>
                                <div className="wrapper-overflow">
                                  {vendor.pincode &&
                                    vendor.pincode.map((pincode, index) => (
                                      <span
                                        style={{ marginBottom: ".5rem" }}
                                        key={index}
                                      >
                                        <Tag color="geekblue">
                                          {pincode.pincode}
                                        </Tag>
                                      </span>
                                    ))}
                                </div>
                              </td>
                              <td className="cm_no_wrap">
                                <button
                                  className="btn btn-dark btn-sm"
                                  onClick={(e) => showEdit(vendor)}
                                >
                                  Edit
                                </button>
                                &nbsp;
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={(e) => showDelete(vendor)}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
                <Pagination
                  activePage={pageNo}
                  itemsCountPerPage={state.countPerPage}
                  totalItemsCount={state.totalVendorCount}
                  pageRangeDisplayed={state.pageRangeDisplayed}
                  onChange={handlePageChange}
                />
                {editVendorModal && (
                  <AddVendors
                    addVendorModal={editVendorModal}
                    hide={hide}
                    selectedVendor={state.selectedVendor}
                    getVendors={getVendors}
                    actionType="Update Vendor"
                    _ismounted={true}
                    key='update vendor'
                  />
                )}
              </div>
            ) : (
              <h3 style={{ textAlign: "center" }}>No vendors found</h3>
            )}
            {addVendorModal && (
              <AddVendors
                addVendorModal={addVendorModal}
                hide={hide}
                getVendors={getVendors}
                actionType="Add new Vendor"
                selectedVendor={null}
                key='Add new Vendor'
              />
            )}
            {/* {  <VendorForm addVendorModal={addVendorModal} hide={hide} getVendors={getVendors}/> } */}

            <PopUpModal
              isOpen={deletePopup}
              closeModal={hideDelete}
              submit={(e) => removeVendors(state.selectedVendor)}
              message={`Are you sure to delete this vendor?`}
              validationError={""}
              submitTitle="Delete"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vendors;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup"
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import noImage from '../../assets/images/no-image.jpg'
import Pagination from "react-js-pagination";
import { addRewardProducts, editRewardProducts, deleteRewardProducts, getRewardProducts } from '../../config/services/rewardProductsService';
import { toast } from 'react-toastify';

import moment from 'moment';
import { DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;


function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
}


class RewardProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            products: [],
            search: '',
            count: 10,
            totalProducts: 15,
            pageNo: 1,
            sortType: -1,
            sortField: 'created',
            AddProductPopup: false,
            deleteProductPopup: false,
            viewProductPopup: false,
            productImage: '',
            title: '',
            imagePreview: '',
            titleErrorMsg: '',
            editProductPopup: false,
            tempProductId: "",
            minAmount: '',
            maxAmount: '',
            startDate: '',
            endDate: ''
        }
    }


    handleImgPreivew = e => {
        let reader = new FileReader();
        let file = e.target.files[0];
        this.setState({ productImage: e.target.files[0] })
        reader.addEventListener("load", () => {
            this.setState({ imagePreview: reader.result })
        }, false);
        if (file) { reader.readAsDataURL(file) }
    }



    openAddProductPopup = () => this.setState({ AddProductPopup: !this.state.AddProductPopup });

    openDeleteProductPopup = (data, product) => this.setState({ deleteProductPopup: !this.state.deleteProductPopup, tempProductId: product._id });

    deleteProduct = (e) => {
        e.preventDefault();
        const { tempProductId } = this.state
        let paramsObj = {
            productId: tempProductId
        }
        deleteRewardProducts(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    this.closeModal();
                    this.getRewardProductsList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }

    openViewProductPopup = (data, product) => {
        this.setState({
            viewProductPopup: true,
            tempProductId: product._id,
            productName: product.productName,
            coinsRequired: product.coinsRequired,
            productType: product.productType,
            imagePreview: product.productImage,
        })
    };


    closeModal = () => this.setState({
        AddProductPopup: false, deleteProductPopup: false, viewProductPopup: false,
        title: "", productImage: "", editProductPopup: false, imagePreview: "", tempProductId: "", titleErrorMsg: "", productName: "", coinsRequired: "", productType: "",
    });


    handleSearch = e => this.setState({ search: e.target.value }, () => this.getRewardProductsList());


    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? -1 : 1 }, () => this.getRewardProductsList());


    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getRewardProductsList() });


    getRewardProductsList = () => {
        let { search, count, pageNo, sortType, sortField } = this.state;
        let paramsObj = {
            params: {
                search, count, pageNo: pageNo - 1, sortType, sortField
            }
        }
        getRewardProducts(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({
                        products: res.data.responseData.result,
                        totalProducts: res.data.responseData.totalCount,
                        loader: false
                    })
                } else {
                    this.setState({ loader: false })
                }
            })
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value, titleErrorMsg: '' })
    }




    handleValidation = () => {
        let validate = true;
        let { startDate, endDate, productName, coinsRequired, productType, productImage, imagePreview, minAmount, maxAmount } = this.state;

        if (!productName) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please Enter Product name' })
        }
        else if (!productType) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please select the  product type' })
        }
        else if (productType == 2 && (startDate == '' || endDate == '')) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please Enter the start and end date' })
        }
        else if (productType == 2 && !minAmount) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please Enter the min value of product' })
        }
        else if (productType == 2 && !maxAmount) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please Enter the max value of product' })
        }
        else if (productType == 1 && !coinsRequired) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please Enter the value of product' })
        }



        else if (!productImage && !imagePreview) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please upload an image of product' })
        } else {
            this.setState({ titleErrorMsg: '' })
        }
        return validate
    }




    handleAddProduct = e => {
        e.preventDefault();

        if (this.handleValidation()) {
            let { startDate, endDate, productName, coinsRequired, productType, productImage, minAmount, maxAmount } = this.state;
            let params = new FormData();
            params.append('productName', productName);

            if (productType == 1)
                params.append('coinsRequired', coinsRequired);

            params.append('productType', productType);
            params.append('productImage', productImage);

            if (productType == 2) {
                params.append('maxAmount', maxAmount);
                params.append('minAmount', minAmount);
                params.append('validFrom', startDate);
                params.append('validTo', endDate);
            }

            this.addProductApi(params);
        }
    }

    addProductApi = (params) => {
        this.setState({ loader: true })
        addRewardProducts(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false }, () => this.getRewardProductsList())
                    this.closeModal();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({
                        titleErrorMsg: res.data.error.errorMessage,
                        loader: false
                    })
                }
            })
    }



    openEditProductPopup = (data, product) => {
        // console.log("ksldmladbjaskndlksadkl", product)
        this.setState({
            editProductPopup: true,
            tempProductId: product._id,
            productName: product.productName,
            coinsRequired: product.coinsRequired,
            productType: product.productType,
            imagePreview: product.productImage,
            minAmount: product.minAmount,
            maxAmount: product.maxAmount,
            startDate: product.validFrom ? product.validFrom : null,
            endDate: product.validTo ? product.validTo : null
        })
    }

    editProductSubmit = (e) => {

        e.preventDefault();
        if (this.handleValidation()) {
            let { startDate, endDate, productName, coinsRequired, productType, productImage, tempProductId, maxAmount, minAmount } = this.state;
            let params = new FormData();
            params.append('productName', productName);
            if (productType == 1)
                params.append('coinsRequired', coinsRequired);
            params.append('productType', productType);
            params.append('productImage', productImage);
            params.append('productId', tempProductId);
            if (productType == 2) {
                params.append('maxAmount', maxAmount);
                params.append('minAmount', minAmount);
            }

            params.append('validFrom', startDate);
            params.append('validTo', endDate)

            this.editProductApi(params);
        }
    }

    editProductApi = (params) => {
        this.setState({ loader: true })
        editRewardProducts(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.getRewardProductsList();
                    this.setState({ loader: false })
                    this.closeModal();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({
                        titleErrorMsg: res.data.error.errorMessage,
                        loader: false
                    })
                }
            })
    }


    componentDidMount() {
        this.getRewardProductsList();
    }

    dateChange = (dates, dateStrings) => {
        let startDate = dates[0].startOf('day').toDate().getTime()
        let endDate = dates[1].endOf('day').toDate().getTime()
        this.setState({ startDate, endDate })
    }


    render() {
        const {
            loader,
            products,
            search,
            AddProductPopup,
            imagePreview,
            titleErrorMsg,
            deleteProductPopup,
            viewProductPopup,
            pageNo,
            editProductPopup,
            count,
            totalProducts,
            productName,
            coinsRequired,
            productType,
            startDate,
            endDate
        } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Reward Products</li>
                        </ol>
                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="cm_page_heading">Reward Products</h4>
                                    </div>
                                    <div className="col-md-3 text-right cm_search">
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search by product name..."
                                            onChange={this.handleSearch}
                                            value={search}
                                        />
                                        <i className="fa fa-search" />
                                    </div>
                                    <div className="col-md-3 text-right">
                                        <button className="btn btn-primary btn-block" onClick={this.openAddProductPopup}>Add New</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('productName')}>Product Name &nbsp;
                                <i className={`fa fa-sort-alpha-asc`} />
                                        </th>
                                        <th scope="col">Product Image</th>

                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('created')}>Value &nbsp;
                                <i className={`fa fa-sort-numeric-asc`} />
                                        </th>
                                        <th scope="col">Product type</th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('created')}>Created &nbsp;
                                <i className={`fa fa-sort-numeric-asc`} />
                                        </th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        products.map((product, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>

                                                    <td><span className="cm_table_three_dots">{product.productName}</span></td>

                                                    <td><img src={product.productImage ? product.productImage : noImage} className="sm_image" /></td>

                                                    <td className="cm_no_wrap">{product.productType == 2 ? `${product.minAmount} - ${product.maxAmount}` : product.coinsRequired}</td>

                                                    <td className="cm_no_wrap">{product.productType == 1 ? 'Product' : product.productType == 2 ? 'Deal' : 'N/A'}</td>

                                                    <td className="cm_no_wrap">{dateFormat(product.created, "d mmmm yyyy")}</td>


                                                    <td className="cm_no_wrap">

                                                        <button className="btn btn-primary btn-sm" onClick={(data) => this.openViewProductPopup(data, product)}>View</button>
                                                        &nbsp;&nbsp;

                                                        <button className="btn btn-dark btn-sm" onClick={(data) => this.openEditProductPopup(data, product)} >Edit</button>
                                                        &nbsp;&nbsp;

                                                        <button className="btn btn-danger btn-sm" onClick={(data) => this.openDeleteProductPopup(data, product)}>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })

                                    }
                                </tbody>
                            </table>


                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalProducts}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!products.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}

                        </div>

                    </div>
                </div>



                <Popup
                    open={AddProductPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <form onSubmit={this.handleAddProduct}>
                            <div className="form-group">
                                <label>Product Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="productName"
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>Product Type</label>
                                <select className="form-control" defaultValue='Selelct Product Type' onChange={this.handleChange} name="productType">
                                    <option disabled> Selelct Product Type</option>
                                    <option value={1} > Product </option>
                                    <option value={2} > Deal </option>
                                </select>
                            </div>

                            {
                                this.state.productType == 2 ?
                                    <>
                                        <div className="form-group">
                                            <label>Valid From - Valid To</label>
                                            <br />
                                            <RangePicker
                                                disabledDate={disabledDate}
                                                onChange={this.dateChange}
                                                dropdownClassName="upar_index"
                                            />
                                        </div>


                                        <div className="form-group">
                                            <label>Min value (in coins)</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="minAmount"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Max value (in coins)</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="maxAmount"
                                                onChange={this.handleChange}
                                            />
                                        </div>

                                    </>
                                    :
                                    <div className="form-group">
                                        <label>Value (in coins)</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="coinsRequired"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                            }

                            <div className="form-group">
                                <label>Product Image</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    onChange={this.handleImgPreivew}
                                />
                            </div>

                            {imagePreview && <img className="exercise_category_img" src={imagePreview} />}
                            <div className="text-center">
                                {titleErrorMsg && <h6 className="text-center mb-2 mt-2 error_message">{titleErrorMsg}</h6>}
                                <button className="btn btn-primary" type="submit">Add Product</button>
                            </div>
                        </form>
                    </div>
                </Popup>



                <Popup
                    open={editProductPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <form onSubmit={this.editProductSubmit}>


                            <div className="form-group">
                                <label>Product Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="productName"
                                    value={productName}
                                    onChange={this.handleChange}
                                />
                            </div>


                            <div className="form-group">
                                <label>Product Type</label>
                                <select className="form-control" onChange={this.handleChange} name="productType" disabled>
                                    <option selected={productType === 1 ? true : false} value={1} > Product </option>
                                    <option selected={productType === 2 ? true : false} value={2}> Deal </option>
                                </select>
                            </div>

                            {
                                this.state.productType == 2 ?
                                    <>
                                        <div className="form-group">
                                            <label>Valid From - Valid To</label>
                                            <br />
                                            <RangePicker
                                                disabledDate={disabledDate}
                                                onChange={this.dateChange}
                                                dropdownClassName="upar_index"
                                                defaultValue={[startDate ? moment(startDate) : '', endDate ? moment(endDate) : '']}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Min value (in coins)</label>
                                            <input
                                                type="number"
                                                value={this.state.minAmount}
                                                className="form-control"
                                                name="minAmount"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Max value (in coins)</label>
                                            <input
                                                type="number"
                                                value={this.state.maxAmount}
                                                className="form-control"
                                                name="maxAmount"
                                                onChange={this.handleChange}
                                            />
                                        </div>

                                    </>
                                    :
                                    <div className="form-group">
                                        <label>Value (in coins)</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="coinsRequired"
                                            value={coinsRequired}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                            }

                            <div className="form-group">
                                <label>Product Image</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    onChange={this.handleImgPreivew}
                                />
                            </div>



                            {imagePreview && <img className="exercise_category_img" src={imagePreview} />}
                            <div className="text-center">
                                {titleErrorMsg && <h6 className="text-center mb-2 mt-2 error_message">{titleErrorMsg}</h6>}
                                <button className="btn btn-primary" type="submit">Update Product</button>
                            </div>
                        </form>
                    </div>
                </Popup>





                <Popup
                    open={deleteProductPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h6 className="text-center mb-2 mt-2">Are you sure, you want to delete this Product?</h6>
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-danger btn-sm btn-block" onClick={this.deleteProduct}>Delete</button>
                            </div>
                            <div className="col">
                                <button className="btn btn-warning btn-sm btn-block" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Popup>




                <Popup
                    open={viewProductPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="card card_style">
                        <img className="card-img-top" src={imagePreview} alt="Food image" />
                        <span class="card_style_cat">Value : {coinsRequired} Coins</span>
                        <div className="p-3 text-center">
                            <h4 className="card-title three_dots">{productName}</h4>
                            <p className="card-text">{productType == 1 ? "Product" : productType == 2 ? "Deal" : "N/A"} </p>
                        </div>
                    </div>
                </Popup>
            </HOC>
        )
    }
}



export default RewardProducts;
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LoaderSvg } from '../Reusable';
import { getCRMAgentList } from '../../config/services/CRMAgent';
import Pagination from 'react-js-pagination';
import PopUpModal from '../../components/common/PopUpModal';
import Loader from '../loader';
import dateFormat from 'dateformat';
import AddCRMAgent from './AddCRM';
import { Tag, Switch } from 'antd';
import { changeDieticianStatus } from '../../config/services/adminService';
import { handleChangeStatus } from '../utils/Util';

export const CRMAgent = () => {
    const initialState = {
        loading: true,
        crmAgentList: [],
        countPerPage: 10,
        sortType: -1,
        sortField: 'created',
        totalItemCount: 0,
        pageRangeDisplayed: 3,
        error: '',
        apiCalled: false
    }

    let tableHeaders = ['Sr. No', 'Name', 'E-mail', 'Phone No.', 'Role', 'Created Date', 'Status', 'Action'];

    const [state, setState] = useState({ ...initialState });
    const [pageNo, setPageNo] = useState(1)
    const [search, setSearch] = useState("");
    const [error, setError] = useState({});
    const [hideShowAdd, setHideShowAddModal] = useState(false);
    const [hideShowEdit, setHideShowEdit] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState("");


    const getCRMAgent = () => {
    
        let params = {
            params: {
                count: state.countPerPage,
                pageNo: pageNo - 1,
                sortType: state.sortType,
                sortField: state.sortField,
                search: search
            }
        }
        getCRMAgentList(params).then(response => {
            let data;
            if (response.data && response.data.statusCode === 1) {
                data = response.data.responseData
                setState({
                    ...state,
                    crmAgentList: response.data.responseData.adminList,
                    loading: false,
                    totalItemCount: response.data.responseData.totalCount,
                    apiCalled: false
                })
            } else {
                setState({ ...state, apiCalled: false })
            }
        })
    }



    const handlePageChange = (pageNum) => {
        setState({ ...state, loading: true })
        setPageNo(pageNum)
        // getPincode();
    }
    const handleSearch = (e) => {
        setState({
            ...state,
            apiCalled: true
        })
        setSearch(e.target.value);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            getCRMAgent();
        }, 1000);
        return () => clearTimeout(timer);

    }, [pageNo, search]);

    const hideAddPop = () => setHideShowAddModal(false);
    const showAddPop = (key) => {
        setHideShowAddModal(true);
    }
    const handleEditAdminPopup = (data) => {
        setHideShowEdit(true); setSelectedAgent(data)
    };
    const hideEditPop = () => { setHideShowEdit(false); setSelectedAgent("") };
    const handleCRMStatus = (id, status, stateName) => {
        handleChangeStatus(id, status, changeDieticianStatus, this, getCRMAgent, stateName);
    }

    return (
        <div className='body-container-wrapper'>
            {state.apiCalled && <Loader />}
            <div className='body-container'>
                <ol className='breadcrumb'>
                    <li className='breadcrumb-item'><Link to='/dashboard'>Dashboard</Link></li>
                    <li className='breadcrumb-item'>CRM Agent</li>
                </ol>
                {state && state.loading && <div className="loader_wrapper"><LoaderSvg /></div>}
                <div className='users_header'>
                    <div className='container-fluid'>
                        <div className="row align-items-center">
                            <div className="col-md-7 col-sm-12">
                                <h4 className="cm_page_heading">CRM Agent</h4>
                            </div>

                            <div className="col-md-3 col-sm-12 text-right cm_search mr-2">
                                <input
                                    type="text"
                                    className="form-control m-b-md-15"
                                    placeholder="Search by Name or Email Address"
                                    onChange={handleSearch}
                                    value={search}
                                />
                                <i className="fa fa-search" />
                            </div>
                            <div className='row col-md-2 col-sm-12'>
                                <button
                                    onClick={() => showAddPop()}
                                    className="btn btn-info btn-sm btn_sm"
                                > Add CRM Agent </button>
                            </div>

                        </div>
                        <div className='table-responsive'>
                            <table className='table table-bordered table-striped text-center'>
                                <thead>
                                    <tr>{tableHeaders.map((item, index) => (<th key={index} scope="col">{item}</th>))}</tr>
                                </thead>
                                <tbody>
                                    {state.crmAgentList.map((crmData, index) => (
                                        <tr key={index}>
                                            <td> {state.countPerPage * (pageNo - 1) + (index + 1)}</td>
                                            <td className="cm_pos_realtive more_padding">
                                                {crmData.firstName + " " + crmData.lastName}
                                            </td>
                                            <td>{crmData.email}</td>
                                            <td>{crmData.mobileNo}</td>
                                            <td>{crmData.rolesAllowed ? crmData.rolesAllowed.map((item,i) => <span key={i} style={{ marginBottom: '.5rem' }}><Tag color="geekblue">{item.roleName}</Tag></span>) : ""}</td>

                                            <td>{dateFormat(crmData.created, "d mmmm yyyy")}</td>
                                            <td>
                                                <Switch className={`cm_ckeckbox_wrapper cm_ckeckbox_wrapper_height ${crmData.status === 1 ? 'cm_active' : 'cm_inactive'}`}
                                                    onClick={() => handleCRMStatus(crmData._id, crmData.status, "statusLoading")}
                                                    checked={crmData.status === 1}
                                                >
                                                    {/* <span className="cm_ckeckbox_btn"></span> */}
                                                </Switch>
                                            </td>
                                            <td>
                                                <button className="btn btn-dark btn-sm" onClick={() => handleEditAdminPopup(crmData)} >Edit</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={state.countsPerPage}
                                totalItemsCount={state.totalItemCount}
                                pageRangeDisplayed={state.pageRangeDisplayed}
                                onChange={handlePageChange}
                            />
                            {hideShowAdd &&
                                <AddCRMAgent
                                    formOpen={hideShowAdd}
                                    hide={hideAddPop}
                                    getCRMAgent={getCRMAgent}
                                />}
                            {hideShowEdit &&
                                <AddCRMAgent
                                    formOpen={hideShowEdit}
                                    hide={hideEditPop}
                                    getCRMAgent={getCRMAgent}
                                    selectedAgent={selectedAgent}
                                />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CRMAgent;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { getProductJobList, addProductJob, deleteProductJob, editProductJob, assignMultipleJobToAssociate, updateJobStatus, markProductJobUrgent, exportProductJobRequest, updateFromExcel } from '../../config/services/ProductJob';
import { toast } from 'react-toastify';
import { Button, Modal, Alert, FormLabel, Row, Form, FormGroup, FormControl, InputGroup, Spinner } from 'react-bootstrap';
import { getAllVendors, getAllEngineers } from '../../config/services/vendorService';
import { SearchTypes } from './Constants';
import AsyncSelect from 'react-select/async';
import Popup from "reactjs-popup";
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import PopUpModal from '../../components/common/PopUpModal';
import ProductServiceIncident from '../productServiceIncident';
import { Card } from 'react-bootstrap';
import moment from 'moment';
import { DatePicker } from 'antd';
import { Switch } from 'antd';
import Loader from '../loader';

const { RangePicker } = DatePicker;
const { getTableHeader, statusOptions, getValue, TatOption } = require("../utils/Util.js");

const columnDetails = [{ fieldName: "S.No.", dataIndex: "index" },
{ fieldName: "Job Creation Date", isSortable: true, dataIndex: "created", type: "dateTime" },
{ fieldName: "Request Id", dataIndex: "requestId", type: "string" },
{ fieldName: "Job Id", dataIndex: "_id", type: "string" },
{ fieldName: "Order Id", parentDataIndex: "requestServiceData", dataIndex: "orderId", type: "string" },
{ fieldName: "DOR", dataIndex: "registrationDate", type: "date", parentDataIndex: "requestServiceData" },
{ fieldName: "Service Type ", dataIndex: "service", parentDataIndex: "requestServiceData" },
{ fieldName: "Status", dataIndex: "status", type: "combo" },
{ fieldName: "Remarks", dataIndex: "remark" },
{ fieldName: "Is Urgent", dataIndex: "isUrgent" },
{ fieldName: "Is Rescheduled", dataIndex: "isRescheduled" },
{ fieldName: "TAT Performance", dataIndex: "inTat" },
{ fieldName: "Tat Date", dataIndex: "tatDate" }, ,
// { fieldName: "Customer TAT", dataIndex: "tat", parentDataIndex: "requestServiceData" },
{ fieldName: "Vendor", dataIndex: "firstName", parentDataIndex: "vendorData" },
{ fieldName: "Vendor Contact Number", dataIndex: "mobileNo", parentDataIndex: "vendorData" },
{ fieldName: "Associate", dataIndex: "firstName", parentDataIndex: "associateEngineerData" },
{ fieldName: "Associate Contact Number", dataIndex: "mobileNo", parentDataIndex: "associateEngineerData" },
{ fieldName: "Engineer Assigned Date", dataIndex: "engineerAssignDate", type: "dateTime" },
{ fieldName: "Job Close Date", dataIndex: "closeDate", type: "dateTime" },
{ fieldName: "Engineer Schedule", dataIndex: "jobScheduleDateTime", type: "dateTime" },

{ fieldName: "Customer Name", dataIndex: "customerId", type: "string" },
{ fieldName: "Customer Email", dataIndex: "customerEmail", type: "string" },
{ fieldName: "Customer Contact Number", dataIndex: "customerPhNO", type: "string" },
{ fieldName: "City", dataIndex: "city", type: "string" },
{ fieldName: "State", dataIndex: "state", type: "string" },
{ fieldName: "PinCode", dataIndex: "pincode", type: "string" },
{ fieldName: "Action", dataIndex: "action" },
{ fieldName: "Images", dataIndex: "images" },
];

const formField = [
    { fieldName: "Request Id", stateName: "requestId", isRequire: true, type: "text", maxLength: 30, invalidMsg: "Request Id can not be empty" },
    // { fieldName: "Job Type", stateName: "jobTypeId", isRequire: true, type: "text", maxLength: 30, invalidMsg: "Job Type is Require" },
    { fieldName: "Vendor", stateName: "vendorId", isRequire: true, invalidMsg: "Please select vendor" },
    { fieldName: "Associate Engineer", stateName: "associateEngineerId" },
    { fieldName: "Status", stateName: "status", isRequire: true, invalidMsg: "Please select status" }

]

class ProductJob extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            addNewJobLoader: false,
            productJobList: [],
            appliedStatusFilter: [],
            search: '',
            count: 10,
            totalProductJob: 15,
            pageNo: 1,
            sortType: -1,
            sortField: "created",
            addProductJobPopup: false,
            deleteProductJobPopup: false,
            selectedId: "",
            error: "",
            assignEngineer: [],
            requestId: this.props.requestId,
            dateFrom: moment().startOf('day').valueOf(),
            dateTo: moment().endOf('day').valueOf(),
            filterStatus: "",
            filterByTat: 0,
            apiCalled: false,

            requestsExcelSheet: '',
            importExcelPopup: false,
            isUploading: false,
            badEntryErrors: [],
            jobImages: {},
            imagesViewPopup: false,
            remarkViewPopup: false,
            displayRemark: false,

        }
    }

    componentDidMount() {
        this.getProductJobList();
        this.getVendors();
    }

    handleSearchType = (search) => {
        this.setState({ searchField: search.value, searchValue: '', searchError: '' }, function() {
            this.getProductJobList();
        });
    }
    
    handleSearch = (e) => {
        let { value } = e.target
        let { searchField } = this.state

        if (!searchField) {
            this.setState({ searchError: 'Select Search Field' })
        }
        else {
            e.persist();
            this.setState({ searchValue: value, searchError: '', apiCalled: true, pageNo: 1 });
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.setState({ apiCalled: false })
                this.getProductJobList();
            }, 400)
        }
    }


    getProductJobList = (isExport) => {
        let { search, searchField, searchValue, count, pageNo, sortType, sortField, dateFrom, dateTo, vendorFilterData, filterStatus, filterByTat, productJobList } = this.state;
        let { isFromUser, requestId } = this.props;

        console.log('filterStatus:::', filterStatus)
        let paramsObj = {
            params: {
                search, count, pageNo: pageNo - 1, sortType, sortField, searchField, searchValue,
                requestId: requestId,
                dateFrom: !isFromUser ? dateFrom ? moment(dateFrom).startOf('day').valueOf() : null : null,
                dateTo: !isFromUser ? dateTo ? moment(dateTo).endOf('day').valueOf() : null : null,
                filterStatus: filterStatus,
                vendorId: vendorFilterData && vendorFilterData.value,
                filterByTat: filterByTat
            }
        }

        if (isExport) {
            if (productJobList.length > 0) {
                var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
                paramsObj.params.tz = tz;
                exportProductJobRequest(paramsObj).then(res => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        this.setState({ apiCalled: false })
                        window.open(response.responseData.result.Location, "_blank");

                    } else if (response.statusCode == 0) {
                        this.setState({ apiCalled: false })
                        toast.error(response.error.errorMessage)
                    }
                })
            }
            else {
                this.setState({ apiCalled: false })
                toast.error("No data available to export")
            }
        } else {
            getProductJobList(paramsObj)
                .then(res => {
                    let response = res.data
                    if (response.statusCode == 1) {
                        this.setState({
                            productJobList: response.responseData.result.data,
                            totalProductJob: response.responseData.result.totalCount,
                            loader: false,
                            apiCalled: false
                        })
                    } else if (response.statusCode == 0) {
                        this.setState({ loader: false, apiCalled: false })
                        toast.error(response.error.errorMessage)
                    }
                })
        }
    }
    handlePageChange = (pageNumber) => { this.setState({ pageNo: pageNumber }, () => { this.getProductJobList() }) }

    handleClose = () => { this.setState({ addProductJobPopup: false, error: "", jobTypeId: "", vendorId: "", status: "", requestId: "" }) }

    handleAddProductJobPopup = (data) => {
        if (data._id) {
            this.getProductJob(data)
        } else {
            this.setState({ addProductJobPopup: true, selectedId: "", selectedAssociate: [], selectedVendor: [] })
        }
    }

    getProductJob = (job) => {
        let selectedVendor = job.vendorData && [{ label: `${job.vendorData.firstName} ${job.vendorData.lastName}`, value: job.vendorData._id }]
        let selectedAssociate = job.associateEngineerData && [{ label: `${job.associateEngineerData.firstName} ${job.associateEngineerData.lastName}`, value: job.associateEngineerData._id }]
        this.setState({
            // jobTypeId: job.jobTypeId,
            vendorId: job.vendorData._id,
            status: job.status,
            selectedId: job._id,
            requestId: job.requestId,
            addProductJobPopup: true,
            selectedVendor: selectedVendor,
            selectedAssociate: selectedAssociate,
            associateEngineerId: job.associateEngineerData && job.associateEngineerData._id
        })

    }

    handleChange = (e, type, field, length) => {
        type ? this.setState({ [type]: e, error: "" }) : this.setState({ [e.target.name]: e.target.value, error: e.target.value > length ? `${field} has reached max length` : "" });
    }
    handleSubmit = (event) => {
        let { isFromUser, requestId } = this.props;
        
        let self = this;
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            this.setState({ validated: true })
            return;
        }
        if (!self.state["vendorId"] || !Number(self.state["status"])) {
            this.setState({ validated: true })
            return;
        }

        let params = {            
            vendorId: self.state["vendorId"],
            associateEngineerId: self.state["associateEngineerId"],
            status: Number(self.state["status"])
        }

        if(!isFromUser) {
            params.requestId = self.state["requestId"].trim()
        } else {
            params.requestId = requestId.trim()
        }
        self.setState({ addNewJobLoader: true })

        let apiObject = addProductJob(params);
        apiObject.then(res => {
            self.setState({ loader: false, addNewJobLoader: false })
            const response = res.data;
            if (response.statusCode == 1) {
                self.setState({ selectedId: "" })
                toast.success(response.responseData.message)
                self.handleClose();
                self.getProductJobList();
            } else if (response.statusCode == 0) {
                self.setState({ error: response.error.errorMessage })
            }
        })

    }
    handleEditSubmit = (event) => {
        let self = this;
        let isError = false;

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            this.setState({ validated: true })
            return;
        }
        if (!self.state["vendorId"] || !Number(self.state["status"])) {
            this.setState({ validated: true })
            return;
        }

        let params = {
            requestId: self.state["requestId"],
            // jobTypeId: self.state["jobTypeId"],
            vendorId: self.state["vendorId"],
            associateEngineerId: self.state["associateEngineerId"],
            status: Number(self.state["status"])

        }

        self.setState({ addNewJobLoader: true })
        let apiObject = editProductJob(params);
        params["jobId"] = self.state.selectedId;
        apiObject.then(res => {
            self.setState({ loader: false, addNewJobLoader: false })
            const response = res.data;
            if (response.statusCode == 1) {
                self.setState({ selectedId: "" })
                toast.success(response.responseData.message)
                self.handleClose();
                self.getProductJobList();
            } else if (response.statusCode == 0) {
                self.setState({ error: response.error.errorMessage })
            }
        })
    }
    openDeleteProductJobPopup = (e, productJob) => {
        this.setState({ deleteProductJobPopup: !this.state.deleteProductJobPopup, tempJobId: productJob._id })
    };

    deleteProductJob = (e) => {
        e.preventDefault();
        const { tempJobId } = this.state
        let paramsObj = {
            jobId: tempJobId
        }
        deleteProductJob(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    this.closeModal();
                    this.getProductJobList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }
    closeModal = () => this.setState({ deleteProductJobPopup: false, tempJobId: '', importExcelPopup: false, badEntryErrors: [], isUploading: false, requestsExcelSheet: '', validationError: '', validationSuccess: '', imagesViewPopup: false, jobImages: {}, displayRemark: '', remarkViewPopup: false })
        
    getVendors = (val) => {
        let paramsobj = { params: { count: 0, pageNo: 0, search: val } }
        return getAllVendors(paramsobj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    let result = res.data.responseData.vendors;
                    let vendors = []
                    result && result.map(vendor => {
                        vendors.push({ label: `${vendor.firstName} ${vendor.lastName}`, value: vendor._id })
                    })
                    this.setState({ vendors })
                    return vendors
                }
                else if (res.data.statusCode == 0) {
                    console.log(res.data.error.errorMessage)
                }
            })
    }
    // get Engineer data
    getAssociateEngineer = (val) => {
        let paramsobj = { params: { vendorId: this.state.vendorId } }
        return getAllEngineers(paramsobj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    let result = res.data.responseData.engineers;
                    let associateEngineers = []
                    result && result.map(associateEngineer => {
                        associateEngineers.push({ label: `${associateEngineer.firstName} ${associateEngineer.lastName}`, value: associateEngineer._id })
                    })
                    this.setState({ associateEngineers })
                    return associateEngineers
                }
                else if (res.data.statusCode == 0) {
                    console.log(res.data.error.errorMessage)
                }
            })
    }

    loadVendors = (val) => {
        return this.getVendors(val)
    }

    loadOptionByval = (val) => {
        return this.getVendors(val)
    }

    vendorSelect = (vendor) => {
        this.setState({ vendorId: vendor ? vendor.value : "", selectedVendor: vendor, selectedAssociate: [] });
        if (vendor) {
            this.loadAssociateEngineer();
        }
    }

    engineerSelect = (engineer) => {
        this.setState({ associateEngineerId: engineer.value, selectedAssociate: engineer })
    }
    loadAssociateEngineer = (val) => {
        return this.getAssociateEngineer(val)
    }

    onCheckHandle = (e, value) => {
        let { assignEngineer, productJobList } = this.state;
        if (e.target.checked) {
            if (value === "all") {
                productJobList.forEach(element => {
                    !element.isAssigned && assignEngineer.push(element._id);
                });
            }
            else {
                !assignEngineer.includes(value) && assignEngineer.push(value);
            }

        }
        else {
            if (value !== "all") {

                assignEngineer = assignEngineer.filter(item => item !== value)
            }
            else {
                assignEngineer = []
            }

        }
        this.setState({ assignEngineer: assignEngineer })
    }

    assignJobToEngineer = () => {
        let { assignEngineer, assignEnigneerSelectedId } = this.state;
        let paramsObj = {
            jobIds: JSON.stringify(assignEngineer),
            engineerId: assignEnigneerSelectedId
        }
        assignMultipleJobToAssociate(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ assignEngineer: [], assignEnigneerSelected: false });
                    this.getProductJobList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })

    }

    assignEnigneerSelect = (val) => {
        this.setState({ assignEnigneerSelected: !this.state.assignEnigneerSelected, assignEnigneerSelectedId: val ? val.value : "", assignEnigneerSelectedName: val, validationError: this.state.assignEngineer.length == 0 ? " Please Select Jobs " : "" })
    }
    changeStatus = (statusValue, id) => {
        this.setState({ statusPopup: true, selectedStatus: statusValue, selectedJobForJobId: id })
    }
    closeStatusPopup = () => {
        this.setState({ statusPopup: false, selectedStatus: "", selectedJobForJobId: "" })
    }

    changeJobStaus = () => {
        let { selectedJobForJobId, selectedStatus } = this.state;
        let paramsObj = {
            jobId: selectedJobForJobId,
            jobStatus: selectedStatus
        }
        updateJobStatus(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ selectedJobForJobId: "", statusValue: "", statusPopup: false });
                    this.getProductJobList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })

    }

    onHandleSelect = (data, fieldName) => {
        let fieldNameData = `${fieldName}Data`
        this.setState({ [fieldName]: data.label, vendorFilter: data._id, [fieldNameData]: data, apiCalled: true }, () => this.getProductJobList())
    }

    filterByDate = (date) => {
        let dateFrom = null, dateTo = null;
        if (date) {
            dateFrom = moment(date[0]).startOf('day').valueOf()
            dateTo = moment(date[1]).endOf('day').valueOf()
        }
        this.setState({ dateFrom, dateTo, apiCalled: true, pageNo: 1 }, () => { this.getProductJobList() })
    }

    resetFilter = () => {
        this.setState({
            searchField: '', searchValue: '', search: '', dateFrom: moment().startOf('day').valueOf(),
            dateTo: moment().endOf('day').valueOf(), filterStatus: "", appliedStatusFilter: [], filterByTat: 0, pageNo: 1, Loader: true,
            vendorFilterData: []
        }, () => this.getProductJobList())
    }

    handleFilterChange = (value, fieldName) => {
        if( fieldName == 'filterStatus') {   
            let seachStatus = [];
            let foundAll = 0;
            value && value.length && value.map((item) => {
                if(item.label == "ALL") {
                    foundAll = 1;
                } else {
                    seachStatus.push(item.value);
                }      
            });

            if(foundAll == 1) {
                if(value.length > 1) {
                    var lastIndex = value[value.length - 1].label;
                    if(lastIndex == 'ALL') {
                        seachStatus = [];
                        value = [];
                        var status = {};
                        status.label = 'ALL';
                        status.value = '';                
                        value.push(status);
                    } else {
                        value = value.filter(function(item){ return item.label != 'ALL' }) 
                    }
                }               
            }
            
            seachStatus = seachStatus.join();
            this.setState({ appliedStatusFilter: value, [fieldName]: seachStatus, apiCalled: true }, () => this.getProductJobList());           
        } else {
            this.setState({ [fieldName]: value, apiCalled: true }, () => this.getProductJobList());
        }
    }

    handleChangeStatusUrgent(id, status, e) {
        let loadingState = `urgentLoading${id}`
        this.setState({ [loadingState]: true });
        let self = this;
        let obj = {
            jobId: id,
            isUrgent: !status
        }

        markProductJobUrgent(obj)
            .then((res) => {
                if (res.data.statusCode === 1) {
                    self.getProductJobList();
                    this.setState({ [loadingState]: false })
                }
                else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }
    onJobClick = (jobId) => {
        this.setState({ selectedJobId: jobId }, () => this.getProductJobList());
    }

    onCustomerClick = (jobId) => {
        var win = window.open('/profile/' + jobId, '_blank')
        win.focus();
    }

    // excel export function
    showHideExport = () => {
        this.setState({ showHideExport: true });
    }

    exportHandler = () => {
        this.setState({ apiCalled: false, showHideExport: false }, function () {
            this.getProductJobList(true)
        });
    }

    // excel import process
    importHandler = () => {
        this.setState({ importExcelPopup: true, validationError: '', validationSuccess: '' })
    }

    handleFileDrop = (accepted, rejected) => {
        if (accepted && accepted.length) {
            this.setState({ requestsExcelSheet: accepted[0], validationError: '', validationSuccess: '' })
        }
        else if (rejected && rejected.length) {
            this.setState({ validationError: "This file format is not supported. Format should be 'xls, xlsx or csv'", requestsExcelSheet: '' })
        }
    }

    submitValidateExcelUpload = (e) => {
        e.preventDefault();
        const { requestsExcelSheet } = this.state;
        if (!requestsExcelSheet) {
            this.setState({ validationError: 'Please select a file!' })
            return;
        }
        else {
            let params = new FormData()
            params.append('requestsExcelSheet', requestsExcelSheet);
            this.setState({ validationError: '', isUploading: true })
            updateFromExcel(params)
                .then(res => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        this.setState({ validationSuccess: 'file imported successfully' });
                        // let responseData = response.responseData.result;
                        // if(responseData.successUpdateJobId) {
                        //     let getSuccess = '';
                        //     responseData.successUpdateJobId.forEach(e => {
                        //         var err = '<div>'
                        //         err += e;
                        //         err += '</div>';   
                        //         getSuccess += err;  
                        //     })
                        //     this.setState({ validationSuccess: getSuccess });
                        // }
                        // if(responseData.failUpdateJobId && responseData.failUpdateJobId.length > 0) {
                        //     let getError = '';
                        //     responseData.failUpdateJobId.forEach(error => {
                        //         var err = '<div>'
                        //         err += error.JobId;
                        //         if(error.err.length > 0) {
                        //             err += ' : ';
                        //             error.err.forEach(e => {
                        //                 err += e;
                        //             })
                        //         }
                        //         err += '</div>';   
                        //         getError += err;                             
                        //     })
                        //     this.setState({ validationError: getError });
                        // }         
                        // importExcelPopup: false,      
                        this.setState({ isUploading: false })
                    } else if (response.statusCode == 0) {
                        this.setState({ isUploading: false })
                        this.setState({ validationError: response.error.errorMessage })
                    }
                    this.setState({ isUploading: false })
                })
        }
    }
   
    openRemarkView = (remark) => {
        this.setState({ remarkViewPopup: true, displayRemark: remark });  
    }

    openImageVideoView = (jobDetails) => {
        let { afterImage, beforeImage, afterImages, beforeImages } = jobDetails;
        let { jobImages } = this.state;
        
        jobImages['beforeImage'] = beforeImage ? beforeImage : '';
        jobImages['afterImage'] = afterImage ? afterImage : '';
        jobImages['beforeImages'] = beforeImages && beforeImages.length > 0 ? beforeImages : '';
        jobImages['afterImages'] = afterImages && afterImages.length > 0 ? afterImages : '';
        jobImages['before_file_type'] = '';
        jobImages['before_img_url'] = '';
        jobImages['before_video_url'] = '';
        jobImages['before_video_mimetype'] = '';
        jobImages['after_file_type'] = '';
        jobImages['after_img_url'] = '';
        jobImages['after_video_url'] = '';
        jobImages['after_video_mimetype'] = '';

        if(beforeImages && beforeImages[0] && beforeImages[0].url) {
            jobImages['before_file_type'] = beforeImages[0].file_type;
            jobImages['before_img_url']   = beforeImages[0].file_type == 'image'?beforeImages[0].url:beforeImages[0].thumbnail;
            jobImages['before_video_url'] = beforeImages[0].url;
            jobImages['before_video_mimetype'] = beforeImages[0].mimetype;
        }
        if(afterImages && afterImages[0] && afterImages[0].url) {
            jobImages['after_file_type'] = afterImages[0].file_type;
            jobImages['after_img_url']   = afterImages[0].file_type == 'image'?afterImages[0].url:afterImages[0].thumbnail;
            jobImages['after_video_url'] = afterImages[0].url;
            jobImages['after_video_mimetype'] = afterImages[0].mimetype;
        }

        this.setState({ imagesViewPopup: true, jobImages });      
    }

    openBeforeImageVideoView = (file) => {
        let { jobImages } = this.state;
        jobImages['before_file_type'] = '';
        jobImages['before_img_url'] = '';
        jobImages['before_video_url'] = '';
        jobImages['before_video_mimetype'] = '';
        if(file && file.url) {
            jobImages['before_file_type'] = file.file_type;
            jobImages['before_img_url']   = file.file_type == 'image'?file.url:file.thumbnail;
            jobImages['before_video_url'] = file.url;
            jobImages['before_video_mimetype'] = file.mimetype;
        }
        this.setState({ jobImages }); 
    }

    openAfterImageVideoView = (file) => {
        let { jobImages } = this.state;
        jobImages['after_file_type'] = '';
        jobImages['after_img_url'] = '';
        jobImages['after_video_url'] = '';
        jobImages['after_video_mimetype'] = '';
        if(file && file.url) {
            jobImages['after_file_type'] = file.file_type;
            jobImages['after_img_url']   = file.file_type == 'image'?file.url:file.thumbnail;
            jobImages['after_video_url'] = file.url;
            jobImages['after_video_mimetype'] = file.mimetype;
        }
        this.setState({ jobImages }); 
    }


    render() {
        let {
            loader, productJobList, search, pageNo, count, totalProductJob, addProductJobPopup, error, deleteProductJobPopup, vendors, associateEngineers, assignEngineer, assignEnigneerSelected, assignEnigneerSelectedName, validationError, validationSuccess,
            dateFrom, dateTo, statusPopup, validated, urgentLoading, apiCalled, importExcelPopup, badEntryErrors, isUploading, jobImages, imagesViewPopup, appliedStatusFilter, addNewJobLoader } = this.state;
        let { isFromUser, requestId } = this.props;
        let isRequestIdLinked = this.props.history ? true : false;
        if (!this.state["selectedJobId"] && productJobList.length > 0) {
            this.state["selectedJobId"] = productJobList[0]._id;

        }

        return (
            <HOC>
                {apiCalled && <Loader />}
                <div className={!isFromUser ? "body-container-wrapper" : ""} >
                    <div className={!isFromUser ? "body-container" : ""}>
                        <ol className="breadcrumb" style={{ display: isFromUser ? "none" : "" }}>
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Product Job</li>
                        </ol>

                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}
                        <div className="users_header" style={{ display: isFromUser ? "none" : "" }}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="cm_page_heading">Product Job</h4>
                                    </div>
                                    <div className="col-md-6 list-inline-item text-right">
                                        {!isFromUser ? <button className="btn btn-info mt-2" onClick={this.handleAddProductJobPopup}>Add New Job</button> : ''}
                                        <Button className='ml-1 mt-2' type="primary" onClick={this.importHandler}>Import</Button>
                                        {/* {productJobList.length ? <ExportExcel  productJobs={productJobList} /> : null} */}
                                        <Button disabled={apiCalled} className='ml-1 mt-2' type="primary" onClick={this.showHideExport}>Export</Button>
                                    </div>

                                </div>
                            </div>
                        </div>

                        

                        {!isFromUser && <div className="row mb-2">
                            <div className="col-md-12 align-items-baseline d-inline-flex">

                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item mt-2">
                                        <label inline className="mr-2">Filter By Date:</label>
                                        <RangePicker
                                            style={{ height: "2.8em" }}
                                            size={"middle"}
                                            onChange={(val) => this.filterByDate(val)}
                                            value={dateFrom && dateTo && [moment(dateFrom), moment(dateTo)]}
                                            defaultValue={[moment(dateFrom), moment(dateTo)]}
                                        />
                                    </li>

                                    <li className="list-inline-item mt-2">
                                        <Select
                                            isMulti
                                            className='cm_min_width'
                                            onChange={(e) => this.handleFilterChange(e, "filterStatus")}
                                            placeholder="Status"
                                            options={statusOptions}
                                            value={appliedStatusFilter}
                                        />
                                    </li>

                                    <li className="list-inline-item mt-2">
                                        <AsyncSelect
                                            className='cm_min_width'
                                            isDisabled={false}
                                            cacheOptions
                                            value={this.state["vendorFilterData"]}
                                            loadOptions={this.loadVendors}
                                            onFocus={() => this.loadVendors()}
                                            defaultOptions={vendors}
                                            onChange={(val) => this.onHandleSelect(val, "vendorFilter")}
                                            placeholder="Select Vendor"
                                        />
                                    </li>
                                    <li className="list-inline-item mt-2">
                                        <Select
                                            className='cm_min_width'
                                            onChange={(e) => this.handleFilterChange(e.value, "filterByTat")}
                                            placeholder="TAT"
                                            options={TatOption}
                                            value={TatOption.find(element => element.value === this.state.filterByTat)}

                                        />
                                    </li>
                                    <li className="list-inline-item mt-2">
                                        <Select
                                            isSearchable
                                            options={SearchTypes}
                                            placeholder="Search Field"
                                            className="cm_min_filter_width"
                                            onChange={(val) => this.handleSearchType(val)}
                                        />
                                    </li>
                                    <li className="list-inline-item mt-2">
                                        <FormControl id="search" className="h-auto" name='search' value={this.state.searchValue} placeholder="Search here......" onChange={this.handleSearch} />
                                    </li>
                                    
                                    <li className="list-inline-item">
                                        <Button variant="outline-dark" className="mr-2" size='sm' onClick={this.resetFilter} > <i className="fa fa-refresh" /></Button>
                                    </li>

                                    {/* <li className="list-inline-item">
                                        <button className="btn btn-info btn-block" onClick={this.handleAddProductJobPopup}>Add New Job</button>
                                    </li> */}
                                </ul>
                            </div>

                       

                          

                        </div>
                        }


                        <div className="table-responsive" style={{ minHeight: '500px', paddingBottom: '13rem' }}>
                            <table className="table table-bordered table-striped text-center mb-5">
                                {getTableHeader(columnDetails)}
                                <tbody>
                                    {
                                        productJobList && productJobList.map((details, i) => {
                                            return (
                                                <tr key={i}>
                                                    {
                                                        columnDetails.map((item) => {
                                                            let col = [];
                                                            if (item.dataIndex == "index") {
                                                                col.push(<td>{((i + 1) + ((pageNo - 1) * count))}</td>)
                                                            }
                                                            else if (item.dataIndex == "isUrgent") {
                                                                col.push(<td>
                                                                    <Switch loading={this.state[`urgentLoading${details["_id"]}`]} className={`cm_ckeckbox_wrapper cm_ckeckbox_wrapper_height ${details["isUrgent"] === true ? 'cm_active' : 'cm_inactive'}`}
                                                                        onClick={(e) => this.handleChangeStatusUrgent(details["_id"], details["isUrgent"], e)}
                                                                        checked={details["isUrgent"] === true}
                                                                    >
                                                                        {/* <span className="cm_ckeckbox_btn"></span> */}
                                                                    </Switch>
                                                                </td>)
                                                            }
                                                            else if (item.dataIndex == "status") {
                                                                col.push(<td className="cm_no_wrap">
                                                                    {
                                                                        <Select
                                                                            className='cm_min_width min_height_new'
                                                                            onChange={(e) => this.changeStatus(e.value, details._id)}
                                                                            placeholder="Select..."
                                                                            options={statusOptions.filter((item) => item.value)}
                                                                            value={statusOptions.find(status => status.value === details.status)}
                                                                            optionClassName="option-AS"
                                                                        />}
                                                                </td>

                                                                )
                                                            }
                                                            else if (item.dataIndex === "requestId") {
                                                                col.push(<td>{isRequestIdLinked && details.requestServiceData &&
                                                                    <Link to={`/service/${details.requestServiceData["userId"]}/productService`}>{details[item.dataIndex]} </Link>
                                                                    ||
                                                                    details[item.dataIndex]}</td>);
                                                            }
                                                            else if (item.dataIndex === "_id") {
                                                                col.push(<td><Link to="#" onClick={() => this.onJobClick(details["_id"])} >{details["_id"]}</Link></td>);
                                                            }
                                                            else if (item.dataIndex === "isRescheduled") {
                                                                col.push(<td className="cm_no_wrap"> {details["isRescheduled"] && details["isRescheduled"] == true && "True" || "False"}</td>)
                                                            }
                                                            else if (item.dataIndex === "tatDate") {
                                                                let dateToRender = moment(details["created"]).add(Number(details["requestServiceData"] && details["requestServiceData"]["pincodeData"] && details["requestServiceData"]["pincodeData"].tat) || 0, "days").format('Do MMM YY');
                                                                col.push(<td className="cm_no_wrap"> {dateToRender}</td>)
                                                            }
                                                            else if (item.dataIndex === "inTat") {
                                                                let calculteTatDay = moment().diff(details["created"], 'days');
                                                                col.push(<td className="cm_no_wrap"> {calculteTatDay <= details && details.requestServiceData && ["requestServiceData"]["pincodeData"].tat ? "In Tat" : "Out Tat"}</td>)
                                                            }
                                                            else if (item.dataIndex === "customerId") {
                                                                col.push(
                                                                    <td>
                                                                        {details["customerData"] ?
                                                                            <Link to="#" onClick={() => this.onCustomerClick(details["customerData"]["_id"])} >{details["customerData"]["firstName"]} {details["customerData"]["lastName"]}</Link>
                                                                            : null}
                                                                    </td>
                                                                );
                                                            }
                                                            else if (item.dataIndex === "customerEmail") {
                                                                col.push(
                                                                    <td>
                                                                        {details["customerData"] ? details["customerData"]["email"] : null}
                                                                    </td>
                                                                );
                                                            }
                                                            else if (item.dataIndex === "customerPhNO") {
                                                                col.push(
                                                                    <td>
                                                                        {details["customerData"] ? details["customerData"]["fullMobileNo"] : null}
                                                                    </td>
                                                                );
                                                            }
                                                            else if (item.dataIndex === "city") {
                                                                col.push(<td>{details["requestServiceData"] && details["requestServiceData"]["pincodeData"] ? details["requestServiceData"]["pincodeData"]['city'] : null}</td>);
                                                            }
                                                            else if (item.dataIndex === "state") {
                                                                col.push(<td>{details["requestServiceData"] && details["requestServiceData"]["pincodeData"] ? details["requestServiceData"]["pincodeData"]["state"] : null}</td>);
                                                            }
                                                            else if (item.dataIndex === "pincode") {
                                                                col.push(<td>{details["requestServiceData"] && details["requestServiceData"]["pincodeData"] ? details["requestServiceData"]["pincodeData"]["pincode"] : null}</td>);
                                                            }
                                                            else if (item.dataIndex === "remark") {
                                                                col.push(<td> <span className="cm_table_three_dots" onClick={() => this.openRemarkView(details["remark"])}> {details["remark"] ? details["remark"] : null} </span></td>)
                                                            }
                                                            else if (item.dataIndex === "images") {
                                                                col.push(
                                                                    (details && ((details.afterImages && details.afterImages.length > 0) || (details.beforeImages && details.beforeImages.length > 0))) ?
                                                                        <td className="cm_no_wrap"><button className="btn btn-info btn-sm" onClick={() => this.openImageVideoView(details)}>View</button></td>
                                                                        : 
                                                                        (details && ((details.afterImage) || (details.beforeImage))) ?
                                                                        <td className="cm_no_wrap"><button className="btn btn-info btn-sm" onClick={() => this.openImageVideoView(details)}>View</button></td>
                                                                        :
                                                                        'N/A'
                                                                    )
                                                            }
                                                            else if (item.dataIndex != "action") {
                                                                col.push(<td><span className="cm_table_three_dots">
                                                                    {
                                                                        item.parentDataIndex ? details[item.parentDataIndex] && getValue(item.type, details[item.parentDataIndex][item.dataIndex]) || "" : item.dataIndex == "status" ? statusOptions.find(o => o.value === details[item.dataIndex]) && statusOptions.find(o => o.value === details[item.dataIndex]).label : getValue(item.type, details[item.dataIndex])}</span>
                                                                </td>)
                                                            }
                                                            else if (item.dataIndex === "action") {
                                                                col.push(<td className="cm_no_wrap"> <button className="btn btn-dark btn-sm" onClick={() => this.handleAddProductJobPopup(details)} >Edit</button>

                                                                &nbsp;&nbsp;
                                                                <button className="btn btn-danger btn-sm" onClick={(e) => this.openDeleteProductJobPopup(e, details)}>Delete</button>

                                                                </td>)
                                                            }
                                                            return col

                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                            {!productJobList.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}
                        </div>
                        <br />
                        <Pagination
                            activePage={pageNo}
                            itemsCountPerPage={count}
                            totalItemsCount={totalProductJob}
                            pageRangeDisplayed={3}
                            onChange={this.handlePageChange}
                        />
                        {(this.state["selectedJobId"]) && < Card >
                            <Card.Header>
                                <h4 className="cm_page_heading">Service Incident Manangement</h4>
                            </Card.Header>
                            <Card.Body>
                                <ProductServiceIncident requestId={requestId} jobId={this.state["selectedJobId"]} />
                            </Card.Body>
                        </Card>}
                    </div>

                </div>

                <Modal show={addProductJobPopup} onHide={this.handleClose} centered size="lg" backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>{this.state.selectedId ? "Edit Job" : "Add New Job"}</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                        <Form noValidate validated={validated} onSubmit={(e) => { !this.state.selectedId ? this.handleSubmit(e) : this.handleEditSubmit(e) }} className="d-flex justify-content-center">
                            <div className="col-md-9">
                                {!isFromUser && <FormGroup className="form-group row">
                                    <FormLabel className="col-md-4">Request Id</FormLabel>
                                    <FormControl
                                        maxLength={30}
                                        className="form-control col-md-6"
                                        name="requestId"
                                        type="text"
                                        required
                                        onChange={(e) => this.handleChange(e, "", "RequestId", 30)}
                                        value={this.state["requestId"]}
                                        placeholder="Request Id"


                                    ></FormControl>
                                    <Form.Control.Feedback type="invalid">
                                        <FormLabel className="col-md-4"></FormLabel>
                                        <FormLabel className="col-md-6"> {"Request Id is required"}</FormLabel>

                                    </Form.Control.Feedback>
                                </FormGroup>
                                }
                                {/* {<FormGroup className="form-group row">
                                    <FormLabel className="col-md-4">Job Type</FormLabel>
                                    <FormControl
                                        minLength={3}
                                        maxLength={50}
                                        className="form-control col-md-6"
                                        name="jobTypeId"
                                        type="text"
                                        required
                                        onChange={(e) => this.handleChange(e, "", "job Type", 30)}
                                        value={this.state["jobTypeId"]}
                                        placeholder="Job TypeId"

                                    ></FormControl>
                                    <Form.Control.Feedback type="invalid">
                                        <FormLabel className="col-md-4"></FormLabel>
                                        <FormLabel className="col-md-6"> {"Job TypeId is required"}</FormLabel>

                                    </Form.Control.Feedback>

                                </FormGroup>
                                } */}
                                {
                                    <FormGroup className="form-group row">
                                        <FormLabel className="col-md-4">Vendor</FormLabel>
                                        <AsyncSelect
                                            cacheOptions
                                            className="col-md-6 pl-0 pr-0"
                                            require={true}
                                            cacheOptions
                                            value={this.state["selectedVendor"]}
                                            loadOptions={this.loadOptionByval}
                                            onFocus={() => this.loadOptionByval()}
                                            defaultOptions={vendors}
                                            onChange={(val) => this.vendorSelect(val)}
                                        />
                                        <Form.Control.Feedback type="invalid" style={{ display: !this.state.vendorId && "block" || "none" }}>
                                            <FormLabel className="col-md-4"></FormLabel>
                                            <FormLabel className="col-md-6"> {validated && !this.state.vendorId && "Please select vendor"}</FormLabel>
                                        </Form.Control.Feedback> </FormGroup>
                                }
                                {
                                    <FormGroup className="form-group row">
                                        <FormLabel className="col-md-4">Associate Engineer</FormLabel>
                                        <AsyncSelect
                                            className="col-md-6 pr-0 pl-0"
                                            isDisabled={false}
                                            value={this.state["selectedAssociate"]}
                                            cacheOptions
                                            defaultOptions={associateEngineers}
                                            loadOptions={this.loadAssociateEngineer}
                                            onChange={(val) => this.engineerSelect(val)}
                                        />
                                    </FormGroup>
                                }
                                {
                                    <FormGroup className="form-group row">
                                        <FormLabel className="col-md-4">Status</FormLabel>
                                        <Select
                                            className="col-md-6 pr-0 pl-0"
                                            onChange={(e) => this.handleChange(e.value, "status")}
                                            placeholder="Select..."
                                            options={statusOptions.filter((item) => item.value)}
                                            value={statusOptions.find(status => status.value && status.value === this.state["status"])}
                                        />
                                        <Form.Control.Feedback type="invalid" style={{ display: !this.state["status"] && "block" || "none" }}>
                                            <FormLabel className="col-md-4"></FormLabel>
                                            <FormLabel className="col-md-6"> {validated && !this.state["status"] && "Status is required"}</FormLabel>

                                        </Form.Control.Feedback>
                                    </FormGroup>
                                }
                                {error && <Row className="justify-content-center">
                                    <h4 className="text-center mb-2  error_message">{error}</h4>

                                </Row>
                                }
                                <Row className="justify-content-center">
                                    <Button variant="danger" onClick={this.handleClose}>Cancel</Button>                                    
                                    < Button type="submit" className="ml-2" variant="success"  disabled={addNewJobLoader}>
                                    {!addNewJobLoader ? <span>Submit</span> :
                                        <div>
                                            <i className="fa fa-spinner fa-pulse fa-2x fa-fw margin-bottom"></i>
                                            <span>Submitting....</span>
                                        </div>
                                    }
                                    </Button>

                                </Row>
                            </div>
                        </Form>

                    </Modal.Body>
                </Modal >

                <PopUpModal isOpen={this.state["showHideExport"]} closeModal={() => this.setState({ showHideExport: false })} submit={this.exportHandler} message={`Are you sure to export`} submitTitle="Export" />

                <Modal show={importExcelPopup} onHide={this.closeModal} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Upload File!</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Dropzone multiple={false} onDrop={(acceptedFiles, rejectedFiles) => this.handleFileDrop(acceptedFiles, rejectedFiles)} accept='.xlsx, .xls, .csv'>
                            {({ getRootProps, getInputProps, isDragActive, acceptedFiles }) => (
                                <section>
                                    <div {...getRootProps()} className="dropzone text-center"> <input {...getInputProps()} />
                                        <i className="fa fa-cloud-upload" />
                                        {isDragActive ? <p>Drop it like it's hot!</p> : acceptedFiles.length ? <p>{acceptedFiles[0].name}</p> : <p>Drag file or click to upload</p>}
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </Modal.Body>

                    {validationSuccess && <Alert variant="success"> {validationSuccess} </Alert>}
                    {validationError && <Alert variant="danger"> {validationError} </Alert>}
                    {badEntryErrors.length > 0 && <Alert variant="danger">
                        {badEntryErrors.map(err => {
                            return (
                                <>
                                    <span>Row: {err.rowNumber}, &nbsp;&nbsp; </span>
                                </>
                            )
                        })}
                    </Alert>}


                    <Modal.Footer>
                        <Button variant="danger" onClick={this.closeModal} size="sm">Cancel</Button>
                        <Button disabled={validationError} variant="success" onClick={this.submitValidateExcelUpload} disabled={validationError || isUploading} size="sm">
                            {isUploading ? <><Spinner animation="border" variant="warning" size="sm" /> Uploading..</> : 'Upload'}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.remarkViewPopup} onHide={this.closeModal} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Remark</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div> {this.state.displayRemark} </div>
                    </Modal.Body>
                </Modal>


                <Popup
                    open={deleteProductJobPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h5 className="text-center mb-4 mt-5">Are you sure, you want to delete this Job?</h5>
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-danger btn-sm btn-block" onClick={this.deleteProductJob}>Delete</button>
                            </div>

                            <div className="col">
                                <button className="btn btn-warning btn-sm btn-block" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Popup>

                <PopUpModal isOpen={assignEnigneerSelected} closeModal={this.assignEnigneerSelect} submit={this.assignJobToEngineer} message={`Are you sure to assign selected jobs to ${assignEnigneerSelectedName && assignEnigneerSelectedName.label} ?`} validationError={validationError} />
                <PopUpModal isOpen={statusPopup} closeModal={this.closeStatusPopup} submit={this.changeJobStaus} message={`Are you sure to change the status of job`} submitTitle="Update Status" />


                {imagesViewPopup && <Modal show={imagesViewPopup} onHide={this.closeModal} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Job Images/Videos</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="card card_style">
                            <div className="card-body  text-center">
                                <div className="form-group">
                                    <div className='mt-2'>
                                        <p><b>Before Image/Video</b></p>
                                        {jobImages.beforeImages.length > 0 ?
                                            <div className="img_gallery">
                                                <div className="sidereel">
                                                    {jobImages.beforeImages && jobImages.beforeImages.length > 0 && jobImages.beforeImages.map(file => (
                                                        <>
                                                            {file && file.file_type == 'image' ?
                                                                <div className="imagereel" onClick={() => this.openBeforeImageVideoView(file)}>
                                                                    <img title='Before Image' src={file.url} />
                                                                </div>
                                                                : 
                                                                <div className="videoreel" onClick={() => this.openBeforeImageVideoView(file)}>
                                                                    <img title='Before Video' src={file.thumbnail} />
                                                                    <i class="fa fa-video-camera"></i>
                                                                </div>
                                                            }
                                                        </>
                                                    ))}
                                                </div>
                                                <div className="fullimgslider">
                                                    {jobImages.before_file_type == 'video' ?
                                                        <video
                                                            className="cm_video"
                                                            src={jobImages.before_video_url}
                                                            type={jobImages.before_video_mimetype}
                                                            poster={jobImages.before_img_url}
                                                            controls
                                                        />                                                                
                                                        : 
                                                        <img title='Before Image' src={jobImages.before_img_url}  />                                                                
                                                    }
                                                </div>
                                            </div>
                                            : 
                                            jobImages.beforeImage ?
                                                <>
                                                <div className="img_gallery">
                                                    <div className="sidereel">
                                                        <div className="imagereel">
                                                            <img title='Before Image' src={jobImages.beforeImage} />
                                                        </div>
                                                    </div>                                               
                                                    <div className="fullimgslider">
                                                        <img title='Before Image' src={jobImages.beforeImage}  />  
                                                    </div>
                                                </div>
                                                </>
                                            :'N/A'
                                        }
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className='mt-2'>
                                        <p><b>After Image/Video</b></p>
                                        {jobImages.afterImages.length > 0 ?
                                            <div className="img_gallery">
                                                <div className="sidereel">
                                                    {jobImages.afterImages && jobImages.afterImages.map(file => (
                                                        <>
                                                            {file && file.file_type == 'image' ?
                                                                <div className="imagereel" onClick={() => this.openAfterImageVideoView(file)}>
                                                                    <img title='Before Image' src={file.url} />
                                                                </div>
                                                                : 
                                                                <div className="videoreel" onClick={() => this.openAfterImageVideoView(file)}>
                                                                    <img title='Before Video' src={file.thumbnail} />
                                                                    <i class="fa fa-video-camera"></i>
                                                                </div>
                                                            }
                                                        </>
                                                    ))}
                                                </div>
                                                <div className="fullimgslider">
                                                    {jobImages.after_file_type == 'video' ?
                                                        <video
                                                            className="cm_video"
                                                            src={jobImages.after_video_url}
                                                            type={jobImages.after_video_mimetype}
                                                            poster={jobImages.after_img_url}
                                                            controls
                                                        />                                                                
                                                        : 
                                                        <img title='Before Image' src={jobImages.after_img_url}  />                                                                
                                                    }
                                                </div>
                                            </div>
                                        :
                                        jobImages.afterImage ?
                                            <>
                                            <div className="img_gallery">
                                                <div className="sidereel">
                                                    <div className="imagereel">
                                                        <img title='Before Image' src={jobImages.afterImage} />
                                                    </div>
                                                </div>                                               
                                                <div className="fullimgslider">
                                                    <img title='Before Image' src={jobImages.afterImage}  />  
                                                </div>
                                            </div>
                                            </>
                                        :'N/A'
                                        }
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="danger" onClick={this.closeModal} size="sm">Close</Button>
                    </Modal.Footer>
                </Modal>}
            </HOC >
        )
    }
}




export default ProductJob;
import axios from 'axios';
import API from '../Api';

export function getServiceList(params) {
    return axios.get(API.GET_ADMINSERVICE, params)
}

export function addRequestsFromExcel(params) {
    return axios.post(API.ADD_REQUESTS_FROM_EXCEL, params)
}

export function findExistingOrderIdFromExcel(params) {
    return axios.post(API.FIND_EXISTING_ORDERID_FROM_EXCEL, params)
}

export function addMultipleRequestsByAdmin(params) {
    return axios.post(API.ADD_MULTI_REQUESTS_BY_ADMIN, params)
}

export function addRequestByAdmin(params) {
    return axios.post(API.ADD_REQUEST_BY_ADMIN, params)
}

export function deleteRequest(params) {
    return axios.post(API.DELETE_REQUEST, params)
}

export function assignRequestToVendor(params) {
    return axios.post(API.ASSIGN_REQ_TO_VENDOR, params)
}
export function updateRequestStatus(params) {
    return axios.post(API.CHANGE_REQUEST_STATUS, params)

}
export function markSelfDone(params) {
    return axios.post(API.MARK_REQUEST_SELFDONE, params)
}

export function exportServiceRequest(params) {
    return axios.get(API.EXPORT_SERVICE_REQUEST, params);
}
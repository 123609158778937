import React from 'react';
import FoodPlaceholder from '../../assets/images/FoodPlaceholder.jpg';
const FoodItem = ({label, proteinContent}) =>{
    return (
        <div className="col">
             <div> <p>{label}</p></div>
             <div> <p>{proteinContent} gm</p></div>
        </div>
    )
}


const FoodCard = ({foodItem}) => {
    return (
        <div className="about_food">
        <img src={foodItem.foodImage ? foodItem.foodImage : FoodPlaceholder} alt="" />
        <div className="food_content_bg">
            <h4>{foodItem.foodName}</h4>
            <div className="row">
                <div className="col"> <h6>{foodItem.calorieContent} cal</h6></div>
                <div className="col"> <h6>Qty. {foodItem.quantity}.0 {foodItem.unit}</h6></div>
            </div>

            <div className="row">
                <FoodItem 
                    label= "Protein"
                    proteinContent={foodItem.proteinContent}
                />
                <FoodItem 
                    label= "Fat"
                    proteinContent={foodItem.fatContent}
                />
                <FoodItem 
                    label= "Carbs"
                    proteinContent={foodItem.carbContent}
                />
                <FoodItem 
                    label= "Fibre"
                    proteinContent={foodItem.fibreContent}
                />
            </div>
        </div>
    </div>
    );
};

export default FoodCard;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup"
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import _ from 'lodash';
import { addServicePolicy, getServicePolicyV2, updateServicePolicy, deleteServicePolicy } from '../../config/services/servicePolicyService';
import { getCustomerTypesList } from '../../config/services/CustomerTypesService';
import { getCustomerServiceTypesList } from '../../config/services/CustomerServiceTypesService';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Modal, Button, Form, Alert } from 'react-bootstrap';


class ServicePolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            servicePolicyList: [],
            customerTypesList: [],
            getCustomerServiceTypesList: [],
            customerServiceTypesList: [],
            customerServiceType: [{}],
            customerType: '',
            versionName: '',
            version: '',
            search: '',
            count: 10,
            totalServicePolicy: 15,
            pageNo: 1,
            sortType: '',
            sortField: '',
            AddServicePolicyPopup: false,
            deleteServicePolicyPopup: false,
            viewServicePolicyPopup: false,
            title: '',
            validationError: '',
            updateServicePolicyPopup: false,
            ServicePolicyId: "",

            installation: {},
            maintenance: {},
            dieticianConsultation: {},
            trainerConsultation: {},
            doctorConsultation: {},
            exercisePlan: {},
            dietPlan: {},
            sessions: {},
            healthCoins: {},
            outdoor: {},
            gaming: {},
        }
    }


    componentDidMount() {
        this.getServicePolicyV2();
        this.getCustomerTypesList();
        this.getCustomerServiceTypesList();
    }

    getCustomerTypesList = () => {
        getCustomerTypesList()
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    response.responseData.result.customerTypes.map((data, i) => {
                        data.label = data.customerType
                        data.value = data._id
                    })
                    this.setState({
                        customerTypesList: response.responseData.result.customerTypes,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    getCustomerServiceTypesList = () => {

        getCustomerServiceTypesList()
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {

                    response.responseData.result.customerServiceTypes.map((data, i) => {

                        data.label = data.customerServiceType
                        data.value = data._id
                    })
                    this.setState({
                        customerServiceTypesList: response.responseData.result.customerServiceTypes,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }


    getServicePolicyV2 = () => {
        let { search, count, pageNo, sortType, sortField } = this.state;
        let paramsObj = {
            params: {
                search, count, pageNo: pageNo - 1, sortType, sortField
            }
        }
        getServicePolicyV2(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {

                    response.responseData.result.servicePolicy.map((data, i) => {
                        data.customerTypeId.label = data.customerTypeId.customerType
                        data.customerTypeId.value = data.customerTypeId._id

                        // data.customerServiceType.map((data2, i) => {
                        //     data2.customerServiceTypeId.label = data2.customerServiceTypeId.customerServiceType
                        //     data2.customerServiceTypeId.value = data2.customerServiceTypeId._id
                        // })
                    })
                    this.setState({
                        servicePolicyList: response.responseData.result.servicePolicy,
                        totalServicePolicy: response.responseData.result.servicePolicyCount,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    openAddServicePolicyPopup = () => this.setState({ AddServicePolicyPopup: !this.state.AddServicePolicyPopup });

    closeModal = () => this.setState({
        AddServicePolicyPopup: false,
        customerTypeId: '',
        customerServiceTypeId: '',
        customerServiceType: [{}],
        versionName: '',
        version: '',
        deleteServicePolicyPopup: false,
        updateServicePolicyPopup: false,
        viewServicePolicyPopup: false,
        servicePolicyId: '',
        validationError: '',
        installation: {},
        maintenance: {},
        dieticianConsultation: {},
        trainerConsultation: {},
        doctorConsultation: {},
        exercisePlan: {},
        sessions: {},
        dietPlan: {},
        healthCoins: {},
        outdoor: {},
        gaming: {}
    });

    handleChange = (e, i) => {
        let { name, value } = e.target
        this.setState({ [name]: value, validationError: '' })
    }
    handleSelectChange = (customerTypeId) => {
        this.setState({ customerTypeId, validationError: '' })
    }

    handleCustomerServiceTypeId = (data, i) => {
        let { customerServiceType } = this.state
        customerServiceType[i]['customerServiceTypeId'] = data
        this.setState({ customerServiceType, validationError: '' })
    }

    handleCustomerServiceTypes = (e, serviceType) => {
        let { name, value } = e.target
        this.setState({ [serviceType]: { ...this.state[serviceType], [name]: value, access: true }, validationError: '' })
    }

    addMoreService = () => {
        let customerServiceType = Array.from(this.state.customerServiceType)
        customerServiceType.push({ customerServiceTypeId: '', duration: '', quantum: '', eligbilityFromStartDate: '' })
        this.setState({ customerServiceType, validationError: '' })
    }

    deleteService = (i) => {
        let customerServiceType = Array.from(this.state.customerServiceType)
        if (customerServiceType.length == 1) {
            this.setState({ validationError: 'Service Policy should contain atleast one Customer Service type' })
        } else {
            customerServiceType.splice(i, 1)
            this.setState({ customerServiceType })
        }
    }

    validateAddedPolicy = (serviceArray) => {

        let valid = true
        if (serviceArray.length) {
            serviceArray.map((service, i) => {
                if (!service.customerServiceTypeId) {
                    valid = false
                    this.setState({ validationError: 'Select a Customer Service Type' + ' ' + (i + 1) })
                }
                else if (service.duration === '' || service.duration === undefined) {
                    valid = false
                    this.setState({ validationError: 'Enter the Duration of Customer Service Type' + ' ' + (i + 1) })
                }
                else if (service.quantum === '' || service.quantum === undefined) {
                    valid = false
                    this.setState({ validationError: 'Enter the Quantum of Customer Service Type' + ' ' + (i + 1) })
                }
                else if (service.eligbilityFromStartDate === '' || service.eligbilityFromStartDate === undefined) {
                    valid = false
                    this.setState({ validationError: 'Enter the Eligbility of Customer Service Type' + ' ' + (i + 1) })
                }
            })
        }

        return valid
    }

    handleSubmitServicePolicy = (e) => {
        e.preventDefault();
        let { customerTypeId, customerServiceType, versionName, version, installation, maintenance, dieticianConsultation, trainerConsultation, doctorConsultation, exercisePlan, sessions, healthCoins, outdoor, dietPlan, gaming } = this.state;
        let params = {
            customerTypeId, customerServiceType, versionName, version, installation, maintenance, dieticianConsultation, trainerConsultation, doctorConsultation, exercisePlan, sessions, healthCoins, outdoor, dietPlan, gaming
        }


        if (!customerTypeId) {
            this.setState({ validationError: 'Select the customer type' })
        }
        // else if (!customerServiceType) {
        //     this.setState({ validationError: 'Select the customer Service type' })
        // }
        else if (!versionName) {
            this.setState({ validationError: 'Enter the version Name' })
        }
        else if (version === '' || version === undefined) {
            this.setState({ validationError: 'Enter the version' })
        }
        else {
            this.setState({ validationError: "" })
            this.addServicePolicyApi(params)
        }
    }

    addServicePolicyApi = (params) => {
        addServicePolicy(params)
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.getServicePolicyV2()
                    this.setState({ loader: false })
                    toast.success(response.responseData.message)
                    this.closeModal()
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }


    openDeleteServicePolicyPopup = (data, servicePolicy) => this.setState({ deleteServicePolicyPopup: !this.state.deleteServicePolicyPopup, servicePolicyId: servicePolicy._id });


    deleteServicePolicy = (e) => {
        e.preventDefault();
        const { servicePolicyId } = this.state
        let paramsObj = {
            servicePolicyId: servicePolicyId
        }
        deleteServicePolicy(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    this.closeModal();
                    this.getServicePolicyV2();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }


    openViewServicePolicyPopup = (data, servicePolicy) => {
        let { _id, customerTypeId, customerServiceType, versionName, version, installation, maintenance, dieticianConsultation, trainerConsultation, doctorConsultation, exercisePlan, sessions, healthCoins, outdoor, gaming } = servicePolicy


        this.setState({
            viewServicePolicyPopup: true,
            servicePolicyId: _id,
            customerTypeId,
            // customerType: servicePolicy.customerTypeId.customerType,
            // customerServiceId: servicePolicy.customerServiceType._id,
            // customerServiceType: servicePolicy.customerServiceType,
            versionName, version, installation, maintenance, dieticianConsultation, trainerConsultation, doctorConsultation, exercisePlan, sessions, healthCoins, outdoor, gaming
        })
    }

    openUpdateServicePolicyPopup = (data, servicePolicy) => {
        let { _id, customerTypeId, customerServiceType, versionName, version, installation, maintenance, dieticianConsultation, trainerConsultation, doctorConsultation, exercisePlan, sessions, healthCoins, outdoor, gaming } = servicePolicy

        this.setState({
            updateServicePolicyPopup: true,
            servicePolicyId: _id,
            customerTypeId,
            // customerType: customerTypeId.customerType,
            // customerServiceId: customerServiceType._id,
            // customerServiceType: customerServiceType,
            versionName, version, installation, maintenance, dieticianConsultation, trainerConsultation, doctorConsultation, exercisePlan, sessions, healthCoins, outdoor, gaming
        })
    }


    updateServicePolicySubmit = (e) => {
        e.preventDefault();

        const { servicePolicyId, customerTypeId, versionName, version, installation, maintenance, dieticianConsultation, trainerConsultation, doctorConsultation, exercisePlan, sessions, healthCoins, outdoor, dietPlan, gaming } = this.state

        let paramsObj = {
            servicePolicyId, customerTypeId, versionName, version, installation, maintenance, dieticianConsultation, trainerConsultation, doctorConsultation, exercisePlan, sessions, healthCoins, outdoor,
            dietPlan, gaming
        }

        if (!customerTypeId) {
            this.setState({ validationError: 'Enter the customer type' })
        }
        else if (!versionName) {
            this.setState({ validationError: 'Enter the version Name' })
        }
        else if (version === '' || version === undefined) {
            this.setState({ validationError: 'Enter the version' })
        }
        else {
            this.setState({ validationError: "" })
            this.updateServicePolicyApi(paramsObj)
        }
    }

    updateServicePolicyApi = (params) => {
        updateServicePolicy(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    this.closeModal();
                    this.getServicePolicyV2();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }


    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? 2 : 1 }, () => this.getServicePolicyV2());

    handleSearch = e => this.setState({ search: e.target.value }, () => this.getServicePolicyV2());

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getServicePolicyV2() });


    render() {
        let {
            loader, servicePolicyList, search, customerTypesList, customerServiceTypesList, customerTypeId, customerType, customerServiceType, versionName, version, AddServicePolicyPopup, validationError, deleteServicePolicyPopup, viewServicePolicyPopup, pageNo, updateServicePolicyPopup, count, totalServicePolicy, installation, maintenance, dieticianConsultation, trainerConsultation, doctorConsultation, exercisePlan, sessions, dietPlan, healthCoins, outdoor, gaming } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Service Policy</li>
                        </ol>


                        {loader
                            ? <div className="loader_wrapper"><LoaderSvg /></div>
                            : null}

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="cm_page_heading">Service Policy</h4>
                                    </div>
                                    <div className="col-md-3 text-right cm_search">
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search by version name"
                                            onChange={this.handleSearch}
                                            value={search}
                                        />
                                        <i className="fa fa-search" ></i>
                                    </div>
                                    <div className="col-md-3 text-right">
                                        <button className="btn btn-primary btn-block" onClick={this.openAddServicePolicyPopup}>Add New Service Policy</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col" >Customer Type </th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('versionName')}>Version Name   <i className={`fa fa-sort-alpha-asc`} />  </th>
                                        <th scope="col" >Version </th>

                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('created')}>Created &nbsp;  <i className={`fa fa-sort-numeric-asc`} />
                                        </th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        servicePolicyList.map((servicePolicy, i) => {
                                            return (
                                                <tr key={servicePolicy._id}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                                                    <td><span className="cm_table_three_dots">{servicePolicy.customerTypeId.customerType}</span></td>
                                                    <td><span className="cm_table_three_dots">{servicePolicy.versionName}</span></td>
                                                    <td><span className="cm_table_three_dots">{servicePolicy.version}</span></td>
                                                    <td><span className="cm_table_three_dots">{dateFormat(servicePolicy.created, "d mmmm yyyy")}</span></td>
                                                    <td className="cm_no_wrap">
                                                        <button className="btn btn-primary btn-sm" onClick={(data) => this.openViewServicePolicyPopup(data, servicePolicy)}>View</button>
                                                        &nbsp;&nbsp;
                                                        <button className="btn btn-dark btn-sm" onClick={(data) => this.openUpdateServicePolicyPopup(data, servicePolicy)} >Edit</button>
                                                        &nbsp;&nbsp;
                                                        <button className="btn btn-danger btn-sm" onClick={(data) => this.openDeleteServicePolicyPopup(data, servicePolicy)}>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalServicePolicy}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!servicePolicyList.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}

                        </div>

                    </div>
                </div>



                <Popup
                    open={AddServicePolicyPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Add Service Policy</h3>
                        <form onSubmit={this.handleSubmitServicePolicy}>

                            <div className="form-group">
                                <label>Customer Type</label>
                                <Select
                                    isSearchable
                                    onChange={(val) => this.handleSelectChange(val)}
                                    placeholder="Select..."
                                    options={customerTypesList}
                                />
                            </div>
                            <div className="form-group">
                                <label>Version Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="versionName"
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Version</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="version"
                                    onChange={this.handleChange}
                                />
                            </div>
                            {customerTypeId && customerTypeId.installation ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Installation  </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'installation')}
                                            value={installation.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'installation')}
                                            value={installation.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'installation')}
                                            value={installation.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}

                            {customerTypeId && customerTypeId.gaming ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Gaming  </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'gaming')}
                                            value={gaming.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'gaming')}
                                            value={gaming.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'gaming')}
                                            value={gaming.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}


                            {customerTypeId && customerTypeId.maintenance ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Maintenance </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'maintenance')}
                                            value={maintenance.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'maintenance')}
                                            value={maintenance.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'maintenance')}
                                            value={maintenance.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}


                            {customerTypeId && customerTypeId.healthCoins ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Health Coins </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'healthCoins')}
                                            value={healthCoins.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'healthCoins')}
                                            value={healthCoins.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'healthCoins')}
                                            value={healthCoins.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}

                            {customerTypeId && customerTypeId.outdoor ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Outdoor </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'outdoor')}
                                            value={outdoor.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'outdoor')}
                                            value={outdoor.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'outdoor')}
                                            value={outdoor.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}


                            {customerTypeId && customerTypeId.dieticianConsultation ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Dietician Consultation </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'dieticianConsultation')}
                                            value={dieticianConsultation.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'dieticianConsultation')}
                                            value={dieticianConsultation.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'dieticianConsultation')}
                                            value={dieticianConsultation.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}

                            {customerTypeId && customerTypeId.trainerConsultation ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Trainer Consultation </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'trainerConsultation')}
                                            value={trainerConsultation.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'trainerConsultation')}
                                            value={trainerConsultation.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'trainerConsultation')}
                                            value={trainerConsultation.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}

                            {customerTypeId && customerTypeId.doctorConsultation ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Doctor Consultation </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'doctorConsultation')}
                                            value={doctorConsultation.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'doctorConsultation')}
                                            value={doctorConsultation.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'doctorConsultation')}
                                            value={doctorConsultation.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}

                            {customerTypeId && customerTypeId.exercisePlan ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Exercise Plan </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'exercisePlan')}
                                            value={exercisePlan.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'exercisePlan')}
                                            value={exercisePlan.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'exercisePlan')}
                                            value={exercisePlan.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}


                            {customerTypeId && customerTypeId.dietPlan ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Diet Plan </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'dietPlan')}
                                            value={dietPlan.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'dietPlan')}
                                            value={dietPlan.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'dietPlan')}
                                            value={dietPlan.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}

                            {customerTypeId && customerTypeId.sessions ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Sessions </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'sessions')}
                                            value={sessions.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'sessions')}
                                            value={sessions.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'sessions')}
                                            value={sessions.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}



                            {validationError && <Alert variant="danger"> {validationError} </Alert>}
                            <div className="text-center"> <button className="btn btn-primary" type="submit">Add Policy</button></div>
                        </form>
                    </div>
                </Popup>

                <Popup
                    open={updateServicePolicyPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Edit Service Policy</h3>
                        <form onSubmit={this.updateServicePolicySubmit}>
                            <div className="form-group">
                                <label>Customer Type</label>
                                <Select
                                    isSearchable
                                    onChange={(val) => this.handleSelectChange(val)}
                                    value={customerTypeId}
                                    options={customerTypesList}
                                />
                            </div>
                            <div className="form-group">
                                <label>Version Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="versionName"
                                    value={versionName}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Version</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="version"
                                    value={version}
                                    onChange={this.handleChange}
                                />
                            </div>

                            {customerTypeId && customerTypeId.installation ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Installation  </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'installation')}
                                            value={installation.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'installation')}
                                            value={installation.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'installation')}
                                            value={installation.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}

                            {customerTypeId && customerTypeId.gaming ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Gaming </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'gaming')}
                                            value={gaming.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'gaming')}
                                            value={gaming.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'gaming')}
                                            value={gaming.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}

                            {customerTypeId && customerTypeId.maintenance ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Maintenance </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'maintenance')}
                                            value={maintenance.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'maintenance')}
                                            value={maintenance.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'maintenance')}
                                            value={maintenance.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}


                            {customerTypeId && customerTypeId.healthCoins ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Health Coins </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'healthCoins')}
                                            value={healthCoins.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'healthCoins')}
                                            value={healthCoins.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'healthCoins')}
                                            value={healthCoins.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}

                            {customerTypeId && customerTypeId.outdoor ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Outdoor </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'outdoor')}
                                            value={outdoor.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'outdoor')}
                                            value={outdoor.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'outdoor')}
                                            value={outdoor.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}


                            {customerTypeId && customerTypeId.dieticianConsultation ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Dietician Consultation </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'dieticianConsultation')}
                                            value={dieticianConsultation.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'dieticianConsultation')}
                                            value={dieticianConsultation.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'dieticianConsultation')}
                                            value={dieticianConsultation.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}

                            {customerTypeId && customerTypeId.trainerConsultation ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Trainer Consultation </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'trainerConsultation')}
                                            value={trainerConsultation.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'trainerConsultation')}
                                            value={trainerConsultation.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'trainerConsultation')}
                                            value={trainerConsultation.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}

                            {customerTypeId && customerTypeId.doctorConsultation ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Doctor Consultation </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'doctorConsultation')}
                                            value={doctorConsultation.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'doctorConsultation')}
                                            value={doctorConsultation.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'doctorConsultation')}
                                            value={doctorConsultation.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}

                            {customerTypeId && customerTypeId.exercisePlan ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Exercise Plan </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'exercisePlan')}
                                            value={exercisePlan.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'exercisePlan')}
                                            value={exercisePlan.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'exercisePlan')}
                                            value={exercisePlan.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}


                            {customerTypeId && customerTypeId.dietPlan ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Diet Plan </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'dietPlan')}
                                            value={dietPlan.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'dietPlan')}
                                            value={dietPlan.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'dietPlan')}
                                            value={dietPlan.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}

                            {customerTypeId && customerTypeId.sessions ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Sessions </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'sessions')}
                                            value={sessions.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'sessions')}
                                            value={sessions.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'sessions')}
                                            value={sessions.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}

                            {validationError && <h5 className="text-center mb-4 mt-5 error_message">{validationError}</h5>}
                            <div className="text-center"> <button className="btn btn-primary" type="submit">Update</button></div>
                        </form>
                    </div>
                </Popup>


                <Popup
                    open={deleteServicePolicyPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h5 className="text-center mb-4 mt-5">Are you sure, you want to delete this ServicePolicy?</h5>
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-danger btn-sm btn-block" onClick={this.deleteServicePolicy}>Delete</button>
                            </div>
                            <div className="col">
                                <button className="btn btn-warning btn-sm btn-block" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Popup>




                <Modal show={viewServicePolicyPopup} onHide={this.closeModal} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Policy Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <p>Customer Type : {customerType}</p>
                            <p>Version Name : {versionName}</p>
                            <p>Version : {version}</p>


                            {customerTypeId && customerTypeId.gaming ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Gaming  </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'gaming')}
                                            value={gaming.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'gaming')}
                                            value={gaming.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'gaming')}
                                            value={gaming.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}

                            {customerTypeId && customerTypeId.installation ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Installation  </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'installation')}
                                            value={installation.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'installation')}
                                            value={installation.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'installation')}
                                            value={installation.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}


                            {customerTypeId && customerTypeId.maintenance ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Maintenance </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'maintenance')}
                                            value={maintenance.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'maintenance')}
                                            value={maintenance.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'maintenance')}
                                            value={maintenance.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}

                            {customerTypeId && customerTypeId.healthCoins ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Health Coins </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'healthCoins')}
                                            value={healthCoins.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'healthCoins')}
                                            value={healthCoins.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'healthCoins')}
                                            value={healthCoins.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}

                            {customerTypeId && customerTypeId.outdoor ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Outdoor </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'outdoor')}
                                            value={outdoor.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'outdoor')}
                                            value={outdoor.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'outdoor')}
                                            value={outdoor.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}


                            {customerTypeId && customerTypeId.dieticianConsultation ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Dietician Consultation </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'dieticianConsultation')}
                                            value={dieticianConsultation.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'dieticianConsultation')}
                                            value={dieticianConsultation.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'dieticianConsultation')}
                                            value={dieticianConsultation.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}

                            {customerTypeId && customerTypeId.trainerConsultation ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Trainer Consultation </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'trainerConsultation')}
                                            value={trainerConsultation.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'trainerConsultation')}
                                            value={trainerConsultation.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'trainerConsultation')}
                                            value={trainerConsultation.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}

                            {customerTypeId && customerTypeId.doctorConsultation ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Doctor Consultation </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'doctorConsultation')}
                                            value={doctorConsultation.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'doctorConsultation')}
                                            value={doctorConsultation.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'doctorConsultation')}
                                            value={doctorConsultation.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}

                            {customerTypeId && customerTypeId.exercisePlan ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Exercise Plan </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'exercisePlan')}
                                            value={exercisePlan.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'exercisePlan')}
                                            value={exercisePlan.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'exercisePlan')}
                                            value={exercisePlan.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}

                            {customerTypeId && customerTypeId.sessions ?
                                <div className="form-group row">
                                    <h4 className="cm_pos_rel col-12"> Sessions </h4>
                                    <div className="form-group col">
                                        <label>Duration</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'sessions')}
                                            value={sessions.duration}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Quantum</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantum"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'sessions')}
                                            value={sessions.quantum}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <label>Eligbility</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="eligbilityFromStartDate"
                                            disabled
                                            onChange={(e) => this.handleCustomerServiceTypes(e, 'sessions')}
                                            value={sessions.eligbilityFromStartDate}
                                        />
                                    </div>
                                </div> : null}
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="danger" onClick={this.closeModal}> Close </Button>
                    </Modal.Footer>
                </Modal>

            </HOC >
        )
    }
}



export default ServicePolicy;
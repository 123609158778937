import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { getUsersMessages, getUserDetails } from '../../config/services/usersService';
//import { getDieticianProfile } from '../../config/service/adminService';
//import cogoToast from 'cogo-toast';
import moment from 'moment'
import TabTitle from './TabTitle'

class Messages extends Component {
    constructor(props) {
        super(props)
        this.state = {
            messagesList: [],
            userDetails: {},
            dieticianDetails:{},
            loader: false,
        }
    }

    componentDidMount() {
        const {id, userDetails} = this.props
    
        this.setState({
            userDetails,
            userId : id
        },()=>{
            this.getUsersMessages();
        })  
    }

    profilePic
    firstName
    lastName

    getUsersMessages = () => {
        const { userId, userDetails } = this.state
        let dieticianId;
        if(userDetails && userDetails.dieticianDetails && userDetails.dieticianDetails.length > 0 ){
            dieticianId = userDetails.dieticianDetails[0]._id
            this.setState({ 
                loader: true,
                dieticianId: dieticianId,
                dieticianDetails: userDetails.dieticianDetails[0]
             }, ()=>{
                let params = {
                    params: { 
                        dieticianId,
                        receiverId: userId 
                    }
                }
                getUsersMessages(params).then(res => {
                    if (res.data.statusCode == 1) {
                        this.setState({ messagesList: res.data.responseData.messages.reverse(), loader: false })
                    } else {
                        // cogoToast.error(res.data.error.errorMessage)
                        this.setState({ loader: false })
                    }
                })
             })

        }
      
    }

    render() {
        let {  messagesList, loader, userId, userDetails, dieticianId, dieticianDetails } = this.state
        return (
            <div className="body-container cm_profile_wrapper cm_profile_wrapper_bg">
            <TabTitle title="Excercise Plan" />
                <div className="page_wrapper">
                    <div className="chat_wrapper">
                        <div className="row">
                            <div className="col-lg">
                                <div className="card_wrapper cm_window_hieght pt-0 pb-0">

                                    <div className="chat_massage_body" id="messageBox">

                                        {loader ?
                                            <div className='cm_center_pos'>
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div> : (!messagesList.length ?
                                                    <div className="text-center">
                                                        <span className="text-danger">No messages yet!</span></div> : null)}

                                                       

                                        {messagesList.map((messages, index) => (
                                            <div key={index} className={userId == messages.senderId ? "chat_wrap other_chat" : "chat_wrap my_chat"}>
                                                <div className="chat_timeline">
                                                    <span className="timeline_img"><img src= {dieticianId == messages.senderId ? dieticianDetails.profilePic : userDetails.profilePic} /></span>
                                                    <span className="timeline_name">
                                                        {dieticianId == messages.senderId ? dieticianDetails.firstName + " " + dieticianDetails.lastName : userDetails.firstName + " " + userDetails.lastName}
                                                    </span>
                                                </div>

                                                <div className="chat__text text-left display-linebreak">
                                                    {messages.message}
                                                    <span className="_msg_time"><i className="fa fa-clock-o" /> {moment(messages.created).local().format('Do MMM hh : mm a')}
                                                    </span>
                                                </div>

                                            </div>
                                        ))}
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        )
    }
}

export default Messages;
import React, { Component } from 'react';
import HOC from '../../HOC';
import { DeviceService } from '../../config/services/DeviceService';
import { LoaderSvg } from '../Reusable';
import { Link } from 'react-router-dom';

class EditDevice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            type: '',
            uniqueId: '',
            openDropdown: false,
            types: ['Application', 'Treadmill', 'Manual Machine', 'Fitness Trackers', 'Smartscale', "Mannual data", 'Voice Control', 'Bikes', 'Outdoor Cycle', 'Outdoor Run'],
            Loader: true,
        }

        this.deviceDetails = this.deviceDetails.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleValidetion = this.handleValidetion.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    deviceDetails() {
        let params = { deviceId: this.props.match.params.id }

        DeviceService.getDeviceOneDevice(params)
            .then((res) => {
                if (res.data.statusCode === 1) {
                    let { name, type, uniqueId } = res.data.responseData.device;
                    this.setState({
                        name,
                        type,
                        uniqueId,
                        Loader: false,
                    })
                } else {
                    localStorage.removeItem('accessToken')
                    this.props.history.push('/login');
                }
            })
    }

    handleOpenDropdown = () => {
        this.setState({ openDropdown: !this.state.openDropdown })
    }

    getDeiveType = (val) => {
        this.setState({
            type: val,
            openDropdown: !this.state.openDropdown
        })
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
            errors: false
        })
    }

    handleValidetion() {
        let validate = true;
        let name = this.state.name;
        let uniqueId = this.state.uniqueId;

        if (name === '' || name === undefined) {
            validate = false;
        } else {
            validate = true;
        }

        if (uniqueId === '' || uniqueId === undefined) {
            validate = false;
        } else {
            validate = true;
        }

        return validate
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ errors: true })

        let { name, uniqueId, type } = this.state;
        let obj = {
            deviceId: this.props.match.params.id,
            name: name,
            uniqueId: uniqueId,
            type: type
        };


        if (this.handleValidetion()) {
            DeviceService.UpdateDevice(obj)
                .then(res => {
                    if (res.data.statusCode === 1) {
                        this.setState({
                            errors: false,
                            openAddDevicePopup: false,
                            name: '',
                            uniqueId: '',
                            type: null
                        });

                        this.props.history.push('/device/');
                    }
                })
        } else {
            this.setState({ validateError: true, errors: false })
        }
    }


    componentDidMount() {
        this.deviceDetails();
    }



    render() {
        let { name, type, uniqueId, types } = this.state;
        return (
            <HOC>
                {
                    this.state.Loader
                        ? <div className="loader_wrapper"><LoaderSvg /></div>
                        : null
                }
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item"><Link to="/device">Device</Link></li>
                            <li className="breadcrumb-item">Edit Device</li>
                        </ol>
                        <h3 className="text-center mb-4 mt-5">Edit Device</h3>
                        <form onSubmit={this.handleSubmit} className="edit_device_form mb-5">

                            <div className="form-group">
                                <label>Name of device</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={name}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>Unique connection id of device</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="uniqueId"
                                    value={uniqueId}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className={this.state.openDropdown ? 'form-group cm_dropdown open' : 'form-group cm_dropdown'}>
                                <label>Type of devices</label>
                                <span className="form-control cm_blank_input" onClick={this.handleOpenDropdown}>{types[type]}</span>
                                <input type="hidden" value={type} name="type" />
                                <ul>
                                    <li onClick={() => this.getDeiveType(0)}>
                                        <h4>Application</h4>
                                        <p>Google fit, Samsung Health, Fitbit, Apple Health</p>
                                    </li>
                                    <li onClick={() => this.getDeiveType(1)}>
                                        <h4>Treadmill</h4>
                                        <p>RPM Fitness, Fitkit, ES Linker</p>
                                    </li>
                                    <li onClick={() => this.getDeiveType(2)}>
                                        <h4>Manual Machine</h4>
                                        <p>RPM Fitness</p>
                                    </li>
                                    <li onClick={() => this.getDeiveType(3)}>
                                        <h4>Fitness Trackers</h4>
                                        <p>Mi Band</p>
                                    </li>
                                    <li onClick={() => this.getDeiveType(4)}>
                                        <h4>Smartscale</h4>
                                        <p>F+</p>
                                    </li>
                                    <li onClick={() => this.getDeiveType(6)}>
                                        <h4>Voice Controls</h4>
                                        <p>Alexa , Google home</p>
                                    </li>
                                    <li onClick={() => this.getDeiveType(7)}>
                                        <h4>Bikes</h4>
                                        <p>Spin Bike , Mannual Bike</p>
                                    </li>
                                    <li onClick={() => this.getDeiveType(8)}>
                                        <h4>OutDoor</h4>
                                        <p>Urban Terrain</p>
                                    </li>
                                    <li onClick={() => this.getDeiveType(9)}>
                                        <h4>OutDoor Run</h4>
                                        <p>Urban Terrain</p>
                                    </li>
                                </ul>
                            </div>

                            <button className="btn btn-primary btn-block mt-4">Save</button>
                            {this.state.validateError ? <span className="error">error</span> : null}

                        </form>
                    </div>
                </div>
            </HOC>
        )


    }
}

export default EditDevice;
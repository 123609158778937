import React, { Component } from 'react';
import dateFormat from 'dateformat';
import { LoaderSvg } from '../Reusable';
import { getAllHistory, getDietPlanHistory } from '../../config/services/dietPlanService'

import DietHistoryBody from './DietHistoryBody';
import TabTitle from './TabTitle'

class DietPlanHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dietHistory :[]
        }
    }

    componentDidMount() {
        const { id } = this.props
        this.setState({
            dietOfUserId: id
        }, ()=>{
            this.getAllHistory()
            }
        )
    }

    getAllHistory(){
        const {dietOfUserId}  = this.state 
        const params = {
            params: {
                dietOfUserId
            }
        }
        getAllHistory(params).then((historyData)=>{
            if(historyData &&
                historyData && 
                historyData.data && 
                historyData.data.responseData && 
                historyData.data.responseData.dietHistory
                ){
                    this.setState({
                        dietHistory: historyData.data.responseData.dietHistory
                    }, ()=>{
                        const [ history ] = this.state.dietHistory
                        if(history && history._id){
                            const params = {
                                params: {
                                    dietOfUserId,
                                    dietPlanHistoryId: history._id
                                }
                            }
                            this.selectHistory(params)
                        }
                    })
                }
        })
    }

    selectHistoryHandler = (dietPlanHistoryId) =>{
        const { dietOfUserId } = this.state
        const params = {
            params: {
                dietOfUserId,
                dietPlanHistoryId
            }
        }
        this.setState({
            loader: true
        }, ()=>{
            this.selectHistory(params)
        })
    }

    selectHistory(params){
        getDietPlanHistory(params).then((respose)=>{
                if(respose && respose.data &&  respose.data.responseData )
                {
                    this.setState({
                        selectedHistory:respose.data.responseData.result,
                        loader: false
                    })
                }
        })
    }

    makeActive = (currenId) =>{
        const { selectedHistory } = this.state
        return (selectedHistory && selectedHistory._id === currenId) ? "nav-link active" : "nav-link "
    }

    render() {
        const { dietHistory , selectedHistory,loader} = this.state
        return (
            
            <div className="body-container cm_profile_wrapper cm_profile_wrapper_bg">
                 <TabTitle title="Diet Plan History" />
                 {
                    !dietHistory.length ?<div className="text-center"><span className="text-danger">No history data!</span></div> : 
                 <>   
                    <div className="cm_left_tabs" >
                        <div className="flex-column nav nav-pills">
                        {
                            dietHistory.map((history)=>{
                            return <div className="nav-item">
                                        <a onClick={ () =>this.selectHistoryHandler(history.value) } className={this.makeActive(history.value)}>{history.label} Cals, {dateFormat(history.updated, "d mmm, yy")}</a>
                                    </div>
                            })
                        }
                        </div>
                    </div>
                    <div className="cm_right_tabs">
                    {loader
                                ? <div className="loader_wrapper"><LoaderSvg /></div>
                                : null}
                        <div className="tab-content">
                            {
                                selectedHistory && <DietHistoryBody dietPlanDetails={selectedHistory} />
                            }
                        </div>
                    </div>
                    </>
                }
            </div>
            
        )
    }
}


export default DietPlanHistory;

export default {
    api: {
      url: 'https://qadevapi.fitplus.biz/fitstar/api/',
      mode: 'cors',
      socket:'https://qadevsocket.fitplus.biz'
    },
    firebaseConfig: {
      apiKey: "AIzaSyAhMbkezcOXFUdGpfT4Bp4W_d7ANXBCKVs",
      authDomain: "fitplus-dev.firebaseapp.com",
      databaseURL: "https://fitplus-dev.firebaseio.com",
      projectId: "fitplus-dev",
      storageBucket: "fitplus-dev.appspot.com",
      messagingSenderId: "164811617082",
      appId: "1:164811617082:web:a7afabcbd8dc09026c1603",
      measurementId: "G-9W4TX7F8NC"
    },
    sfsserver:{
      host:"dev-sfs-admin.fit-warz.com",
      port:8443,
      ssl:true
    }
  }
  
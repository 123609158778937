import React,{Component} from 'react';
import { Tab, Nav } from 'react-bootstrap';

import TabTitle from './TabTitle'
class Questions extends Component{
    constructor(props) {
        super(props);
    }
   
    render(){
        const { questionAnswer } = this.props
        return (
            <div className="body-container cm_profile_wrapper cm_profile_wrapper_bg">
                <TabTitle title="Questions" />
                <div className="cm_left_tabs">
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link eventKey="first">Answered Questions</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <div className="cm_right_tabs inner_scroll">
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                        {
                            (!questionAnswer.length) ? <div className="text-center"> <span className="text-danger">No Answer yet!</span></div> : 
                            <ul className="questions_list ">
                                {questionAnswer.map((questions, i) => (
                                    <li key={i}>
                                        <p className="cm_questions" >{i + 1}. {questions.question}</p>

                                        {questions.answer ? questions.answer.map((answers, index) => (
                                            <p className="cm_answers" key={index}>{answers}</p>
                                        )) : ""}

                                        <p className="cm_answers" >{questions.text}</p>
                                    </li>
                                ))}
                            </ul>
                               } 
                        </Tab.Pane>    
                    </Tab.Content>
                </div>
        </div>                                
    );
    }
}

export default Questions;
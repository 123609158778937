import React, { Component } from 'react'
import dateFormat from 'dateformat'
import { Link } from 'react-router-dom'
import Pagination from "react-js-pagination"
import { CouponService } from '../../config/services/CouponService'
import HOC from '../../HOC'
import { LoaderSvg } from '../Reusable'
import Popup from "reactjs-popup"
import LaddaButton, { EX, SLIDE_UP } from 'react-ladda';
import { toast } from 'react-toastify';

class Coupons extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageNo: 1,
            activePage: 0,
            count: 20,
            coupons: [],
            Loader: true,
            open: false,
            openEmailPopup: false,
            couponCount: 1,
            Category: 0,
            couponId: '',
            couponCode: '',
            couponEmail: '',
            FilterStatus: false,
            couponCategorySort: false,
            filterCouponCategory: '',
            sortType: -1,
            sortField: '',
            sortFieldIcon: false,
            totalCoupenCount: 0,
            filterCoupon: '',
            couponSucsessPopup: false,
            EmailCouponError: '',
            EmailCouponSucssesPopup: false,
            couponDeletedPopup: false,
            couponCategory: 0,
            consumedByUser: {}
        }

        this.showCouponList = this.showCouponList.bind(this);
        this.handleCouponsCountSubmit = this.handleCouponsCountSubmit.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    openModal = () => this.setState({ open: true })
    closeModal = () => this.setState({ open: false, openEmailPopup: false, couponSucsessPopup: false, EmailCouponSucssesPopup: false, couponDeletedPopup: false })

    handleCouponsCountChange = event => {
        this.setState({ couponCount: Number(event.target.value) });
    }


    handleChange = (event) => {
        let { id, value } = event.target;
        this.setState({ [id]: value })
    }

    showCouponList() {
        let { count, pageNo, sortType, sortField, filterCoupon, filterCouponCategory } = this.state;

        let paramsObj = {
            params: {
                count,
                pageNo: pageNo - 1,
                sortType,
                sortField,
                filterCoupon,
                filterCouponCategory,
            }
        }        

        CouponService.getAllCoupon(paramsObj)
            .then(res => {
                if (res.data.statusCode === 1) {
                    this.setState({
                        coupons: res.data.responseData,
                        Loader: false
                    })
                }
            })
    }

    hendleSortCoupons = (filter_coupon, filterCategory) => {

        if (filterCategory == "filterCouponStatus") {
            this.setState({ Loader: true,pageNo:1, filterCouponCategory: '', filterCoupon: filter_coupon }, () => {
                this.showCouponList();
            })
        }

        

        if (filterCategory == "filterCouponCategory") {
            this.setState({ Loader: true,pageNo:1,filterCoupon: '', filterCouponCategory: filter_coupon }, () => {
                this.showCouponList();
            })
        }
    }

    handleCouponCreatePopup = () => {
        this.setState({ couponSucsessPopup: true })
        this.showCouponList();
    }


    handleCouponsCountSubmit(e) {
        e.preventDefault();
        this.setState({ Loader: true, open: false });
        let params = {
            numberOfCoupons: this.state.couponCount,
            couponCategory: this.state.couponCategory
        };
        CouponService.generateCouponService(params)
            .then(res => {
                if (res.data.statusCode === 1) {
                    this.setState({ CouponsCount: 1 });
                    this.handleCouponCreatePopup();
                }
            })
    }


    componentDidMount() {
        let token = localStorage.getItem('accessToken');
        if (token) { this.showCouponList() }

        CouponService.getAllCouponCount()
            .then(res => {
                if (res.data.statusCode === 1) {
                    this.setState({ totalCoupenCount: res.data.responseData })
                }
            })
    }


    handlePageChange(pageNumber) {

        this.setState({ pageNo: pageNumber, Loader: true }, () => {
            this.showCouponList();
        });

    }

    handleSendMail = (id, code) => {
        this.setState({
            openEmailPopup: true,
            couponId: id,
            couponCode: code
        })
    }


    handleEmailCouponChange = event => {
        this.setState({ couponEmail: event.target.value, EmailCouponError: '' });
    }

    handleEmailCouponSucssesPopup = () => {
        this.setState({ EmailCouponSucssesPopup: true })
        this.showCouponList();
    }

    submitEmailCoupon = e => {
        e.preventDefault();
        if (this.state.couponEmail === '') {
            this.setState({ EmailCouponError: 'Email is require' });
            return
        }

        this.setState({ couponEmail: '', loader: true, openEmailPopup: false });
        let obj = {
            couponCode: this.state.couponCode,
            couponEmail: this.state.couponEmail
        }

        CouponService.sendCouponMail(obj)
            .then(res => {
                if (res.data.statusCode === 1) {
                    this.setState({ EmailCouponError: '' })
                    this.handleEmailCouponSucssesPopup();
                } else if (res.data.statusCode === 0) {
                    this.setState({ EmailCouponError: res.data.error.errorMessage })
                }
            })
    }

    handleClickOutside = event => {
        if (event.target.id !== 'filter_text') {
            this.setState({
                FilterStatus: false
            })
        } else {
            this.setState({
                FilterStatus: true
            })
        }
    }

    toggleFilterStatus = () => {
        this.setState({
            FilterStatus: !this.state.FilterStatus
        })
        // document.addEventListener('click', this.handleClickOutside);
    }

    toggleFilterCategory = () => {
        this.setState({
            couponCategorySort: !this.state.couponCategorySort
        })
    }


    handleSortList = sortField => {
        const { sortType } = this.state;
        let sortOrder = sortType === 1 ? 2 : 1;

        this.setState({ sortField: sortField, Loader: true, sortType: sortOrder }, () => {
            this.showCouponList();
            this.setState({
                sortFieldIcon: !this.state.sortFieldIcon
            })
        });
    }


    handleDeleteCoupon = (couponCode, status) => {
        this.setState({ [couponCode]: true, progress: 0.5, })

        let obj = {
            couponCode: couponCode,
            couponStatus: status === 1 ? 0 : 1
        }
        let self = this;
        CouponService.deleteCoupon(obj)
            .then(res => {
                if (res.data.statusCode === 1) {
                    self.showCouponList()
                    self.setState({ [couponCode]: false })
                }
            })
    }

    render() {
        const { consumedByUser,count } = this.state;
        return (
            <HOC>
                {this.state.Loader
                    ? <div className="loader_wrapper"><LoaderSvg /></div>
                    : null}
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Coupons</li>
                        </ol>

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-9">
                                        <h4 className="cm_page_heading">Coupons</h4>
                                    </div>

                                    {/* <div className="col-md-3 cm_search">
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search"
                                        />
                                        <i className="fa fa-search"></i>
                                    </div> */}

                                    <div className="col-md-3 text-right m-b-md-15">
                                        <button onClick={this.openModal} className="btn btn-primary btn-block">Generate Coupons</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Coupon Code</th>

                                        {/* <th id="filter_text" className={this.state.couponCategorySort ? 'filter_text open' : 'filter_text'} onClick={this.toggleFilterCategory}>
                                            Coupon Category <i className="fa fa-filter" ></i>
                                            <ul>
                                                <li onClick={() => this.hendleSortCoupons(1, "filterCouponCategory")}>Fitness Coupons</li>
                                                <li onClick={() => this.hendleSortCoupons(0, "filterCouponCategory")}>Diet Coupon</li>
                                                <li onClick={() => this.hendleSortCoupons('', "filterCouponCategory")}>All Coupon</li>
                                            </ul>
                                        </th> */}


                                        <th className="filter_text">Created Date <i className={`fa fa-sort-numeric-${this.state.sortFieldIcon ? 'asc' : 'desc'}`}></i><button onClick={() => this.handleSortList('created')} value="filterFirstName"></button></th>

                                        <th className="filter_text">Sent to</th>


                                        <th className="filter_text">Consumed By</th>

                                        <th id="filter_text" className={this.state.FilterStatus ? 'filter_text open' : 'filter_text'} onClick={this.toggleFilterStatus}>
                                            Coupon Status <i className="fa fa-filter" ></i>
                                            <ul>
                                                <li onClick={() => this.hendleSortCoupons(true, "filterCouponStatus")}>Used Coupon</li>
                                                <li onClick={() => this.hendleSortCoupons(false, "filterCouponStatus")}>Unused Coupon</li>
                                                <li onClick={() => this.hendleSortCoupons('', "filterCouponStatus")}>All Coupon</li>
                                            </ul>
                                        </th>
                                        <th className="filter_text">Coupon status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.coupons.map((coupon, i) => {
                                            return (
                                                <tr key={coupon._id}>
                                                    <td>{((i + 1) + ((this.state.pageNo - 1) * 20))}</td>
                                                    <td>{coupon.couponCode}</td>

                                                    {/**will be used later */}
                                                    {/* <td>{coupon.couponCategory ? 'Fitness' : 'Diet'}</td> */}

                                                    <td>{dateFormat(coupon.created, "d mmmm yyyy")}</td>
                                                    <td>{coupon.couponSentTo ? coupon.couponSentTo : "Not sent yet"}</td>

                                                    <td>{coupon.consumedByUserDetails.length > 0 ? coupon.consumedByUserDetails[0].email : 'Not Available'}</td>
                                                    <td style={{ position: 'relative' }}>
                                                        {coupon.consumed ? <div className="send_coupon_email_wrapper">Consumed<span className="consumed cm_no"></span></div> : <div className="send_coupon_email_wrapper"><button onClick={() => this.handleSendMail(coupon._id, coupon.couponCode)} className="btn btn-info btn-sm">Send Coupon</button><span className="consumed cm_yes"></span></div>}
                                                        {/* <span onClick={() => this.handleDeleteCoupon(coupon.couponCode)} className="delete_coupon"><i className="fa fa-trash"></i>
                                                        </span> */}
                                                    </td>
                                                    <td style={{ position: 'relative' }}>
                                                        <LaddaButton
                                                            loading={this.state[coupon.couponCode]}
                                                            onClick={() => this.handleDeleteCoupon(coupon.couponCode, coupon.status)}
                                                            data-color="red"
                                                            data-size={EX}
                                                            data-style={SLIDE_UP}
                                                            data-spinner-size={45}
                                                            data-spinner-color="rgb(255,108,11)"
                                                            data-spinner-lines={20}
                                                        >
                                                            <span
                                                                className={`cm_ckeckbox_wrapper ${coupon.status === 1 ? 'cm_inactive' : 'cm_active'}`}
                                                                onClick={() => this.handleChangeStatus(coupon._id, coupon.status)}
                                                            >
                                                                <span className="cm_ckeckbox_btn"></span>
                                                            </span>
                                                        </LaddaButton>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <Pagination
                                activePage={this.state.pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={this.state.totalCoupenCount}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />
                        </div>


                    </div>
                </div>



                <Popup
                    open={this.state.open}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal  pl-5 pr-5">
                        <span className="cm_modal_close" onClick={this.closeModal}>
                            &times;
                        </span>
                        <br />
                        <br />
                        <h3 className="text-center mb-4">Generate Coupons</h3>
                        <br />
                        <form onSubmit={this.handleCouponsCountSubmit}>
                            <div className="form-group">
                                <label>Number of Coupons</label>
                                <input
                                    type="number"
                                    pattern="[0-9]*"
                                    className="form-control"
                                    name="CouponsCount"
                                    // value={this.state.CouponsCount}
                                    onChange={this.handleCouponsCountChange}
                                    required
                                />
                                <small className="cm_input_info">Enter numeric value only.</small>
                            </div>

                            {/* {Will be used later, now only diet coupon will be generated default and can be used in both Diet and fitness section on app} */}
                            {/* <div className="form-group mt-5 mb-5">
                                <label className="d-block">Select Category</label>
                                <div className="radio_wrapper">
                                    <label>
                                        Diet
                                            <input type="radio" defaultChecked={true} onChange={this.handleChange} value={0} id="couponCategory" name="couponCategory" />
                                        <span></span>
                                    </label>
                                    <label>
                                        Fitness
                                            <input type="radio" value={1} onChange={this.handleChange} id="couponCategory" name="couponCategory" />
                                        <span></span>
                                    </label>

                                </div>
                            </div> */}
                            <button className="btn btn-primary btn-block mt-4">Generate</button>
                            <br />
                            <br />
                        </form>

                    </div>
                </Popup>

                <Popup
                    open={this.state.openEmailPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal  pl-5 pr-5">
                        <span className="cm_modal_close" onClick={this.closeModal}>
                            &times;
                            </span>
                        <br />
                        <br />
                        <h3 className="text-center mb-4">Send This Coupon Via Email</h3>

                        <form onSubmit={this.submitEmailCoupon}>
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    type="email"
                                    className={`form-control ${this.state.EmailCouponError ? 'is-invalid' : ''}`}
                                    onChange={this.handleEmailCouponChange}
                                    value={this.state.couponEmail}
                                />
                                {
                                    this.state.EmailCouponError ? <div className="cm_alert_danger">{this.state.EmailCouponError}</div> : null
                                }

                            </div>

                            <button className="btn btn-primary btn-block mt-4">Send</button>
                            <br />
                            <br />
                        </form>

                    </div>
                </Popup>


                <Popup
                    open={this.state.couponSucsessPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Generate New Coupons Sucsessfull</h3>
                    </div>
                </Popup>


                <Popup
                    open={this.state.EmailCouponSucssesPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Coupon has been Sent Successfully</h3>
                    </div>
                </Popup>


                <Popup
                    open={this.state.couponDeletedPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Coupon Deleted Successfully</h3>
                    </div>
                </Popup>
            </HOC>
        )
    }
}

export default Coupons;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup"
import dateFormat from 'dateformat';
import { toast } from 'react-toastify';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { FitnessTemplateService } from '../../config/services/fitnessPlanTemplateService';

class FitnessPlanTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fitnessPlansTemplate: [],
            pageNo: 1,
            count: 10,
            sortType: -1, //-1=descending 1=ascending
            sortField: 'created',
            search: '',
            templatesCount: 100, //Will be changed according to API,
            loader:false,
            deleteTemplatePopup: false,  
            templateId: ''
        }
    }

    componentDidMount() {
        this.getFitnessTemplatesList();
    }

    handleSort = (sortField) => {
        let { sortType } = this.state;
        sortType = sortType == -1 ? 1 : -1
        this.setState({ sortType, sortField }, () => this.getFitnessTemplatesList())
    }

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getFitnessTemplatesList() });


    getFitnessTemplatesList = () => {
        const { pageNo, count, sortField, sortType, search } = this.state;
        this.setState({loader:true})

        let paramsObj = {
            params: {
                pageNo: pageNo - 1, count, sortField, sortType, search
            }
        }
        
        FitnessTemplateService.getFitnessTemplatesByAadmin(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({
                        fitnessPlansTemplate: res.data.responseData.result,
                        templatesCount: res.data.responseData.templatesCount,
                        loader: false
                    })
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                }
            })
    }

    handleSearch = (e) => {
        this.setState({ search: e.target.value }, () => this.getFitnessTemplatesList())
    }

    openDeleteTemplatePopup = (templateId) => this.setState({ deleteTemplatePopup: !this.state.deleteTemplatePopup, deleteTemplateId: templateId });
    closeModal = () => this.setState({
        deleteTemplatePopup: false,  templateId: ''
    });

    deleteTemplate = (e) => {
        e.preventDefault();
        const { deleteTemplateId } = this.state
        let paramsObj = {
            templateId: deleteTemplateId
        }
        console.log('paramsObj', paramsObj)
        FitnessTemplateService.deleteTemplateById(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    this.closeModal();
                    this.getFitnessTemplatesList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }
    render() {
        const {
            loader,
            fitnessPlansTemplate,
            search,
            pageNo,
            count,
            templatesCount,
            deleteTemplatePopup,
            sortType,
        } = this.state;

        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Fitness Plans</li>
                        </ol>


                        {loader
                            ? <div className="loader_wrapper"><LoaderSvg /></div>
                            : null}

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-9">
                                        <h4 className="cm_page_heading">Fitness Plan Templates</h4>
                                    </div>
                                    <div className="col-md-3 text-right cm_search">
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search by Diet plan title"
                                            onChange={this.handleSearch}
                                            value={search}
                                        />
                                        <i className="fa fa-search" ></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('templateTitle')}>Title &nbsp;
                        <i className={`fa fa-sort`}></i>
                                        </th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('created')}>Created on &nbsp;
                                        <i className={`fa fa-sort`} />
                                        </th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        fitnessPlansTemplate.map((fitnessPlan, i) => {
                                            return (
                                                <tr key={fitnessPlan._id}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>

                                                    <td><span className="cm_table_three_dots">
                                                        <Link to={`/fitnessPlanTemplate/viewTemplate/${fitnessPlan._id}`}> {fitnessPlan.label}</Link>
                                                    </span></td>

                                                    
                                                    <td className="cm_no_wrap">{dateFormat(fitnessPlan.created, "d mmmm yyyy")}</td>
                                                    <td className="cm_no_wrap">
                                                        <button className="btn btn-danger btn-sm" onClick={() => this.openDeleteTemplatePopup(fitnessPlan._id)}>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })

                                    }
                                </tbody>
                            </table>


                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={templatesCount}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!fitnessPlansTemplate.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}

                        </div>

                    </div>
                </div>
                <Popup
                    open={deleteTemplatePopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h5 className="text-center mb-4 mt-5">Are you sure, you want to delete this template?</h5>
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-danger btn-sm btn-block" onClick={this.deleteTemplate}>Delete</button>
                            </div>

                            <div className="col">
                                <button className="btn btn-warning btn-sm btn-block" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Popup>
            </HOC>)
    }
}



export default FitnessPlanTemplate;
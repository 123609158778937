import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { POST } from '../../config/services/adminService';
import Logo from '../../assets/images/fitplusLogo.png';
import { LoaderSvg } from '../Reusable'
import axios from 'axios';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            emailErrorMsg: '',
            passwordErrorMsg: '',
            errors: false,
            Loader: false,
            isShowPassword: false,
            _statusCode: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleValidetion = this.handleValidetion.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.ShowPassword = this.ShowPassword.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
            errors: false,
            _statusCode: ''
        })
    }

    ShowPassword() {
        this.setState({ isShowPassword: !this.state.isShowPassword })
    }

    handleValidetion() {
        let validate = true;
        let validateEmail = this.state.email;
        let validatePassword = this.state.password;
        var pattern = /(([a-zA-Z0-9\-?\.?]+)@(([a-zA-Z0-9\-_]+\.)+)([a-z]{2,3}))+$/;
        let validEmail = new RegExp(pattern).test(validateEmail);


        if (validateEmail === '' || validateEmail === undefined) {
            validate = false;
            this.setState({
                emailErrorMsg: 'Enter Email'
            })
        } else if (!validEmail) {
            validate = false;
            this.setState({
                emailErrorMsg: 'Enter valid e-mail'
            })
        }
        else {
            this.setState({
                emailErrorMsg: ''
            })
        }

        if (validatePassword === '' || validatePassword === undefined) {
            validate = false;
            this.setState({
                passwordErrorMsg: 'Enter Password'
            })
        } else {
            this.setState({
                passwordErrorMsg: ''
            })
        }

        return validate
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ Loader: true })
        if (this.handleValidetion()) {
            let obj = {
                email: this.state.email,
                password: this.state.password,
                role: 1
            }
            POST(obj)
                .then(res => {
                    if (res.data.statusCode == 1) {
                        let { result, accessToken } = res.data.responseData
                        localStorage.setItem('accessToken', accessToken)
                        localStorage.setItem('role', result.role)
                        localStorage.setItem('expertId', result._id)

                        axios.defaults.headers.common['accessToken'] = `${localStorage.getItem('accessToken')}`;
                        axios.defaults.headers.common['Authorization'] = `${'Basic Zml0c3Rhcl9hZG1pbjphZG1pbkBmaXRzdGFy'}`;
                        axios.defaults.headers.common['platform'] = 3;

                        if (localStorage.getItem('accessToken')) {
                            this.props.history.push('/dashboard')
                        }
                    }
                    else {
                        this.setState({ errors: true, Loader: false, _statusCode: 'Invalid Email or Password. Try again!' })
                    }
                })
        } else {
            this.setState({ errors: true, Loader: false })
        }
    }

    render() {

        if (localStorage.getItem('accessToken')) {
            this.props.history.push('/dashboard')
        }

        return (
            <div className="login-wrapper" style={{ backgroundImage: `url(${require('../../assets/images/fitPlusBackground.jpg')})` }} >
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-md-6 m-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title">
                                        <img src={Logo} alt="Fitstar" />
                                        {this.state._statusCode ? <p style={{ color: 'red' }}>{this.state._statusCode}</p> : null}
                                    </div>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <input
                                                className={`form-control mb-4 ${this.state.errors ? 'is-invalid' : ''}`}
                                                type="email"
                                                name="email"
                                                placeholder="Enter Email"
                                                onChange={this.handleChange}
                                                value={this.state.email}
                                            />
                                            <div className="cm_alert_danger">{this.state.emailErrorMsg}</div>

                                        </div>
                                        <div className="form-group cm_show_pass">
                                            <input
                                                className={`form-control ${this.state.errors ? 'is-invalid' : ''}`}
                                                type="password"
                                                type={this.state.isShowPassword ? 'text' : 'password'}
                                                name="password"
                                                placeholder="Enter Password"
                                                onChange={this.handleChange}
                                                value={this.state.password}
                                            />
                                            <div className="cm_alert_danger">{this.state.passwordErrorMsg}</div>
                                            <i
                                                className={`fa fa-eye${this.state.isShowPassword ? '' : '-slash'}`}
                                                onClick={this.ShowPassword}
                                                aria-hidden="true"></i>
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col"><button className="btn btn-primary" type="submit">Login</button></div>
                                                <div className="col text-right pt-2"><Link to="/forget-password">Forgot password</Link></div>
                                            </div>
                                        </div>

                                        {
                                            this.state.Loader
                                                ? <div className="loader_wrapper"><LoaderSvg /></div>
                                                : null
                                        }


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;

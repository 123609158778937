import React, { useState, Fragment, useEffect } from "react";
import Popup from "reactjs-popup";
import { addVendors, updateVendor } from "../../config/services/vendorService";
import {
  getAllPincodes,
  getAllStates,
  getAllCitys,
} from "../../config/services/servicePincodes";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import { List, InfiniteLoader } from "react-virtualized";
const {  removeDuplicates, } = require("../utils/Util.js");

let style = { textTransform: "capitalize", fontWeight: "Bold" };
let styleForWrapper = { marginBottom: ".4em" };

const addVendor = ({
  hide,
  addVendorModal,
  getVendors,
  selectedVendor,
  actionType,
  _ismounted,
}) => {
  let labels = ["state", "city"];
  const initialState = {
    error: "",
    firstname: "",
    lastname: "",
    mobile: "",
    email: "",
    state: [],
    tat: "",
    countycode: "91",
    selectedPincode: {},
    states: [],
    city: [],
    pincode: [],
    service: {},
    demoGraphic: [],
    isError: true,
    newError: {},
  };

  const [state, setState] = useState({ ...initialState });

  let [ismounted, setIsmounted] = useState(_ismounted);

  // const [selectedID, setSelectedId] = useState('');
  // const [selectedService, setSelectedService] = useState('');
  // const [error, setError] = useState('');
  const [selectedPincode, setPincode] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedCity, setselectedCity] = useState([]);
  const [renderValue, setRenderValue] = useState(false);
  const [newError, setNewError] = useState({});

  let [filteredpincodes, setFilteredpincodes] = useState([]);

  let [count, setCount] = useState(200);

  let [pageno, setPageNo] = useState(0);
  let [totalcount, setTotalCount] = useState(0);
  let [filteredcount, setFilteredCount] = useState(0);
  let pageNo = 0;

  let [isfetching, setIsFetching] = useState(false);

  let [loadmore, setLoadmore] = useState(false);

  // useEffect(() => {
  //   getPincodeData();
  // }, []);

  const handleChange = (e, value, selector) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    switch (selector) {
      case "firstname":
        newError.firstname =
          value.length < 3 ? "First Name must be 3 characters long!" : "";
        break;
      case "lastname":
        newError.lastname =
          value.length < 3 ? "Last Name must be 3 characters long!" : "";
        break;
      case "email":
        newError.email =
          value.length > 0
            ? re.test(value)
              ? ""
              : "Please enter a valid email!"
            : "";
        break;
      case "mobile":
        newError.mobile =
          value.length > 9 && value.length < 11
            ? ""
            : "Please enter a valid mobile!";
        break;
      default:
        break;
    }
    setState({ ...state, [selector]: value });
    setNewError(newError);
  };

  // do not change anything in the useEffect

  // useEffect(() => {
  //   setPincode([]);
  //   setFilteredpincodes([]);
  // }, []);

  useEffect(() => {
    if (selectedVendor) {
      setIsmounted(true);
      setLoadmore(true);
    }
  }, []);

  useEffect(() => {
    setPageNo(0);

    if (ismounted) {
      return;
    }
    setLoadmore(false);
    if (!selectedState || selectedState.length === 0) {
      setselectedCity([]);
      setPincode([]);
      setFilteredpincodes([]);
      setFilteredCount(0);

      setRemoved([]);

      setRenderkey(renderkey + 1);
    } else {

      setFilteredpincodes([]);
      setRemoved([]);

      getPincodeData();
    }
  }, [selectedState]);

  useEffect(() => {
    setPageNo(0);

    if (ismounted) {
      return;
    }
    setLoadmore(false);
    if (!selectedCity || (selectedCity.length === 0 && !ismounted)) {

      setFilteredpincodes([]);
      setRemoved([]);

      setFilteredCount(0);
    } else {
      setFilteredpincodes([]);
      setRemoved([]);

      setPincode([]);
      getPincodeData();
    }
  }, [selectedCity]);

  useEffect(() => {
    if (
      selectedState &&
      selectedState.length > 1 &&
      selectedCity &&
      selectedCity.length === 0
    ) {
      setPincode([]);
    }
  }, [selectedPincode]);

  useEffect(() => {
    if (selectedVendor) {
      let states = [];
      let cities = [];
      let __result = [];
      let _removed = [];

      // if (selectedVendor.state && selectedVendor.state.length > 0) {
      //   states = selectedVendor.state.map((data) => {
      //     return { _id: data };
      //   });
      // }
      if (selectedVendor.pincode && selectedVendor.pincode.length > 0) {
        states = removeDuplicates(selectedVendor.pincode, "state").map((data) => {
          return { _id: data.state };
        });
      }     

      // if (selectedVendor.city && selectedVendor.city.length > 0) {
      //   cities = selectedVendor.city.map((data) => {
      //     return { _id: data };
      //   });
      // }
      if (selectedVendor.pincode && selectedVendor.pincode.length > 0) {
        cities = removeDuplicates(selectedVendor.pincode, "city").map((data) => {
          return { _id: data.city };
        });
      }

      if (selectedVendor.pincode && selectedVendor.pincode.length > 0) {
        __result = selectedVendor.pincode.map((element) => {
          return element.pincode;
        });
      }
      if (selectedVendor.removedpincode && selectedVendor.removedpincode.length > 0) {
        _removed = selectedVendor.removedpincode.map((element) => {
          return element.pincode;
        });
      }

      setState({
        ...state,
        firstname: selectedVendor.firstName,
        lastname: selectedVendor.lastName,
        mobile: selectedVendor.mobileNo,
        email: selectedVendor.email,
      });
      setSelectedState(states);
      setselectedCity(cities);

      if(selectedVendor.pincode && selectedVendor.pincode.length > 0) {
        setTotalCount(selectedVendor.pincode.length);
      } else {
        setTotalCount(0);
      }
      if(selectedVendor.pincode && selectedVendor.pincode.length > 0) {
        setFilteredCount(selectedVendor.pincode.length);
      } else {
        setFilteredCount(0);
      }
      
      setRemoved(_removed)      
      setFilteredpincodes(__result);
      setRenderkey(renderkey + 1);

    }
  }, [selectedVendor]);

  // do not change anything in the useEffect

  const handleSubmit = () => {
    const matcherForFeilds = {
      "firstname": "First name",
      "lastname": "Last name",
      "mobile": "Mobile",
      "email": "Email",
    }


    // this part of the validation is for the plain text and number feilds 
    let feildsToValidate = ["firstname", "lastname", "mobile"]

    feildsToValidate.forEach(feild => {
      if (state[feild] === '' || state[feild] === null || state[feild] === undefined) {
        newError[feild] = `${matcherForFeilds[feild]} cannot be left blank`
      }
    });

    // this part of validation is for the dropdowns
    let dropDownFeilds = ["selectedCity", "selectedState", "selectedPincode"];
    dropDownFeilds.forEach((feild) => {
      if (feild === "selectedCity") {
        newError[feild] =
          selectedCity && selectedCity.length > 0 ? "" : "City Cannot be blank";
      } else if (feild === "selectedState") {
        newError[feild] =
          selectedState && selectedState.length > 0
            ? ""
            : "state Cannot be blank";
      } else {
        newError[feild] =
          filteredpincodes && filteredpincodes.length > 0
            ? ""
            : "pincode Cannot be blank";
      }
    });

    for (var key in newError) {
      if (newError[key] === "") {
        delete newError[key];
      }
    }

    setRenderValue(!renderValue);
    if (Object.keys(newError).length < 1) {
      const params = {
        firstName: state.firstname,
        lastName: state.lastname,
        email: state.email,
        mobileNo: state.mobile,
        countryCode: state.countycode,
        city: selectedCity,
        state: selectedState,
        tat: state.tat,
        pincode: removed,
        user_id: selectedVendor ? selectedVendor._id : "",
      };
      if (actionType == "Add new Vendor") {
        addVendors(params).then((response) => {
          if (response && response.data.statusCode === 1) {
            hide();
            getVendors();
            toast.success(`${state.firstname} added as vendor !`);
            hide();
          } else {
            setState({
              ...state,
              error: response.data.statusCode === 0 ? response.data.error.errorMessage : "Something went  wrong during saving the data, please  try again"
            });
          }
        });
      } else {
        updateVendor(params).then((response) => {
          if (response && response.data.statusCode === 1) {
            hide();
            getVendors();
            toast.success(
              `${params.firstName + params.lastName} Edited successfull!`
            );
          } else {
            setState({ ...state, error: "Vendor Not updated!" });
            toast.error("Vendor Not updated !");
          }
        });
      }
    }
  };

  // for state
  const getStateData = (val) => {
    let params = { search: val, sortField: "_id", sortType: 1 };

    return getAllStates({ params }).then((res) => {
      if (res && res.data.statusCode === 1) {
        let data = res.data.responseData;
        setState({ ...state, states: data.result });
        return data.result;
      } else if (res.data.statusCode == 0) {
        console.log(res.data.error.errorMessage);
      }
    });
  };

  const loadStateServices = (key) => {
    return getStateData(key);
  };

  const serviceStateSelect = (service) => {
    setIsmounted(false);

    let selectedState = service;
    if (!service) {
      selectedState = [];
    }

    // service &&
    //   service.map((item) => {
    //     selectedState.push(item);
    //     city.push(item.label);
    //   });
    if (selectedState.length > 0) {
      getCityData(true, service);
    }
    setSelectedState(selectedState);
    setNewError({ ...newError, selectedState: "" });
  };

  // for city

  const getCityData = (val, states) => {
    // this above is done to prepopulate
    // the cities when a user selects the state

    let params = { search: val, sortField: "_id", sortType: 1 };

    if (states) {
      let t = selectedState.map((city) => city._id).join();
      params["states"] = states.map((city) => city._id).join();
    }

    // if (val) {
    //   params.citySearch = val;
    // }
    // params.states = states.join(' ,')

    return getAllCitys({ params }).then((res) => {
      if (res && res.data.statusCode === 1) {
        let data = res.data.responseData;
        let citys = res.data.responseData.result;

        setState({ ...state, citys: citys });

        if (val == true) {
          setselectedCity(citys);
        }

        return citys;
      } else if (res.data.statusCode == 0) {
        console.log(res.data.error.errorMessage);
      }
    });
  };
  const loadCityServices = (key) => {
    return getCityData(key);
  };

  const serviceCitySelect = (service) => {
    setIsmounted(false);

    let pincodes = [];
    let selectedCity = [];
    service &&
      service.map((item) => {
        selectedCity.push(item);
        pincodes.push(item.label);
      });
    // if (service) {
    //   setPageNo(0);
    //   getPincodeData("", pincodes);
    // }


    setselectedCity(selectedCity);
    setNewError({ ...newError, selectedCity: "" });
  };

  // for pincode
  const getPincodeData = (val, pincodes) => {
    // if (filteredpincodes.length == setTotalCount || filteredpincodes.length > setTotalCount) {

    //   return ;
    // }
    // setPincode(selectedPincode.filter((item) => pincodes.includes(item.city)));
    // let citys = pincodes.join();
    let citys = selectedCity.map((state) => state._id).join();
    // let states = selectedState.map(state => state.label).join();
    let states = [];

    let params = {
      count: count,
      pageNo: pageno,
      pincodeSearch: "",
      pincodes: [],
      citys: [],
    };

    // if (val) {
    //   params["pincodeSearch"] = val;
    // }

    if (citys) {
      params.citys = citys;
    }

    // if (states && states.length > 0) {
    //   params.pincodes = citys;
    // }

    return getAllPincodes({ params }).then((res) => {
      if (res && res.data.statusCode === 1) {
        let data = res.data.responseData;
        let result = data[0].result;

        setTotalCount(data[1]);

        let __result = [];

        result.forEach((element) => {
          __result.push(element.pincode);
        });

        let pincodeMerge;
        if (pageno == 0) {
          pincodeMerge = __result;
        } else {
          pincodeMerge = [...filteredpincodes, ...__result];
        }

        setFilteredCount(data[1]);

        setFilteredpincodes(pincodeMerge);

        setState({ ...state, pincode: result });

        return pincodes;
      } else if (res.data.statusCode == 0) {
        console.log(res.data.error.errorMessage);
      }
    });
  };

  const loadServicesPincode = (key) => {
    return getPincodeData(key);
  };

  const servicePincodeSelect = (service) => {
    let selectedPincode = [];
    service &&
      service.map((item) => {
        selectedPincode.push(item);
      });
    setPincode(selectedPincode);
  };

  let [renderkey, setRenderkey] = useState(1);
  let [removekey, setRemoveKey] = useState(1);
  let [removed, setRemoved] = useState([]);

  let removeChip = (index) => {
    let _filteredpincodes = filteredpincodes;

    let removeitems = removed;
    removeitems.push(_filteredpincodes[index]);

    setRemoved(removeitems);

    setRemoveKey(removekey + 1)

    _filteredpincodes.splice(index, 1);

    setFilteredpincodes(_filteredpincodes);
    let length = _filteredpincodes.length;
    setFilteredCount(length);
    setRenderkey(renderkey + 1);
  };

  let addChip = (data, index) => {
    let removeitems = removed;

    removeitems.splice(index, 1);

    setRemoved(removeitems);

    setRemoveKey(removekey + 1);

    setRenderkey(renderkey + 1);

    let _filteredpincodes = filteredpincodes;

    _filteredpincodes.unshift(data);

    setFilteredCount(_filteredpincodes.length);

    setFilteredpincodes(_filteredpincodes);
  };

  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) {
    return (
      <div className="cm-state1" key={index} style={style}>
        {filteredpincodes[index]}
        <button
          onClick={() => {
            removeChip(index);
          }}
          type="button"
          className="close"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }

  function removedrowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) {
    return (
      <div className="cm-state2" key= {index + 'removed'} style={style}>
        {/* <p className="" key={index}> */}
        {removed[index]}
        {/* <a> */}
        <svg
          onClick={() => {
            addChip(removed[index], index);
          }}
          width="5em"
          height="3em"
          viewBox="0 0 16 16"
          className="bi bi-check cursor-pointer"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
          />
        </svg>
        {/* </a> */}
        {/* </p> */}
      </div>
    );
  }

  function loadMoreRows({ startIndex, stopIndex }) {

    if (ismounted || loadmore) {
      return;
    }

    setIsFetching(true);

    setPageNo(pageno + 1);

    getPincodeData(true).then(() => {
      setIsFetching(false);
    });
  }

  const renderOptions = (val) => {
    if (val === "pincode") {
      return (
        <InfiniteLoader
          isRowLoaded={false}
          loadMoreRows={loadMoreRows}
          rowCount={filteredpincodes ? Math.floor(filteredpincodes.length) : 0}
        >
          {({ onRowsRendered, registerChild }) => (
            <List
              width={400}
              height={300}
              rowCount={filteredpincodes.length}
              rowHeight={20}
              rowRenderer={rowRenderer}
              onRowsRendered={onRowsRendered}
              ref={registerChild}
            // key={filteredpincodes.length}
            />
          )}
        </InfiniteLoader>

        // <div>
        //   <AsyncSelect
        //     isMulti
        //     value={selectedPincode}
        //     isDisabled={Array.isArray(selectedCity) && selectedCity.length > 0 ? false : true}
        //     cacheOptions
        //     loadOptions={loadServicesPincode}
        //     // defaultOptions={state.pincode}
        //     onChange={(val) => servicePincodeSelect(val)}
        //     placeholder="pincode"
        //   />
        //   {newError && newError["selectedPincode"] && <label style={{ color: "red" }}>{newError["selectedPincode"]}</label>}
        // </div>
      );
    } else if (val === "city") {
      return (
        <div>
          <AsyncSelect
            isMulti
            isDisabled={
              Array.isArray(selectedState) && selectedState.length > 0
                ? false
                : true
            }
            cacheOptions
            loadOptions={loadCityServices}
            // defaultOptions={state.city}
            onChange={(val) => serviceCitySelect(val)}
            placeholder="city"
            value={selectedCity}
            options={state.city}
            getOptionLabel={(opt) => opt._id}
            getOptionValue={(opt) => opt._id}
          />
          {newError && newError["selectedCity"] && (
            <label style={{ color: "red" }}>{newError["selectedCity"]}</label>
          )}
        </div>
      );
    }
    if (val === "state") {
      return (
        <div key={renderkey}>
          <AsyncSelect
            isMulti
            isDisabled={false}
            cacheOptions
            loadOptions={loadStateServices}
            value={selectedState}
            // defaultOptions={state.states}
            onChange={(val) => serviceStateSelect(val)}
            placeholder="state"
            getOptionLabel={(opt) => opt._id}
            getOptionValue={(opt) => opt._id}
          />
          {newError && newError["selectedState"] && (
            <label style={{ color: "red" }}>{newError["selectedState"]}</label>
          )}
        </div>
      );
    }
  };

  return (
    <Popup
      className="addvender-form"
      open={addVendorModal}
      closeOnDocumentClick={false}
      // onClose={hide}
      closeOnEscape
      lockScroll={true}
      onClose={() => {
        hide();
      }}
      key={actionType}
    >
      <div key={actionType} className="cm_modal pl-5 pr-5">

        <div className="text-center mb-4 mt-5">
          <button variant="danger" onClick={() => hide()}>Back</button>  
        </div>

        <h3 className="text-center mb-4 mt-5">{actionType}</h3>
        {state && state.error && (
          <div className="text-danger text-center">{state.error}</div>
        )}
        <Fragment>
          <Fragment>
            <div style={{ ...styleForWrapper }} className="form-group">
              <label style={{ ...style }}>firstname</label>
              <input
                type={"text"}
                className="form-control"
                onChange={(e) => handleChange(e, e.target.value, "firstname")}
                name={"firstname"}
                value={state.firstname}
                placeholder={"Firstname"}
              />

              {newError && newError.firstname && (
                <label style={{ color: "red" }}>{newError.firstname}</label>
              )}
            </div>
          </Fragment>
          <Fragment>
            <div style={{ ...styleForWrapper }} className="form-group">
              <label style={{ ...style }}>lastname</label>
              <input
                type={"text"}
                className="form-control"
                onChange={(e) => handleChange(e, e.target.value, "lastname")}
                name={"lastname"}
                value={state.lastname}
                placeholder={"Lastname"}
              />
              {newError && newError.lastname && (
                <label style={{ color: "red" }}>{newError.lastname}</label>
              )}
            </div>
          </Fragment>
          <Fragment>
            <div style={{ ...styleForWrapper }} className="form-group">
              <label style={{ ...style }}>email</label>
              <input
                type={"email"}
                className="form-control"
                onChange={(e) => handleChange(e, e.target.value, "email")}
                name={"email"}
                value={state.email}
                placeholder={"Email"}
                disabled={actionType == 'Update Vendor'}
              />
              {newError && newError.email && (
                <label style={{ color: "red" }}>{newError.email}</label>
              )}
            </div>
          </Fragment>
          <Fragment>
            <div style={{ ...styleForWrapper }} className="form-group">
              <label style={{ ...style }}>mobile</label>
              <input
                type={"number"}
                className="form-control"
                onChange={(e) => handleChange(e, e.target.value, "mobile")}
                name={"mobile"}
                value={state.mobile}
                placeholder={"Mobile"}
                maxLength={10}
              />
              {newError && newError.mobile && (
                <label style={{ color: "red" }}>{newError.mobile}</label>
              )}
            </div>
          </Fragment>
          {labels.map((label, index) => {
            return (
              <div key={index} className="form-group">
                <label style={{ textTransform: "capitalize" }}>{label}</label>
                {renderOptions(label)}
              </div>
            );
          })}
          {filteredpincodes || removed.length > 0 ? (
            <div className="row cm-state-container">
              <div key={removekey} className="state-wrapper col-6">
                {removed && removed.length > 0 ? (
                  <List
                    className="cm-state"
                    width={400}
                    height={300}
                    rowCount={removed.length}
                    rowHeight={20}
                    rowRenderer={removedrowRenderer}
                    key={renderkey + 9}
                  />
                ) : (
                    ""
                  )}
              </div>

              <div key={renderkey} className="state-wrapper col-6">
                <InfiniteLoader
                  isRowLoaded={() => {
                    return isfetching;
                  }}
                  loadMoreRows={loadMoreRows}
                  rowCount={filteredpincodes.length}
                >
                  {({ onRowsRendered, registerChild }) => (
                    <List
                      ref={registerChild}
                      width={400}
                      height={300}
                      rowCount={filteredpincodes.length}
                      rowHeight={20}
                      rowRenderer={rowRenderer}
                      onRowsRendered={onRowsRendered}
                      key={renderkey + 7}
                      className="cm-state"
                    />
                  )}
                </InfiniteLoader>
              </div>
            </div>
          ) : (
              ""
            )}
        </Fragment>

        
        <button
          onClick={handleSubmit}
          className="btn btn-primary btn-block mt-2"
        >
          Save
        </button>
      </div>
    </Popup>
  );
};

export default addVendor;

import React, { Component } from 'react';
import dateFormat from 'dateformat';
import Pagination from "react-js-pagination";
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable'
import Popup from "reactjs-popup"
import { Link } from 'react-router-dom';
import { DieticianService } from '../../config/services/dieticianService';
import { toast } from 'react-toastify';
import StarRatings from 'react-star-ratings';


class UserRatings extends Component {
    constructor(props) {
        super(props);

        this.searchTimeOut = null;
        this.state = {
            dieticianId: this.props.match.params.id,
            userList: [],
            countsPerPage: 10,
            pageNo: 1,
            search: '',
            sortType: 1,
            sortField: 'firstName',
            totalCount: 10,
        }
    }


    componentDidMount() {
        this.getDieticianRatings();
    }


    getDieticianRatings = () => {
        let { dieticianId, search, sortType, sortField,pageNo,countsPerPage } = this.state
        let paramsObj = {
            params: {
                dieticianId,
                search,
                sortField,
                sortType,
                pageNo:pageNo-1,
                count:countsPerPage
            }
        }
        this.setState({ loader: true })
        DieticianService.getDieticianRatings(paramsObj)
            .then(res => {
                const response = res.data
                if (response.statusCode == 1) {
                    this.setState({ userList: response.responseData.result,totalCount:response.responseData.totalCount,loader: false })
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage);
                    this.setState({ loader: false })
                }
            })
    }

    handleSearch = (e) => {
        this.setState({ search: e.target.value }, () => {
            this.getDieticianRatings();
        })
    }


    handleSortList = (sortField) => {
        const { sortType } = this.state;
        let sortOrder = sortType == 1 ? -1 : 1;
        this.setState({ sortField: sortField, loader: true, sortType: sortOrder }, () => {
            this.getDieticianRatings();
        })
    }



  handlePagination = (pageNumber) => this.setState({ pageNo: pageNumber }, () => {
    this.getDieticianRatings()
  });



    render() {

        const { userList, loader, sortType, pageNo, countsPerPage,totalCount } = this.state;

        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item"><Link to="/dieticians">Dieticians</Link></li>
                            <li className="breadcrumb-item">Ratings</li>
                        </ol>

                        {
                            loader
                                ? <div className="loader_wrapper"><LoaderSvg /></div>
                                : null
                        }

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="cm_page_heading">Users</h4>
                                    </div>
                                    <div className="col-md-3 text-right cm_search">
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search by Name or E-mail"
                                            name="search"
                                            onChange={this.handleSearch}
                                        />
                                        <i className="fa fa-search"></i>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>

                                        <th scope="col" className="filter_text">Name
                                        <i className={`fa fa-sort-alpha-${sortType == 1 ? 'asc' : 'desc'}`}></i><button onClick={() => this.handleSortList('firstName')}></button>
                                        </th>

                                        <th scope="col" className="filter_text">E-mail
                                        </th>

                                        <th scope="col" className="filter_text">Ratings</th>
                                        <th scope="col" className="filter_text">Reviews</th>
                                        <th scope="col" className="filter_text">Rated on:</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {userList.map((userList, index) => (
                                        <tr>
                                            <td>{((index + 1) + ((pageNo - 1) * countsPerPage))}</td>
                                            <td className="cm_pos_realtive">
                                                {userList.userDetails.firstName + " " + userList.userDetails.lastName}
                                            </td>
                                            <td>{userList.userDetails.email}</td>

                                            <td>
                                                <StarRatings
                                                    rating={userList.rating}
                                                    starDimension="20px"
                                                    starSpacing="8px"
                                                    starRatedColor='rgba(255,108,11,1)'
                                                />
                                            </td>
                                            <td>{userList.review}</td>
                                            <td>{dateFormat(userList.created, "d mmmm yyyy")}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {!userList.length ? <h3 className="empty_error">Sorry, We couldn't find any content!</h3> : null}
                        </div>

                        <Pagination
                            activePage={pageNo}
                            itemsCountPerPage={countsPerPage}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={4}
                            onChange={this.handlePagination}
                        />
                    </div>
                </div>
            </HOC>
        );
    }
}

export default UserRatings;
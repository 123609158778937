import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup"
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { changeStatus, addStream, getStreamList, editStream, deleteStream, downloadStreamList, downloadUserList, downloadFilteredUserList } from '../../config/services/streamingService';
import { toast } from 'react-toastify';
import { DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import FileSaver from 'file-saver';
import { getServicesForSession } from '../../config/services/serviceCategoriesService';
import { getAllTrainers, getExpertsByServiceId } from '../../config/services/trainerService';
import { getLeaderboardConfig } from '../../config/services/usersService';
import { ADMIN_ROLES, CONFIG_VERSIONS } from '../../utils/constants';
import { ReactComponent as RemoveFilter } from './../../assets/images/remove-filter.svg';
import { ReactComponent as DownloadIcon } from './../../assets/images/download.svg'
import { ReactComponent as EditIcon } from './../../assets/images/edit.svg'
import { ReactComponent as DeleteIcon } from './../../assets/images/delete.svg'
import { ReactComponent as StartIcon } from './../../assets/images/start.svg'
import { ReactComponent as StopIcon } from './../../assets/images/stop.svg'
import { ReactComponent as ViewIcon } from './../../assets/images/view.svg'
import { Tooltip } from 'antd';

const { RangePicker } = TimePicker;

class Streaming2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            products: [],
            search: '',
            count: 10,
            totalProducts: 15,
            pageNo: 1,
            sortType: -1,
            sortField: 'startTime',
            AddProductPopup: false,
            deleteProductPopup: false,
            viewProductPopup: false,
            productImage: '',
            title: '',
            imagePreview: '',
            titleErrorMsg: '',
            editProductPopup: false,
            tempProductId: "",
            minAmount: '',
            maxAmount: '',

            // new
            sessionName: '',
            categoryId: '',
            trainerName: '',
            youtubeUrl: '',
            startDate: '',
            endDate: '',
            coverImage: '',
            categories: [],
            trainersList: [],
            streamList: [],
            serviceList: [],
            serviceId: '',
            startTime: '',
            endTime: '',
            loggedInRole: 0,
            loggedInUser: localStorage.getItem('expertId'),
            savingLoader: false,
            filterData: {
                serviceId: '',
                trainersList: [],
                expertId: '',
                categoryId: '',
                dateFrom: moment().startOf('day').valueOf(),
                dateTo: moment().endOf('day').valueOf()
            },
            filterKey: 1,
            isFilterValid: false
        }
    }



    handleImgPreivew = e => {
        let reader = new FileReader();
        let file = e.target.files[0];
        this.setState({ productImage: e.target.files[0] })
        reader.addEventListener("load", () => {
            this.setState({ imagePreview: reader.result })
        }, false);
        if (file) { reader.readAsDataURL(file) }
    }



    openAddProductPopup = () => {
        this.setState({ AddProductPopup: !this.state.AddProductPopup })
        document.getElementById('body-id').style.overflow = "hidden"
    }

    openDeleteProductPopup = (data, product) => this.setState({ deleteProductPopup: !this.state.deleteProductPopup, broadCastId: product._id });

    deleteProduct = (e) => {
        e.preventDefault();
        const { broadCastId } = this.state
        let paramsObj = {
            broadCastId: broadCastId
        }
        deleteStream(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    this.closeModal();
                    this.getRewardProductsList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }

    openViewProductPopup = (data, product) => {
        this.setState({
            viewProductPopup: true,
            tempProductId: product._id,
            productName: product.productName,
            coinsRequired: product.coinsRequired,
            productType: product.productType,
            imagePreview: product.productImage,
        })
    };

    getDefaultConfigs = () => {
        let params = { version: CONFIG_VERSIONS.ADMIN_CONFIG }
        getLeaderboardConfig(params)
            .then(res => {
                const response = res.data;
                if (response.statusCode == 1) {
                    let metaData = response.responseData.result.metaData
                    let streamingTypes = metaData && JSON.parse(metaData) ? JSON.parse(metaData).STREAMING_TYPES : []
                    streamingTypes.map(type => { type.id = type.value; type.name = type.label })
                    this.setState({ categories: streamingTypes })
                }
                else if (response.statusCode == 0) {
                    toast.success(response.error.errorMessage)
                }
            })
    }

    closeModal = () => {
        this.clearState()
        this.setState({
            AddProductPopup: false, deleteProductPopup: false, viewProductPopup: false, title: "", productImage: "", editProductPopup: false, imagePreview: "", tempProductId: "", titleErrorMsg: "", productName: "", coinsRequired: "", productType: "", categoryId: "", savingLoader: false
        });
        document.getElementById('body-id').style.overflow = ''
    }


    handleSearch = e => this.setState({ search: e.target.value }, () => this.getRewardProductsList());


    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? -1 : 1 }, () => this.getRewardProductsList());


    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getRewardProductsList() });


    getRewardProductsList = (data) => {
        this.setState({ loader: true })
        let { search, count, pageNo, sortType, sortField, filterData } = this.state;
        let params
        if (data && typeof (data == 'object') && Object.keys(data) && Object.keys(data).length) {
            params = { ...{ search, count, pageNo: pageNo - 1, sortType, sortField }, ...data }
        }
        else {
            params = { ...{ search, count, pageNo: pageNo - 1, sortType, sortField }, ...filterData }
        }

        params = { ...params, trainersList: [] }

        let paramsObj = {
            params
        }
        getStreamList(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({
                        streamList: res.data.responseData.result.broadCastData,
                        totalProducts: res.data.responseData.result.broadCastCount,
                        loader: false
                    })
                } else {
                    this.setState({ loader: false })
                }
            })
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value, titleErrorMsg: '' })
    }

    handleValidation = () => {
        let validate = true;
        let reg = /^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$/
        let {
            sessionName,
            youtubeUrl,
            startDate,
            endDate,
            trainerName,
            startTime,
            endTime,
            serviceId,
            categoryId
        } = this.state;

        if (!sessionName) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please Enter Session name' })
        }
        else if (!categoryId) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please select category' })
        }
        else if (serviceId == '') {
            validate = false;
            this.setState({ titleErrorMsg: 'Please select the session type' })
        }
        else if (!trainerName) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please select the  trainer name' })
        }
        else if (youtubeUrl == "") {
            validate = false;
            this.setState({ titleErrorMsg: 'Please enter url' })
        }
        else if (!reg.test(youtubeUrl)) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please enter valid youtube url' })
        }

        else if (startDate == '') {
            validate = false;
            this.setState({ titleErrorMsg: 'Please enter date' })
        }
        else if (startTime == '' || endTime == '') {
            validate = false;
            this.setState({ titleErrorMsg: 'Please enter start & end time' })
        }
        else {
            this.setState({ titleErrorMsg: '' })
        }
        return validate
    }


    clearState = () => {
        this.setState({
            broadCastId: '',
            sessionName: '',
            trainerName: '',
            startDate: '',
            startTime: '',
            endTime: '',
            youtubeUrl: '',
            serviceId: '',
            categoryId: ''
        })
    }


    setDMY = (originalDate, newDate) => {
        let changedDate = Number(moment(newDate).set("date", moment(originalDate).get('date')).set("month", moment(originalDate).get('month')).set("year", moment(originalDate).get('year')).format('x'))
        return Number(changedDate)
    }


    handleAddProduct = e => {
        e.preventDefault();
        if (this.handleValidation()) {
            let { startTime, endTime, sessionName, trainerName, youtubeUrl, startDate, endDate, serviceId, categoryId } = this.state;
            startTime = this.setDMY(startDate, startTime)
            endTime = this.setDMY(startDate, endTime)

            let params = {
                startDate,
                startTime,
                endTime,
                endDate,
                url: youtubeUrl,
                title: 'fit+',
                expertId: trainerName,
                videoSessionType: 3,
                serviceId: serviceId,
                title: sessionName,
                categoryId: parseInt(categoryId)
            }
            this.addProductApi(params)
        }
    }

    addProductApi = (params) => {
        this.setState({ loader: true, savingLoader: true })
        addStream(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false, savingLoader: false }, () => this.getRewardProductsList())
                    this.closeModal();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({
                        titleErrorMsg: res.data.error.errorMessage,
                        loader: false, savingLoader: false
                    })
                }
            })
    }



    openEditProductPopup = (data, params) => {
        const { serviceId, expertId } = params || {}
        this.setState({
            editProductPopup: true,
            broadCastId: params._id,
            sessionName: params.title,
            trainerName: expertId && expertId._id,
            startDate: params.startDate,
            // endDate: params.endDate,
            startTime: params.startTime,
            endTime: params.endTime,
            youtubeUrl: params.url,
            serviceId: serviceId && serviceId._id,
            categoryId: params.categoryId
        }, function () {
            if (serviceId && serviceId._id) {
                let paramsObj = {
                    params: {
                        serviceId: serviceId._id, isLive: 1
                    }
                }
                this.callExpertsByServiceApi(paramsObj);
            }
        })
        document.getElementById('body-id').overflow = 'hidden'
    }

    editProductSubmit = (e) => {
        e.preventDefault();
        if (this.handleValidation()) {
            let { startTime, endTime, broadCastId, sessionName, trainerName, youtubeUrl, startDate, endDate, serviceId, categoryId } = this.state;
            startTime = this.setDMY(startDate, startTime)
            endTime = this.setDMY(startDate, endTime)

            let params = {
                startTime,
                endTime,
                startDate,
                broadCastId,
                endDate,
                url: youtubeUrl,
                title: 'fit+',
                expertId: trainerName,
                serviceId: serviceId,
                videoSessionType: 3,
                title: sessionName,
                categoryId: parseInt(categoryId)
            }
            this.editProductApi(params);
        }
    }

    editProductApi = (params) => {
        this.setState({ loader: true, savingLoader: true })
        editStream(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.getRewardProductsList();
                    this.setState({ loader: false, savingLoader: false })
                    this.closeModal();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ titleErrorMsg: res.data.error.errorMessage, loader: false, savingLoader: false })
                }
            })
    }

    filterByDate = (date) => {
        let dateFrom = null, dateTo = null;
        let __data;
        if (date) {
            let dateFrom = moment(date[0]).startOf('day').valueOf()
            let dateTo = moment(date[1]).endOf('day').valueOf()
            __data = { ...this.state.filterData, dateFrom, dateTo }
        } else {
            __data = { ...this.state.filterData, dateFrom, dateTo }
        }
        this.setState({ dateFrom, dateTo, filterData: __data, pageNo: 1 }, () => {
            this.getRewardProductsList(__data)
        })
        this.setState({ isFilterValid: true });
    }

    filterSessions = (value, key) => {
        let __data;
        // if (key == 'startDate') {
        //     let dateFrom =  moment(value).startOf('day').valueOf() 
        //     let dateTo =  moment(value).endOf('day').valueOf() 
        //     __data = { ...this.state.filterData, dateFrom , dateTo }
        // } else {
        //     __data = { ...this.state.filterData, [key]: value }           
        // }
        __data = { ...this.state.filterData, [key]: value }

        if (key == 'serviceId') {
            let paramsObj = {
                params: {
                    serviceId: value,
                    isLive: 1
                }
            }

            __data = { ...__data, expertId: '' }
            this.callExpertsByServiceApi(paramsObj, true);
        }
        this.setState({
            filterData: __data,
        })
        this.getRewardProductsList(__data);

        this.setState({ isFilterValid: true });
    }

    downloadStreamingList = async () => {

        try {

            this.setState({
                loader: true
            })

            let { search, count, pageNo, sortType, sortField } = this.state;

            let params = { ...{ search, count, pageNo: pageNo - 1, sortType, sortField }, ...this.state.filterData }

            let res = await downloadStreamList(params)

            var blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, `${moment() + ' sessions'}.xlsx`);
            this.setState({
                loader: false
            })

        } catch (error) {

            console.log("error", error);

            this.setState({
                loader: false
            })
        }
    }



    componentDidMount() {
        this.getRewardProductsList();
        this.getServicesForSession();
        this.setRole();
        this.getDefaultConfigs();
    }


    setRole = () => {
        this.setState({ loggedInRole: Number(localStorage.getItem('role')) })
    }

    categoryName = (catId) => {
        let catgoryInfo = this.state.categories.find(e => e.id === catId);
        if (catgoryInfo && catgoryInfo.name) {
            return catgoryInfo.name;
        } else {
            return 'N/A';
        }
    }

    changeTime = (dates, dateStrings) => {
        this.setState({ startTime: dates[0].toDate().getTime(), endTime: dates[1].toDate().getTime(), titleErrorMsg: '' })
    }

    dateChange = (dates, dateStrings) => {
        this.setState({ startDate: dates.toDate().getTime(), titleErrorMsg: '' })
    }

    getServicesForSession = () => {
        getServicesForSession()
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {

                    response.responseData.result.map((data, i) => {
                        data.label = data.serviceName
                        data.value = data._id
                    })
                    this.setState({
                        serviceList: response.responseData.result,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }




    getTrainersList() {
        let self = this;
        let params = {
            params: { count: 200, isLive: 1 }
        }
        getAllTrainers(params)
            .then(function (res) {
                const response = res.data;
                if (response.statusCode == 1) {
                    const { result } = res.data.responseData
                    self.setState({ trainersList: result })
                } else
                    if (response.statusCode == 0) {
                        toast.error(response.error.errorMessage)
                    }
            })
    }

    handleSelectService = (e) => {
        this.setState({ serviceId: e.target.value, titleErrorMsg: '', trainerName: '' })
        let paramsObj = {
            params: {
                serviceId: e.target.value, isLive: 1
            }
        }
        this.callExpertsByServiceApi(paramsObj);
    }

    callExpertsByServiceApi = (paramsObj, isfilter) => {
        getExpertsByServiceId(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    response.responseData.result.map((data, i) => {
                        data.label = data.firstName + ' ' + data.lastName
                        data.value = data._id
                    })
                    if (!isfilter) {
                        this.setState({
                            trainersList: response.responseData.result,
                            loader: false
                        })
                    }
                    else {
                        let __data = { ...this.state.filterData, trainersList: response.responseData.result }
                        this.setState({
                            filterData: __data,
                            loader: false
                        })
                    }

                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }



    changeStatusStream = (status, item) => {
        // e.preventDefault();
        const { tempProductId } = this.state
        let paramsObj = {
            status: status,
            broadCastId: item._id
        }
        changeStatus(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    // this.closeModal();
                    this.getRewardProductsList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }

    getIsEnable = product => {
        if (moment(product.startDate).format('D') == moment().format('D') && moment(product.endTime) < moment()) {
            return 0
        }
        if (moment(product.startDate) < moment() && moment(product.endTime) < moment()) {
            return 0
        }
        else {
            return 1
        }

    }

    clearFilterData = () => {
        let _filterdata = {
            serviceId: '',
            trainersList: [],
            expertId: '',
            categoryId: '',
            dateFrom: moment().startOf('day').valueOf(),
            dateTo: moment().endOf('day').valueOf(),
        }

        this.setState({ filterData: _filterdata })

        this.setState({ filterKey: this.state.filterKey + 1 })

        this.getRewardProductsList(_filterdata)
        this.setState({ isFilterValid: false });
    }

    _downloadUserList = async (title, firstName, streamingId) => {
        try {
            this.setState({ loader: true })
            downloadUserList({ params: { streamingId } })
                .then(async (response) => {
                    let resp = response.data
                    if (resp.statusCode == 1) {
                        let { result } = resp.responseData
                        FileSaver.saveAs(result.fileFullUrl)
                        this.setState({ loader: false })
                        toast.success(resp.responseData.message)
                    }
                    else if (resp.statusCode == 0) {
                        this.setState({ loader: false })
                        toast.error(resp.error.errorMessage)
                    }
                })
        }
        catch (error) {
            this.setState({ loader: false })
            console.log(error);
        }
    };

    _downloadFilteredUserList = async () => {
        let { filterData } = this.state
        try {
            this.setState({ loader: true })
            downloadFilteredUserList({ params: filterData })
                .then(async (response) => {
                    let resp = response.data
                    if (resp.statusCode == 1) {
                        let { result } = resp.responseData
                        FileSaver.saveAs(result.fileFullUrl)
                        this.setState({ loader: false })
                        toast.success(resp.responseData.message)
                    }
                    else if (resp.statusCode == 0) {
                        this.setState({ loader: false })
                        toast.error(resp.error.errorMessage)
                    }
                })
        }
        catch (error) {
            this.setState({ loader: false })
            console.error(error);
        }
    };

    checkConnectedNonConnectedSession = (categoryId) => {
        let check = this.state.categories.find(ele => ele.value == categoryId);
        if (check) {
            if (check.connectedSession == true) {
                return true;

            }
            else {
                return false;
            }
        }
        else {
            return false;

        }


    }

    getSessionStatus = (startDate, startTime, endTime) => {
        if (
            moment(startDate) < moment() &&
            moment(endTime) < moment()
        ) {
            return "Completed";
        }

        // if (moment(startDate) > moment()) {
        //   return "Not Started";
        // }
        if (moment() < moment(startTime)) {
            return "Yet to start";
        } else if (moment() > moment(endTime)) {
            return "Completed";
        } else if (
            moment() >= moment(startTime) &&
            moment() <= moment(endTime)
        ) {
            return "Started";
        }
        // else if (
        //   moment() >= moment(startTime) &&
        //   moment() <= moment(endTime)
        // ) {
        //   return "Started";
        // }
    };


    render() {
        const {
            loader,
            products,
            search,
            AddProductPopup,
            imagePreview,
            titleErrorMsg,
            deleteProductPopup,
            viewProductPopup,
            pageNo,
            editProductPopup,
            count,
            totalProducts,
            productName,
            coinsRequired,
            productType,
            streamList,
            categories,
            categoryId,
            loggedInRole,
            loggedInUser,
            savingLoader,
            filterData,
            filterKey
        } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container body-container-stream">
                        <div className="d-sm-flex justify-content-between border-bottom custom-list mb-3">
                            <ol className="breadcrumb breadcrumb-custom">
                                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item">Streaming management</li>
                            </ol>
                        </div>
                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}

                        <div key={filterKey} className="users_header user-header-streaming m-0">
                            <div className="streaming-header-wrapper">
                                <div className="streaming-filter mb-3 mb-sm-0">
                                    <Tooltip
                                        placement="topLeft"
                                        onClick={() => {
                                            this.state.isFilterValid && this.clearFilterData();
                                        }}
                                        className="d-flex align-items-center"
                                        title={`${this.state.isFilterValid ? "Clear Filter" : "No filter applied yet"
                                            }`}
                                        arrowPointAtCenter
                                    >
                                        {this.state.isFilterValid ? (
                                            <RemoveFilter className="remove-filter" />
                                        ) : (
                                                <i className="fa fa-filter"></i>
                                            )}
                                        <span className="text-filter-mobile d-block d-sm-none ml-3">
                                            Clear Filter
                                        </span>
                                    </Tooltip>
                                </div>
                                <div className="filter-wrappers">
                                    <div className="filter-select-wrapper">
                                        <div className="form-group">
                                            <label>Category</label>
                                            <select className="form-control" onChange={(e) => { this.filterSessions(e.target.value, 'categoryId') }} name="categoryId">
                                                <option selected disabled> Selelct Category</option>
                                                {
                                                    categories.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.id} > {`${item.name}`} </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="filter-select-wrapper">
                                        <div className="form-group">
                                            <label>Session Type</label>
                                            <select className="form-control" onChange={(e) => { this.filterSessions(e.target.value, 'serviceId') }} name="serviceId">
                                                <option selected disabled> Selelct Session Type</option>
                                                {
                                                    this.state.serviceList.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item._id} > {`${item.serviceName}`} </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="filter-select-wrapper">
                                        <div className="form-group">
                                            <label>Trainer Name</label>
                                            <select className="form-control" onChange={(e) => { this.filterSessions(e.target.value, 'expertId') }} name="trainerName">
                                                <option selected value=''> Selelct trainer name</option>
                                                {
                                                    this.state.filterData.trainersList.map((item, index) => {
                                                        return (
                                                            <option value={item._id} key={index} > {`${item.firstName} ${item.lastName}`} </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="filter-select-wrapper">
                                        <div className="form-group">
                                            <label>Filter By Session Date</label> <br />
                                            <DatePicker.RangePicker
                                                className="form-control"
                                                size={"middle"}
                                                onChange={(val) => this.filterByDate(val)}
                                                defaultValue={[moment(filterData.dateFrom), moment(filterData.dateTo)]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="downloads-button-wrapper d-flex flex-column justify-content-center">

                                {/* add new session */}
                                {loggedInRole !== ADMIN_ROLES.TRAINER ?
                                    <div className="form-group add-session ml-auto">
                                        <button className="btn btn-primary btn-block" onClick={this.openAddProductPopup}>
                                            <i className="fa fa-plus"></i>
                                            &nbsp; Add New
                                        </button>
                                    </div>
                                    : null}
                                <div className="downloads d-flex flex-sm-row flex-md-column flex-lg-row align-items-center justify-sm-content-between justify-lg-content-end mt-2 mb-4 mb-md-0">
                                    <div className="form-group  ml-md-auto mr-lg-3  mb-md-3">
                                        <button className="btn button-primary btn-block" onClick={() => { this.downloadStreamingList() }}>
                                            <DownloadIcon />
                                            &nbsp;Stream List
                                        </button>
                                    </div>
                                    <div className="form-group ml-auto">

                                        <button className="btn button-primary btn-block" onClick={() => { this._downloadFilteredUserList() }}>
                                            <DownloadIcon />
                                            &nbsp;Users List
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive custom-table-view">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        {/* <th scope="col" className="filter_text" onClick={() => this.handleSort('productName')}>Session Name &nbsp;
                                         <i className={`fa fa-sort-alpha-asc`} />
                                        </th> */}
                                        <th scope="col" style={{ width: '140px' }}>Category</th>
                                        <th scope="col">Service type</th>
                                        <th scope="col">Trainer Name</th>
                                        <th scope="col">Session Name</th>
                                        <th scope="col">Session date/Time</th>
                                        <th scope="col">Youtube Url</th>
                                        <th scope="col" className="filter_text"
                                        //  onClick={() => this.handleSort('created')}
                                        >Created &nbsp;
                                            {/* <i className={`fa fa-sort-numeric-asc`} /> */}
                                        </th>
                                        <th scope="col">Action</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">View</th>
                                        <th scope="col">User List</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        streamList.map((product, i) => {
                                            console.log(product);
                                            return (
                                                <tr key={i}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>

                                                    {/* <td><span className="cm_table_three_dots">{product.title}</span></td> */}
                                                    <td style={{ width: "140px", minWidth: "140px" }}>
                                                        <span style={{ width: "140px" }}>
                                                            {/* {product.categoryId ? product.serviceId.serviceName : 'N/A'} */}
                                                            {/* {categories.filter(function (category) { return category.id == product.serviceId })} */}

                                                            {product.categoryId ? this.categoryName(product.categoryId) : 'N/A'}
                                                        </span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">{product.serviceId ? product.serviceId.serviceName : 'N/A'}</span></td>

                                                    <td><span className="cm_table_three_dots">{product.expertId ? `${product.expertId.firstName} ${product.expertId.lastName}` : 'N/a'}</span></td>

                                                    <td><span className="cm_table_three_dots">{product.title}</span></td>

                                                    <td className="cm_no_wrap">{`${dateFormat(product.startDate, "d/mm/yyyy")}`} <br />

                                                        {`${moment(product.startTime).format('LT')} - ${moment(product.endTime).format('LT')}`}
                                                    </td>

                                                    <td className="cm_no_wrap"><a href={product.url} target="_blank">{product.url}</a></td>



                                                    <td className="cm_no_wrap">{dateFormat(product.created, "d mmmm yyyy")}</td>


                                                    <td className="cm_no_wrap">

                                                        {/* <button className="btn btn-primary btn-sm" onClick={(data) => this.openViewProductPopup(data, product)}>View</button>
                                                        &nbsp;&nbsp; */}
                                                        <div className="d-flex align-items-center">
                                                            <span disabled={loggedInRole === ADMIN_ROLES.TRAINER} onClick={(data) => this.openEditProductPopup(data, product)} >
                                                                <i className="fa fa-edit stream-action-icon mt-1"></i>
                                                            </span>
                                                            &nbsp;&nbsp;

                                                            <span className="d-flex align-items-center" disabled={loggedInRole === ADMIN_ROLES.TRAINER} onClick={(data) => this.openDeleteProductPopup(data, product)}>
                                                                <i className="fa fa-trash stream-action-icon text-danger"></i>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="cm_no_wrap">
                                                        {
                                                            product.isEnable == 0 ?
                                                                <span disabled={loggedInRole === ADMIN_ROLES.TRAINER} onClick={() => this.changeStatusStream(1, product)} >
                                                                    <StartIcon className="stream-action-icon" />
                                                                </span>
                                                                :
                                                                <span disabled={loggedInRole === ADMIN_ROLES.TRAINER} onClick={() => this.changeStatusStream(0, product)} >
                                                                    <StopIcon className="stream-action-icon" />
                                                                </span>
                                                        }
                                                    </td>


                                                    <td className="">
                                                        {
                                                            this.checkConnectedNonConnectedSession(product.categoryId) ? product && product.expertId ?
                                                                ADMIN_ROLES.TRAINER === loggedInRole && product.expertId._id !== loggedInUser ? 'Not Authorised' :
                                                                    <Link target="_blank" to={"/streamingDetails2?expertId=" + product.expertId._id + '&streamingId=' + product._id + '&&isEnable=' + this.getIsEnable(product)}>
                                                                        <ViewIcon className="stream-action-icon" />
                                                                    </Link>

                                                                : '-' : product && product.expertId ?
                                                                    ADMIN_ROLES.TRAINER === loggedInRole && product.expertId._id !== loggedInUser ? 'Not Authorised' :
                                                                        <Link target="_blank" to={"/streamingDetails?expertId=" + product.expertId._id + '&streamingId=' + product._id + '&&isEnable=' + this.getIsEnable(product)}>
                                                                            <ViewIcon className="stream-action-icon" />
                                                                        </Link>

                                                                    : '-'
                                                        }

                                                    </td>
                                                    <td>
                                                        {this.getSessionStatus(product.startDate, product.startTime, product.endTime) == "Completed" &&
                                                            loggedInRole === ADMIN_ROLES.ADMIN
                                                            ? (
                                                                <div>
                                                                    <a
                                                                        onClick={() => {
                                                                            this._downloadUserList(product.title, product.firstName, product._id);
                                                                        }}
                                                                        className="hover btn btn-primary d-flex align-items-center"

                                                                    >
                                                                        <DownloadIcon className="stream-action-icon" />
                                                                    </a>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <button disabled={true}
                                                                        className="hover btn btn-primary d-flex align-items-center"

                                                                    >
                                                                        <DownloadIcon className="stream-action-icon" />
                                                                    </button>
                                                                </div>
                                                            )}
                                                    </td>
                                                </tr>
                                            )
                                        })

                                    }
                                </tbody>
                            </table>


                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalProducts}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!streamList.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}
                        </div>
                    </div>
                </div>



                <Popup
                    open={AddProductPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <form onSubmit={this.handleAddProduct}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Session Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="sessionName"
                                            placeholder='Session Name.....'
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Category</label>
                                        <select className="form-control" onChange={this.handleChange} name="categoryId">
                                            <option selected disabled> Selelct Category</option>
                                            {
                                                categories.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.id} > {`${item.name}`} </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Session Type</label>
                                        <select className="form-control" onChange={this.handleSelectService} name="serviceId">
                                            <option selected disabled> Selelct Session Type</option>
                                            {
                                                this.state.serviceList.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item._id} > {`${item.serviceName}`} </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Trainer Name</label>
                                        <select className="form-control" onChange={this.handleChange} name="trainerName">
                                            <option selected value='' > Selelct trainer name</option>
                                            {
                                                this.state.trainersList.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item._id} > {`${item.firstName} ${item.lastName}`} </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Youtube url</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="youtubeUrl"
                                            placeholder="eg.www.youtube.com/aq2hfLHwY_0"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Date</label> <br />
                                        <DatePicker
                                            onChange={this.dateChange}
                                            dropdownClassName="upar_index"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Start Time -End Time</label> <br />
                                        <RangePicker
                                            onChange={this.changeTime}
                                            dropdownClassName="upar_index"
                                            className="form-control"
                                            popupClassName="time-range-popup"
                                            format={'HH:mm'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                {titleErrorMsg && <h6 className="text-center mb-2 mt-2 error_message">{titleErrorMsg}</h6>}
                                <button className="btn btn-primary" type="submit" disabled={savingLoader}>{savingLoader ? 'Adding Session' : 'Add Session'} </button>
                            </div>
                        </form>
                    </div>
                </Popup>



                <Popup
                    open={editProductPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <form onSubmit={this.editProductSubmit}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Session Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="sessionName"
                                            value={this.state.sessionName}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Category</label>
                                        <select className="form-control" onChange={this.handleChange} name="categoryId" value={categoryId}>
                                            <option selected value='' > Selelct Category</option>
                                            {
                                                categories.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.id} > {`${item.name}`} </option>
                                                    )
                                                })
                                            }

                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Session Type</label>
                                        <select className="form-control" onChange={this.handleSelectService} value={this.state.serviceId} name="serviceId">
                                            <option selected disabled>Selelct Session Type</option>
                                            {
                                                this.state.serviceList.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item._id} > {`${item.serviceName}`} </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Trainer Name</label>
                                        <select className="form-control" onChange={this.handleChange} value={this.state.trainerName} name="trainerName">
                                            <option selected value=''> Selelct trainer name</option>
                                            {
                                                this.state.trainersList.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item._id} > {`${item.firstName} ${item.lastName}`} </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Youtube url</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="youtubeUrl"
                                            value={this.state.youtubeUrl}
                                            placeholder="eg.www.youtube.com/aq2hfLHwY_0"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Date</label> <br />
                                        <DatePicker
                                            defaultValue={moment(new Date(this.state.startDate))}
                                            onChange={this.dateChange}
                                            dropdownClassName="upar_index"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Start Time -End Time</label> <br />
                                        <RangePicker
                                            defaultValue={[moment(new Date(this.state.startTime)), moment(new Date(this.state.endTime))]}
                                            onChange={this.changeTime}
                                            dropdownClassName="upar_index"
                                            className="form-control"
                                            format={'HH:mm'}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="text-center">
                                {titleErrorMsg && <h6 className="text-center mb-2 mt-2 error_message">{titleErrorMsg}</h6>}
                                <button className="btn btn-primary" type="submit" disabled={savingLoader}>{savingLoader ? 'Updating Session' : 'Update Session'}</button>
                            </div>
                        </form>
                    </div>
                </Popup>





                <Popup
                    open={deleteProductPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h6 className="text-center mb-2 mt-2">Are you sure, you want to delete this Session?</h6>
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-danger btn-sm btn-block" onClick={this.deleteProduct}>Delete</button>
                            </div>
                            <div className="col">
                                <button className="btn btn-warning btn-sm btn-block" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Popup>




                <Popup
                    open={viewProductPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="card card_style">
                        <img className="card-img-top" src={imagePreview} alt="Food image" />
                        <span className="card_style_cat">Value : {coinsRequired} Coins</span>
                        <div className="p-3 text-center">
                            <h4 className="card-title three_dots">{productName}</h4>
                            <p className="card-text">{productType == 1 ? "Product" : productType == 2 ? "Deal" : "N/A"} </p>
                        </div>
                    </div>
                </Popup>
            </HOC>
        )
    }
}



export default Streaming2
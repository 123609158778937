import React, { Component, Fragment } from 'react';
import Pagination from "react-js-pagination";
import HOC from '../../HOC';
import { Link } from 'react-router-dom';
import { getServiceList, addRequestsFromExcel, findExistingOrderIdFromExcel, addMultipleRequestsByAdmin, addRequestByAdmin, deleteRequest, assignRequestToVendor, updateRequestStatus, markSelfDone, exportServiceRequest } from '../../config/services/admin_Services';
import { getAllVendors } from '../../config/services/vendorService';
import { LoaderSvg } from '../Reusable'
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import { Button, Modal, FormControl, Form, Alert, FormGroup, FormLabel, Spinner, InputGroup, FormCheck, Row } from 'react-bootstrap';
import { ServiceTypes, SearchTypes } from './Constants';
import Select from 'react-select';
import { DatePicker, Comment, Avatar } from 'antd';
import UserJobDetails from '../profile/UserJobDetails';
import PopUpModal from '../../components/common/PopUpModal';
import { getAllPincodes, getAllStates, getAllCitys } from '../../config/services/servicePincodes'
import { addNoteToServicerequest } from '../../config/services/ProductServiceIncident';
import Loader from '../loader';
import { UserOutlined } from '@ant-design/icons';
import Popup from '../Popup';
import { getCRMAgentList, assignRequestToAgent } from '../../config/services/CRMAgent';
import { Switch } from 'antd';
const { removeDuplicates } = require("../utils/Util.js");
const { serviceTypeOptions, statusFilterOptions, sourceFilterOptions, getSortingIcon } = require("../utils/Util.js");
const { RangePicker } = DatePicker;

const columnDetails = [
    { fieldName: "S.No." },
    { fieldName: "Creation Date", isSortable: true, dataIndex: "created" },

    { fieldName: "Request Id" },
    { fieldName: "Order Id" },
    { fieldName: "Customer Name" },
    { fieldName: "Pincode" },
    { fieldName: "Customer Address" },
    { fieldName: "City " },
    { fieldName: "State" },
    { fieldName: "TAT Days" },
    { fieldName: "Email" },
    { fieldName: "Contact No." },
    // { fieldName: "Customer Type" },
    { fieldName: "Brand" },
    { fieldName: "Model" },
    { fieldName: "Vertical" },
    { fieldName: "Source" },
    { fieldName: "Service Type" },
    // { fieldName: "Product Service" },
    { fieldName: "Self Done",  dataIndex: "selfDone" },
    { fieldName: "Assign Professionals" },
    { fieldName: "Assigned Agent" },
    { fieldName: "Status" },
    { fieldName: "Purchase Date", isSortable: true, dataIndex: "purchaseDate" },
    { fieldName: "Delivery Date" },
    { fieldName: "Registration Date", isSortable: true, dataIndex: "registrationDate" },
    { fieldName: "Action" },
    { fieldName: "Notes" },
];

const states = []

class AdminService extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageNo: 1,            
            count: 10,
            statePageNo: 0,
            stateCount: 1000,
            cityPageNo: 0,
            cityCount: 1000,
            loader: true,
            UserSearch: '',
            sortField: 'created',
            sortFieldIcon: false,
            sortType: 2,
            loading: false,
            openAddServicePopup: false,
            serviceRequestsList: [],
            serviceRequestsCount:0,
            validationError: '',
            importExcelPopup: false,
            isUploading: false,
            requestsExcelSheet: '',
            validatedServiceRequests: [],
            validateExistingRequestsPopup: false,
            firstName: '',
            lastName: '',
            email: '',
            countryCode: null,
            mobileNo: null,
            orderId: '',
            modelNo: '',
            deliveryDate: "",
            address: '',
            remark: '',
            serviceType: '',
            service: '',
            mailingStreet: '',
            mailingCity: '',
            mailingState: '',
            pincode: null,
            selectedService: {},
            searchField: '',
            searchValue: '',
            searchError: '',
            deleteRequestPopup: false,
            selectedRequest: {},
            vendors: [],
            assignVendorPopup: false,
            selectedVendor: {},
            dateFrom: moment().startOf('day').valueOf(),
            dateTo: moment().endOf('day').valueOf(),
            statusFilter: "",
            serviceTypeFilter: "",
            sourceFilter: "",
            sortInfo: {},
            badEntryErrors: [],
            productWebsite: null,
            addNotes: false,
            notes: '',
            showNotes: false,
            apiCalled: false,
            closePopUp: true,
            sourceWebsite: {},
            crmAgents: [],
            allstates: [],
            citys: [],
            pincodes: []
        }
        this.timer = 1;
    }

    componentDidMount() {
        this.getServiceList();
        this.getAllStates();
        this.loadCRMAgent()
    }

    getAllStates = () => {
        const { statePageNo, stateCount } = this.state;
        let params = { search: '', sortField: "_id", sortType: 1, pageNo: statePageNo, count: stateCount }
        getAllStates({ params })
        .then(res => {
            let response = res.data
            if (response.statusCode == 1) {                
                let data = res.data.responseData
                let totalCount = data.totalCount;
                let pgno = statePageNo + 1;
                
                if (data.result && data.result.length > 0) {
                    data.result.forEach(key => {
                        states.push({
                            label: key._id,
                            value: key._id,
                        })
                    })
                }

                this.setState({ searchField: 0, statePageNo: pgno, allstates: states, citys: [], pincodes: [], mailingCity: '', pincode: '' }, function () {
                    if(totalCount > (pgno * stateCount)) {
                        this.getAllStates();
                    }
                });  
            } else if (response.statusCode == 0) {
                toast.error(response.error.errorMessage)
            }
        })
    }

    handleSteteChange = (value, fieldName, callListApi) => {
        this.setState({ [fieldName]: value, cityFilter: "", cityFilterData: [], pincodeFilter: "", pincodeFilterData: [], apiCalled: true },function() {            
            if(callListApi) {
                this.getServiceList();
            }
            this.getAllCitys('statechagne',value);  
        });
    }   

    getAllCitys = (action,val) => {
        const { mailingState, stateFilter, citySearch, cityPageNo, cityCount } = this.state;
        let params = { sortField: "_id", sortType: 1, pageNo: cityPageNo, count: cityCount }        
        if(action == 'statechagne') {
            params.states = val;
            params.citySearch = '';
        }
        if(action == 'citychagne') {
            params.states = stateFilter;
            params.citySearch = val;
        }
        
        return getAllCitys({ params })
        .then(res => {
            if (res && res.data.statusCode === 1) {                
                let data = res.data.responseData
                let citys = []
                let option = []
                if (data.result && data.result.length > 0) {
                    data.result.forEach(state => {
                        citys.push({
                            label: state._id,
                            value: state._id
                           })
                    })
                }
                
                this.setState({ "citys": citys, "cityData": citys, pincodes: [], mailingCity: '', pincode: '', apiCalled: false})
                return citys;       
            } else {
                console.log(res.data.error.errorMessage)
            }
        })
    }

    handleCityChange = (value, fieldName) => {
        this.setState({ [fieldName]: value, pincodeFilter: "", pincodeFilterData: [], apiCalled: true },function() {            
            this.getAllPincodes('cityChange',value);  
        });
    }

    getAllPincodes = (action,val) => {
        const { mailingState, mailingCity, stateFilter, cityFilter } = this.state;
        let params = { sortField: "_id", sortType: 1, pageNo: 0, count: 100 }
        if(action == 'cityChange') {
            params.stateSearch = mailingState;
            params.citySearch = mailingCity;
        }
        if(action == 'cityChangeSearch') {
            params.stateSearch = stateFilter;
            params.citySearch = cityFilter;
        }
        if(action == 'pincodeChagne') {
            params.stateSearch = stateFilter;
            params.citySearch = cityFilter;
            params.pincodeSearch = val;
        }
        return getAllPincodes({ params })
        .then(res => {            
            if (res && res.data.statusCode === 1) {                
                let data = res.data.responseData[0];                
                let pincodes = []
                if (data.result && data.result.length > 0) {
                    data.result.forEach(pin => {
                        pincodes.push({
                            label: pin.pincode,
                            value: pin.pincode,
                        })
                    })
                }

                this.setState({ "pincodes": pincodes, pincodeData: pincodes, pincode: '', pincodeFilter: "", pincodeFilterData: [], apiCalled: false })
                return pincodes;
            } else {
                console.log(res.data.error.errorMessage)
            }
        })
    }

    handlePincodeChange = (value, fieldName) => {
        this.setState({ [fieldName]: value, apiCalled: false },function() {  });
    }


    handleAddServicePopup = () => {
        let { isFromUser, profile } = this.props;
        if (isFromUser) {
            this.setState({
                openAddServicePopup: !this.state.openAddServicePopup,
                firstName: profile["firstName"],
                countryCode: profile["countryCode"],
                lastName: profile["lastName"],
                mobileNo: profile["mobileNo"],
                email: profile["email"]
            })

        }
        else {
            this.setState({ openAddServicePopup: !this.state.openAddServicePopup })
        }
    }


    handleChange = (e) => {
        let { name, value } = e.target
        this.setState({ [name]: value, validationError: '' })
    }

    closeModal = () => {
        this.setState({
            openAddServicePopup: false,
            openViewAdmin: false,
            viewAdminDetails: {},
            firstName: "",
            lastName: "",
            email: "",
            countryCode: "",
            mobileNo: "",
            validationError: "",
            roleId: "",
            importExcelPopup: false,
            isUploading: false, requestsExcelSheet: '', validatedServiceRequests: [], validateExistingRequestsPopup: false, selectedService: {}, searchError: '',
            deleteRequestPopup: false, selectedRequest: {}, assignVendorPopup: false, selectedVendor: {}, badEntryErrors: [], productWebsite: null, validated: false,
            addNotes: false,
            showNotes: false,
            citys: [], 
            pincodes: [], 
            mailingState: '', 
            mailingCity: '', 
            pincode: ''
        })
        // this is added to refresh the data 
        // from the DB to get real time notes
        this.getServiceList();
    }


    getVendors = (val, pincodeVal) => {
        this.setState({ vendors: [] })
        let paramsobj = { params: { count: 0, pageNo: 0, search: val, pincodeFilter: pincodeVal } }

        return getAllVendors(paramsobj)
            .then(res => {
                if (res.data.statusCode == 1) {

                    let result = res.data.responseData.vendors;
                    let vendors = []
                    result && result.map(vendor => {
                        vendors.push({ label: `${vendor.firstName} ${vendor.lastName}`, value: vendor._id })
                    })
                    this.setState({ vendors })
                    return vendors
                }
                else if (res.data.statusCode == 0) {
                    console.log(res.data.error.errorMessage)
                }
            })
    }

    loadVendors = (val, pincode) => {
        return this.getVendors(val, pincode);
    }

    loadVendorsFocus = (val) => {
        return this.getVendors("", val)
    }


    loadOptionByval = (type, value) => {
        if (type === "pincode") {
            // return this.getDropDownData(value, "pincodeSearch", "pincodeData");
            return this.getAllPincodes('pincodeChagne',value); 
        } else if (type === "city") {
            return this.getAllCitys('citychagne',value);
        } else {
            return null;
        }
    }

    getDropDownData = (val, searchField, stateField) => {
        let paramsobj = { params: { count: 100, pageNo: 0, [searchField]: val } }
        return getAllPincodes(paramsobj).then(res => {
            if (res && res.data.statusCode === 1) {
                let data = res.data.responseData
                let result = data[0].result
                let option = []
                let removeDublicatess = removeDuplicates(result, searchField.replace("Search", ""));

                if (removeDublicatess && removeDublicatess.length > 0) {
                    removeDublicatess.forEach(item => {
                        option.push({
                            label: item[stateField.replace("Data", "")],
                            value: item._id,
                        })
                    })
                }
                this.setState({ [stateField]: option })
                return option
            } else if (res.data.statusCode == 0) {
                console.log(res.data.error.errorMessage)
            }
        })
    }

    getServiceList = (isExport) => {
        let { search, count, pageNo, sortInfo, searchField, searchValue, dateFrom, dateTo, statusFilter, serviceTypeFilter, sourceFilter, pincodeFilter, cityFilter, stateFilter, serviceRequestsList, agentFilter } = this.state;
        let { fieldName, order } = sortInfo;
        let { isFromUser, userId } = this.props;
        let params = {
            search, count, pageNo: pageNo - 1, sortType: order, sortField: fieldName, searchField, searchValue,
            dateFrom: !isFromUser ? dateFrom ? moment(dateFrom).startOf('day').valueOf() : null : null,
            dateTo: !isFromUser ? dateTo ? moment(dateTo).endOf('day').valueOf() : null : null,
            statusFilter: statusFilter,
            serviceTypeFilter: serviceTypeFilter,
            sourceFilter: sourceFilter,
            reqOfUserId: userId,
            pincodeFilter: pincodeFilter,
            cityFilter: cityFilter,
            stateFilter: stateFilter,
            agentFilter: agentFilter
        }
        if (isExport) {
            if (serviceRequestsList.length > 0) {
                exportServiceRequest({ params }).then(res => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        this.setState({ apiCalled: false })
                        window.open(response.responseData.result.Location, "_blank");

                    } else if (response.statusCode == 0) {
                        this.setState({ apiCalled: false })
                        toast.error(response.error.errorMessage)
                    }
                })
            }
            else {
                this.setState({ apiCalled: false })
                toast.error("No data available to export")
            }
        }
        else {
            getServiceList({ params })
                .then(res => {
                    let response = res.data
                    if (response.statusCode == 1) {
                        this.setState({
                            serviceRequestsList: response.responseData.result.productServices,
                            serviceRequestsCount: response.responseData.result.productServiceCount,
                            loader: false,
                            apiCalled: false
                        })
                    } else if (response.statusCode == 0) {
                        this.setState({ loader: false })
                        toast.error(response.error.errorMessage)
                    }
                })
        }
    }

    
    importHandler = () => {
        this.setState({ importExcelPopup: true })
    }

    handleFileDrop = (accepted, rejected) => {
        if (accepted && accepted.length) {
            this.setState({ requestsExcelSheet: accepted[0], validationError: '' })
        }
        else if (rejected && rejected.length) {
            this.setState({ validationError: "This file format is not supported. Format should be 'xls, xlsx or csv'", requestsExcelSheet: '' })
        }
    }


    submitExcelUpload = (e) => {
        e.preventDefault();
        const { requestsExcelSheet, productWebsite } = this.state;

        let params = new FormData()
        params.append('requestsExcelSheet', requestsExcelSheet)
        params.append('productWebsite', productWebsite)


        if (!requestsExcelSheet) {
            this.setState({ validationError: 'Choose a valid file!' })
        }
        else {
            this.setState({ validationError: '', isUploading: true })
            addRequestsFromExcel(params)
                .then(res => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        this.getServiceList();
                        this.closeModal();
                        this.setState({ isUploading: false })
                        toast.success(response.responseData.message)
                    } else if (response.statusCode == 0) {
                        this.setState({ isUploading: false })
                        this.setState({ validationError: response.error.errorMessage })
                    }
                })
        }
    }

    submitValidateExcelUpload = (e) => {
        e.preventDefault();
        const { requestsExcelSheet, productWebsite } = this.state;
        if (!requestsExcelSheet) {
            this.setState({ validationError: 'Please select a file!' })
            return;
        }
        else {
            let params = new FormData()
            params.append('requestsExcelSheet', requestsExcelSheet);
            params.append('productWebsite', productWebsite)
            this.setState({ validationError: '', isUploading: true })
            findExistingOrderIdFromExcel(params)
                .then(res => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        if (response.responseData.result && response.responseData.result.alreadyExisting && response.responseData.result.alreadyExisting.length) {
                            let validatedServiceRequests = []
                            let { alreadyExisting, requestParams } = response.responseData.result

                            requestParams.map(serviceRequest => {
                                if (alreadyExisting.find(obj => obj.orderId === serviceRequest.orderId)) {
                                    serviceRequest.alreadyExists = true
                                    validatedServiceRequests.push(serviceRequest)
                                } else {
                                    serviceRequest.alreadyExists = false
                                    validatedServiceRequests.push(serviceRequest)
                                }
                            })

                            this.setState({ isUploading: false, importExcelPopup: false, requestsExcelSheet: '', validatedServiceRequests, validateExistingRequestsPopup: true })
                        }
                        else {
                            this.submitExcelUpload(e)
                        }
                    }
                    else if (response.statusCode == 0) {
                        this.setState({ isUploading: false })
                        this.setState({ validationError: response.error.errorMessage, badEntryErrors: response.error.errors ? response.error.errors : [] })
                    }
                })
        }
    }


    handleDeleteRequestFromExcel = (reqDetails, i) => {
        let validatedServiceRequests = Array.from(this.state.validatedServiceRequests)
        validatedServiceRequests.splice(i, 1);
        this.setState({ validatedServiceRequests, validationError: '' })
    }


    submitAddMultipleRequestsByAdmin = () => {
        let { validatedServiceRequests, productWebsite } = this.state
        if (validatedServiceRequests.find(req => req.alreadyExists === true)) {
            this.setState({ validationError: "Please remove all the invalid data first!" })
        } else {
            let params = {
                requestsArray: validatedServiceRequests,
                productWebsite: productWebsite,

            }
            this.setState({ isUploading: true })

            addMultipleRequestsByAdmin(params)
                .then(res => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        this.getServiceList();
                        this.closeModal();
                        this.setState({ isUploading: false })
                        toast.success(response.responseData.message)
                    } else if (response.statusCode == 0) {
                        this.setState({ isUploading: false })
                        this.setState({ validationError: response.error.errorMessage })
                    }
                })
        }
    }

    handleServiceTypeSelector = (service) => {
        this.setState({ selectedService: service })
    }
    handleSourceSelector = (productWebsite) => {
        this.setState({ sourceWebsite: productWebsite })
    }


    submitAddRequest = (event) => {
        let isError = false;
        event.preventDefault();
        event.stopPropagation();
        let { firstName, lastName, email, countryCode, mobileNo, orderId, modelNo, deliveryDate, address, remark, mailingStreet, mailingCity, mailingState, pincode, selectedService, company, vertical, sourceWebsite } = this.state;

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            isError = true;
            this.setState({ validated: true })
            return;

        }
        if (!deliveryDate || Object.keys(selectedService).length <= 0 || Object.keys(selectedService).length <= 0) {
            isError = true;
            this.setState({ validated: true })
            return;
        }
        this.setState({ isSubmitClicked: true });

        let params = {
            firstName, lastName, email, countryCode, mobileNo, orderId, modelNo, deliveryDate, address, remark, serviceType: selectedService.id, service: selectedService.label, mailingStreet, mailingCity, mailingState, pincode, company, vertical, productWebsite: sourceWebsite && sourceWebsite.value
        }


        if (!isError) {
            addRequestByAdmin(params)
                .then(res => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        this.getServiceList();
                        this.closeModal();
                        this.setState({ isSubmitClicked: false })
                        toast.success(response.responseData.message)
                    } else if (response.statusCode == 0) {
                        this.setState({ isSubmitClicked: false })
                        this.setState({ validationError: response.error.errorMessage })
                    }
                })
        }
    }

    handleSearchType = (search) => {
        this.setState({ searchField: search.value, searchError: '' })
    }

    handleSearch = (e) => {
        let { value } = e.target
        let { searchField } = this.state

        if (!searchField) {
            this.setState({ searchError: 'Select Search Field' })
        }
        else {
            e.persist();
            this.setState({ searchValue: value, searchError: '', apiCalled: true });
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.getServiceList();
                this.setState({ apiCalled: false })
            }, 400)

        }
    }

    handleDeleteRequestPopup = (request) => {
        this.setState({ deleteRequestPopup: true, selectedRequest: request })
    }

    handleAddNotesPopup = (request) => {
        this.setState({ addNotes: true, selectedRequest: request }, () => this.getServiceList())
    }

    handleViewNotesPopup = (request) => {
        this.setState({ showNotes: true, selectedRequest: request })
    }


    submitDeleteRequest = () => {
        let { selectedRequest } = this.state
        this.setState({ loader: true })

        deleteRequest({ requestId: selectedRequest._id })
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.getServiceList();
                    this.closeModal();
                    toast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    this.setState({ validationError: response.error.errorMessage, loader: false })
                }
            })
    }

    handleAssignVendorPopup = (vendorDetails, request) => {
        this.setState({ assignVendorPopup: true, selectedVendor: vendorDetails, selectedRequest: request })
    }
    handleAgentPopup = (agentDetail, request) => {
        this.setState({ assignAgentPopup: true, selectedAgent: agentDetail, selectedRequest: request })

    }

    submitAssignRequest = () => {
        let { selectedVendor, selectedRequest } = this.state

        assignRequestToVendor({ vendorId: selectedVendor.value, requestId: selectedRequest._id })
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.getServiceList();
                    this.closeModal();
                    toast.success(response.responseData.message);
                } else if (response.statusCode == 0) {
                    this.setState({ validationError: response.error.errorMessage, loader: false })
                }
            })
    }

    handleDate = (val, key) => {
        this.setState({ [key]: moment(val).format() })
    }

    onWebsiteTypeSelect = (value) => {
        this.setState({ productWebsite: Number(value), validationError: '', badEntryErrors: [] })
    }
    handleDateFilter = (date, dateField) => {
        switch (dateField) {
            case 'dateFrom':
                this.setState({ dateFrom: date })
                break

            case 'dateTo':
                this.setState({ dateTo: date })
                break
        }
    }

    resetFilter = () => {
        this.setState({
            searchField: 0,
            dateFrom: moment().startOf('day').valueOf(),
            dateTo: moment().endOf('day').valueOf(), sourceFilter: "", serviceTypeFilter: "", statusFilter: "", pageNo: 1, Loader: true, pincodeFilter: "", cityFilter: "", stateFilter: "", agentFilter: "",
            pincodeFilterData: [], cityFilterData: [], stateFilterData: []
        }, () => this.getServiceList())
    }

    handleFilterChange = (value, fieldName) => {
        this.setState({ [fieldName]: value, apiCalled: true }, () => this.getServiceList());

    }

    onHandleSelect = (data, fieldName) => {         
        let fieldNameData = `${fieldName}Data`
        if (fieldName == "agentFilter") {
            this.setState({ [fieldName]: data.value, vendorFilterId: data._id, [fieldNameData]: data, apiCalled: true }, () => this.getServiceList())
        } else if(fieldName == "cityFilter") {
            this.setState({ [fieldName]: data.label, vendorFilterId: data._id, [fieldNameData]: data, pincodeFilter: "", pincodeFilterData: [], apiCalled: true }, function() {
                this.getServiceList();
                this.getAllPincodes('cityChangeSearch',data.value); 
            })
        } else {
            this.setState({ [fieldName]: data.label, vendorFilterId: data._id, [fieldNameData]: data, apiCalled: true }, () => this.getServiceList())
        }
    }

    filterByDate = (date) => {
        let dateFrom = null, dateTo = null;
        if (date) {
            dateFrom = moment(date[0]).startOf('day').valueOf()
            dateTo = moment(date[1]).endOf('day').valueOf()
        }
        this.setState({ dateFrom, dateTo, apiCalled: true, pageNo: 1 }, () => { this.getServiceList() })
    }

    handleSort = (field) => {
        let { order, fieldName } = this.state.sortInfo;
        let orderType = 1;
        if (order && fieldName === field) {
            orderType = order < 0 ? '' : -1;
        }
        this.setState({
            sortInfo: {
                fieldName: field,
                order: orderType
            }
        }, () => this.getServiceList());
    }

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getServiceList() });
    onRequestClick = (requestId) => {
        this.setState({ productJob: true, selectedProductRequest: requestId });

    }
    changeStatus = (statusValue, id, customerId) => {
        this.setState({ statusPopup: true, selectedStatus: statusValue, selectedRequestId: id, selectedRequestCustomerId: customerId })
    }
    closeStatusPopup = () => {
        this.setState({ statusPopup: false, selectedStatus: "", selectedRequestId: "" })
    }
    changeRequestStaus = () => {
        let { selectedRequestId, selectedStatus, selectedRequestCustomerId } = this.state;
        let paramsObj = {
            requestId: selectedRequestId,
            requestStatus: selectedStatus,
            customerId: selectedRequestCustomerId
        }
        updateRequestStatus(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ selectedRequestId: "", statusValue: "", statusPopup: false });
                    this.getServiceList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }

    selfMarkPopup = (selectedId, selfDone) => {
        this.setState({ markSelfDonePopUP: !this.state.markSelfDonePopUP, selectedRequestId: selectedId ? selectedId : "", selfDone: selfDone ? false : true });
    }
    markSelfDone = () => {
        let { selectedRequestId, selfDone } = this.state;
        let paramsObj = {
            requestId: selectedRequestId,
            selfDone: selfDone
        }

        markSelfDone(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ selectedRequestId: "", markSelfDonePopUP: false });
                    this.getServiceList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }

    handleNotesOnChange = (e) => {
        this.setState({ notes: e.target.value })
    }

    addNotesToRequest = (note, requestId) => {
        let params = {
            note: note,
            requestId: requestId._id
        }
        addNoteToServicerequest(params).then(response => {
            if (response && response.data.statusCode === 1) {
                this.setState({ addNotes: false })
                toast.success(`Note added to request ${requestId._id}!`)
            } else {
                toast.error(`${response.data.error.errorMessage}!`)
            }
        })
    }
    exportHandler = () => {
        this.setState({ apiCalled: true, showHideExport: false }, () => this.getServiceList(true));
    }
    showHideExport = () => {
        this.setState({ showHideExport: true });
    }
    getCRMList = (val) => {
        this.setState({ crmAgents: [] })
        let paramsobj = { params: { count: 0, pageNo: 0, search: val } }
        return getCRMAgentList(paramsobj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    let crmAgentList = res.data.responseData.adminList;
                    let crmAgents = []
                    crmAgentList && crmAgentList.map(item => {
                        crmAgents.push({ label: `${item.firstName} ${item.lastName}`, value: item._id })
                    })
                    this.setState({ crmAgents })
                    return crmAgents;
                }
                else if (res.data.statusCode == 0) {
                    console.log(res.data.error.errorMessage)
                }
            })
    }

    loadCRMAgent = (val) => {       
        return this.getCRMList(val)
    }
    loadCRMAgentFilter = (val) => {
        return this.getCRMList(val)
    }

    assignRequestToAgent = () => {
        let { selectedAgent, selectedRequest } = this.state;
        assignRequestToAgent({ agentId: selectedAgent.value, requestId: selectedRequest._id })
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.getServiceList();
                    this.setState({ assignAgentPopup: false })
                    toast.success(response.responseData.message);
                } else if (response.statusCode == 0) {
                    this.setState({ validationError: response.error.errorMessage, loader: false })
                }
            })

    }

    render() {
        const {
            pageNo,
            productJob,
            validationError,
            count,
            serviceRequestsList,
            serviceRequestsCount,
            importExcelPopup,
            isUploading,
            loader,
            validatedServiceRequests,
            validateExistingRequestsPopup,
            openAddServicePopup,
            deleteRequestPopup,
            vendors,
            assignVendorPopup,
            selectedVendor,
            productWebsite,
            selectedProductRequest,
            statusPopup,
            dateTo,
            dateFrom,
            sortInfo,
            badEntryErrors,
            validated,
            markSelfDonePopUP,
            addNotes,
            notes,
            showNotes,
            selectedRequest,
            apiCalled,
            crmAgents,
            assignAgentPopup,
            allstates,
            citys,
            pincodes
        } = this.state;

        let { fieldName, order } = sortInfo;
        let { isFromUser } = this.props;
        let productJobOpen = productJob;
        if (this.props.productJob) {
            productJobOpen = true
        }


        return (
            <HOC>
                {this.state.apiCalled && <Loader />}
                {!productJobOpen &&
                    < div className={!isFromUser ? "body-container-wrapper" : ""}>
                        <div className="body-container">
                            <ol className="breadcrumb" style={{ display: isFromUser ? "none" : "" }}>
                                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item">Services</li>
                            </ol>
                            {loader ? <div className="loader_wrapper"><LoaderSvg /></div> : null}

                            <div className="users_header" style={{ display: isFromUser ? "none" : "" }} >
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <h4 className="cm_page_heading">Services</h4>
                                        </div>
                                    </div>

                                    <div className="list-inline-item row">
                                        <div className="col-md-3">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <Button className='ml-1 mt-2' type="primary" onClick={this.importHandler}>Import</Button>
                                                    <Button disabled={apiCalled} className='ml-1 mt-2' type="primary" onClick={this.showHideExport}>Export</Button>
                                                </div>
                                                <div className="col-md-9 align-items-baseline d-inline-flex">

                                                    <ul className="list-inline mb-0">
                                                        <li className="list-inline-item mt-2 ">
                                                            <label inline='true' className="mr-2">Filter By Date:</label>
                                                            <RangePicker
                                                                style={{ height: "2.8em" }}
                                                                size={"middle"}
                                                                onChange={(val) => this.filterByDate(val)}
                                                                value={dateTo && dateFrom && [moment(dateFrom), moment(dateTo)]}
                                                                defaultValue={[moment(dateFrom), moment(dateTo)]}
                                                            />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <ul className="col-md-12">
                                                <li className="list-inline-item mt-2">
                                                    <Select
                                                        className='cm_min_width'
                                                        onChange={(e) => this.handleFilterChange(e.value, "serviceTypeFilter")}
                                                        placeholder="Service Type"
                                                        options={serviceTypeOptions}
                                                        value={serviceTypeOptions.find(element => element.value === this.state.serviceTypeFilter)}

                                                    />
                                                </li>
                                                <li className="list-inline-item mt-2">
                                                    <Select
                                                        className='cm_min_width'
                                                        onChange={(e) => this.handleFilterChange(e.value, "statusFilter")}
                                                        placeholder="Status"
                                                        options={statusFilterOptions}
                                                        value={statusFilterOptions.find(element => element.value === this.state.statusFilter)}

                                                    />
                                                </li>
                                                <li className="list-inline-item mt-2 ">
                                                    <Select
                                                        className='cm_min_width'
                                                        onChange={(e) => this.handleFilterChange(e.value, "sourceFilter")}
                                                        placeholder="Source"
                                                        options={sourceFilterOptions}
                                                        value={sourceFilterOptions.find(element => element.value === this.state.sourceFilter)}

                                                    />
                                                </li>
                                                
                                                <li className="list-inline-item mt-2">
                                                    <Select
                                                        className='cm_min_width'
                                                        onChange={(e) => this.handleSteteChange(e.value, "stateFilter", 1)}
                                                        placeholder="Filter By State"
                                                        options={allstates}
                                                        value={this.state.stateFilter?allstates.find(element => element.value === this.state.stateFilter):null}
                                                    />                      
                                                </li>
                                                
                                                <li className="list-inline-item mt-2">
                                                    <AsyncSelect
                                                        className='cm_min_width'
                                                        isDisabled={false}
                                                        // cacheOptions
                                                        value={this.state.cityFilterData?this.state.cityFilterData:null}
                                                        loadOptions={(val) => this.loadOptionByval("city", val)}
                                                        defaultOptions={this.state.cityData}
                                                        onChange={(val) => this.onHandleSelect(val, "cityFilter")}
                                                        placeholder='Filter By City'
                                                    /> 
                                                </li>

                                                <li className="list-inline-item mt-2">
                                                    <AsyncSelect
                                                        className='cm_min_width'
                                                        isDisabled={false}
                                                        cacheOptions
                                                        value={this.state["pincodeFilterData"]}
                                                        loadOptions={(val) => this.loadOptionByval("pincode", val)}
                                                        defaultOptions={this.state.pincodeData}
                                                        onChange={(val) => this.onHandleSelect(val, "pincodeFilter")}
                                                        placeholder='Filter By Pincode'
                                                    />
                                                </li>

                                                <li className="list-inline-item mt-2">
                                                    <AsyncSelect
                                                        cacheOptions
                                                        className='cm_min_width'
                                                        value={this.state["agentFilterData"]}
                                                        defaultOptions={crmAgents}
                                                        loadOptions={this.loadCRMAgentFilter}
                                                        // defaultOptions={services}
                                                        onChange={(val) => this.onHandleSelect(val, "agentFilter")}
                                                        placeholder='Filter By Agent'
                                                    />
                                                </li>

                                                <li className="list-inline-item mt-2">
                                                    <Button variant="outline-dark" className="mr-2" size='sm' onClick={this.resetFilter}> <i className="fa fa-refresh" /></Button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-9 text-right cm_search">
                                            <InputGroup >
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text>
                                                        <Select
                                                            isSearchable
                                                            options={SearchTypes}
                                                            placeholder="Search Field"
                                                            onChange={(val) => this.handleSearchType(val)}
                                                            className="cm_min_filter_width"

                                                        />
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl id="search" className="h-auto" name='search' placeholder="Search here......" onChange={this.handleSearch} />
                                            </InputGroup>
                                        </div>
                                        <div className="col-md-3 text-right">
                                            <button className="btn btn-primary btn-block" onClick={this.handleAddServicePopup}>Add New Service</button>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {isFromUser &&
                                <div className="col-md-3 text-right float-right mb-2">
                                    <button className="btn btn-primary btn-block" onClick={this.handleAddServicePopup}>Add New Service</button>
                                </div>}

                            <div className="table-responsive" style={{ minHeight: '500px', paddingBottom: '13rem' }}>
                                <table className="table table-bordered table-striped text-center">
                                    <thead>
                                        <tr>
                                            {columnDetails.map((item, i) => {
                                                let { isSortable, dataIndex } = item;
                                                return isSortable ? <th scope="col" key={i} className="filter_text" onClick={() => this.handleSort(dataIndex)}>{item.fieldName} &nbsp;  <i className={getSortingIcon(dataIndex == fieldName && order)} /></th>
                                                    : <th scope="col" key={i}>{item.fieldName}</th>
                                            })}
                                        </tr>

                                        {/* <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col" className="filter_text">Name <i className={`fa fa-sort-alpha-${this.state.sortFieldIcon ? 'desc' : 'asc'}`}></i><button onClick={() => this.handleSortList('firstName')} // value="filterFirstName"></button></th>
                                        <th scope="col">Assigned Dietician</th>
                                        <th scope="col" className="filter_text">Email <i className={`fa fa-sort-alpha-${this.state.sortFieldIcon ? 'desc' : 'asc'}`} ></i><button onClick={() => this.handleSortList('email')} // value="filterEmail"></button></th>
                                        <th scope="col">Gender</th>
                                        <th scope="col">Diet plan</th>
                                    </tr> */}
                                    </thead>


                                    <tbody>
                                        {serviceRequestsList.map((request, index) => {
                                            return (
                                                <tr key={request._id}>
                                                    <td className="cm_no_wrap">{((index + 1) + ((pageNo - 1) * count))}</td>
                                                    <td className="cm_no_wrap">{request.created ? moment(request.created).format('Do MMM YY') : '-'}</td>
                                                    <td className="cm_no_wrap"> {isFromUser && <Link to="#" onClick={() => this.onRequestClick(request._id, request.userId)}>{request._id}</Link> || (request._id)}</td>
                                                    <td className="cm_no_wrap"> {request.orderId}</td>
                                                    <td className="cm_no_wrap"> {!isFromUser && <Link to={`/service/${request.userId}/productService`}>{request.firstName + " " + request.lastName}</Link> || (request.firstName + " " + request.lastName)}</td>
                                                    <td className="cm_no_wrap"> {request.pincode}</td>
                                                    <td className="text-left cm_min_width_address" ><div>{request.mailingStreet}</div> </td>
                                                    <td className="cm_no_wrap"> {request.mailingCity}</td>
                                                    <td className="cm_no_wrap"> {request.mailingState}</td>
                                                    <td className="cm_no_wrap"> {request.pincodeData && request.pincodeData.tat}</td>
                                                    <td className="cm_no_wrap">{request.email}</td>
                                                    <td className="cm_no_wrap">{request.mobileNo}</td>
                                                    {/* <td className="cm_no_wrap">Pending</td> */}
                                                    <td className="cm_no_wrap">{request.company}</td>
                                                    <td className="cm_no_wrap">{request.modelNo}</td>
                                                    <td className="cm_no_wrap">{request.vertical}</td>

                                                    <td className="cm_no_wrap">{request.productWebsite === 0 ? "Amazon" : request.productWebsite === 1 ? "Flipkart" : '-'}</td>
                                                    <td className="cm_no_wrap">{request.service}</td>
                                                    {/* <td className="cm_no_wrap"> Pending </td> */}
                                                    <td className="cm_no_wrap">
                                                        {/* {!request.isSelfDone ? <Button className='m-1' variant="outline-dark" onClick={() => this.selfMarkPopup(request._id)}> <i className="fa fa-check" />Self Done </Button> : request.isSelfDone.toString() === "true" && "Yes"} */}

                                                        <Switch loading={this.state[`urgentLoading${request._id}`]} className={`cm_ckeckbox_wrapper cm_ckeckbox_wrapper_height ${request.isSelfDone === true ? 'cm_active' : 'cm_inactive'}`}
                                                                        onClick={(e) => this.selfMarkPopup(request._id, request.isSelfDone, e)}
                                                                        checked={request.isSelfDone === true}
                                                                    >
                                                        </Switch>
                                                    </td>

                                                    <td className="cm_no_wrap">
                                                        {request.assignedVendorDetails ?
                                                            request.assignedVendorDetails.firstName + " " + request.assignedVendorDetails.lastName
                                                            : <AsyncSelect
                                                                cacheOptions
                                                                className='cm_min_width'
                                                                loadOptions={(val) => this.loadVendors(val, request.pincode)}
                                                                defaultOptions={vendors}
                                                                onFocus={() => this.loadVendorsFocus(request.pincode)}
                                                                onChange={(val) => this.handleAssignVendorPopup(val, request)}
                                                            />}
                                                    </td>
                                                    <td className="cm_no_wrap">
                                                        {
                                                            request.assignedAgentDetails ?
                                                                request.assignedAgentDetails.firstName + " " + request.assignedAgentDetails.lastName :
                                                                <AsyncSelect
                                                                    cacheOptions
                                                                    className='cm_min_width'
                                                                    defaultOptions={crmAgents}
                                                                    loadOptions={this.loadCRMAgent}
                                                                    onFocus={() => this.loadCRMAgent()}
                                                                    // defaultOptions={services}
                                                                    onChange={(val) => this.handleAgentPopup(val, request)}
                                                                />
                                                        }
                                                    </td>
                                                    <td className="cm_no_wrap">
                                                        {
                                                            <Select
                                                                className='cm_min_width'
                                                                onChange={(e) => this.changeStatus(e.value, request._id, request.userId)}
                                                                placeholder="Select..."
                                                                options={statusFilterOptions.filter((item) => item.value)}
                                                                value={statusFilterOptions.find(status => status.value === request.statusType)}
                                                            />}

                                                    </td>
                                                    {/* <td className="cm_no_wrap">{request.status}</td> */}
                                                    <td className="cm_no_wrap">{request.purchaseDate ? moment(request.purchaseDate).format('Do MMM YY') : '-'}</td>
                                                    <td className="cm_no_wrap">{request.deliveryDate ? moment(request.deliveryDate).format('Do MMM YY') : '-'}</td>
                                                    <td className="cm_no_wrap">{request.registrationDate ? moment(request.registrationDate).format('Do MMM YY') : '-'}</td>
                                                    <td className="cm_no_wrap">
                                                        {/* <Button className='m-1' variant="outline-info" onClick={() => this.handleEditRequestPopup(request)}> <i className="fa fa-pencil" /> </Button> */}
                                                        <Button className='m-1' variant="outline-danger" onClick={() => this.handleDeleteRequestPopup(request)}> <i className="fa fa-trash" /> </Button>
                                                    </td>
                                                    <td className="cm_no_wrap">
                                                        {/* <Button className='m-1' variant="outline-info" onClick={() => this.handleEditRequestPopup(request)}> <i className="fa fa-pencil" /> </Button> */}
                                                        <Button className='m-1' variant="outline-danger" onClick={() => this.handleAddNotesPopup(request)}> <i className="fa fa-sticky-note" /> </Button>
                                                        <Button className='m-1' variant="outline-danger" onClick={() => this.handleViewNotesPopup(request)}> <i className="fa fa-eye" /> </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                {this.state.serviceRequestsList.length === 0 ? <h3 className="empty_error">Sorry, we couldn't find any content for the service <span>{this.state.UserSearch}</span></h3> : null}
                            </div>
                            <br />
                            <Pagination
                                activePage={this.state.pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={serviceRequestsCount}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />
                        </div>
                    </div>}

                {
                    productJobOpen &&
                    <Fragment>
                        <div className="row col-md-12 mb-2 mt-2">
                            <div className="col-md-3 text-right">
                                <button className="btn btn-primary btn-block" onClick={() => { this.setState({ productJob: false }) }}>Back to request</button>
                            </div>
                        </div>
                        <div >
                            <UserJobDetails isFromUser={isFromUser} requestId={selectedProductRequest} />
                        </div>

                    </Fragment>
                }


                <Modal show={deleteRequestPopup} onHide={this.closeModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Are you sure to delete this request?</h6> </Modal.Title>
                    </Modal.Header>
                    {validationError && <Alert variant="danger"> {validationError} </Alert>}
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.closeModal} size="sm">Cancel</Button>
                        <Button variant="success" onClick={this.submitDeleteRequest} size="sm"> Delete </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={addNotes} onHide={this.closeModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Please add Notes</h6> </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <textarea rows={4} cols={50} onChange={(e) => this.handleNotesOnChange(e)} value={notes}></textarea>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={() => this.addNotesToRequest(notes, this.state.selectedRequest)} size="sm"> save </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showNotes} onHide={this.closeModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Notes</h6> </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedRequest.notes && selectedRequest.notes.note ?

                            <Comment
                                author={<a>{selectedRequest.notes.notedBy.firstName}{selectedRequest.notes.notedBy.lastName}</a>}
                                avatar={
                                    <Avatar
                                        style={{
                                            backgroundColor: '#87d068',
                                        }}
                                        icon={<UserOutlined />}
                                    />
                                }
                                content={
                                    <p>
                                        {selectedRequest.notes.note}
                                    </p>
                                }
                                datetime={<span>{moment(selectedRequest.notes.notedOn).format('Do MMM YY, h:mm:ss a')}</span>}
                            />


                            : "No Notes added"}
                    </Modal.Body>
                </Modal>

                <Modal show={assignVendorPopup} onHide={this.closeModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Are you sure to assign this request to <b>{selectedVendor.label}</b>?</h6> </Modal.Title>
                    </Modal.Header>
                    {validationError && <Alert variant="danger"> {validationError} </Alert>}
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.closeModal} size="sm">Cancel</Button>
                        <Button variant="success" onClick={this.submitAssignRequest} size="sm"> Assign </Button>
                    </Modal.Footer>
                </Modal>


                <Modal show={importExcelPopup} onHide={this.closeModal} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Upload File!</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Dropzone multiple={false} onDrop={(acceptedFiles, rejectedFiles) => this.handleFileDrop(acceptedFiles, rejectedFiles)} accept='.xlsx, .xls, .csv'>
                            {({ getRootProps, getInputProps, isDragActive, acceptedFiles }) => (
                                <section>
                                    <div {...getRootProps()} className="dropzone text-center"> <input {...getInputProps()} />
                                        <i className="fa fa-cloud-upload" />
                                        {isDragActive ? <p>Drop it like it's hot!</p> : acceptedFiles.length ? <p>{acceptedFiles[0].name}</p> : <p>Drag file or click to upload</p>}
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                        <div className="row col-md-4">
                            <FormCheck type='radio' checked={productWebsite == 0} label="Amazon" onChange={() => this.onWebsiteTypeSelect(0)} />
                            <FormCheck className="ml-2" type='radio' checked={productWebsite == 1} label="Flipkart" onChange={() => this.onWebsiteTypeSelect(1)} />
                        </div>
                    </Modal.Body>

                    {validationError && <Alert variant="danger"> {validationError} </Alert>}
                    {badEntryErrors.length > 0 && <Alert variant="danger">
                        {badEntryErrors.map(err => {
                            return (
                                <>
                                    <span>Row: {err.rowNumber}, &nbsp;&nbsp; </span>
                                </>
                            )
                        })}
                    </Alert>}


                    <Modal.Footer>
                        <Button variant="danger" onClick={this.closeModal} size="sm">Cancel</Button>
                        <Button disabled={validationError} variant="success" onClick={this.submitValidateExcelUpload} disabled={validationError || isUploading} size="sm">
                            {isUploading ? <><Spinner animation="border" variant="warning" size="sm" /> Uploading..</> : 'Upload'}
                        </Button>
                    </Modal.Footer>
                </Modal>



                <Modal show={validateExistingRequestsPopup} onHide={this.closeModal} centered size="lg" dialogClassName="cm_modal_hw">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Excel Not Valid!</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        {columnDetails.map((item, index) => {
                                            let { fieldName } = item;
                                            return <th key={index} scope="col">{fieldName}</th>
                                        })}
                                    </tr>
                                </thead>


                                <tbody>
                                    {validatedServiceRequests.map((request, index) => {
                                        return (
                                            <tr key={request._id} className={request.alreadyExists ? 'cm_error_row' : ''} >
                                                <td className="cm_no_wrap">{((index + 1) + ((pageNo - 1) * count))}</td>
                                                <td className="cm_no_wrap"> {request._id}</td>
                                                <td className="cm_no_wrap"> {request.orderId}</td>
                                                <td className="cm_no_wrap"> {request.firstName + " " + request.lastName}</td>
                                                <td className="cm_no_wrap"> {request.pincode}</td>
                                                <td className="cm_no_wrap"> {request.mailingStreet}</td>
                                                <td className="cm_no_wrap"> {request.mailingCity}</td>
                                                <td className="cm_no_wrap"> {request.mailingState}</td>
                                                <td className="cm_no_wrap"> {request.tat}</td>
                                                <td className="cm_no_wrap">{request.email}</td>
                                                <td className="cm_no_wrap">{request.mobileNo}</td>
                                                {/* <td className="cm_no_wrap">Pending</td> */}
                                                <td className="cm_no_wrap">{request.company}</td>
                                                <td className="cm_no_wrap">{request.modelNo}</td>
                                                <td className="cm_no_wrap">{request.vertical}</td>

                                                <td className="cm_no_wrap">{request.productWebsite === 0 ? "Amazon" : request.productWebsite === 1 ? "Flipkart" : '-'}</td>
                                                <td className="cm_no_wrap">{request.service}</td>
                                                {/* <td className="cm_no_wrap"> Pending </td> */}
                                                <td className="cm_no_wrap">
                                                    <AsyncSelect
                                                        cacheOptions
                                                        className='cm_min_width'
                                                        loadOptions={this.loadServices}
                                                        // defaultOptions={services}
                                                        onChange={(val) => this.serviceSelect(val)}
                                                    />
                                                </td>
                                                <td className="cm_no_wrap">
                                                    <AsyncSelect
                                                        cacheOptions
                                                        className='cm_min_width'
                                                        loadOptions={this.loadServices}
                                                        // defaultOptions={services}
                                                        onChange={(val) => this.serviceSelect(val)}
                                                    />
                                                </td>
                                                <td className="cm_no_wrap">{request.status}</td>
                                                <td className="cm_no_wrap">{request.purchaseDate ? moment(request.purchaseDate).format('Do MMM YY') : '-'}</td>
                                                <td className="cm_no_wrap">{request.registrationDate ? moment(request.registrationDate).format('Do MMM YY') : '-'}</td>
                                                <td className="cm_no_wrap">{request.deliveryDate ? moment(request.deliveryDate).format('Do MMM YY') : '-'}</td>
                                                <td className="cm_no_wrap">
                                                    <button className="btn btn-danger btn-xs ml-2" onClick={() => this.handleDeleteRequestFromExcel(request, index)}>
                                                        <i className="fa fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <div className="row col-md-4">
                            <FormCheck type='radio' checked={productWebsite == 0} label="Amazon" onChange={() => this.onWebsiteTypeSelect(0)} />
                            <FormCheck className="ml-2" type='radio' checked={productWebsite == 1} label="Flipkart" onChange={() => this.onWebsiteTypeSelect(1)} />
                        </div>
                    </Modal.Body>
                    {
                        validationError ? <h4 className="text-center mb-2  error_message">{validationError}</h4> : ''
                    }
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.closeModal} size="sm">Cancel</Button>
                        <Button variant="success" onClick={this.submitAddMultipleRequestsByAdmin} size="sm">
                            {isUploading ? <><Spinner animation="border" variant="warning" size="sm" /> Uploading..</> : 'Upload'}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={openAddServicePopup} onHide={this.closeModal} centered size="lg" backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Add Service Request</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form noValidate validated={validated} onSubmit={(e) => this.submitAddRequest(e)}>
                            <div className='row'>
                                <FormGroup className='col-6'>
                                    <FormLabel>First Name</FormLabel>
                                    <FormControl
                                        placeholder="First Name"
                                        value={this.state["firstName"]}
                                        name='firstName' onChange={this.handleChange}
                                        required
                                        type="text"
                                        maxLength={30} />
                                    <Form.Control.Feedback type="invalid">
                                        <FormLabel >First Name must be between 1 to 30 character</FormLabel>
                                    </Form.Control.Feedback>
                                </FormGroup>

                                <FormGroup className='col-6'>
                                    <FormLabel>Last Name</FormLabel>
                                    <FormControl
                                        placeholder="Last Name"
                                        value={this.state["lastName"]}
                                        name='lastName' onChange={this.handleChange}
                                        required
                                        maxLength={30} />
                                    <Form.Control.Feedback type="invalid">
                                        <FormLabel >Last Name must be between 1 to 30 character</FormLabel>
                                    </Form.Control.Feedback>
                                </FormGroup>
                            </div>

                            <div className='row'>
                                <FormGroup className='col-2'>
                                    <FormLabel>Country Code</FormLabel>
                                    <FormControl
                                        placeholder="Ex: +91"
                                        value={this.state["countryCode"]}
                                        required
                                        minLength={2}
                                        maxLength={2}
                                        name='countryCode' onChange={this.handleChange} />
                                    <Form.Control.Feedback type="invalid">
                                        <FormLabel >Country Code is required</FormLabel>
                                    </Form.Control.Feedback>
                                </FormGroup>

                                <FormGroup className='col-4'>
                                    <FormLabel>Mobile No</FormLabel>
                                    <FormControl
                                        placeholder="Mobile No"
                                        value={this.state["mobileNo"]}
                                        required
                                        type='text'
                                        pattern="[0-9]{10}"
                                        maxLength={10}

                                        name='mobileNo' onChange={this.handleChange} />
                                    <Form.Control.Feedback type="invalid">
                                        <FormLabel >Mobile No is required</FormLabel>
                                    </Form.Control.Feedback>
                                </FormGroup>


                                <FormGroup className='col-6'>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl
                                        type='email'
                                        placeholder="abc@abc.com"
                                        value={this.state["email"]}
                                        required
                                        name='email' onChange={this.handleChange} />
                                    <Form.Control.Feedback type="invalid">
                                        <FormLabel >Email is required</FormLabel>
                                    </Form.Control.Feedback>
                                </FormGroup>
                            </div>

                            <div className='row'>
                                <FormGroup className='col-4'>
                                    <FormLabel>Order Id</FormLabel>
                                    <FormControl
                                        placeholder="Order Id"
                                        required
                                        minLength={5}
                                        maxLength={30}
                                        // value={weight}
                                        name='orderId' onChange={this.handleChange} />
                                    <Form.Control.Feedback type="invalid">
                                        <FormLabel >Order Id must be between 5 to 30 character</FormLabel>
                                    </Form.Control.Feedback>
                                </FormGroup>

                                <FormGroup className='col-4'>
                                    <FormLabel>Model No.</FormLabel>
                                    <FormControl
                                        placeholder="Model No"
                                        // value={weight}
                                        required
                                        name='modelNo' onChange={this.handleChange} />
                                    <Form.Control.Feedback type="invalid">
                                        <FormLabel >Model No is required</FormLabel>
                                    </Form.Control.Feedback>
                                </FormGroup>
                                <FormGroup className='col-4'>
                                    <FormLabel>Delivery Date</FormLabel>
                                    <DatePicker onChange={(val) => this.handleDate(val, 'deliveryDate')} />
                                    {validated && !this.state["deliveryDate"] && < Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                        <FormLabel >Delivery Date is required</FormLabel>
                                    </Form.Control.Feedback>}
                                </FormGroup>
                            </div>


                            <div className='row'>
                                <FormGroup className='col-6'>
                                    <FormLabel>Address</FormLabel>
                                    <FormControl
                                        placeholder="Address"
                                        // value={age}
                                        required
                                        maxLength={150}
                                        name='address' onChange={this.handleChange} />
                                    <Form.Control.Feedback type="invalid">
                                        <FormLabel >Address  must be between 1 to 150 character</FormLabel>
                                    </Form.Control.Feedback>
                                </FormGroup>

                                <FormGroup className='col-6'>
                                    <FormLabel>Street</FormLabel>
                                    <FormControl
                                        placeholder="Street"
                                        required
                                        maxlLength={50}
                                        // value={weight}
                                        name='mailingStreet' onChange={this.handleChange} />
                                    <Form.Control.Feedback type="invalid">
                                        <FormLabel >Street must be between 1 to 30 character</FormLabel>
                                    </Form.Control.Feedback>
                                </FormGroup>   
                                
                            </div>

                            <div className='row'>                                 

                                <FormGroup className='col-4'>
                                    <FormLabel>State</FormLabel>  
                                    <Select
                                        className='cm_min_width'
                                        onChange={(e) => this.handleSteteChange(e.value, "mailingState", 0)}
                                        placeholder="Select State"
                                        options={allstates}
                                        value={this.state.mailingState?allstates.find(element => element.value === this.state.mailingState):null}
                                    />

                                    {validated && !this.state.mailingState && <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                    <FormLabel >State is required</FormLabel>
                                    </Form.Control.Feedback> }
                                </FormGroup>

                                <FormGroup className='col-4'>
                                    <FormLabel>City</FormLabel>  
                                    <Select
                                        className='cm_min_width'
                                        onChange={(e) => this.handleCityChange(e.value, "mailingCity")}
                                        placeholder="Select City"
                                        options={citys}
                                        value={this.state.mailingCity?citys.find(element => element.value === this.state.mailingCity):null}
                                    />

                                    {validated && !this.state.mailingCity && <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                    <FormLabel >City is required</FormLabel>
                                    </Form.Control.Feedback> }
                                </FormGroup>

                                <FormGroup className='col-4'>
                                    <FormLabel>Pincode</FormLabel>
                                    <Select
                                        className='cm_min_width'
                                        onChange={(e) => this.handlePincodeChange(e.value, "pincode")}
                                        placeholder="Select Pincode"
                                        options={pincodes}
                                        value={this.state.pincode?pincodes.find(element => element.value === this.state.pincode):null}
                                    />
       
                                    {validated && !this.state.pincode && <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                    <FormLabel >Pincode is required</FormLabel>
                                    </Form.Control.Feedback> }
                                </FormGroup>
                            </div>

                            

                            <div className='row'>
                                <FormGroup className='col-6'>
                                    <FormLabel>Remark</FormLabel>
                                    <FormControl
                                        placeholder="Remark"
                                        // value={weight}
                                        name='remark' onChange={this.handleChange} />
                                </FormGroup>
                                <FormGroup className='col-6'>
                                    <FormLabel>Service Type</FormLabel>
                                    <Select
                                        isSearchable
                                        options={ServiceTypes}
                                        placeholder="Select service.."
                                        onChange={(val) => this.handleServiceTypeSelector(val)}
                                    />
                                    {validated && (!Object.keys(this.state["selectedService"]).length && !this.state["selectedService"].length) && <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                        <FormLabel >Service Type is required</FormLabel>
                                    </Form.Control.Feedback>}

                                </FormGroup>
                            </div>
                            <div className='row'>
                                <FormGroup className='col-6'>
                                    <FormLabel>Vertical</FormLabel>
                                    <FormControl
                                        placeholder="Vertical"
                                        required
                                        name='vertical' onChange={this.handleChange} />
                                    {validated && <Form.Control.Feedback type="invalid">
                                        <FormLabel >Vertical  must be between 1 to 150 character</FormLabel>
                                    </Form.Control.Feedback>}
                                </FormGroup>

                                <FormGroup className='col-6'>
                                    <FormLabel>Company</FormLabel>
                                    <FormControl
                                        placeholder="Company"
                                        required
                                        name='company' onChange={this.handleChange} />
                                    {validated && <Form.Control.Feedback type="invalid">
                                        <FormLabel >Company  must be between 1 to 150 character</FormLabel>
                                    </Form.Control.Feedback>}
                                </FormGroup>
                            </div>

                            <div className='row'>
                                <FormGroup className='col-6'>
                                    <FormLabel>Product Website</FormLabel>
                                    <Select
                                        isSearchable
                                        options={sourceFilterOptions.filter(item => item.value || item.value === 0)}
                                        placeholder="productWebsite.."
                                        onChange={(val) => this.handleSourceSelector(val)}
                                    />
                                    {validated && (!Object.keys(this.state["sourceWebsite"]).length && !this.state["sourceWebsite"].length) && <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                        <FormLabel >Product Website is required</FormLabel>
                                    </Form.Control.Feedback>}

                                </FormGroup>
                            </div>
                            <Row className="justify-content-center">
                                <Button variant="danger" onClick={this.closeModal}>Cancel</Button>
                                <Button disabled={this.state["isSubmitClicked"]} className="ml-2" variant="success" type="submit">Submit</Button>
                            </Row>

                        </Form>
                    </Modal.Body>
                </Modal>
                <PopUpModal isOpen={statusPopup} closeModal={this.closeStatusPopup} submit={this.changeRequestStaus} message={`Are you sure to change the status of request`} submitTitle="Update Status" />
                <PopUpModal 
                    isOpen={markSelfDonePopUP} 
                    closeModal={this.selfMarkPopup} 
                    submit={this.markSelfDone} 
                    message={this.state.selfDone?`Are you sure to mark to self done`:`Are you sure to remove the self-done for this customer?`} 
                    submitTitle="Ok" 
                />
                <PopUpModal isOpen={this.state["showHideExport"]} closeModal={() => this.setState({ showHideExport: false })} submit={this.exportHandler} message={`Are you sure to export`} submitTitle="Export" />
                <PopUpModal isOpen={assignAgentPopup} closeModal={() => this.setState({ assignAgentPopup: false })} submit={this.assignRequestToAgent} message={`Are you sure to assign selected request`} validationError={validationError} />

            </HOC >
        );
    }
}

export default AdminService;